import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { callApi, showTawkToWidget } from "../../../components/src/Toolkit";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { iDirection, iLanguage } from "../../../components/src/i18n";
import React from "react";
import { getStorageData,setStorageData } from "../../../framework/src/Utilities";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    categorySection: string;
    cardImage: string;
    CardButtonAction: string;
    category_name: string;
    titleName: string;
    linkText: string;
    MainContainer: string;
    title: string;
    subTitle: string;
    CarouselContainer: string;
    carouselDot: string;
    carousel: string;
    CardContainer:string;
    infoCarouselSection:string;
    headerBoxLg:string;
    normalSectionBox:string;
  }
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  carouselBannersData: any;
  showLoader: boolean;
  isHovered: string | null;
  activePage: number;
  categoryArr: Array<{
    name: string;
    path: string;
    backgroundColor: string;
    logo: string;
    color: string;
  }>;
  InfoCarousel: Array<{
    id: string;
    attributes:{
    title: string;
    description: string;
    image: string;
    }
  }>;
  selectedCountry: any;
  tawkToConfig: {
    propertyId: string;
    widgetId: string;
  };
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  mainCarouselCallId: string;
  categoryCallId:string = "";
  tawkToRef: React.RefObject<any>;
  infoCarouselCallId: string;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.mainCarouselCallId = "";
    this.infoCarouselCallId = "";

    this.state = {
      showLoader: false,
      carouselBannersData: [],
      isHovered: null,
      activePage: 0,
      categoryArr: [],
      InfoCarousel: [],
      selectedCountry: {},
      tawkToConfig: {
        propertyId: "",
        widgetId: ""
      }
    };
    this.tawkToRef = React.createRef();
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const selectedCountry = await getStorageData('selectedCountry', true);
    const categoryArr = await getStorageData('category',true)
    this.setState({
      selectedCountry,
      categoryArr : categoryArr || []
     })
    if(selectedCountry){
    this.getCarouselBanner();
    this.setState({
      tawkToConfig: {
        propertyId: selectedCountry.attributes.property_id,
        widgetId:
          iLanguage === "en"
            ? selectedCountry.attributes.widgetid_en
            : selectedCountry.attributes.widgetid_ar
      }
    });
    // if chat is already configured
    showTawkToWidget();
    this.getInfoBanner();
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson != null) {
        if (!responseJson.errors) {
          if (
            apiRequestCallId === this.mainCarouselCallId &&
            responseJson.data
          ) {
            this.setState({ carouselBannersData: responseJson.data });
          } else if (apiRequestCallId === this.infoCarouselCallId && responseJson.data) {
            this.setState({ InfoCarousel : responseJson.data });
          }
        }
      }
      this.setState({ showLoader: false });
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  

  handleMouseEvent = (itemId: string | null) => {
    this.setState({ isHovered: itemId });

  };

  handlePageChange = (pageNumber: number) => {
    this.setState({ activePage: pageNumber });
  };

  getCarouselBanner = () => {
    this.mainCarouselCallId = callApi(
      {
        contentType: configJSON.jsonApiContentType,
        method: configJSON.GET,
        endPoint: configJSON.carouselEndPoint + `?language_code=${iLanguage}&country_id=${this.state.selectedCountry.id}`,
        headers: {}
      },
      runEngine
    );
  };

  onTawkToLoad = async () => {
    const userInformation = await getStorageData("user", true);
    if (userInformation) {
      this.tawkToRef.current.setAttributes({
        name: userInformation.attributes.full_name,
        email: userInformation.attributes.email
      });
    }
  };
  getInfoBanner = () => {
    this.infoCarouselCallId = callApi(
      {
        contentType: configJSON.jsonApiContentType,
        method: configJSON.GET,
        endPoint: configJSON.infoEndPoint + `?language_code=${iLanguage}`,
        headers: {},
      },
      runEngine
    );
  };

  getPageScrollerHeight = ():string => {
    const height = window.innerHeight;
    if(height >= 800){
      return "84vh"
    }else if(height <= 680 && height > 620){
      return "78vh"
    }
    return "80vh"
  }
  getCarouselSetting = (dataLength:number) => {
    const settings = {
      dots: true,
      infinite: true,
      initialSlide:0,
      speed: 500,
      slidesToShow: dataLength < 4 ? dataLength : 4,
      slidesToScroll: 1,
      arrow: true,
      prevArrow: iDirection === 'rtl' ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />,
      nextArrow: iDirection === 'rtl' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />,
      rtl: iDirection === 'rtl',
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: dataLength < 3 ? dataLength : 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 1010,
          settings: {
            slidesToShow: dataLength < 2 ? dataLength : 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    };
    return settings;
  }
  getCategories = () => {
    const { categoryArr } = this.state;
    const rtlCategories = [...categoryArr].reverse()
    return iDirection === "rtl" ? rtlCategories : categoryArr
  }
  // Customizable Area End
}
