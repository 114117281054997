import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  withStyles, 
  Theme
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { flexCenter } from "../../../components/src/CommonStyles"
import { deviceBasedDynamicDimension } from "../../../components/src/Toolkit";
import TableOfContentWeb from "../../../components/src/TableOfContent.web";
import Spinner from "../../../components/src/Spinner.web";
import { t } from "../../../components/src/i18n";
import PolicyContentWeb from "../../../components/src/PolicyContent.web";
import SearchEngineOptimisationseo from "../../SearchEngineOptimisationseo/src/SearchEngineOptimisationseo.web";
// Customizable Area End

import TermsAndConditions3Controller, {
  Props,
  configJSON,
} from "./TermsAndConditions3Controller";

export default class TermsAndConditions3 extends TermsAndConditions3Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box className={this.props.classes?.flexCenter}>
        <SearchEngineOptimisationseo
        title={t("termsAndConditions.title")!}
        metaTags={[{
          name:'description',
          content:this.state.termsAndConditions?.[0]?.description
        },{
          name:"keywords",
          content: t("seo.keywords")
        }]}
        navigation={this.props.navigation} id={this.props.id} />
        <Spinner showLoader={this.state.isLoading}/>
        <Grid container className={this.props.classes?.MainContainer}>
          <Grid xs={12} item className={`${this.props.classes?.flexCenter} ${this.props.classes?.titleGrid}`}>
            <Typography component={'h1'} className={this.props.classes?.titleText} data-test-id="title">{t("termsAndConditions.title")}</Typography>
          </Grid>
          <Grid item xs={12} md={4} className={this.props.classes?.tocGridItem}>
            {/* Table of Content */}
            <TableOfContentWeb tableContent={this.state.tableOfContentTnC} onItemClick={this.handleOnItemClickTnc}/>
          </Grid>
          <Grid item xs={12}  md={8}>
            {/* Terms and Conditions */}
            <PolicyContentWeb setDescriptionRef={this.setTncRef} policyContent={this.state.termsAndConditions}/>
          </Grid>
          {/* Privacy Policy */}
          <Grid xs={12} item className={`${this.props.classes?.flexCenter} ${this.props.classes?.titleGrid} PrivacyPolicy`} ref={this.privacyPolicyRef}>
            <Typography component={'h1'} className={this.props.classes?.titleText} data-test-id="titlePrivacyPolicy">{t("privacyPolicy.title")}</Typography>
          </Grid>
          <Grid item xs={12} md={4} className={this.props.classes?.tocGridItem}>
            {/* Table of Content */}
            <TableOfContentWeb tableContent={this.state.tableOfContentPP} onItemClick={this.handleOnItemClickPP}/>
          </Grid>
          <Grid item xs={12}  md={8}>
            {/* Terms and Conditions */}
            <PolicyContentWeb setDescriptionRef={this.setPPRef}policyContent={this.state.privacyPolicies}/>
          </Grid>
        </Grid>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const temsAndConditionStyle = (theme: Theme) => ({
  MainContainer: {
    minHeight: "70vh",
    maxWidth: "1400px",
    padding:64,
    [theme.breakpoints.down('xs')]:{
      padding:24,
    }
  },
  flexCenter: flexCenter,
  titleGrid:{
    marginTop:32,
    marginBottom:64,
    "&.PrivacyPolicy":{
      marginTop:64,
    }
  },
  titleText: {
    color: "rgba(0, 77, 116, 1)",
    fontFamily: "'Manrope', sans-serif",
    fontStyle: "normal",
    fontWeight: "bold" as const,
    fontSize: deviceBasedDynamicDimension(48, true, 1),
    lineHeight: 1,
    letterSpacing: 1.5,
    textDecoration:'underline' as const,
    textDecorationThickness:3,
    [theme.breakpoints.down('xs')]:{
      fontSize:deviceBasedDynamicDimension(24, true, 1),
    }
  },
  tocGridItem:{
    position:'relative' as const
  }
});
const TermsAndConditionsPage = withStyles(temsAndConditionStyle)(TermsAndConditions3)
export { TermsAndConditionsPage, TermsAndConditions3 }
// Customizable Area End
