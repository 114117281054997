import React from "react";
// Customizable Area Start
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Box, Button, IconButton, Drawer, List, ListItem, Hidden, makeStyles, Theme, withStyles } from "@material-ui/core";
import {Link} from "react-router-dom"
import MenuIcon from "@material-ui/icons/Menu";
import { cartIcon, logo, userIcon } from "./assets";
import LanguageSupport from "../../blocks/LanguageSupport/src/LanguageSupport.web";
import {iDirection, t, iLanguage} from "./i18n";
// Customizable Area End

import HeaderController, {
    Props
} from "./HeaderController.web";

// Customizable Area Start
const cartButtonStyle = makeStyles((theme: Theme) => ({
    CartIconButton: {
        padding: 0
    },
    ShoppingCartInfo: {
        position: 'relative',
        "& .CartIconBox": {
            height: 54,
            width: 40,
            display: 'flex',
            alignItems: 'center',
        },
        "& .CartCount": {
            height: 14,
            width: 14,
            backgroundColor: theme.palette.primary.main,
            borderRadius: 50,
            position: 'absolute',
            top: 0,
            right: 0,
            paddingBottom: 2,
            "& .count": {
                color: theme.palette.common.white,
                fontSize: 12,
            }
        },
        "& .CartIcon": {
            height: 28,
            width: 36,
            objectFit: 'contain' as const,
        }
    },
}))
export const CartButton = (props: { onCartClick: () => void, cartCount: number }) => {
    const classes = cartButtonStyle();
    return (
        <IconButton
            color="inherit"
            aria-label="Shopping Cart"
            className={classes.CartIconButton}
            onClick={props.onCartClick}>
            <Box className={classes.ShoppingCartInfo}>
                <Box className="CartCount">
                    <Typography className="count">
                        {props.cartCount}
                    </Typography>
                </Box>
                <Box className="CartIconBox">
                    <img src={cartIcon} className="CartIcon" alt="Cart" />
                </Box>
            </Box>
        </IconButton>
    );
}

export const headerStyles = (theme: Theme) => ({
    FirtsToolBar: {
        display: "flex",
        justifyContent: "flex-end",
        minHeight: 48,
        "& .FirtsToolBarButtonText": {
            borderBottom: `1px solid ${theme.palette.text.primary}`,
            fontSize: "16px",
            color: theme.palette.text.primary,
            fontWeight: 400,
            textTransform: "initial" as const,
            display: "flex",
            alignItems: "center",
            padding: "4px 0px"
        },
        "& .Divider": {
            fontSize: 10,
            margin: "0px 6px"
        },
        "& .Flag": {
            height: 18,
            width: 32,
            margin: "0px 4px",
            objectFit: 'contain' as const
        }
    },
    ToolbarSecondary: {
        display: "flex",
        justifyContent: "flex-start",
        minHeight: "0px !important",
        width: "100%",
        "& .ItemText": {
            lineHeight: "32px",
            color: 'rgba(0, 77, 116, 1)',
            fontWeight: 600,
            opacity: '0.9',
            textAlign: "center" as const,
            textDecoration:"none",
            padding: "4px 24px",
            alignItems: 'center',
            fontFamily:'Manrope',
            borderRadius: '6px',
            background:"transparent",
            fontSize: '24px'
        },
        "& .ToggleButton": {
            height: "42px",
            color: theme.palette.text.primary
        },
    },
    LoginHeaderDrawer: {
        "& .ItemText": {
            color: "#004D74"
        }
    },
    "@media (min-width: 1080px)": {
        DesktopHeader: {
            "& .LeftHeaderMenu": {
                gap:"24px"
            },
        },
      },
    DesktopHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between" as const,
        width: "100%",
        "& .LeftHeaderMenu": {
            display: "flex",
            alignItems: "center",
        },
        "& .RightHeaderMenu": {
            display: "flex",
            alignItems: "center",
            "& .HowItWorkButton": {
                border: "1px solid #004d74",
                height: "42px"
            },
            "& .PersonButton": {
                border: "1px solid #004d74",
                borderRadius: "6px",
                height: "42px",
                width: "42px",
                margin: "0px 12px",
                "& .PersonIcon": {
                    height: "26px",
                    width: "26px"
                },
            },
        },
    },
    HomeLink:{ 
        cursor: "pointer",
        "&.mobile":{
            margin:'auto'
        }
    },
    HeaderLogo: {
        height: "79px",
        width: "79px",
        objectFit: "contain" as const,
        margin: "0px 24px"
    },
    MobileHeaderLogo: {
        height: "79px",
        width: "79px",
        objectFit: "contain" as const,
        margin: "24px 0px"
    },
    HowItWorkText: {
        fontSize: "14px",
        color: "#004d74",
        fontWeight: 400,
        fontFamily: "Inter",
        textAlign: "center" as const,
        textTransform: "initial" as const
    },
    HeaderDrawer: {
        "& .MuiDrawer-paper": {
            minWidth: "180px"
        },
        "& .ItemText": {
            lineHeight: "32px",
            color: "#697479",
            fontWeight: 500,
            fontFamily: "Inter",
            textAlign: "center" as const,
            margin: "8px 0px",
            padding: "10px",
            alignItems: 'center',
            background: 'transparent',
            width: '165px',
            borderRadius: '6px',
            fontSize: '24px',
            textDecoration: 'none',
        }
    },
    ElectronicsTab: {
        background: 'linear-gradient(45deg, #20A2C1, #004D74)',
        padding: "3px 10px",
        fontSize:"15px",
        color:'#fff !important',
        borderRadius:"10px",
      },
      SportTab : {
        background: 'linear-gradient(45deg, #F96758, #EE821A)',
        padding: "3px 10px",
        fontSize:"15px",
        color:'#fff !important',
        borderRadius:"10px",
      },
      FurnitureTab: {
        background: 'linear-gradient(45deg, #61D49B, #008443)',
        padding: "3px 10px",
        fontSize:"15px",
        borderRadius:"10px",
        color:'#fff !important'
      },
      FashionTab: {
        background: 'linear-gradient(45deg, #7D7CF9, #855DD1)',
        padding: "3px 10px",
        fontSize:"15px",
        color:'#fff !important',
        borderRadius:"10px",
      },
      "@media (min-width: 1280px) and (max-height: 620px)": {
        HeaderLogo: {
            height:64,
            width:64
        },
      },
      root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    mainBox: {
        width: '404px',
        backgroundColor: '#FFFFFF',
        borderRadius: '20px',
        padding: 32,
        [theme.breakpoints.down('xs')]: {
            width: '80%',
            padding: 0,
        }
    },
    OptionBox: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column' as const,
        alignItems: 'center',
        margin: 24,
    },
    ButtonBox: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginBottom: 32,
    },

    CofirmButton: {
        background: '#004D74',
        borderRadius: 50,
        padding: '12px 72px',
        marginTop: 4,
        "&:hover": {
            background: '#004D74',
        },
    },
    ConfirmText: {
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 20,
        lineHeight: 1.5,
        textAlign: 'center' as const,
        textTransform: 'initial' as const,
        color: '#FFFFFF',
    },
    InputField: {
        width: "100%",
        margin: "8px 24px",
        borderRadius: 12,
    },
    InputFieldSelect: {
        width: '100%',
        borderRadius: 12,
        "& .MuiOutlinedInput-input": {
            padding: "14px 0px"
        },
        '@supports (-moz-appearance:none)': {
            "& .RTLFieldset fieldset": {
                direction:'rtl'
            },
        },
    },
    RenderedCountryBox: {
        display: 'flex',
        alignItems: 'center',
        margin: '0px 8px',
        '& img':{
            width:32,
            objectFit:'contain' as const
        },
        "& .CountryName": {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 18,
            lineHeight: 1.5,
            letterSpacing: 0.1878,
            color: '#051720',
            margin: '0px 12px',
        }
    },
    SelectedLanguageBox: {
        margin: '0px 12px',
        display: 'flex',
        "& .LanguageText": {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 18,
            lineHeight: 1.5,
            letterSpacing: 0.1878,
            color: '#051720',
        }
    },
    MenuItemSelect: {
        '& img':{
            width:32,
            objectFit:'contain' as const
        },
        '& .DisplayName': {
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 18,
            lineHeight: 1.5,
            letterSpacing: 0.1878,
            color: '#051720',
            '&.Country': {
                margin: '4px 8px'
            }
        }
    }

})
// Customizable Area End

export class Header extends HeaderController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { cartItemCount, selectedCountry, selectedLanguage,customerLogin,categoryArr,categoryTabHover} = this.state;
        const { id, navigation, classes } = this.props;
        const array = window.location.pathname.split("/");
        const path = array[array.length - 1];
        // const path = window.location.pathname;
        // console.log("context",path)
        // This is static header page, will be changes once designs are approved
        return (
            <>
                <Toolbar className={classes.FirtsToolBar}>
                    <Box>
                        <Button size="small" onClick={this.openLanguageSelectionModal}>
                            <Typography className="FirtsToolBarButtonText">
                                <span>{t("common.language")}</span>:&nbsp;<span>{selectedLanguage.name}</span>
                            </Typography>
                        </Button>
                    </Box>
                    <Typography
                        component="h2"
                        variant="h5"
                        color="inherit"
                        align="center"
                        noWrap
                        className={"Divider"}
                    >
                        {" | "}
                    </Typography>
                    <Box>
                        <Button size="small" onClick={this.openLanguageSelectionModal}>
                            <Typography className="FirtsToolBarButtonText">
                                {t("common.country")}:&nbsp; <img className="Flag" src={selectedCountry?.attributes?.logo} crossOrigin="anonymous" alt={selectedCountry?.attributes?.country_name}/>&nbsp;{iLanguage === 'en' ? selectedCountry?.attributes?.country_name : selectedCountry?.attributes?.country_name_arabic}
                            </Typography>
                        </Button>
                    </Box>
                </Toolbar>
                <Toolbar component="nav" variant="dense" >
                    <Box className={`${classes.ToolbarSecondary} ${customerLogin && classes.LoginHeaderDrawer} `}>

                        {/*** Desktop Header ***/}
                        <Box className={classes.DesktopHeader}>
                            <Box className="LeftHeaderMenu">
                                <Hidden mdUp>
                                    <IconButton
                                        color="inherit"
                                        className="ToggleButton"
                                        aria-label="open drawer"
                                        onClick={this.handleDrawerOpen}
                                        edge="start">
                                        <MenuIcon />
                                    </IconButton>
                                </Hidden>
                                <Hidden smDown>
                                    <Link
                                        to="/"
                                        className={classes.HomeLink}
                                    >
                                        <img className={classes.HeaderLogo} src={logo} alt="Logo Img" crossOrigin="anonymous" />
                                    </Link>
                                    {categoryArr && categoryArr.length > 0 && categoryArr.map((item:any) => (
                                     <Link key={item.attributes.id} to={`/Category/${item.attributes.id}`} onMouseEnter={() => this.setState({categoryTabHover: item.attributes.id})} onMouseLeave={() => this.setState({categoryTabHover: ''})} className={`ItemText`} style={path === `${item.attributes.id}` || categoryTabHover === item.attributes.id ? {background: item.attributes.bg_color,color:"#fff"} : {} }>
                                         {item.attributes.name}
                                     </Link>
                                    ))}
                                    <Link to="/ShoppingCartOrders" className="ItemText">
                                        <CartButton onCartClick={this.onCartClick} cartCount={cartItemCount} />
                                    </Link>
                                </Hidden>
                            </Box>
                            <Box className="RightHeaderMenu">
                                <Hidden mdUp>
                                <Link to="/ShoppingCartOrders" className="ItemText">
                                        <CartButton onCartClick={this.onCartClick} cartCount={cartItemCount} />
                                    </Link>
                                </Hidden>
                                <Hidden smDown>
                                    <Button size="small" variant="outlined" className="HowItWorkButton" onClick={this.navigateToHowItWorks}>
                                        <Typography className={classes.HowItWorkText}>
                                            {t("header.how_it_work")}
                                        </Typography>
                                    </Button>
                                </Hidden>
                                <IconButton className="PersonButton" onClick={customerLogin ? this.goToUserProfile : this.handleUser}>
                                    <img src={userIcon} className="PersonIcon" alt="User" />
                                </IconButton>
                            </Box>
                        </Box>

                        {/*** mobile Header ***/}
                        <Hidden mdUp>
                            <Drawer
                                dir={iDirection}
                                className={classes.HeaderDrawer}
                                variant="temporary"
                                anchor="left"
                                hideBackdrop={false}
                                transitionDuration={500}
                                open={this.state.drawerStatus}
                                onClose={this.handleDrawerClose}
                            >
                                <List>
                                    <ListItem>
                                        <Link
                                         to="/"
                                         className={`${classes.HomeLink} mobile`}
                                        >
                                            <img className={classes.MobileHeaderLogo} src={logo} alt="Logo Img" crossOrigin="anonymous"/>
                                        </Link>
                                    </ListItem>
                                    {categoryArr && categoryArr.length > 0 && categoryArr.map((item:any) => (
                                    <ListItem key={item.id} button onMouseEnter={() => this.setState({categoryTabHover: item.attributes.id})} onMouseLeave={() => this.setState({categoryTabHover: ''})}>
                                            <Link to={`/Category/${item.attributes.id}`} className={`ItemText`} style={path === `${item.attributes.id}` || categoryTabHover === item.attributes.id ? {background: item.attributes.bg_color,color:"#fff"} : {} }>
                                                {item.attributes.name}
                                            </Link>
                                    </ListItem>
                                    ))}
                                    <ListItem button>
                                            <Link to={`/HowItWorks`} className={`ItemText`} style={{background: "#e3eef2"}}>
                                            {t("header.how_it_work")}
                                            </Link>
                                    </ListItem>
                                </List>
                            </Drawer>
                        </Hidden>
                    </Box>

                </Toolbar>
                <LanguageSupport isModalOpen={this.state.isLanguageModalOpen} classes={classes} id={id} navigation={navigation} />
            </>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
export default withStyles(headerStyles)(Header);
// Customizable Area End