import React from 'react'
// Customizable Area Start
import { Box, Typography, Button, withStyles, Theme } from '@material-ui/core'
import { t } from '../../../components/src/i18n';

import RegisterButtonController, {
    Props
} from "./RegisterButtonController.web";
// Customizable Area End


// Customizable Area Start
// Customizable Area End

export class RegisterButton extends RegisterButtonController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
        this.state = {
            // Customizable Area Start
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        // Customizable Area Start
        const {classes} = this.props;
        return (
            <Box className={classes?.ButtomBox}>
                <Typography className="DontAccountText">{t("login.dontHaveAccount")}</Typography>
                <Button
                    data-testId="RegisterButton"
                    variant="text"
                    className="SignUpButton"
                    onClick={this.props.onRegisterNowClick}
                >
                    <Typography className="SignUpText">
                        {t("common.registerNow")}
                    </Typography>
                </Button>
            </Box>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
const registerButtonStyle = (theme:Theme) => ({
    ButtomBox:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 32,
        flexWrap:'wrap' as const,
        "& .DontAccountText":{
            fontFamily: "'Poppins', sans-serif",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 20,
            lineHeight: 1,
            textAlign: "center",
            color: "#000000",
            whiteSpace: "nowrap",
            [theme.breakpoints.down('sm')]:{
                fontSize: 16
            }
        },
        "& .SignUpButton":{
            marginLeft: 8,
            marginRight: 8,
            "& .SignUpText":{
                fontFamily: "'Poppins', sans-serif",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: 20,
                lineHeight: 1,
                textAlign: "center",
                color: "#004D74",
                textTransform: 'initial',
                whiteSpace: "nowrap",
                [theme.breakpoints.down('sm')]:{
                    fontSize: 16
                }
            }
        }
    }
});
export default withStyles(registerButtonStyle)(RegisterButton)
// Customizable Area End
