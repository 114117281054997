import React, { Component } from 'react';
import { Box, Typography, Button, Modal, Theme, withStyles, WithStyles } from '@material-ui/core';

interface Props extends WithStyles {
    isOpen: boolean;
    title: string;
    subTitle: string;
    confirmBtnText: string;
    onConfirm: () => void;
}

interface S {

}

interface SS {

}
class MessageModal extends Component<Props, S, SS> {


    render() {
        const { isOpen, classes, title, subTitle, confirmBtnText, onConfirm } = this.props
        return (
            <Modal
                open={isOpen}
                className={classes.root}
            >
                <Box className={classes.mainBox}>
                    <Box className={classes.TitleBox}>
                        <Typography className={'Title'}>{title}</Typography>
                        <Typography className={'SubTitle'}>{subTitle}</Typography>
                    </Box>
                    <Box className={classes.ButtonBox}>
                        <Button
                            variant="contained"
                            color="primary"
                            className="CofirmButton"
                            data-testId="ConfirmBtn"
                            onClick={onConfirm}
                        >
                            <Typography className="ConfirmText">
                                {confirmBtnText}
                            </Typography>
                        </Button>
                    </Box>

                </Box>
            </Modal>
        );
    }
}

const style = (theme: Theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center' as const,
        justifyContent: 'center'
    },
    mainBox: {
        width: '498px',
        backgroundColor: '#FFFFFF',
        borderRadius: '20px',
        [theme.breakpoints.down('xs')]: {
            width: '80%'
        }
    },
    TitleBox: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column' as const,
        alignItems: 'center' as const,
        marginTop: 42,
        "& .Title": {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: 36,
            lineHeight: 1.5,
            color: '#35414F',
            padding: "0px 32px",
            textAlign: 'center'
        },
        "& .SubTitle": {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 20,
            lineHeight: 1.5,
            color: '#747A80',
            padding: "0px 64px",
            textAlign: 'center',
            marginTop: 16
        }
    },
    ButtonBox: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginBottom: 32,
        "& .CofirmButton": {
            background: theme.palette.primary.main,
            borderRadius: 50,
            padding: '12px 72px',
            marginTop: 32,
            [theme.breakpoints.down(425)]:{
                padding: '12px 42px'
            },
            "&:hover": {
                background: theme.palette.primary.main,
            },
            "& .ConfirmText": {
                fontFamily: 'Manrope',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: 20,
                lineHeight: 1.5,
                textAlign: 'center',
                textTransform: 'initial' as const,
                color: '#FFFFFF',
                whiteSpace:'nowrap' as const
            }
        }
    }

})

export default withStyles(style)(MessageModal);