import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { callApi, hideTawkToWidget } from "../../../components/src/Toolkit";
import { iLanguage } from "../../../components/src/i18n";
export const configJSON = require("./config");
// Customizable Area End

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading:boolean;
  content:{
    description:string;
    details:Array<{
      image:string,
      title:string,
      description:string
    }>
  }
  
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class HowItWorksController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetHowItWorks: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      isLoading:false,
      content:{
        description:'',
        details:[]
      }

    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getHowItWorksContent();
    hideTawkToWidget();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson != null) {
        if (!responseJson.errors) {
          if (apiRequestCallId === this.apiGetHowItWorks) {
              this.handleContentResponse(responseJson);
          }
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      }
      this.setLoadingState(false);
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleContentResponse = (responseJson:any) => {
    if(responseJson.data){
      this.setState({
        content:responseJson.data?.attributes,
      });
    }
  }
  setLoadingState = (state:boolean) => {
    this.setState({
      isLoading:state
    })
  }
  getHowItWorksContent = () => {
    this.setLoadingState(true);
    this.apiGetHowItWorks = callApi({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.howItWorksApiEndPoint}?language_code=${iLanguage}`,
      headers: {}
    }, runEngine);
  }
  // Customizable Area End
}
