// Customizable Area Start
import React from 'react'
import { Hidden, Box, Typography, Grid, Button, InputAdornment, IconButton, Modal, makeStyles } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
// Customizable Area End

// Customizable Area Start
interface TitleProps {
    title: string;
    subTitle: string;
    containerClass?: string;
    titleClass?: string;
    subTitleClass?: string;
}
interface SubmitBtnProps {
    submitText: string;
    onSubmit: (event: any) => void
}
interface HelperBtnProps {
    disabled: boolean;
    onClick: (event: any) => void;
    helperText: string;
    buttonText: string;
}
// Customizable Area End

// Customizable Area Start
export const TitleContainer = ({ title, subTitle, containerClass, titleClass, subTitleClass }: TitleProps) => {
    return (
        <Box className={`${containerClass ? containerClass : "TitleContainer"}`}>
            <Typography className={`${titleClass ? titleClass : "FPTitle"}`}>{title}</Typography>
            <Typography className={`${subTitleClass ? subTitleClass : "FPSubTitle"}`}>{subTitle}</Typography>
        </Box>
    )
}

export const SubmitButton = ({ submitText, onSubmit }: SubmitBtnProps) => {
    return (
        <Grid container className="FPSubmitBtnGrid">
            <Grid item xs={12} className="SubmitBtnGridItem">
                <Button
                    variant="contained"
                    color="primary"
                    className="SubmitBtn"
                    onClick={onSubmit}
                    data-testId="SubmitBtn"
                >
                    <Typography className="SubmitBtnText">
                        {submitText}
                    </Typography>
                </Button>
            </Grid>
        </Grid>
    )
}

export const HelperBtnGroup = ({ disabled, onClick, helperText, buttonText }: HelperBtnProps) => {
    return (
        <Box className="FPGoBackBox">
            <Typography className="GobackText">{helperText}</Typography>
            <Button
                variant="text"
                disabled={disabled}
                className="HelperButton"
                onClick={onClick}
                data-testId="HelperButton"
            >
                <Typography className="ButtonText">
                    {buttonText}
                </Typography>
            </Button>
        </Box>)
}

export const LeftImageCard = (props: { image: string }) => {
    return (
        <Hidden smDown>
            <Grid item md={6} className="FPLIGrid" >
                <img src={props.image} className="FPLI" />
            </Grid>
        </Hidden>
    )
}

export const PasswordVisibility = (props: { toggleVisibility: () => void, visibility: boolean }) => {
    return (<InputAdornment position="end">
        <IconButton
            onClick={props.toggleVisibility}
            data-testId="passwordToggleBtn"
        >
            {props.visibility ? (
                <VisibilityOutlinedIcon htmlColor="#004D74" />
            ) : (
                <VisibilityOffOutlinedIcon htmlColor="#004D74" />
            )}
        </IconButton>
    </InputAdornment>);
}

export const PasswordErrorChecks = (props: { condition: boolean, message: string }) => {
    return (
        <Box className="PasswordChecksBox">
            {props.condition ? <CheckCircleOutlineIcon htmlColor="#008443" /> : <HighlightOffIcon htmlColor="red" />}
            <Typography className={`PasswordMessage ${!props.condition && 'InvalidPassword'}`}>{props.message}</Typography>
        </Box>
    );
}
const messageModalStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    mainBox: {
        width: '498px',
        backgroundColor: '#FFFFFF',
        borderRadius: '20px',
        [theme.breakpoints.down('xs')]: {
            width: '80%'
        }
    },
    TitleBox: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 42,
        "& .Title": {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: 36,
            lineHeight: 1.5,
            color: '#35414F',
            padding: "0px 32px",
            textAlign:'center'
        },
        "& .SubTitle": {
            fontFamily: 'Manrope',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 20,
            lineHeight: 1.5,
            color: '#747A80',
            padding: "0px 32px",
            textAlign: 'center'
        }
    },
    ButtonBox:{
        display:'flex',
        justifyContent:'center',
        width:'100%',
        marginBottom:32,
        "& .CofirmButton": {
            background: '#004D74',
            borderRadius: 50,
            padding: '12px 72px',
            marginTop: 16,
            "&:hover":{
                background: '#004D74',
            },
            "& .ConfirmText": {
                fontFamily: 'Manrope',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: 20,
                lineHeight: 1.5,
                textAlign: 'center',
                textTransform: 'initial',
                color: '#FFFFFF',
            }
        }
    }

}));
export const MessageModal = (props: { isOpen: boolean, title: string, subTitle: string, okBtnText: string, onClickOk: () => {} }) => {
    const classes = messageModalStyles();
    return (
        <Modal
            open={props.isOpen}
            className={classes.root}
        >
            <Box className={classes.mainBox}>
                <Box className={classes.TitleBox}>
                    <Typography className={'Title'}>{props.title}</Typography>
                    <Typography className={'SubTitle'}>{props.subTitle}</Typography>
                </Box>
                <Box className={classes.ButtonBox}>
                    <Button
                        variant="contained"
                        color="primary"
                        className="CofirmButton"
                        data-testId="ConfirmBtn"
                        onClick={props.onClickOk}
                    >
                        <Typography className="ConfirmText">
                            {props.okBtnText}
                        </Typography>
                    </Button>
                </Box>

            </Box>
        </Modal>
    );
}
// Customizable Area End
