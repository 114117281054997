import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { iLanguage } from "../../../components/src/i18n";
import { callApi } from "../../../components/src/Toolkit";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  isModalOpen?: boolean;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  countriesData: Array<{
    id:string | number,
    attributes:{
        country_name:string,
        country_name_arabic: string,
        logo:string,
    }
  }>;
  showLoader: boolean;
  selectedCountry: string;
  countryError: boolean;
  selectedLanguage: string;
  languageError: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class LanguageSupportController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetCountries: string = "";
  categoryCallId: string = "";
  refreshPrevCountryCartDataAPICall : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
        countriesData: [],
        showLoader: false,
        selectedCountry: '',
        countryError: false,
        selectedLanguage: iLanguage,
        languageError: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (responseJson != null) {
          if (!responseJson.errors) {
            switch(apiRequestCallId) {
              case this.apiGetCountries:
                await this.handleCountriesData(responseJson);
                break;
              case this.categoryCallId:
                this.handleCategoryApiResponse(responseJson)
                break;
            }
          } else {
            this.setLoading(false);
            this.parseApiCatchErrorResponse(errorReponse);
          }
          this.setLoading(false);
      }
  }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.isModalOpen !== this.props.isModalOpen) {
      if (this.props.isModalOpen) {
        this.getCountriesData()
      }
    }
  }
  refreshPreviousCountryCart = (countryId: any, token: any) => {
    this.refreshPrevCountryCartDataAPICall = callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.deleteMethod,
      endPoint: configJSON.refreshCartEndPoint,
      headers: { token },
      body: JSON.stringify({ country_id: countryId })
    }, runEngine);
  }

  handleCategoryApiResponse = async(responseJson:any) => {
    await setStorageData('category', JSON.stringify(responseJson.data));
    const prevCountry = await getStorageData('selectedCountry', true);
    const { selectedCountry, selectedLanguage } = this.state;
    await setStorageData('selectedLanguage', selectedLanguage);
    await setStorageData('selectedLanguageDetail', JSON.stringify(this.getSelectedLanguage(selectedLanguage)));
    await setStorageData('selectedCountry', JSON.stringify(this.getSelectedCountry(selectedCountry)));
    if(prevCountry?.id !== this.state.selectedCountry){
        const token = await getStorageData('token');
        if(token){
            this.refreshPreviousCountryCart(this.state.selectedCountry,token);
            await removeStorageData("storedCart");
            if(window.location.pathname.includes("Category")){
            this.props.navigation.navigate('Home');
            }
        }else{
            await removeStorageData("storedCart");
            if(window.location.pathname.includes("Category")){
                this.props.navigation.navigate('Home');
            }
            window.location.reload();
        }
    }  
    window.location.reload();
  }

  handleCountriesData = async (responseJson: any) => {
    const selectedCountry = await getStorageData('selectedCountry', true);
    this.setState({
      countriesData: responseJson.data,
      selectedCountry: selectedCountry ? selectedCountry.id : ''
    }, () => console.log("selectedCountry", this.state.selectedCountry))
  }
  isRequestValid = (): boolean => {
    const { selectedCountry, selectedLanguage } = this.state;
    if (selectedCountry !== '' && selectedLanguage !== '') {
      return true
    }

    this.setState({
      countryError: selectedCountry == '',
      languageError: selectedLanguage == ''
    });
    return false
  }
  getSelectedCountry = (value: string) => {
    return this.state.countriesData.find((country: any) => country.id == value);
  }
  getSelectedLanguage = (value: string) => {
    return configJSON.languageOptions.find((language: any) => language.identifier == value);
  }
  confirmChanges = () => {

    if (this.isRequestValid()) {
      this.getCategoryData(this.state.selectedCountry, this.state.selectedLanguage);

    }
  }

  getCategoryData = (countryID: string, languageCode: string) => {
    this.categoryCallId = callApi(
      {
        contentType: configJSON.jsonApiContentType,
        method: configJSON.GET,
        endPoint: configJSON.categoryEndPoint + `?country_id=${countryID}&language_code=${languageCode}`,
        headers: {},
      },
      runEngine
    );
  }
  setLoading = (isLoading: boolean) => {
    this.setState({ showLoader: isLoading })
  }
  getCountriesData = () => {
    this.setLoading(true);
    this.apiGetCountries = callApi({
      contentType: configJSON.apiContentType,
      method: configJSON.GET,
      endPoint: configJSON.getCountriesListEndPoint,
      headers: {}
    }, runEngine);
  }
  handleCountryChange = (event: React.ChangeEvent<{
    value: unknown;
  }>) => {
    this.setState({
      selectedCountry: event.target.value as string,
    })
  }
  handleLanguageChange = (event: React.ChangeEvent<{
    value: unknown;
  }>) => {
    this.setState({
      selectedLanguage: event.target.value as string,
    })
  }
  // Customizable Area End
}
