Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.apiContentType = 'application/json'
exports.getApiMethod = 'GET'
exports.postApiMethod = 'POST'
exports.putApiMethod = "PUT"

exports.getOrdersApiEndPoint = 'shopping_cart/orders'
exports.orderDetailsEndPoint = "bx_block_order_management/orders/cart";
exports.orderItemsEndPoint = "bx_block_order_management/order_items";
exports.TRANSACTION_FEES = "Transaction Fee"
// Customizable Area End
