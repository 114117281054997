Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.placeHolderMobile = "Mobile";
exports.labelForgotPassword = "Forgot password ?";
exports.btnTxtEmailLogin = "LOGIN WITH EMAIL";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Mobile no. not valid.";
exports.errorTitle = "Error";
exports.labelTitle = "Log in";
exports.loginEndPoint = "bx_block_login/logins";
exports.loginOtpApiEndPoint = "bx_block_login/otp_login";
exports.loginVerifyOtpApiEndPoint = "bx_block_login/verify_login_otp";
exports.loginResendOtpApiEndPoint = "bx_block_login/resend_otp";
exports.POST = "POST";
exports.PATCH = "PATCH";
exports.staticStrings = {
  emptyString:"",
  passwordRequired:"Password is required.",
  password8Digit:"Password must be at least 8 characters"
};
exports.mixPanelUser = "user";
exports.addToCartEndPoint = "bx_block_order_management/orders/add_to_cart";
// Customizable Area End
