// Customizable Area Start
import React from "react";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CircularProgress from "@material-ui/core/CircularProgress";
// Customizable Area End


// Customizable Area Start

// Customizable Area End

interface Props {
    // Customizable Area Start
    showLoader: boolean;
    // Customizable Area End
}
export const Spinner = ({
    // Customizable Area Start
    showLoader
    // Customizable Area End
}: Props) => {
    // Customizable Area Start

    return (
        <>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: 200000
                }}
                open={showLoader}
                // onClose={this.modalhandleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                disableAutoFocus={true}
            >
                <Fade in={showLoader} style={{ border: "none" }}>
                    <div style={{
                        backgroundColor: "transparent",
                        outline: "none",
                        borderRadius: 29,
                        width: "auto",
                        height: "auto",

                    }}>
                        <CircularProgress />
                    </div>
                </Fade>
            </Modal>
        </>
    );
    // Customizable Area End

}


// Customizable Area Start
export default Spinner;
// Customizable Area End