import React from "react";
// Customizable Area Start
import { iDirection, t } from "../../../components/src/i18n";
import CategoryCard from "../../../components/src/Common/CategoryCard.web";
import { withStyles, Theme, Box, Paper, Typography } from "@material-ui/core";
import ShoppingCartOrdersController, {
  Props,
  Category,
} from "./ShoppingCartOrdersController";
import Slider from "react-slick";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Customizable Area End


export class AddShoppingCartOrderItem extends ShoppingCartOrdersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { categoryArr } = this.state;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: categoryArr.length < 4 ? categoryArr.length : 4,
      slidesToScroll: 1,
      arrow: true,
      centerMode: true,
      prevArrow: iDirection === 'rtl' ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />,
      nextArrow: iDirection === 'rtl' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />,
      rtl: iDirection === 'rtl',
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: categoryArr.length < 3 ? categoryArr.length : 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 1010,
          settings: {
            slidesToShow: categoryArr.length < 2 ? categoryArr.length : 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
    };
    return (
      <Box className={classes?.screenBox}>
        <Paper className={`${classes?.addMorePaper}`}>
          <Box >
            <Box>
              <Typography data-test-id="title" className={classes?.shoppingCartText}>{t("shoppingCart.title")}</Typography>
            </Box>
            <Box className={classes?.CarouselContainer}>
            <Slider {...settings} dotsClass={`slick-dots ${classes.carouselDots}`} className={classes.carousel}>
              {
                categoryArr?.map((data: Category) => (
                  <CategoryCard
                    key={data.attributes.id}
                    category={data.attributes}
                    onClick={this.onCategoryClick}
                  />
                ))
              }
            </Slider>
            </Box>
          </Box>
        </Paper>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const addMoreStyle = (theme: Theme) => ({
  addMorePaper: {
    maxWidth:'1280px',
    width:'100%',
    boxShadow: "0px 1px 10px #EBEBEB",
    margin:"32 0",
    borderRadius:12
  },
  shoppingCartText: {
    fontWeight:600,
    fontFamily: "'Manrope', sans-serif",
    fontStyle: "normal",
    fontSize: 28,
    lineHeight: 1.5,
    letterSpacing: 2,
    color: theme.palette.text.primary,
    margin:32
  },
  CarouselContainer: {
    margin: "24px auto",
    maxWidth: "1280px",
    width: "100%"
  },
  screenBox:{
    display:'flex',
    flexDirection:'column' as const,
    alignItems:'center' as const,
    justifyContent:'center',
    minHeight:'70vh'
  },
  carousel: {
    "& svg.slick-prev": {
      color: "#717171",
      border: "2px solid #EBF0F0",
      borderRadius: "50%",
      padding: iDirection === 'rtl' ? "10px" : "10px 5px 10px 15px",
      background: "#fff",
      zIndex: "1",
      left: "2%"
    },
    "& .slick-slide": {
      height: "auto",
      display: "flex",
      justifyContent: "center",
      width: "auto !important",
    },
    "& svg.slick-next": {
      borderRadius: "50%",
      color: "#717171",
      border: "2px solid #EBF0F0",
      zIndex: "1",
      padding: iDirection === 'rtl' ? "10px 12px 10px 9px" : "10px 9px 10px 12px",
      background: "#fff",
      right: "2%"
    },
    "& .slick-track": {
      gap: "30px",
      display: "flex"
    },
  },
  carouselDots: {
    "& li.slick-active button::before": {
      color: theme.palette.primary.main,
      fontSize: "15px"
    },
    "& li button::before": {
      color: theme.palette.primary.main,
      fontSize: "15px"
    }
  },
});
export default withStyles(addMoreStyle)(AddShoppingCartOrderItem);
// Customizable Area End
