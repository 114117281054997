import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { callApi, emailReg, englishArabicCharacterRegex, getNavigationMessage, hideTawkToWidget, nameReg, passwordLowerCaseReg, passwordOneNumbereReg, passwordUpperCaseReg } from "../../../components/src/Toolkit";
import { getStorageData, setStorageData , removeStorageData} from "../../../framework/src/Utilities";
import { iLanguage, t } from "../../../components/src/i18n";
import { MixpanelTracking } from "../../MixpanelIntegration/src/MixpanelIntegration.web";
import { toast } from 'react-toastify';
// Customizable Area End

const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes:{
        MainContainerSU:string,
        LeftImageGrid:string,
        SignupFormBox:string,
        TermBox:string,
        MenuItemSelect:string,
        PasswordChecksBox:string,
        PasswordChecksRow:string,
    }
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    showLoader: boolean;
    email: string;
    emailerror: boolean;
    emailerrorText: string;
    name: string;
    nameError: boolean;
    nameErrorText: string;
    phoneNumber: string;
    phoneNumberError: boolean;
    phoneNumberErrorText: string;
    password: string;
    passwordError: boolean;
    passwordErrorText: string;
    termsCheck: boolean;
    selectedCountry: string;
    selectedCountryError: boolean;
    selectedCountryErrorText: string;
    passwordVisible: boolean;
    phoneNumberPrefix: string;
    openOtpModal: boolean;
    otpHasError: boolean;
    otpErrorText: string;
    otpResendCount:number;
    countriesData: any;
    token: string;
    email_otp_token: string;
    termsCheckError:boolean;
    isTermAndConditionModelOpen: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class SignUpController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    apiSignup: string = "";
    apiGetCountries: string = "";
    apiResendOtp: string = "";
    apiVerifyOtp: string = "";
    signupGuestaddToCartAPICallId: string = "";
    // Customizable Area End    

    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            showLoader: false,
            emailerror: false,
            emailerrorText: '',
            email: "",
            name: "",
            nameError: false,
            nameErrorText: "",
            phoneNumber: '',
            phoneNumberError: false,
            phoneNumberErrorText: '',
            phoneNumberPrefix: '0',
            password: '',
            passwordError: false,
            passwordErrorText: '',
            termsCheck: false,
            selectedCountry: '',
            selectedCountryError: false,
            selectedCountryErrorText: '',
            passwordVisible: false,
            openOtpModal: false,
            otpHasError: false,
            otpErrorText: '',
            otpResendCount:0,
            countriesData: [],
            token: '',
            email_otp_token: '',
            termsCheckError:false,
            isTermAndConditionModelOpen: false
        };

        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.getCountriesData()
        // Hiding chat option
        hideTawkToWidget();
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            if (responseJson != null) {
                if (!responseJson.errors) {
                    await this.parseApiSuccess(responseJson, apiRequestCallId);
                } else {
                    this.parseApiError(responseJson, apiRequestCallId);
                }
            }
            this.setLoading(false);
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // Customizable Area End
    }

    // Customizable Area Start
    parseApiSuccess = async (responseJson: any, apiRequestCallId: string) => {
        if (apiRequestCallId === this.apiSignup) {
            this.setState({
                email_otp_token: responseJson.meta.email_otp_token[0]
            }, () => {
                this.setState({
                    openOtpModal: true,
                    otpHasError: false,
                    otpErrorText: '',
                    otpResendCount:0
                })
            })
            toast.success(responseJson.meta.message);
        } else if (apiRequestCallId === this.apiGetCountries) {
            this.setState({
                countriesData: responseJson.data
            }, async () => {
                const country = await getStorageData("selectedCountry", true); 
                this.setState({
                    selectedCountry: country.id,
                    phoneNumberPrefix: country.attributes?.country_code
                })
            })
        } else if (apiRequestCallId === this.apiResendOtp) {
            this.setState(prevState => ({
                email_otp_token: responseJson.meta.email_otp_token,
                otpResendCount:prevState.otpResendCount + 1
            }))
            toast.success(responseJson.meta.message);
        } else if (apiRequestCallId === this.apiVerifyOtp) {
            await setStorageData('user', JSON.stringify(responseJson.data));
            await setStorageData('token', responseJson.meta.token);
            MixpanelTracking.getInstance().page_views()
            MixpanelTracking.getInstance().userIdentify(responseJson.data.id)
            MixpanelTracking.getInstance().eventTrack(configJSON.mixPanelNewUser, responseJson.data.attributes);
            MixpanelTracking.getInstance().peopleDetails({
              $name: responseJson.data.attributes.full_name,
              $email: responseJson.data.attributes.email,
              $language: iLanguage,
            })
            this.handleSignupStoredCart(responseJson.meta.token);
        }else if(apiRequestCallId === this.signupGuestaddToCartAPICallId){
            await removeStorageData('storedCart');
            this.handleGobackNavigation();
          }
    }
    parseApiError = (apiResponse: any, apiRequestCallId: string) => {
        if (apiRequestCallId === this.apiSignup) {
            this.parseSignupApiErrors(apiResponse);
        } else if (apiRequestCallId === this.apiVerifyOtp) {
            this.parseVerifyOtpErrors(apiResponse);
        } else {
            this.parseApiErrorResponse(apiResponse);
        }
    }
    parseSignupApiErrors = (apiResponse: any) => {
        let errors = apiResponse.errors;
        let keys: any = Object.keys(errors[0]);
        let values: any = Object.values(errors[0]);
        if (keys[0] === 'email' || keys[0] === 'account') {
            this.setState({
                emailerror: true,
                emailerrorText: values[0]
            })
        }
        if(keys[0] === 'phone_number') {
            this.setState({
                phoneNumberError: true,
                phoneNumberErrorText: values[0]
            })
        }
        if(keys[0] === 'password'){
            this.setState({
                passwordError: true,
                passwordErrorText: values[0]
            })
        }
    }
    parseVerifyOtpErrors = (apiResponse: any) => {
        let errors = apiResponse.errors;
        let keys: any = Object.keys(errors[0]);
        let values: any = Object.values(errors[0]);
        if (keys[0] === 'verification_failed') {
            this.setState({
                otpHasError: true,
                otpErrorText: values[0]
            })
        }
    }
    handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        this.isValidEmail(value)
        this.setState({ email: event.target.value.trim() });
    }
    handleEmailBlur = () => {
        this.isValidEmail(this.state.email)
    }
    handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.isValidName(event.target.value.trim())
        this.setState({ name: event.target.value });
    }
    handleNameBlur = () => {
        this.isValidName(this.state.name);
    }
    handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ phoneNumber: event.target.value });
        this.isValidNumber(event.target.value)
    }
    handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ password: event.target.value });
        this.isValidPassword(event.target.value);
    }
    handleTermsCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            termsCheck: event.target.checked,
            termsCheckError:event.target.checked,
        })
    }
    onSignInClick = () => {
        this.send(getNavigationMessage('Login', this.props));
    }

    handleCountryChange = (event: React.ChangeEvent<{
        value: unknown;
    }>) => {
        const country = this.state.countriesData.find((country: any) => country.id == event.target.value);
        this.setState({
            selectedCountry: event.target.value as string,
            phoneNumberPrefix: country.attributes?.country_code
        })
        this.isValidCountry(event.target.value as string)
    }
    isValidName = (value: string) => {
        let isValid: boolean = true;
        if (value.length === 0) {
            this.setState({
                nameError: true,
                nameErrorText: t("signUp.nameIsRequired"),
            });
            isValid = false;
        } else if ((iLanguage === 'en' && !nameReg.test(value)) || (iLanguage === 'ar' && !englishArabicCharacterRegex.test(value))) {
            this.setState({
                nameError: true,
                nameErrorText: t("signUp.invalidName"),
            });
            isValid = false;
        } else {
            this.setState({
                nameError: false,
                nameErrorText: '',
            });
        }

        return isValid;
    }
    isValidEmail = (email: string) => {
        let isValid: boolean = true;
        if (email.length === 0) {
            this.setState({
                emailerror: true,
                emailerrorText: t("signUp.emailRequired"),
            });
            isValid = false;
        } else if (!emailReg.test(email)) {
            this.setState({
                emailerror: true,
                emailerrorText: t("signUp.invalidEmail"),
            });
            isValid = false;
        } else if (emailReg.test(email)) {
            this.setState({
                emailerror: false,
                emailerrorText: '',
            });
        }

        return isValid;
    }

    isValidPassword = (password: string) => {
        let isValid: boolean = true;
        if (password.length === 0) {
            this.setState({
                passwordError: true,
                passwordErrorText: t("signUp.passwordRequired"),
            });
            isValid = false;
        } else if (password.length < 8 || !passwordUpperCaseReg.test(password) || !passwordLowerCaseReg.test(password) || !passwordOneNumbereReg.test(password)) {
            this.setState({
                passwordError: true,
                passwordErrorText: configJSON.staticStrings.emptyString,
            });
            isValid = false;
        } else {
            this.setState({
                passwordError: false,
                passwordErrorText: configJSON.staticStrings.emptyString,
            });
        }

        return isValid;
    }
    isValidNumber = (value: string) => {
        let isValid: boolean = true;
        if (value.length === 0) {
            this.setState({
                phoneNumberError: true,
                phoneNumberErrorText: t("signUp.phoneNumberRequired"),
            });
            isValid = false;
        } else {
            this.setState({
                phoneNumberError: false,
                phoneNumberErrorText: '',
            });
        }

        return isValid;
    }
    isValidCountry = (value: string) => {
        let isValid: boolean = true;
        if (value.length === 0) {
            this.setState({
                selectedCountryError: true,
                selectedCountryErrorText: t("signUp.countryRequired"),
            });
            isValid = false;
        } else {
            this.setState({
                selectedCountryError: false,
                selectedCountryErrorText: '',
            });
        }

        return isValid;
    }
    isValidRequest = (): boolean => {
        const { name, phoneNumber, email, password, selectedCountry } = this.state;

        let isValid = true;
        if (!this.isValidName(name)) {
            isValid = false;
        }
        if (!this.isValidEmail(email)) {
            isValid = false;
        }
        if (!this.isValidPassword(password)) {
            isValid = false;
        }
        if (!this.isValidCountry(selectedCountry)) {
            isValid = false;
        }
        if (!this.isValidNumber(phoneNumber)) {
            isValid = false;
        }
        if (!this.state.termsCheck) {
            this.setState({
                termsCheckError:true,
            })
            isValid = false;
        }
        return isValid;
    }
    togglePasswordVisibility = () => {
        this.setState({ passwordVisible: !this.state.passwordVisible });
    }
    onSubmit = (event: any) => {
        event.preventDefault();
        if (this.isValidRequest()) {
            const countryCode: number = parseInt(this.state.phoneNumberPrefix.replace(/\D/g, ""), 10);

            const httpBody = {
                language_code:iLanguage,
                data: {
                    attributes: {
                        "full_name": this.state.name,
                        "country_code": countryCode,
                        "phone_number": this.state.phoneNumber,
                        "full_phone_number": countryCode + this.state.phoneNumber,
                        "terms_and_conditions": this.state.termsCheck,
                        "email": this.state.email,
                        "password": this.state.password,
                        "bx_block_country_countries_id": Number(this.state.selectedCountry)
                    },
                    type: "email_account"
                }
            }
            this.setLoading(true);
            this.apiSignup = callApi({
                contentType: configJSON.validationApiContentType,
                method: configJSON.POST,
                endPoint: configJSON.signUpEndPoint,
                headers: {},
                body: JSON.stringify(httpBody)
            }, runEngine)
        }
    }
    setLoading = (isLoading:boolean) => {
        this.setState({
            showLoader:isLoading
        })
    }
    resendOtp = () => {
        const httpBody: any = {
            language_code:iLanguage,
            data: { email_otp_token: this.state.email_otp_token }
        }
        this.setLoading(true);
        this.apiResendOtp = callApi({
            contentType: configJSON.validationApiContentType,
            method: configJSON.POST,
            endPoint: configJSON.resendOtpEndPoint,
            headers: {},
            body: JSON.stringify(httpBody)
        }, runEngine);
    }
    verifyOtp = (otp: string) => {
        const httpBody: any = {
            language_code:iLanguage,
            data: { otp: otp, email_otp_token: this.state.email_otp_token }
        }
        this.setLoading(true);
        this.apiVerifyOtp = callApi({
            contentType: configJSON.validationApiContentType,
            method: configJSON.PATCH,
            endPoint: configJSON.verifyOtpEndPoint,
            headers: {},
            body: JSON.stringify(httpBody)
        }, runEngine);
    }
    setOtpError = (hasError: boolean, errorText: string) => {
        this.setState({
            otpHasError: hasError,
            otpErrorText: errorText
        })
    }

    closeOtpModal = () => {
        this.setState({
            openOtpModal: false
        })
    }
    openOtpModal = () => {
        this.setState({
            openOtpModal: true
        })
    }

    getCountriesData = () => {
        this.setLoading(true);
        this.apiGetCountries = callApi({
            contentType: configJSON.validationApiContentType,
            method: configJSON.GET,
            endPoint: configJSON.getCountriesListEndPoint,
            headers: {}
        }, runEngine);
    }
    onTermsClick = () => {
        this.handleTermAndConditionModelOpen()
    }

    handleTermAndConditionModelOpen = () => {
        this.setState({ isTermAndConditionModelOpen: !this.state.isTermAndConditionModelOpen })
    }

    handleSignupStoredCart = async (token:string) => {
        const selectedCountry = await getStorageData('selectedCountry', true);
        const storedCart = await getStorageData("storedCart", true);
        const countryId = selectedCountry.id
        if(storedCart){
          this.SignupLocalCartData(storedCart, countryId, token)
        }else{
          this.handleGobackNavigation();
        }
      }

    handleGobackNavigation = async () => {
        const goBackToOrdermanagement = await getStorageData("comeToOrder", true);
        if (goBackToOrdermanagement) {
          this.send(getNavigationMessage('Ordermanagement', this.props))
          await removeStorageData("comeToOrder");
        } else {
          this.send(getNavigationMessage("Home", this.props));
        }
      };

    SignupLocalCartData = (storedCart: any, selectedCountryId:string, token:string) => {
        let newSignupCartData = storedCart.map((item: any) => {
          return {
            item_list_warranty_id: item.attributes.item_list_warranty_id,
            old_unit_price: item.attributes.old_unit_price,
            item_invoice_id: item.attributes.details.item_invoice_id,
            purchase_date: item.attributes.purchase_date,
            store_list_id: item.attributes.store_list_id,
            quantity: item.attributes.quantity,
            ...(item.attributes?.store_name ? {store_name: item.attributes.store_name} : {})
          };
        });
        const httpParams = {
          country_id: selectedCountryId,
          data: newSignupCartData,
        };
    
        this.setState({ showLoader: true });
        this.signupGuestaddToCartAPICallId = callApi(
          {
            contentType: configJSON.apiSendOtpContentType,
            method: configJSON.addCartDataMethod,
            endPoint: configJSON.addToCartEndPoint,
            headers: { token },
            body: JSON.stringify(httpParams),
          },
          runEngine
        );
      };
    // Customizable Area End
}
