import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  FormControl,
  Divider,
  TextField,
  Modal
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Rating from '@material-ui/lab/Rating';
import {StarBorderRoundedIcon, StarRoundedIcon} from "./assets"
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {confirmationImg} from "./assets";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { iDirection, t } from "../../../components/src/i18n";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import SurveysController, {
  Props,
  configJSON,
} from "./SurveysController";

export default class Surveys extends SurveysController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div style={{ background: "#fff" }}>
        <Modal
          open={this.props.isOpen || false}
          className={this.props.classes.VOMBoxModal}
          dir={iDirection}
          data-test-id="OTPModal"
        >
          {!this.state.surveySubmitted ?
            <div className={this.props.classes.viewSurveyBoxContainer}>
              <div className={this.props.classes.view_BackgroundCopy}>
                {this.props.surveyQuestions && (
                  <>
                    <h1 className={this.props.classes.surveyHeading} data-testId="survey_heading">{t("surveyForm.title")}</h1>
                    <IconButton data-testId="closebtn" className="closeIconButton" onClick={() => this.props.handleSurveyFormCloseBtn()}>
                      <CloseRoundedIcon />
                    </IconButton>
                    <ol className={this.props.classes.surveyQuestionListContainer}>
                      {this.props.surveyQuestions.attributes?.questions.map((question: any, index: number) => (
                        <li key={question.id}>
                          <p className="questionContent">{question.attributes.content}</p>
                          {/* Render based on question type */}
                          {question.attributes.question_type == 'mcq' && (
                            <ol className={this.props.classes.questionTypeMsqlist}>
                              {question.attributes.options.map((option: any, optionIndex: number) => (
                                <Button
                                  data-testId="surveyFormInputMcqs"
                                  key={optionIndex}
                                  value={this.state.inputValues[question.id] || ''}
                                  className="optionButtonAnswer"
                                  style={{ backgroundColor:this.conditionalRendering(this.state.inputValues[question.id]?.option_id === option.attributes.id , 'rgb(216, 235, 245)' , ''), minWidth: this.conditionalRendering(question.attributes.options.length <= 3 , 192 ,100)}}
                                  onClick={() => this.handleInputChanges(question.id, { option_id: option.attributes.id })}
                                >
                                  <p className={this.props.classes.optionButtonContainer}>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      className={this.props.classes.optionButton}>
                                      {this.getAlphabeticalIndex(optionIndex)}
                                    </Button>
                                    <span className="optionText">{option.attributes.name}</span>
                                  </p>
                                </Button>
                              ))}
                            </ol>
                          )}
                          {question.attributes.question_type == 'rating' && (
                            <>
                              {/* Question 2: Rating from 1 to 5 with star design icon */}

                              <FormControl component="fieldset" style={{ boxShadow: "0px 0px 8px -6px", padding: '12px', marginLeft: '-20px', borderRadius: 12 }}>
                                <Rating
                                  value={this.state.inputValues[question.id]}
                                  precision={1}
                                  onChange={(_: any, value: any) => this.handleInputChanges(question.id, { rating: value })}
                                  emptyIcon={<img src={StarBorderRoundedIcon} width="40" height="40" style={{marginRight : 20}} />}
                                  icon={<img src={StarRoundedIcon} style={{ fill: 'rgba(0, 77, 116, 1)', width: 40, height: 40, marginRight: 20 }} />}
                                />
                              </FormControl>
                            </>
                          )}
                          {question.attributes.question_type == 'text-based' && (
                            <TextField
                              id="outlined-basic"
                              label={t("surveyForm.inputPlaceholder")}
                              variant="outlined" 
                              className="InputField"
                              style={{ width: "95%", marginLeft: '-20px' }}
                              type="text"
                              value={this.state.inputValues[question.id]?.content || ""}
                              onChange={(e: any) => this.handleInputChanges(question.id, { content: e.target.value })}
                            />
                          )}
                          <Divider style={(this.props.surveyQuestions.attributes?.questions.length - 1 == index) ? { display: 'none' } : {display: "block"} } className={this.props.classes.horizontalDivider} />
                        </li>
                      ))}
                    </ol>
                    {this.state.validationError && <p style={{ color: "red", textAlign: "center" }}>{t("surveyForm.surveyValidationMsg")}</p>}
                    <div className={this.props.classes.buttonContainerMain}>
                      <Button data-testId="surveyFormButton" className={this.props.classes.placeButtonContainerSurvey} onClick={this.submitSurvey}>
                        {t("surveyForm.submit")}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
            :
            <Box className={this.props.classes.viewSurveyBoxContainer}>
              <Box className={this.props.classes.confirmContainermain}>
                <Box className={this.props.classes.confirmInnerBox}>
                  <img src={confirmationImg} className={this.props.classes.confirmImg} />
                </Box>
                <Box className={this.props.classes.coongrateMessage}>
                  <Typography data-testId="thankText" className={this.props.classes.congrateText}>
                    {t("surveyForm.thankMsgText")}
                  </Typography>
                  <Typography className={this.props.classes.congrateText}>
                    {t("surveyForm.feedbackText")}
                  </Typography>
                </Box>
                <Box className={this.props.classes.confirmInnerBox}>
                  <Button className={this.props.classes.buyAnotherBtn} onClick={this.handleBackToHomeBtn}>
                    {t("surveyForm.backToHome")}
                  </Button>
                </Box>
              </Box>
            </Box>
          }
        </Modal>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
