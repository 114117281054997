import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import WarrantyPlanController, { Props } from "./WarrantyPlanController.web";
import WarrantyPlanPurchaseCard from "../../../components/src/WarrantyPlanPurchaseCard.web";
import { t } from "../../../components/src/i18n";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },

});


class WarrantyPlanPurchase extends WarrantyPlanController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    
    // Customizable Area Start
    const { themeValue ,currency_symbol } = this.state;
    const { classes ,BasicPlan,AccidentalPlan,ComboPlan ,ExtendedPlan,handleCetegoryPlans,CategoryPlans,Expanded} = this.props;
    const isEmpty = BasicPlan?.length === 0 && AccidentalPlan?.length === 0 && ComboPlan?.length === 0 && ExtendedPlan?.length === 0;
    return (
      <Grid container className={`${classes.warrantyPurchaseMainContainer} ${isEmpty && classes.noPlanAvailabelemainContainer}`}>
        <Grid container item xs={12} md={6} className={classes.warrantyPurchaseGridContainer}>
          <Box >
            <Typography className={classes.titleColorCategory} data-testId="warrantyPurchase_title">
              {t("WarrantyPlanPurchase.title")}
            </Typography>
            <Typography className={classes.subtitleColorCategory}>{t("WarrantyPlanPurchase.subTitle")}</Typography>
          </Box>
          <Box className={classes.warranrtPlanMapContainer}>
            <WrapperWithMap title={t("WarrantyPlanPurchase.basicPlan")} currency_symbol={currency_symbol} items={BasicPlan} CategoryPlans={CategoryPlans} text_color={themeValue?.attributes?.text_color} classes={classes} bg_color={themeValue?.attributes?.bg_color} plans={BasicPlan} handleCetegoryPlans={handleCetegoryPlans} Expanded={Expanded}></WrapperWithMap>
            <WrapperWithMap title={t("WarrantyPlanPurchase.extendedPlan")}  currency_symbol={currency_symbol}  items={ExtendedPlan} CategoryPlans={CategoryPlans} text_color={themeValue?.attributes?.text_color} classes={classes} bg_color={themeValue?.attributes?.bg_color} plans={ExtendedPlan} handleCetegoryPlans={handleCetegoryPlans} Expanded={Expanded}></WrapperWithMap>
            <WrapperWithMap title={t("WarrantyPlanPurchase.accidentalWarranty")}  currency_symbol={currency_symbol}  items={AccidentalPlan} CategoryPlans={CategoryPlans} text_color={themeValue?.attributes?.text_color} classes={classes} bg_color={themeValue?.attributes?.bg_color} plans={AccidentalPlan} handleCetegoryPlans={handleCetegoryPlans} Expanded={Expanded}></WrapperWithMap>
            <WrapperWithMap title={t("WarrantyPlanPurchase.comboWarranty")}  currency_symbol={currency_symbol} items={ComboPlan} CategoryPlans={CategoryPlans}text_color={themeValue?.attributes?.text_color} classes={classes} bg_color={themeValue?.attributes?.bg_color} plans={ComboPlan} handleCetegoryPlans={handleCetegoryPlans} Expanded={Expanded}></WrapperWithMap>
           </Box>
        </Grid>
        {isEmpty && 
        <Box className={classes.noPlanAvailableContainer}>
          <Typography data-testId="noPlanAvailable" className={classes.noPlanAvailableText}>
            {t("WarrantyPlanPurchase.noPlansAvailableText")}
          </Typography>
        </Box>
        }
        {Expanded &&
          <Grid className={classes.planExpandedContainer}>
            <Box>
              <WarrantyPlanPurchaseCard  currency_symbol={currency_symbol} CategoryPlans={CategoryPlans} text_color={themeValue?.attributes?.text_color} classes={classes} bg_color={themeValue?.attributes?.bg_color} plans={CategoryPlans} handleCetegoryPlans={handleCetegoryPlans} expand={Expanded} />
            </Box>
          </Grid>
        }
      </Grid>
    );
    // Customizable Area End

  }
}

// Customizable Area Start

export const WrapperWithMap = (props: any) => {
  const { items, title, classes, CategoryPlans, handleCetegoryPlans, Expanded,bg_color ,text_color,currency_symbol} = props;
  return (
    <>
    {items && items.length > 0 ? ( 
    <Box className={classes.warrantyPlaSubTitleContainer}>
      <Typography variant="h6" className={classes.subtitleColorCategory}>
        {title}
      </Typography>
      <div style={{ gridTemplateColumns: Expanded  ? "1fr 1fr" : "1fr 1fr 1fr 1fr" }} className={classes.extendedPlanContainer}>
        {items.map((item: any) =>
          <WarrantyPlanPurchaseCard key={item.id} currency_symbol={currency_symbol} CategoryPlans={CategoryPlans} text_color={text_color} classes={classes} bg_color={bg_color} plans={item.attributes} handleCetegoryPlans={handleCetegoryPlans} expand={false} />
        )}
      </div>
    </Box>
    ) : null}
    </>
  );
};

export default WarrantyPlanPurchase;
export { WarrantyPlanPurchase }
// Customizable Area End
