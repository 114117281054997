import React from "react";
// Customizable Area Start
import Spinner from "../../../components/src/Spinner.web";
import { iDirection, iLanguage, t } from "../../../components/src/i18n";
import {
  Box,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Button,
  FormHelperText
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import OrderDetailsController, { Props } from "./OrderDetailsController.web";
import {handleKeyPressNumber} from "../../../components/src/Toolkit"
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class CustomerInformation extends OrderDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const { classes } = this.props;
    const { countriesData, showLoader } = this.state;
    return (
      <>
        <Spinner showLoader={showLoader} />
        <form
          onSubmit={this.handleSubmit}
          data-testId="CustomerInformationForm"
        >
          <Box className={classes.mainConatiner}>
            <Box className={classes.innerConatiner}>
              <Typography variant="h2" className={classes.title} data-testId="customerInformation_title">
              {t("customerInformation.title")}
              </Typography>
              <Box className={classes.formRow}>
                <TextField
                  data-testId="fullName"
                  placeholder={t("common.fullName")!}
                  label={t("common.fullName")}
                  className="InputField"
                  type="text"
                  variant="outlined"
                  lang={iLanguage}
                  value={this.state.name}
                  onChange={this.handleNameChange}
                  onBlur={this.handleNameBlur}
                  error={this.state.customerNameError}
                  helperText={this.state.customerNameErrorText}
                />
                <TextField
                  data-testId="emailAddress"
                  placeholder={t("common.emailAddress")!}
                  label={t("common.emailAddress")}
                  className="InputField RTLOFF"
                  type="email"
                  variant="outlined"
                  value={this.state.email}
                  InputProps={{
                    classes: {
                      input: `${iLanguage == "ar" && "RTLPlaceholder "}`,
                      root: `${iLanguage == "ar" && "RTLFieldSet"}`,
                    },
                  }}
                  onChange={this.handleEmailChange}
                  onBlur={this.handleEmailBlur}
                  error={this.state.customerEmailError}
                  helperText={this.state.customerEmailErrorText}
                />
              </Box>
              <Box className={classes.formRow}>
                <FormControl
                  variant="outlined"
                  className="InputField InputSelectField"
                >
                  <InputLabel htmlFor="countrySelect">
                    {t("contactUsModel.code")}
                  </InputLabel>
                  <Select
                    data-testId="CodeSelect"
                    variant="outlined"
                    className="InputFieldSelect"
                    labelId="codeSelect"
                    label={t("contactUsModel.code")}
                    value={this.state.selectedCountryCode}
                    onChange={this.handleCountryCodeChange}
                    renderValue={(value) => {
                      const country = countriesData.find(
                        (country: any) => country.id == value
                      );
                      return (
                        <Box className="RenderedSelectItemBox" dir={iDirection}>
                          <img
                            src={country?.attributes?.logo}
                            height={24}
                            width={36}
                            crossOrigin="anonymous"
                            alt={country?.attributes.country_code}
                          />
                          <Typography className="CountryName">
                            {country?.attributes?.country_code}
                          </Typography>
                        </Box>
                      );
                    }}
                    // IconComponent={ExpandMoreIcon}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                  >
                    {countriesData.map((country: any) => {
                      return (
                        <MenuItem
                          value={country.id}
                          key={country.id}
                          className={classes.MenuItemSelect}
                          dir={iDirection}
                        >
                          <img
                            src={country.attributes.logo}
                            height={24}
                            width={36}
                            crossOrigin="anonymous"
                            alt={country.attributes.country_code}
                          />
                          <Typography className="CountryNameInSelect">
                            {country.attributes.country_code}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <TextField
                  data-testId="phoneNumber"
                  placeholder={t("common.phoneNumber")!}
                  label={t("common.phoneNumber")}
                  className="InputField RTLOFF InputFieldPhone"
                  type="number"
                  variant="outlined"
                  value={this.state.phoneNumber}
                  InputProps={{
                    classes: {
                      input: `${
                        iLanguage == "ar" && "RTLPlaceholder RTLInput"
                      }`,
                      root: `${iLanguage == "ar" && "RTLFieldSet"}`,
                    },
                  }}
                  onChange={this.handlePhoneNumberChange}
                  onKeyPress={handleKeyPressNumber}
                  error={this.state.phoneNumberError}
                  helperText={this.state.phoneNumberErrorText}
                />
              </Box>
            </Box>
            <Box className={classes.innerConatiner}>
              <Typography variant="h2" className={classes.subTitle}>
                {t("customerInformation.billingAddress")}
              </Typography>
              <TextField
                data-testId="addressLine1"
                placeholder={t("customerInformation.addressLine1")!}
                label={t("customerInformation.addressLine1")}
                className="InputField"
                type="text"
                variant="outlined"
                lang={iLanguage}
                value={this.state.addressLine1}
                onChange={this.handleAddressLine1Change}
                onBlur={this.handleAddressLine1Blur}
                error={this.state.addressLine1Error}
                helperText={this.state.addressLine1ErrorText}
              />
              <TextField
                data-testId="addressLine2"
                placeholder={t("customerInformation.addressLine2")!}
                label={t("customerInformation.addressLine2")}
                className="InputField"
                type="text"
                variant="outlined"
                lang={iLanguage}
                value={this.state.addressLine2}
                onChange={this.handleAddressLine2Change}
                onBlur={this.handleAddressLine2Blur}
                error={this.state.addressLine2Error}
                helperText={this.state.addressLine2ErrorText}
              />
              <Box className={classes.formRow}>
                <FormControl
                  variant="outlined"
                  className="InputField InputSelectField"
                  style={{ width: '100%' }}
                  error={this.state.countryError}
                >
                  <InputLabel htmlFor="countrySelect">
                    {t("common.country")}
                  </InputLabel>
                  <Select
                    data-testId="countryInput"
                    variant="outlined"
                    className="InputFieldSelect"
                    labelId="codeSelect"
                    placeholder={t("common.country")!}
                    label={t("common.country")}
                    value={this.state.country}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                    }}
                    onChange={(event: any) => this.handleCountryChange(event)}
                    onBlur={this.handleCountryBlur}
                    error={this.state.countryError}
                  >
                    {countriesData.map((country: any) => {
                      return (
                        <MenuItem
                          value={country?.attributes.country_name}
                          key={country.id}
                          className={classes.MenuItemSelect}
                          dir={iDirection}
                        >
                          <Typography className="CountryNameInSelect">
                            {country.attributes.country_name}
                          </Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>{this.state.countryErrorText}</FormHelperText>
                </FormControl>
                <TextField
                  data-testId="stateInput"
                  placeholder={t("customerInformation.state")!}
                  label={t("customerInformation.state")}
                  className="InputField"
                  type="text"
                  variant="outlined"
                  lang={iLanguage}
                  value={this.state.state}
                  onChange={this.handleStateChange}
                  onKeyPress={this.handleStateCityKeyPresssDown}
                  onBlur={this.handleStateBlur}
                  error={this.state.stateError}
                  helperText={this.state.stateErrorText}
                />
              </Box>
              <Box className={classes.formRow}>
                <TextField
                  data-testId="cityInput"
                  placeholder={t("customerInformation.city")!}
                  label={t("customerInformation.city")}
                  className="InputField"
                  type="text"
                  variant="outlined"
                  lang={iLanguage}
                  value={this.state.city}
                  onChange={this.handleCityChange}
                  onKeyPress={this.handleStateCityKeyPresssDown}
                  onBlur={this.handleCityBlur}
                  error={this.state.cityError}
                  helperText={this.state.cityErrorText}
                />
                <TextField
                  data-testId="zipInput"
                  placeholder={t("customerInformation.zip")!}
                  label={t("customerInformation.zip")}
                  className="InputField"
                  type="number"
                  variant="outlined"
                  lang={iLanguage}
                  value={this.state.zip}
                  onChange={this.handleZipChange}
                  onKeyPress={handleKeyPressNumber}
                  onBlur={this.handleZipBlur}
                  error={this.state.zipError}
                  helperText={this.state.zipErrorText}
                />
              </Box>
            </Box>
            <Box className={classes.innerConatiner}>
              <Typography variant="h2" className={classes.subTitle}>
              {t("customerInformation.shippingInformation")}
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.sameShippingAddress}
                    onChange={this.handleSameShippingAddressbox}
                    className="InputCheckboxInside"
                  />
                }
                label={t("customerInformation.sameBilling")}
                className="InputCheckbox"
                data-testId="shippingCheckbox"
              />
              {!this.state.sameShippingAddress && (
                <>
                  <TextField
                    data-testId="shippingAddressLine1Input"
                    placeholder={t("customerInformation.addressLine1")!}
                    label={t("customerInformation.addressLine1")}
                    className="InputField"
                    type="text"
                    variant="outlined"
                    lang={iLanguage}
                    value={this.state.shippingAddressLine1}
                    onChange={this.handleShippingAddressLine1Change}
                    onBlur={this.handleShippingAddressLine1Blur}
                    error={this.state.shippingAddressLine1Error}
                    helperText={this.state.shippingAddressLine1ErrorText}
                  />
                  <TextField
                    data-testId="shippingAddressLine2Input"
                    placeholder={t("customerInformation.addressLine2")!}
                    label={t("customerInformation.addressLine2")}
                    className="InputField"
                    type="text"
                    variant="outlined"
                    lang={iLanguage}
                    value={this.state.shippingAddressLine2}
                    onChange={this.handleShippingAddressLine2Change}
                    onBlur={this.handleShippingAddressLine2Blur}
                    error={this.state.shippingAddressLine2Error}
                    helperText={this.state.shippingAddressLine2ErrorText}
                  />
                  <Box className={classes.formRow}>
                    <FormControl
                      variant="outlined"
                      className="InputField InputSelectField"
                      style={{ width: '100%' }}
                      error={this.state.shippingCountryError}
                    >
                      <InputLabel htmlFor="countrySelect">
                        {t("common.country")}
                      </InputLabel>
                      <Select
                        data-testId="shippingCountryInput"
                        variant="outlined"
                        className="InputFieldSelect"
                        placeholder={t("common.country")!}
                        lang={iLanguage}
                        value={this.state.shippingCountry}
                        label={t("common.country")}
                        onChange={(event: any) => this.handleShippingCountryChange(event)}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                        error={this.state.countryError}
                        onBlur={this.handleShippingCountryBlur}
                      >
                        {countriesData.map((country: any) => {
                          return (
                            <MenuItem
                              value={country.attributes.country_name}
                              key={country.id}
                              className={classes.MenuItemSelect}
                              dir={iDirection}
                            >
                              <Typography className="CountryNameInSelect">
                                {country.attributes.country_name}
                              </Typography>
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText>{this.state.shippingCountryErrorText}</FormHelperText>
                    </FormControl>
                    <TextField
                      data-testId="shippingStateInput"
                      placeholder={t("customerInformation.state")!}
                      label={t("customerInformation.state")}
                      className="InputField"
                      type="text"
                      variant="outlined"
                      lang={iLanguage}
                      value={this.state.shippingState}
                      onChange={this.handleShippingStateChange}
                      onKeyPress={this.handleStateCityKeyPresssDown}
                      onBlur={this.handleShippingStateBlur}
                      error={this.state.shippingStateError}
                      helperText={this.state.shippingStateErrorText}
                    />
                  </Box>
                  <Box className={classes.formRow}>
                    <TextField
                      data-testId="shippingCityInput"
                      placeholder={t("customerInformation.city")!}
                      label={t("customerInformation.city")}
                      className="InputField"
                      type="text"
                      variant="outlined"
                      lang={iLanguage}
                      value={this.state.shippingCity}
                      onChange={this.handleShippingCityChange}
                      onKeyPress={this.handleStateCityKeyPresssDown}
                      onBlur={this.handleShippingCityBlur}
                      error={this.state.shippingCityError}
                      helperText={this.state.shippingCityErrorText}
                    />
                    <TextField
                      data-testId="shippingZipInput"
                      placeholder={t("customerInformation.zip")!}
                      label={t("customerInformation.zip")}
                      className="InputField"
                      type="number"
                      variant="outlined"
                      lang={iLanguage}
                      value={this.state.shippingZip}
                      onChange={this.handleShippingZipChange}
                      onKeyPress={handleKeyPressNumber}
                      onBlur={this.handleShippingZipBlur}
                      error={this.state.shippingZipError}
                      helperText={this.state.shippingZipErrorText}
                    />
                  </Box>
                </>
              )}
            </Box>
          </Box>
          <Box className={classes.buttonContainer}>
            <Button
              data-testId="btnSubmit"
              variant="contained"
              color="primary"
              className={classes.proceedButton}
              type="submit"
            >
              <Typography className={classes.ConfirmText}>{t("customerInformation.continue")}</Typography>
            </Button>
          </Box>
        </form>
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End
