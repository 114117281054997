import React from "react";
// Customizable Area Start
import { withStyles, Theme, Grid, Box, Typography } from "@material-ui/core";
import AboutUsController, { Props } from "./AboutUsController.web";
import { t } from "../../../components/src/i18n";
import { aboutUsLocationIcon, aboutUsWarrantyImage } from "./assets";
import { flexCenter } from "../../../components/src/CommonStyles";
import SearchEngineOptimisationseo from "../../SearchEngineOptimisationseo/src/SearchEngineOptimisationseo.web";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export class AboutUs extends AboutUsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { countryInfo, aboutUsDescription } = this.state;
    const metaDetail = [
      {
        name: "description",
        content: aboutUsDescription
      },
      {
        name: "keywords",
        content: t("seo.keywords")
      }
    ]
    return (
      <>
        <SearchEngineOptimisationseo
        title={t("aboutUs.title")!}
        metaTags={metaDetail}
        navigation={this.props.navigation} id={this.props.id} />
        <Grid container className={classes.MainContainer}>
          <Grid item xs={12} md={1} />
          <Grid item xs={12} md={6}>
            <Box className={classes.aboutUsDescriptionBox}>
              <Typography component={"h1"} className="AboutUsTitle">
                {t("aboutUs.title")}
              </Typography>
              <Typography
                className="AboutUsDescription"
                dangerouslySetInnerHTML={{
                  __html: `${aboutUsDescription || ''}`
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4} className={classes.addressGrid}>
            <Box className="CenterBox">
              <img src={aboutUsWarrantyImage} className="WarrantyLogo" />
              <Box className="AddressContainer">
                <Box className="locationIconBox">
                  <img src={aboutUsLocationIcon} className="locationIcon" />
                </Box>
                <Box className="AddressBox">
                  <Typography className="CountryDetail CountryName">
                    {countryInfo.attributes.country_name}
                  </Typography>
                  <Typography className="CountryDetail">
                    {countryInfo.attributes.address}
                  </Typography>
                  <Typography className="CountryDetail">
                    {countryInfo.attributes.email}
                  </Typography>
                  <Typography className="CountryDetail">{`${countryInfo.attributes.country_code
                    }${countryInfo.attributes.phone_number}`}</Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={1} />
        </Grid>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const aboutUsStyle = (theme: Theme) => ({
  MainContainer: {
    minHeight: "60vh",
    marginTop: 64,
    marginBottom: 32
  },
  aboutUsDescriptionBox: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "flex-start",
    maxWidth: "95%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      maxWidth: "100%",
      padding: 24
    },
    [theme.breakpoints.down("xs")]: {
      padding: 8
    },
    "& .AboutUsTitle": {
      color: "rgba(5, 23, 32, 1)",
      fontFamily: "'Manrope', sans-serif",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 48,
      lineHeight: 1,
      letterSpacing: 1,
      [theme.breakpoints.down("xs")]: {
        fontSize: 32
      }
    },
    "& .AboutUsDescription": {
      color: "rgba(5, 23, 32, 1)",
      fontFamily: "'Manrope', sans-serif",
      fontStyle: "normal",
      fontSize: 22,
      lineHeight: 1.5,
      letterSpacing: 1,
      marginTop: 32,
      opacity: 1,
      wordBreak: 'break-word' as const,
      hyphens: 'auto' as const,
      [theme.breakpoints.down("xs")]: {
        fontSize: 18
      }
    }
  },
  addressGrid: {
    ...flexCenter,
    "& .CenterBox": {
      display: "flex",
      flexDirection: "column" as const,
      justifyContent: "flex-start",
      [theme.breakpoints.down("xs")]: {
        alignItems: "center"
      }
    },
    "& .WarrantyLogo": {
      height: 495,
      width: "100%",
      maxWidth: 495,
      objectFit: "contain",
      marginTop: 64,
      marginBottom: 64,
      [theme.breakpoints.down("sm")]: {
        marginTop: 24,
        marginBottom: 24,
        width: "80%",
        height: "auto"
      }
    },
    "& .AddressContainer": {
      display: "flex",
      justifyContent: "flex-start",
      padding: 8,
      "& .locationIconBox": {
        height: 40,
        width: 40,
        boxShadow: "0px 1px 10px #EBEBEB",
        marginRight: 16,
        ...flexCenter,
        "& .locationIcon": {
          width: 18,
          height: 22,
          objectFit: "contain"
        }
      },
      "& .AddressBox": {
        display: "flex",
        flexDirection: "column" as const,
        alignItems: "flex-start",
        width: "100%",
        "& .CountryDetail": {
          fontStyle: "normal",
          fontWeight: 600,
          includeFontPadding: false,
          textAlignVertical: "top",
          fontFamily: "'Inter', sans-serif",
          fontSize: 20,
          color: "rgba(5, 23, 32, 1)",
          lineHeight: 2,
          letterSpacing: "1.5px",
          [theme.breakpoints.down("xs")]: {
            fontSize: 18
          },
          "&.CountryName": {
            color: "rgba(0, 77, 116, 1)",
            fontFamily: "'Manrope', sans-serif"
          }
        }
      }
    },
    flip: false,
    direction: "ltr" as const
  }
});
export default withStyles(aboutUsStyle)(AboutUs);
// Customizable Area End
