import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from "react";
import { callApi } from "../../../components/src/Toolkit";
import { getStorageData } from "../../../framework/src/Utilities";
import { iLanguage } from "../../../components/src/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:{
    MainContainer:string;
    flexCenter:string;
    titleGrid:string;
    titleText:string;
    tocGridItem:string;
  }
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedCountry:{
    id:string | undefined
  };
  tableOfContentTnC:Array<string>;
  termsAndConditions:Array<{
    title:string,
    description:string
  }>;
  tableOfContentPP:Array<string>;
  privacyPolicies:Array<{
    title:string,
    description:string
  }>;
  isLoading:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class TermsAndConditions3Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetTermsAndCondition: string = "";
  privacyPolicyRef: React.RefObject<any>;
  tncContentRef: React.RefObject<any>[] = [];
  ppContentRef: React.RefObject<any>[] = [];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedCountry:{
        id:undefined
      },
      tableOfContentTnC:[],
      termsAndConditions:[],
      tableOfContentPP:[],
      privacyPolicies:[],
      isLoading:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.privacyPolicyRef = React.createRef();
    this.tncContentRef = []
    this.ppContentRef = []
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    const apiMessageId = getName(MessageEnum.RestAPIResponceMessage)
    if (message.id === apiMessageId) {
      if (responseJson != null) {
        if (!responseJson.errors) {
          if (apiRequestCallId === this.apiGetTermsAndCondition) {
            this.setState({
              tableOfContentTnC:responseJson.terms_and_conditions?.data?.attributes.table_contents,
              termsAndConditions:responseJson.terms_and_conditions?.data?.attributes.tncs,
              tableOfContentPP:responseJson.privacy_policy?.data?.attributes.table_contents,
              privacyPolicies:responseJson.privacy_policy?.data?.attributes.policies
            }, () => {
              this.handlePrivacyPolicy();
            })
          }
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      }
      this.setLoadingState(false);
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const selectedCountry = await getStorageData('selectedCountry', true);
    this.setState({
      selectedCountry
    },() => {
      this.getTermsAndCondition()
    })
  }
  getTermsAndCondition = () => {
    this.setLoadingState(true);
    this.apiGetTermsAndCondition = callApi({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.termAndConditionApiEndPoint}${this.state.selectedCountry.id}/terms_and_conditions?language_code=${iLanguage}`,
      headers: {}
    }, runEngine);
  }
  setLoadingState = (state:boolean) => {
    this.setState({
      isLoading:state
    })
  }
  handlePrivacyPolicy = () => {
    const currentPath = window.location.hash;
    if(currentPath === "#PrivacyPolicy"){
      this.privacyPolicyRef.current.scrollIntoView(configJSON.focusOnElement);
    }
  }
  handleOnItemClickTnc = (index:number) =>{
    this.tncContentRef[index].current.scrollIntoView(configJSON.focusOnElement);
  }

  handleOnItemClickPP = (index:number) =>{
    this.ppContentRef[index].current.scrollIntoView(configJSON.focusOnElement);
  }

  setTncRef = (ref:React.RefObject<any>, index:number) => {
    this.tncContentRef[index] = ref;
  }
  setPPRef = (ref:React.RefObject<any>, index:number) => {
    this.ppContentRef[index] = ref;
  }
  // Customizable Area End
}
