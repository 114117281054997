import React, { Component } from 'react';
import { Box, Typography, withStyles, WithStyles, Tooltip } from '@material-ui/core';
import { deviceBasedDynamicDimension } from '../Toolkit';
interface Props extends WithStyles {
    label: string;
    value: string;
    dir?:"ltr" | "rtl"
}

interface S {

}

interface SS {

}
class InfoBox extends Component<Props, S, SS> {
    render() {
        const { classes, label, value, dir } = this.props;
        return (
            <Box component={'div'}>
                <Typography className={classes.label}>{label}</Typography>
                <Tooltip title={value} aria-label={label} dir={dir}>
                    <Typography className={classes.value}>{value.trim().length > 0 ? value : '-'}</Typography>
                </Tooltip>
            </Box>
        );
    }
}

const style = () => ({
    label: {
        color: 'rgba(137, 145, 164, 1)',
        textAlign: 'left' as const,
        fontStyle: 'normal',
        fontWeight: 'normal' as const,
        fontFamily: 'Inter',
        fontSize: deviceBasedDynamicDimension(18, true, 1)
    },
    value: {
        color: 'rgba(5, 23, 32, 1)',
        textAlign: 'left' as const,
        fontStyle: 'normal',
        fontWeight: 'normal' as const,
        fontFamily: 'Inter',
        fontSize: deviceBasedDynamicDimension(24, true, 1),
        textOverflow: "ellipsis",
        overflow:'hidden',
        "-webkit-box-orient": "vertical",
        "-webkit-line-clamp": 1,
    }
});
export default withStyles(style)(InfoBox);