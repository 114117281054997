import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { t } from "../../../components/src/i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  isModalOpen?: boolean;
  handleClose?: any;
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  name: string;
  email: string;
  phoneNumber: string;
  comments: string;
  enableField: boolean;
  token: string;
  contactUsList: any;
  activeId: number;
  activeName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeDescription: string;
  activeCreatedAt: string;
  isVisible: boolean;
  isConfirmM: boolean;
  selectedCountry: any;
  nameError: boolean;
  nameErrorText: string;
  emailError: boolean;
  emailErrorText: string;
  phoneNumberError: boolean;
  phoneNumberErrorText: string;
  selectedCountryError: boolean;
  selectedCountryErrorText: string;
  showLoader: boolean,
  countryCodeList: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ContactusController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contactUsApiCallId: any;
  deleteContactApiCallId: any;
  addContactApiCallId: any;
  apiGetCountries: string = "";
  addContactUsApiCallId: string = "";
  emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  nameReg = /^[a-zA-Z ]+$/;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.contactUsApiCallId = "";
    this.deleteContactApiCallId = "";
    this.addContactApiCallId = "";

    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      comments: "",
      enableField: false,
      token: "",
      contactUsList: [],
      activeId: 0,
      activeName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeDescription: "",
      activeCreatedAt: "",
      isVisible: false,
      selectedCountry: null,
      isConfirmM: false,
      nameError: false,
      nameErrorText: "",
      emailError: false,
      emailErrorText: "",
      phoneNumberError: false,
      phoneNumberErrorText: "",
      selectedCountryError: false,
      selectedCountryErrorText: "",
      showLoader: false,
      countryCodeList: []
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    const country = await getStorageData("selectedCountry", true);
    this.setState({ selectedCountry: country.attributes.country_code.slice(1) });
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
     if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token });
      if(!this.isPlatformWeb()){
        this.getContactUsList(token);
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Recived", message);

      if (responseJson && responseJson.data) {
        this.handleApiSuccess(apiRequestCallId, responseJson)
      } else if (
        responseJson &&
        responseJson.message &&
        apiRequestCallId === this.deleteContactApiCallId
      ) {
        this.setState({ isVisible: false });
        this.getContactUsList(this.state.token);
      } else if (responseJson && responseJson.errors) {
        if (responseJson.errors) {
          this.setState({ showLoader : false })
          this.handleApiError(apiRequestCallId, responseJson)
        }
      }
      this.setState({ showLoader : false })
    }
    // Customizable Area End
  }

  // Customizable Area Start

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(!prevProps.isModalOpen && this.props.isModalOpen){
      this.getCountriesData();
    }
  }
  txtNameProps = {
    onChangeText: (text: string) => {
      this.setState({ name: text });

      //@ts-ignore
      this.txtNameProps.value = text;
    },
  };

  txtEmailProps = {
    onChangeText: (text: string) => {
      this.setState({ email: text });

      //@ts-ignore
      this.txtEmailProps.value = text;
    },
  };
  txtPhoneNumberProps = {
    onChangeText: (text: string) => {
      this.setState({ phoneNumber: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
    // keyboardType: "phone-pad"
  };
  txtCommentsProps = {
    multiline: true,
    onChangeText: (text: string) => {
      this.setState({ comments: text });

      //@ts-ignore
      this.txtCommentsProps.value = text;
    },
  };

  setName = (text: string) => {
    this.setState({ name: text });
  };

  setEmail = (text: string) => {
    this.setState({ email: text });
  };

  setPhoneNumber = (text: string) => {
    this.setState({ phoneNumber: text });
  };

  setComments = (text: string) => {
    this.setState({ comments: text });
  };

  addQuery = () => {
    this.props.navigation.navigate("AddContactus");
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeName: item.attributes.name,
      activeEmail: item.attributes.email,
      activeDescription: item.attributes.description,
      activePhoneNumber: item.attributes.phone_number,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail = (Email: string) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (reg.test(Email) === false) {
      return false;
    } else {
      return true;
    }
  };

  addQueryApi = () => {
    if (
      this.isStringNullOrBlank(this.state.name) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.phoneNumber) ||
      this.isStringNullOrBlank(this.state.comments)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (!this.isValidEmail(this.state.email.trim())) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    } else {
      let data = {
        data: {
          name: this.state.name.trim(),
          email: this.state.email.trim(),
          phone_number: this.state.phoneNumber.trim(),
          description: this.state.comments.trim(),
        },
      };

      const header = {
        "Content-Type": configJSON.contactUsApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addContactApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getContactUsAPiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteContactUs = (id: number) => {
    const header = {
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteContactApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getContactUsList = (token: string) => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.contactUsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  btnSubmitProps = {
    onPress: () => this.addQueryApi(),
  };

  handleApiSuccess = (apiRequestCallId : any, responseJson : any ) => {
    if (apiRequestCallId === this.contactUsApiCallId) {
      this.setState({ contactUsList: responseJson.data });
    }
    if (apiRequestCallId === this.addContactApiCallId) {
      this.props.navigation.goBack();
    }
    if (apiRequestCallId === this.apiGetCountries) {
      this.setState({
        countryCodeList: responseJson.data,
      });
    }
    if (apiRequestCallId === this.addContactUsApiCallId) {
      this.props.handleClose();
      this.setState({ isConfirmM: true, name: "",
      email: "",
      phoneNumber: "" });
    }
  }

  handleApiError = (apiRequestCallId : any, responseJson : any ) => {
    if(apiRequestCallId === this.addContactUsApiCallId) {
      this.handleContactUsError(responseJson)
    }
    else if (apiRequestCallId === this.addContactApiCallId) {
      responseJson.errors.forEach((error: any) => {
        if (error.contact) {
          this.showAlert(configJSON.errorTitle, error.contact.join("."));
        }
      });
    }
    else {
      this.parseApiErrorResponse(responseJson);
    }
  }

  handleContactUsError = (responseJson: any) => {
    let errors = responseJson.errors;
    let keys: any = Object.keys(errors);
    let values: any = Object.values(errors);
    
    this.setState({
          emailError: keys[0] === "email",
          emailErrorText: keys[0] === "email" ? values[0] : "",
          phoneNumberError: keys[0] === "phone_number",
          phoneNumberErrorText: keys[0] === "phone_number" ? values[0] : "",
        }
      );
  };

  isValidEmailAddress = (emailId: string) => {
    let isValidemail: boolean = true;
    if (emailId.length === 0) {
      this.setState({
        emailError: true,
        emailErrorText: t("signUp.emailRequired"),
      });
      isValidemail = false;
    } else if (!this.emailRegex.test(emailId)) {
      this.setState({
        emailError: true,
        emailErrorText: t("signUp.invalidEmail"),
      });
      isValidemail = false;
    } else if (this.emailRegex.test(emailId)) {
      this.setState({
        emailError: false,
        emailErrorText: "",
      });
    }

    return isValidemail;
  };
  isValidName = (value: string) => {
    let isValid: boolean = true;
    if (value.length === 0) {
      this.setState({
        nameError: true,
        nameErrorText: t("signUp.nameIsRequired"),
      });
      isValid = false;
    } else if (!this.nameReg.test(value)) {
      this.setState({
        nameError: true,
        nameErrorText: "Enter a valid Name.",
      });
      isValid = false;
    } else {
      this.setState({
        nameError: false,
        nameErrorText: "",
      });
    }

    return isValid;
  };

  isValidNumber = (value: string) => {
    let isValid: boolean = true;
    if (value.length === 0) {
      this.setState({
        phoneNumberError: true,
        phoneNumberErrorText: t("signUp.phoneNumberRequired"),
      });
      isValid = false;
    } else {
      this.setState({
        phoneNumberError: false,
        phoneNumberErrorText: "",
      });
    }

    return isValid;
  };

  isValidData = () => {
    const { name, email, phoneNumber } = this.state;
    if (
      !this.isValidName(name.trim()) ||
      !this.isValidEmailAddress(email) ||
      !this.isValidNumber(phoneNumber)
    ) {
      return false;
    }
    return true;
  };

  addContactApi = async () => {
    const { name, email, selectedCountry, phoneNumber } = this.state;
    const token = await getStorageData("token");

    if (!this.isValidData()) return;
    this.setState({ showLoader: true })
    let data = {
      data: {
        name: name.trim(),
        email: email.trim(),
        phone_number: selectedCountry.trim() + phoneNumber.trim(),
        description: this.state.comments.trim(),
      },
    };

    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addContactUsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };
  getCountriesData = async () => {
    const header = {
      "Content-Type": configJSON.contactUsApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetCountries = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCountriesListEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  setContactUsName = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.isValidName(e.target.value.trim());
    this.setState({ name: e.target.value });
  };

  setContactUsEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.isValidEmailAddress(e.target.value);
    this.setState({ email: e.target.value });
  };

  setContactUsPhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(/^\d*$/.test(e.target.value))
    {
      this.isValidNumber(e.target.value);
      this.setState({ phoneNumber: e.target.value.toString() });
    }
  };

  setMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ comments: e.target.value });
  };

  handleCountryChange = (
    event: React.ChangeEvent<{
      value: unknown;
    }>
  ) => {
    this.setState({
      selectedCountry: event.target.value as string,
    });
  };
  btnBackProps = {
    onPress: () => this.setState({ isConfirmM: false }),
  };
  // Customizable Area End
}
