import React from "react";
// Customizable Area Start
import { Grid, Box, Link, Typography,withStyles,Theme } from '@material-ui/core'
import {t} from "./i18n";
import { deviceBasedDynamicDimension } from "./Toolkit";

export const footerStyles = (theme: Theme) => ({
    footerBox:{
        margin:"auto 0"
    },
    mainContainer:{
        padding:`0 ${deviceBasedDynamicDimension(64, true,1)}`
    },
    FooterLinkBox :{
        display: "flex",
        justifyContent: 'space-around',
        flexDirection: 'row' as const,
        alignItems: 'center',
        height: '100%',
        '& .Dot': {
                height: '6px',
                width: '6px',
                backgroundColor: 'grey',
                borderRadius: '50%',
            },
        "& .FooterLinkText": {
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontSize: '20px',
                lineHeight: 1.5,
                letterSpacing: 1.3,
                textDecorationLine: 'none',
                color: 'grey',
                fontWeight: 600,
                opacity: 0.9,
            },
            [theme.breakpoints.down('sm')]:{
                display: 'flex',
                flexDirection: 'column' as const,
                alignItems: 'center',
                justifyContent: 'center',
                height: 'auto',
                '& .Dot':{
                    display: 'none'
                },
                '& .FooterLinkText':{
                    margin:'8px 0px'
                 }
            },
    },
    HorizonatalDivider: {
        height: '1px',
        width: '100%',
        backgroundColor: '#979797',
        margin:'24px 0px'
    },
    RightReserveBox :{
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        margin:'4px 0 48px 0',
        "& .ReserveText" : {
                fontFamily: 'Inter, sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: 1.5,
                letterSpacing: 1,
                color: '#717171',
                mixBlendMode: 'normal',
                opacity: 1,
                textAlign:'center' as const
            }
    },
    "@media (max-height: 720px)":{
        HorizonatalDivider: {
            marginTop: 16,
            marginBottom:4
        }
    }
})

// Customizable Area End

import FooterController, {
    Props
} from "./FooterController.web";
import ContactUsWeb from "../../blocks/contactus/src/Contactus.web";
import { StyledLinks } from "../../blocks/DeepLinking/src/DeepLinking.web";

export class Footer extends FooterController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { id ,navigation,classes } = this.props;
        const { poweredBy, hideSocialIcon } = this.state;
        return (
            <Box className={classes.footerBox} component={'footer'}>
                <Grid container className={classes.mainContainer}>
                    <Grid item xs={12} md={3} style={{ display: hideSocialIcon ? "none" : "block"}}>
                        <StyledLinks navigation={undefined} id={""} handleHideSocialMediaIcon={this.handleHideSocialMediaIcon} />
                    </Grid>
                    <Grid item xs={12} md={9} style={hideSocialIcon ? {margin: 'auto'}: {}}>
                        <Box className={classes.FooterLinkBox}>
                            <Link href="/TermsAndConditions" className="FooterLinkText">
                                {t("footer.termsNCondition")}
                            </Link>
                            <Box className="Dot"></Box>
                            <Link href="/TermsAndConditions#PrivacyPolicy" className="FooterLinkText" >
                                {t("footer.pravicyPolicy")}
                            </Link>
                            <Box className="Dot"></Box>
                            <Link href="/RefundPolicy" className="FooterLinkText">
                                {t("footer.refundPolicy")}
                            </Link>
                            <Box className="Dot"></Box>
                            <Link href="#" className="FooterLinkText" onClick={this.handleModelOpen} >
                                {t("footer.contacts_as")}
                            </Link>
                            <Box className="Dot"></Box>
                            <Link href="/AboutUs" className="FooterLinkText">
                                {t("footer.about_As")}
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className={classes.HorizonatalDivider}></Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box className={classes.RightReserveBox}>
                            <Typography className="ReserveText">{t("footer.keyword_rights_sentence")}&nbsp;{poweredBy}</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <ContactUsWeb handleClose={this.handleModelOpen} isModalOpen={this.state.isModelOpen} id={id} navigation={navigation} />
            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
export default withStyles(footerStyles)(Footer);
// Customizable Area End