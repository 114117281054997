import React from "react";
// Customizable Area Start
import { Box, Typography, Button, Theme, withStyles } from "@material-ui/core";
import { confirmationImg,failedImg } from "../../blocks/ordermanagement/src/assets";
import { deviceBasedDynamicDimension } from "./Toolkit";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { Link } from "react-router-dom";
import { t } from "./i18n";
const orderConfirmStyle = (theme: Theme) => ({
    confirmContainer: {
        width: "773px",
        display: 'flex',
        flexDirection: "column" as const,
        margin: "0px auto 50px auto",
        padding: "100px 0px",
        paddingRight: "0px",
        backgroundColor: '#FFFFFF',
        boxShadow: "1px 1px 10px 0px rgba(0,0,0,0.2)",
        borderRadius: "20px",
        gap: '25px',
        [theme.breakpoints.down('md')]:{
            width: 'auto',
            boxShadow: 'none',
            padding: '50px 0px'
        }
    },
    canclePlanBox:{
        boxShadow: "none",
        borderRadius: "none",
        margin: "50px auto 50px auto",
        width: "700px",
        [theme.breakpoints.down('md')]:{
            width: 'auto',
            margin: '0px auto'
        }
    },
    confirmInnerBox: {
        display: "flex",
        justifyContent: "center"
    },
    confirmImg: {
        width: 146,
        height: 173,
        opacity: 1,
        objectFit: 'contain' as const,
        [theme.breakpoints.down('md')]: {
            width: 100,
            height: 130
        }
    },
    congrateText: {
        color: 'rgba(28, 28, 28, 1)',
        textAlign: 'center' as const,
        fontWeight: 'bold' as const,
        fontFamily: 'Inter',
        fontSize: deviceBasedDynamicDimension(36, true, 1),
        [theme.breakpoints.down('md')]: {
        fontSize: deviceBasedDynamicDimension(24, true, 1),
        }
    },
    requestIdText: {
        marginTop: "24px",
        color: 'rgba(88, 87, 87, 1)',
        textAlign: 'center' as const,
        fontFamily: 'Inter',
        fontSize: deviceBasedDynamicDimension(20, true, 1)
    },
    buyAnotherBtn: {
        padding: '8px 80px',
        fontFamily: 'Manrope',
        fontSize: deviceBasedDynamicDimension(20, true, 1),
        fontStyle: 'normal',
        fontWeight: 'bold' as const,
        color: 'rgba(255, 255, 255, 1)',
        textTransform: "capitalize" as const,
        backgroundColor: 'rgba(0, 77, 116, 1)',
        borderRadius: 40,
        justifyContent: 'center',
        '&:hover': {
            backgroundColor: 'rgba(0, 77, 116, 1)',
        }
    }

});
// Customizable Area End

export interface Props {
    // Customizable Area Start
    classes?: any;
    payment_id?:string;
    message?:string;
    orderTypeMsg?:string;
    subTitle?:string;
    btnText?:string;
    handleButtonAction?: any;
    isFailed?: boolean;
    isBorder?: boolean;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}


class OrderConfirmation extends BlockComponent<Props, S, SS> {
    constructor(props: Props) {
        // Customizable Area Start
        super(props);
        // Customizable Area End
    }
    render() {
        // Customizable Area Start
        const { classes ,payment_id, message,subTitle,orderTypeMsg, btnText,handleButtonAction, isFailed,isBorder} = this.props;
        return (
            <Box className={`${classes.confirmContainer} ${btnText && !isFailed && classes.canclePlanBox} ${isBorder && classes.canclePlanBox}`}>
                <Box className={classes.confirmInnerBox}>
                    <img src={isFailed ? failedImg : confirmationImg} className={classes.confirmImg} />
                </Box>
                <Box className={classes.confirmInnerBox} style={{ flexDirection: 'column' }}>
                    <Typography className={classes.congrateText}>{message ? message : t("confirmation.title")}</Typography>
                    <Typography className={classes.congrateText}>{subTitle ? subTitle : t('confirmation.subTitle')} </Typography>
                    <Typography className={classes.requestIdText}>{orderTypeMsg ? orderTypeMsg : t("confirmation.requestId")} - <span data-testId="payment_id">{payment_id}</span></Typography>
                </Box>
                <Box className={classes.confirmInnerBox} style={{ marginTop: btnText ? "15px" : "70px" }}>
                   {btnText ? 
                    <Button type="submit" data-testId="buttonId" variant="outlined" className={classes.buyAnotherBtn}  onClick={handleButtonAction}>{btnText}</Button>
                    : 
                    <Link to="/">
                    <Button type="submit" variant="outlined" className={classes.buyAnotherBtn}>{t('confirmation.buyAnother')}</Button>
                    </Link>
                   }
                </Box>
            </Box>
        )
    }
    // Customizable Area End
}

// Customizable Area Start
export default withStyles(orderConfirmStyle)(OrderConfirmation);
export { OrderConfirmation }
// Customizable Area End
