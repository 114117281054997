import React, { Component, ReactNode } from 'react';
import { Paper, Box, Typography, withStyles, WithStyles, IconButton, Button, Theme } from '@material-ui/core';
import { deviceBasedDynamicDimension } from '../Toolkit';
import { t } from '../i18n';
type ButtonType = 'icon' | 'normal';
type StyleType = {
    [key: string]: string | number;
};
interface Props extends WithStyles {
    title: string;
    buttonType: ButtonType;
    buttonText?: string;
    buttonIcon?: ButtonType extends 'icon' ? string : undefined;
    onIconButtonClick?: () => void;
    onButtonClick?: () => void;
    childrenNode?: ReactNode;
    childNodeBoxStyle?:StyleType
}

interface S {

}

interface SS {

}
class InfoWrapper extends Component<Props, S, SS> {
    render() {
        const { classes, title, buttonType, buttonIcon, buttonText, childNodeBoxStyle } = this.props;
        return (
            <Paper className={classes.mainPaper}>
                <Box className={`${classes.headerBox} ${buttonType === 'normal' && 'responsive'}`}>
                    <Typography className={classes.titleText}>{title}</Typography>
                    {
                        buttonType === 'icon' ? (
                            <IconButton onClick={this.props.onIconButtonClick} data-test-id="IconButton">
                                <img src={buttonIcon} alt="Image" className={classes.imageIcon} />
                            </IconButton>
                        ) : (
                            <Box component={'div'} className={classes.saveButtonBox}>
                                <Button onClick={this.props.onButtonClick} className={classes.saveButton} data-test-id="NormalButton">
                                    <Typography className="buttonText"> { buttonText || t("common.save")}</Typography>
                                </Button>
                            </Box>
                        )
                    }
                </Box>
                <Box className={`${classes.childNodeBox}`} style={childNodeBoxStyle || {}}>
                    {
                        this.props.childrenNode
                    }
                </Box>
            </Paper>
        );
    }
}

const style = (theme: Theme) => ({
    mainPaper: {
        margin: `${deviceBasedDynamicDimension(24, true, 1)} 0`,
        padding: deviceBasedDynamicDimension(32, true, 1),
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderRadius: deviceBasedDynamicDimension(12, true, 1),
        boxShadow: "0px 1px 10px #EBEBEB",
    },
    headerBox:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        "&.responsive":{
            [theme.breakpoints.down(425)]:{
                flexDirection:'column' as const
            }
        }
    },
    imageIcon: {
        width: deviceBasedDynamicDimension(30, true, 1),
        objectFit: 'contain' as const
    },
    titleText: {
        color: 'rgba(5, 23, 32, 1)',
        textAlign: 'left' as const,
        fontFamily: 'Manrope',
        fontSize: deviceBasedDynamicDimension(24, true, 1),
        fontWeight: 600,
        letterSpacing: 1.3,
        textOverflow: "ellipsis",
        overflow:'hidden',
        whiteSpace:'nowrap' as const
    },
    saveButtonBox: {
        height: 'auto',
    },
    saveButton: {
        backgroundColor: theme.palette.primary.main,
        color: "#FFFFFF",
        width: 'auto',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50px",
        padding: `8px 44px`,
        "&:hover": {
            color: "#FFFFFF",
            backgroundColor: theme.palette.primary.main,
        },
        "& .buttonText": {
            fontFamily: "'Manrope', sans-serif",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "20px",
            lineHeight: 1.2,
            textTransform: "initial",
            color: "#FFFFFF",
            whiteSpace: "nowrap"
        }
    },
    childNodeBox: {
        marginTop: deviceBasedDynamicDimension(24, true, 1),
        marginBottom: deviceBasedDynamicDimension(24, true, 1)
    }
});
export default withStyles(style)(InfoWrapper);