import React from "react";
// Customizable Area Start
import { Theme, withStyles, Box, Button, Typography } from "@material-ui/core";
import CartCard from "../../../components/src/CartCard.web";
import Spinner from "../../../components/src/Spinner.web";
import { t } from "../../../components/src/i18n";

export const ShoppingCartStyles = (theme: Theme) => ({
  // Customizable Area Start
  mainConatiner: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    alignItems: "center",
    margin: "40px 0",
    paddingBottom: "40px",
    borderBottom: "1px solid #D8D8D8",
  },
  cartContainer: {
    maxWidth: "1140px",
    boxShadow: "0px 0px 12px -5px gray",
    borderRadius: "20px",
    width: "100%",
    backgroundColor: "rgba(252, 252, 252, 1)",
    maxHeight: "700px",
  },
  cartHeaderConatiner: {
    display: "flex",
    justifyContent: "space-between",
    padding: "30px 30px 0",
    alignItems: "center",
  },
  cartHeading: {
    fontFamily: "manrope",
    fontWeight: "bold" as const,
    fontSize: "28px",
    color: "#004D74",
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
    },
  },
  addMoreText: {
    fontFamily: "manrope" as const,
    fontWeight: "bold" as const,
    fontSize: "22px",
    color: "#004D74",
    textDecoration: "underline",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
  cartListContainer: {
    maxHeight: "600px",
    overflow: "auto" as const,
    padding: "0 30px",
    margin: "20px 0",
  },
  card: {
    backgroundColor: "#f3f3f3",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    boxShadow: "none",
    borderRadius: "12px",
    overflowX: "auto" as const,
  },
  cardMainSection: {
    display: "flex",
    gap: "30px",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      gap: "10px",
      padding: "8px",
    },
  },
  cardLogo: {
    height: "80px",
    minWidth: "80px",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      height: "70px",
      minWidth: "70px",
    },
  },
  cardTitle: {
    fontFamily: "manrope",
    fontWeight: "bold" as const,
    fontSize: "20px",
    color: "rgba(65, 65, 65, 1)",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  cardSubTitle: {
    fontFamily: "manrope",
    fontWeight: "bold" as const,
    fontSize: "18px",
    color: "rgba(137, 145, 164, 1)",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  cardWarrantyId: {
    fontFamily: "manrope",
    fontWeight: "bold" as const,
    fontSize: "18px",
    color: "rgba(137, 145, 164, 1)",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  warantyInitials: {
    fontSize: "24px",
    fontWeight: "bold" as const,
    fontFamily: "manrope",
    color: "rgba(255, 255, 255, 1)",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  cardPrice: {
    fontSize: "24px",
    fontWeight: "bold" as const,
    color: "rgba(0, 132, 67, 1)",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
    },
  },
  cardAction: {
    fontFamily: "manrope" as const,
    fontSize: "16px",
    color: "#004D74",
    cursor: "pointer",
    textDecoration: "underline",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
    },
  },
  cardActionContainer: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "space-between",
    textAlign: "end" as const,
    padding: "16px !important",
    [theme.breakpoints.down("xs")]: {
      padding: "8px !important",
    },
  },
  confirmModalClose: {
    display: "flex",
    justifyContent: "end",
    cursor: "pointer",
    marginBottom: "10px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "40px",
  },
  ConfirmText: {
    fontFamily: "Manrope" as const,
    fontStyle: "normal",
    fontWeight: "bold" as const,
    fontSize: 20,
    lineHeight: 1.5,
    textAlign: "center" as const,
    textTransform: "capitalize" as const,
    color: "#FFFFFF",
  },
  noItemMessage: {
    fontSize: "32px",
    padding: "20px",
    textAlign: "center" as const,
    fontWeight: 600,
    fontFamily: "manrope",
    color: "#6e6e6e",
    [theme.breakpoints.down("xs")]: {
      fontSize: "24px",
      padding: "10px",
    },
  },
  proceedButton: {
    maxWidth: "360px",
    width: "100%",
    borderRadius: "40px",
    padding: "12px 0",
    margin: "0 10px",
  },
  noItemContainer: {
    textAlign: "center" as const,
  },
  cartIcon: {
    width: "200px",
    marginTop: "10px",
    [theme.breakpoints.down("xs")]: {
      width: "150px",
    },
  },
  priceContainer: {
    display: "flex",
    justifyContent: "end",
    gap: "5px",
  },
});
// Customizable Area End

import ShoppingCartOrdersController, {
  CartItem,
  Props,
} from "./ShoppingCartOrdersController";
import { cartIcon } from "./assets";

class ShoppingCartOrders extends ShoppingCartOrdersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, id, navigation } = this.props;
    const { cartData, showLoader } = this.state;
    return (
      <>
        <Spinner showLoader={showLoader} />
        <Box className={classes.mainConatiner}>
          <Box className={classes.cartContainer}>
            <Box className={classes.cartHeaderConatiner}>
              <Box className={classes.cartHeading} data-testId="title">
                {t("shoppingCart.title")}
              </Box>
              <Box
                className={classes.addMoreText}
                data-testId="addMoreBtn"
                onClick={this.handleAddMoreClick}
              >
                {t("shoppingCart.addMore")}
              </Box>
            </Box>
            <Box className={`${classes.cartListContainer} orderDetailsScroll`}>
              {cartData.length ? (
                cartData.map((item: CartItem, index: number) => (
                  <CartCard
                    index={index}
                    key={index}
                    cartData={cartData}
                    CardData={item?.attributes}
                    classes={classes}
                    navigation={navigation}
                    id={id}
                    updateCartData={this.updateCartData}
                    updateInputQuantity={this.updateInputQuantity}
                  />
                ))
              ) : (
                <Box className={classes.noItemContainer}>
                  <img
                    className={classes.cartIcon}
                    alt="cartIcon"
                    src={cartIcon}
                  />
                  <Box className={classes.noItemMessage}>
                    {t("shoppingCart.noCartMessage")}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        {cartData.length !== 0 && (
          <Box className={classes.buttonContainer}>
            <Button
              data-test-id="btnProceed"
              variant="contained"
              color="primary"
              className={classes.proceedButton}
              onClick={this.handleProceed}
            >
              <Typography className={classes.ConfirmText}>
                {t("shoppingCart.proceed")}
              </Typography>
            </Button>
          </Box>
        )}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(ShoppingCartStyles)(ShoppingCartOrders);
export { ShoppingCartOrders };
// Customizable Area End
