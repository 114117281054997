import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  Modal,
  withStyles,
  Theme
} from "@material-ui/core";
import TermsAndConditionsModalController, { Props } from "./TermsAndConditionsModalController.web";
import { t, iDirection } from "../../../components/src/i18n";
import { closeIcon } from "./assets";
// Customizable Area End

// Customizable Area Start
// Customizable Area End
class TermsAndConditionsModal extends TermsAndConditionsModalController{
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { handleClose, isModalOpen, classes } = this.props;
    return (
        <Modal
          open={isModalOpen}
          onClose={handleClose}
          className={classes.root}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.mainBox}>
            <Box className={classes.modalHeader}>
              <Typography className={classes.typography} variant="h4" data-test-id="title">
                {t("termsAndConditions.title")}
              </Typography>
              <img src={closeIcon} alt="closeIcon" onClick={handleClose} />
            </Box>
            <Box className={`${classes.contentContainer} orderDetailsScroll`}>
              {!this.state.termsAndConditions?.length ? <Box className={classes.noTermAndCondition}>{t("termsAndConditions.noTermsAndConditionsAvailable")}</Box> : 
                this.state.termsAndConditions?.map((content: { title: string, description: string }, index: number) => {
                    return (
                        <div key={index} data-test-id="refDiv">
                            <Box className={classes.contentBox} >
                                <Typography className={classes.tableOfContentText} data-test-id={`termsAndConditionTitle-${index}`}>{content.title}</Typography>
                                {/* the description is comming as html content from rich text area */}
                                <Typography
                                    className={classes.tableOfContentItemText}
                                    dangerouslySetInnerHTML={{
                                        __html: `${content.description}`
                                    }}
                                />
                            </Box>
                        </div>
                    )
                })
              }
            </Box>
          </Box>
        </Modal>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const ModalStyles = (theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center" as const,
    justifyContent: "center",
  },
  mainBox: {
    width: "60%",
    height: "70%",
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    padding: "20px 24px",
    overflowY: "hidden" as const,
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center" as const,
    cursor: "pointer",
    direction: iDirection,
  },
  typography: {
    fontWeight: 600,
    fontSize: "32px",
    [theme.breakpoints.down('xs')]:{
      fontSize: "20px",
    }
  },
  contentBox: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'flex-start' as const
  },
  tableOfContentText: {
      width: "100%",
      fontStyle: 'normal',
      fontWeight: 'bold' as const,
      color: 'rgba(0, 77, 116, 1)',
      textAlign: 'left' as const,
      textAlignVertical: 'top',
      fontFamily: 'Manrope' as const,
      wordBreak: "break-word" as const,
      hyphens: 'auto' as const,
      textTransform: 'capitalize' as const,
      [theme.breakpoints.down('xs')]:{
        fontSize: "16px",
      }
  },
  tableOfContentItemText: {
      width: "100%",
      fontStyle: 'normal',
      fontWeight: 'normal' as const,
      color: 'rgba(5, 23, 32, 1)',
      textAlign: 'left' as const,
      textAlignVertical: 'top' ,
      fontFamily: 'Manrope' as const,
      wordBreak: "break-word" as const,
      hyphens: 'auto' as const,
      [theme.breakpoints.down('xs')]:{
        fontSize: "14px",
      }
  },
  contentContainer: {
    overflowY: "auto" as const,
    maxHeight: "90%",
    padding: "10px 20px",
    backgroundColor: "#eaeaea90",
    borderRadius: "10px",
    [theme.breakpoints.down('xs')]:{
      marginTop: "10px",
    }
  },
  noTermAndCondition: {
    padding:"20px",
    fontSize: "24px",
    [theme.breakpoints.down('xs')]:{
      padding:"10px",
      fontSize: "16px",
    }
  }
});

export default withStyles(ModalStyles)(TermsAndConditionsModal);
export { TermsAndConditionsModal };
// Customizable Area End
