// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { callApi, emailReg, getNavigationMessage } from "../../../components/src/Toolkit";
import { setStorageData } from "../../../framework/src/Utilities";
import { iLanguage, t } from "../../../components/src/i18n";
// Customizable Area End

const configJSON = require("./config");

// Customizable Area Start
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes:{
        FPMainContainer:string,
        FPRightGridItem:string,
        FPFormBox:string
    }
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    showLoader: boolean;
    email: string;
    emailError: boolean;
    emailErrorText: string;
    otpGenerated:boolean;
    otpToken:string;
    otp:string;
    otpError:boolean;
    otpErrorText:string;
    isTimerRunning:boolean;
    seconds:number;
    minutes:number;
    otpTimer:any;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ForgotPasswordontroller extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiSendOtp: string = "";
    apiVerifyOtp:string = "";
    apiResendOtp:string = "";
    // Customizable Area End  

    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials)
        ];

        this.state = {
            showLoader: false,
            email: '',
            emailError: false,
            emailErrorText: '',
            otpGenerated:false,
            otpToken:'',
            otp:'',
            otpError:false,
            otpErrorText:'',
            isTimerRunning:false,
            seconds:0,
            minutes:2,
            otpTimer:null
        };

        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (responseJson != null) {
                if (!responseJson.errors) {
                    if (apiRequestCallId === this.apiSendOtp || apiRequestCallId === this.apiResendOtp) {
                        this.setState({
                            otpGenerated:true,
                            otpToken:responseJson.meta.token,
                            seconds:0,
                            minutes:2
                        }, () => {
                            this.startOtpTimer();
                        })
                    }else if(apiRequestCallId === this.apiVerifyOtp){
                        await setStorageData('changeToken', responseJson.token);
                        this.send(getNavigationMessage('ResetPassword', this.props));
                    }
                } else {
                    this.parseApiError(responseJson, apiRequestCallId)
                }
            }
            this.setLoading(false)
            this.parseApiCatchErrorResponse(errorReponse);

        }
        // Customizable Area End
    }
    // Customizable Area Start
    parseApiError = (apiResponse: any, apiRequestCallId: string) => {
        let errors = apiResponse.errors;
        let keys: any = Object.keys(errors[0]);
        let values: any = Object.values(errors[0]);
        if (apiRequestCallId === this.apiSendOtp) {
            if (keys[0] === 'account' || keys[0] === 'otp') {
                this.setState({
                    emailError: true,
                    emailErrorText: values[0]
                })
            }
        } else if(apiRequestCallId === this.apiVerifyOtp || apiRequestCallId === this.apiResendOtp) {
            if (keys[0] === 'otp') {
                this.setState({
                    otpError: true,
                    otpErrorText: values[0]
                })
            }
        } else{
            this.parseApiErrorResponse(apiResponse);
        }
    }
    isValidEmail = (email: string) => {
        let isValid: boolean = true;
        
        if (email.length === 0) {
            this.setState({
                emailError: true,
                emailErrorText: t("forgotPassword.validations.emailRequired"),
            });
            isValid = false;
        } else if (!emailReg.test(email)) {
            this.setState({
                emailError: true,
                emailErrorText: t("forgotPassword.validations.emailNotValid"),
            });
            isValid = false;
        } else if (emailReg.test(email)) {
            this.setState({
                emailError: false,
                emailErrorText: "",
            });
        }

        return isValid;
    }
    handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim();
        this.setState({ email: value });
        this.isValidEmail(value)
    }
    handleEmailBlur = () => {
        this.isValidEmail(this.state.email)
    }
    gotoLogin = () => {
        this.send(getNavigationMessage('Login', this.props));
    }
    setLoading = (isLoading:boolean) => {
        this.setState({
            showLoader:isLoading
        })
    }
    onOtpChange = (newOtp:string) => {
        this.setState({
            otp:newOtp
        })
        this.validateOtp(newOtp);
    }
    startOtpTimer = () => {
        this.setState({
            otpTimer: setInterval(this.tick, 1000),
            isTimerRunning:true
        })
    }
    stopOtpTimer = () => {
        clearInterval(this.state.otpTimer);
        this.setState({
            isTimerRunning:false
        })
    }
    tick = () => {
        const { seconds, minutes } = this.state;
        if (seconds > 0) {
            this.setState(prevState => ({
                seconds: prevState.seconds - 1
            }), () => {
                if (this.state.seconds <= 0 && this.state.minutes <= 0) {
                    this.stopOtpTimer();
                }
            });
        }

        if (seconds === 0) {
            if (minutes === 0) {
                this.stopOtpTimer()
            } else {
                this.setState(prevState => ({
                    seconds: 59,
                    minutes: prevState.minutes - 1
                }));
            }
        }
    }
    onSubmit = (event: any) => {
        event.preventDefault();
        if(this.isValidEmail(this.state.email)){
            this.setLoading(true);
            const payload = {
                language_code:iLanguage,
                data: {
                    attributes: {
                        "email": this.state.email,
                    },
                    type: "email_account"
                }
            }
            this.apiSendOtp = callApi({
                contentType: configJSON.forgotPasswordAPiContentType,
                method: configJSON.POST,
                endPoint: configJSON.forgotPasswordSendOtpEndPoint,
                headers: {},
                body: JSON.stringify(payload)
            }, runEngine)
        }
    }
    validateOtp = (otp: string): boolean => {
        if (otp.length < 6) {
            this.setState({
                otpError: true,
                otpErrorText: t("forgotPassword.validations.otpNotValid")
            })
            return false;
        }else{
            this.setState({
                otpError: false,
                otpErrorText: ""
            })
            return true;
        }

    }
    verifyOtp = () => {
        if(this.validateOtp(this.state.otp)){
            this.setState({
                showLoader: true
            });
            const httpBody: any = {
                language_code:iLanguage,
                data: { otp_code: this.state.otp, token: this.state.otpToken }
            }

            this.apiVerifyOtp = callApi({
                contentType: configJSON.forgotPasswordAPiContentType,
                method: configJSON.POST,
                endPoint: configJSON.forgotPasswordVerifyOtpEndPoint,
                headers: {},
                body: JSON.stringify(httpBody)
            }, runEngine);
        }
    }
    resendOtp = () => {
        this.setLoading(true);
        const httpBody: any = {
            language_code:iLanguage,
            data: { token: this.state.otpToken }
        }

        this.apiResendOtp = callApi({
            contentType: configJSON.forgotPasswordAPiContentType,
            method: configJSON.POST,
            endPoint: configJSON.forgotPasswordResendOtpEndPoint,
            headers: {},
            body: JSON.stringify(httpBody)
        }, runEngine);
    }
    // Customizable Area End
}