// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { callApi, getNavigationMessage } from "../../../components/src/Toolkit";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { iLanguage } from "../../../components/src/i18n";
// Customizable Area End

const configJSON = require("./config");

// Customizable Area Start
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes:{
        RPMainContainer:string,
        RPRightGridItem:string,
        RPFormBox:string,
        PasswordChecksRow:string
    }
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    showLoader: boolean;
    changeToken: string;
    firstPasswordVisible: boolean;
    firstPassword: string;
    secondPasswordVisible: boolean;
    secondPassword: string;
    apiError: string;
    openSuccessModal:boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class ResetPasswordontroller extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiResetPassword: string = "";
    // Customizable Area End  

    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials)
        ];

        this.state = {
            showLoader: false,
            changeToken: '',
            firstPasswordVisible: false,
            firstPassword: '',
            secondPasswordVisible: false,
            secondPassword: '',
            apiError: '',
            openSuccessModal:false
        }

        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        const changeToken = await getStorageData('changeToken');
        if (changeToken) {
            this.setState({ changeToken })
        } else {
            this.send(getNavigationMessage('ForgotPasswordWeb', this.props));
        }
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (responseJson != null) {
                if (!responseJson.errors) {
                    if (apiRequestCallId === this.apiResetPassword) {
                        this.setState({
                            openSuccessModal:true
                        })
                    }
                } else {
                    this.parseApiError(responseJson, apiRequestCallId)
                }
            }
            this.setLoading(false)
            this.parseApiCatchErrorResponse(errorReponse);

        }
        // Customizable Area End
    }
    // Customizable Area Start
    parseApiError = (apiResponse: any, apiRequestCallId: string) => {
        let errors = apiResponse.errors;
        let values: any = Object.values(errors[0]);
        if (apiRequestCallId === this.apiResetPassword) {
            this.setState({
                apiError: values[0]
            })
        } else {
            this.parseApiErrorResponse(apiResponse);
        }
    }
    setLoading = (isLoading: boolean) => {
        this.setState({
            showLoader: isLoading
        })
    }
    toggleFirstPasswordVisibility = () => {
        this.setState({ firstPasswordVisible: !this.state.firstPasswordVisible });
    }
    handleFirstPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ firstPassword: event.target.value });
    }
    toggleSecondPasswordVisibility = () => {
        this.setState({ secondPasswordVisible: !this.state.secondPasswordVisible });
    }
    handleSecondPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ secondPassword: event.target.value });
    }
    getTwoPasswordValidations = (): { passwordUCTest: boolean, passwordLCTest: boolean, passwordNumberTest: boolean, min8LengthPassword: boolean, passwordEqual: boolean, allConditionSatified: boolean } => {
        const { firstPassword, secondPassword } = this.state;
        const passwordUCTest: boolean = configJSON.passwordUpperCase.test(firstPassword) && configJSON.passwordUpperCase.test(secondPassword);
        const passwordLCTest: boolean = configJSON.passwordLowerCase.test(firstPassword) && configJSON.passwordLowerCase.test(secondPassword);
        const passwordNumberTest: boolean = configJSON.passwordOneNumbereReg.test(firstPassword) && configJSON.passwordOneNumbereReg.test(secondPassword);
        const min8LengthPassword: boolean = firstPassword.length >= 8 && secondPassword.length >= 8;
        const passwordEqual: boolean = firstPassword === secondPassword;
        const allConditionSatified: boolean = passwordUCTest && passwordLCTest && passwordNumberTest && min8LengthPassword;
        return { passwordUCTest, passwordLCTest, passwordNumberTest, min8LengthPassword, passwordEqual, allConditionSatified }
    }

    isPasswordValid = () => {
        const { passwordEqual, allConditionSatified } = this.getTwoPasswordValidations();
        if ((passwordEqual) && (allConditionSatified)) {
            return true;
        }
        return false;
    }
    goToHome = async () => {
        await removeStorageData('changeToken');
        this.send(getNavigationMessage('Home', this.props));
    }
    
    resetPassword = () => {
        if (this.isPasswordValid()) {
            this.setLoading(true);
            this.setState({
                apiError: ''
            })
            const payload = {
                language_code:iLanguage,
                data: {
                    "token": this.state.changeToken,
                    "new_password": this.state.firstPassword,
                    "repeat_password": this.state.secondPassword,
                }
            }
            this.apiResetPassword = callApi({
                contentType: configJSON.forgotPasswordAPiContentType,
                method: configJSON.POST,
                endPoint: configJSON.forgotPasswordSetPasswordEndPoint,
                headers: {},
                body: JSON.stringify(payload)
            }, runEngine)
        }
    }
    // Customizable Area End
}