Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TermsAndConditions3";
exports.labelBodyText = "TermsAndConditions3 Body";

exports.btnExampleTitle = "CLICK ME";
exports.termAndConditionApiEndPoint = 'bx_block_country/countries/';
exports.howItWorksApiEndPoint = "bx_block_guide/process_overviews";
exports.focusOnElement = {
  behavior: "smooth",
  block: "start",
  inline: "start"
}
// Customizable Area End