import React from "react";
// Customizable Area Start
import { BlockComponent } from "../../framework/src/BlockComponent";
import {
    Box,
    withStyles,
    WithStyles,
    Theme,
    Typography
} from "@material-ui/core";
import { t, iLanguage } from "./i18n";
import { deviceBasedDynamicDimension } from "./Toolkit";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props extends WithStyles {
    // Customizable Area Start
    tableContent?: Array<string>;
    onItemClick?:(index:number) => void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area End

export class TableOfContent extends BlockComponent<Props, S, SS> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        // Customizable Area Start
        const { classes, tableContent, onItemClick } = this.props;
        const handleContentClick = (index:number) => {
            if(onItemClick){
                onItemClick(index);
            }
        }
        // Customizable Area End
        return (
            <>
                {/* Customizable Area Start */}
                <Box className={classes.MainBox}>
                    <Typography component={'h3'} className={classes.tableOfContentText}>{t("termsAndConditions.tableContent")}</Typography>
                    {
                        tableContent?.map((content: string, index: number) => (
                            <Typography key={index} data-test-id="tocTitle" onClick={handleContentClick.bind(this, index)} className={classes.tableOfContentItemText}>{`${index+1}.`}{content}</Typography>
                        ))
                    }
                </Box>
                {/* Customizable End Start */}
            </>
        );
    }
}

// Customizable Area Start
const styles = (theme: Theme) => ({
    MainBox: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'flex-start' as const,
        padding:"0px 8px",
        position:'sticky' as const,
        top:'8%',
        [theme.breakpoints.down('xs')]:{
            alignItems:'center' as const
        }
    },
    tableOfContentText: {
        width:"100%",
        fontStyle: 'normal',
        fontWeight: 'bold' as const,
        color: 'rgba(0, 77, 116, 1)',
        textAlign: 'left' as const,
        textAlignVertical: 'top',
        fontFamily: 'Manrope',
        lineHeight:1.5,
        wordBreak:"break-word"  as const,
        hyphens:'auto' as const,
        fontSize: deviceBasedDynamicDimension(32, true, 1),
        padding: deviceBasedDynamicDimension(0, true, 1),
        [theme.breakpoints.down('xs')]:{
          fontSize:deviceBasedDynamicDimension(22, true, 1),
        }
    },
    tableOfContentItemText:{
        width:"100%",
        fontStyle: 'normal',
        fontWeight: 'bold' as const,
        color: 'rgba(5, 23, 32, 1)',
        textAlign: 'left' as const,
        textAlignVertical: 'top',
        fontFamily: 'Manrope',
        lineHeight:2,
        cursor:'pointer',
        wordBreak:"break-word"  as const,
        hyphens:'auto' as const,
        fontSize: deviceBasedDynamicDimension(24, true, 1),
        padding: deviceBasedDynamicDimension(0, true, 1),
        [theme.breakpoints.down('xs')]:{
          fontSize:deviceBasedDynamicDimension(18, true, 1),
        }
    }
})
export default withStyles(styles)(TableOfContent);
// Customizable Area End
