import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import React from "react";
import { callApi } from "../../../components/src/Toolkit";
import { getStorageData } from "../../../framework/src/Utilities";
import { iLanguage } from "../../../components/src/i18n";
export const configJSON = require("./config");
// Customizable Area End

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleClose: () => void;
  isModalOpen: boolean;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedCountryData:{
    id:string | undefined
  };
  termsAndConditions:Array<{
    title:string,
    description:string
  }>;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class TermsAndConditionsModalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetTermsAndCondition: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      selectedCountryData:{
        id:undefined
      },
      termsAndConditions:[],
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const selectedCountryData = await getStorageData('selectedCountry', true);
    this.setState({
      selectedCountryData
    },() => {
      this.getTermsAndConditionsData()
    })
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJsonData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponseData = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJsonData != null) {
        if (!responseJsonData.errors) {
          if (apiRequestCallId === this.apiGetTermsAndCondition) {
            this.setState({
              termsAndConditions:responseJsonData.terms_and_conditions?.data?.attributes.tncs,
            })
          }
        } else {
          this.parseApiErrorResponse(responseJsonData);
        }
      }
      this.parseApiCatchErrorResponse(errorReponseData);
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getTermsAndConditionsData = () => {
    this.apiGetTermsAndCondition = callApi({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.termAndConditionApiEndPoint}${this.state.selectedCountryData.id}/terms_and_conditions?language_code=${iLanguage}`,
      headers: {}
    }, runEngine);
  }

  // Customizable Area End
}
