import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { getStorageData } from "../../../framework/src/Utilities";
import { callApi, getNavigationMessage } from "../../../components/src/Toolkit";
import { iLanguage } from "../../../components/src/i18n";
const configJSON = require("./config");
// Customizable Area End

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  countryInfo: {
    attributes: {
      address: string;
      country_name: string;
      email: string;
      phone_number: string;
      country_code: string;
    };
  };
  aboutUsDescription:string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AboutUsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetAboutUsDetails: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      countryInfo: {
        attributes: {
          country_code: "",
          country_name: "",
          email: "",
          address: "",
          phone_number: ""
        }
      },
      aboutUsDescription:''
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getAboutUsData();
    const selectedCountry = await getStorageData("selectedCountry", true);
    if (selectedCountry) {
      this.setState({
        countryInfo: selectedCountry
      });
    } else {
      this.send(getNavigationMessage("Home", this.props));
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson != null) {
        if (!responseJson.errors) {
          if (apiRequestCallId === this.apiGetAboutUsDetails) {
            this.setState({
              aboutUsDescription: responseJson.data[0]?.attributes.description
            });
          }
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getAboutUsData = () => {
    this.apiGetAboutUsDetails = callApi({
      contentType: configJSON.jsonApiContentType,
      method: configJSON.GET,
      endPoint: `${configJSON.aboutUsEndPoint}?language_code=${iLanguage}`,
      headers: {}
    }, runEngine);
  };
  // Customizable Area End
}
