import React from "react";
// Customizable Area Start
import {
  Typography,
  Card,
  CardContent,
  Box,
} from "@material-ui/core";
import { t } from "./i18n";
import CartCardController, { Props } from "./CartCardController.web";
import RemoveItemModal from './Common/RemoveItemModal.web'
import QuantityBox from './Common/QuantityBox.web'
// Customizable Area End

class CartCard extends CartCardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  render() {
    const { classes, index, CardData, updateInputQuantity } = this.props;
    const { isModelOpen, currency_symbol } = this.state;
    return (
      <>
        {/* Customizable Area Start */}
        <Card className={classes.card}>
          <CardContent className={classes.cardMainSection}>
            <Box
              className={classes.cardLogo}
              style={{ background: CardData?.details?.category_bg_color, color: CardData?.details?.category_text_color }}
            >
              <Box className={classes.warantyInitials}>{this.shortWarrantyTitle(CardData?.details?.warranty_type)}</Box>
            </Box>
            <Box>
              <Typography variant="h6" className={classes.cardTitle} dir="ltr">
                {CardData.details.warranty_name}
              </Typography>
              <Typography variant="subtitle1" className={classes.cardSubTitle}>
                {t("shoppingCart.item")} -
                {" " + CardData.details.item_name}
              </Typography>
              <Typography
                variant="subtitle2"
                className={classes.cardWarrantyId}
              >
                {t("shoppingCart.warrantyNo")} :
                {" " + CardData.details.item_invoice_id}
              </Typography>
              <QuantityBox itemIndex={index} quantity={CardData.quantity} updateQuantity={updateInputQuantity} onDeleteItemClick={this.toggleModal}  />
            </Box>

          </CardContent>
          <CardContent className={classes.cardActionContainer}>
            <Box className={classes.priceContainer} dir="ltr">
              <Typography variant="h6" className={classes.cardPrice}>{currency_symbol}</Typography>
              <Typography variant="h6" className={classes.cardPrice}>
                {
                Number(CardData.total_price).toFixed(2)
                }
              </Typography>
            </Box>
            <Box>
              <Typography
                className={classes.cardAction}
                onClick={this.handleEdit}
              >
                {t("shoppingCart.edit")}
              </Typography>
              <Typography
                className={classes.cardAction}
                onClick={this.toggleModal}
                data-test-id="remove-button"
              >
                {t("shoppingCart.remove")}
              </Typography>
            </Box>
          </CardContent>
        </Card>
        <RemoveItemModal itemId={CardData.id} removeItem={this.handleDeleteCartItem} isModalOpen={isModelOpen} closeModal={this.toggleModal} />
        {/* Customizable End Start */}
      </>
    );
  } 
  // Customizable Area End
}

// Customizable Area Start

export default CartCard;

// Customizable Area End
