Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET"
exports.httpPostMethod = "POST"
exports.httpPutMethod = "PUT"
exports.httpDeleteMethod = "DELETE"

exports.ordersApiContentType = "application/json";
exports.ordersAPiEndPoint = "order_management/orders";
exports.rateAPiEndPoint = "catalogue/reviews";
exports.orderDetailsEndPoint = "bx_block_order_management/orders/cart";
exports.paymentEndPoint = "bx_block_order_management/payments"
exports.getCountriesListEndPoint = "bx_block_country/countries";
exports.addCustomerInformationEndPoint="bx_block_order_management/addresses";
exports.getUserInformationEndPoint="account_block/accounts/show";
exports.paymentEndPoint = "bx_block_order_management/payments";
exports.surveyQuestionEndPoint = "bx_block_surveys/surveys";
exports.orderStatusEndPoint = "bx_block_order_management/payments/fetch_payment_id";
exports.myFatoraahPaymentEndPoint = "bx_block_order_management/myfatoorah_payments";
exports.warrantyPurchaseCompleted = "Warranty Purchase Completed";
exports.cartItems = "Cart Abandoned";
exports.TRANSACTION_FEES = "Transaction Fee"
exports.checkoutPaymentEvent = "Proceed To Checkout Warranty Plan Payment";
exports.orderItemsEndPoint = "bx_block_order_management/order_items";
// Customizable Area End