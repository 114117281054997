import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../framework/src/Utilities";
import { iLanguage, t } from "./i18n";
import { callApi } from "./Toolkit";
export const configJSON = require("./config");
// Customizable Area End


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes:{
        mainContainer:string;
        footerBox:string;
        FooterLinkBox:string;
        HorizonatalDivider:string;
        RightReserveBox:string;
    }
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isModelOpen:boolean;
    poweredBy:string;
    pathName: string;
    hideSocialIcon: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class FooterController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    apiGetServiceProvider:string = "";
    // Customizable Area End    

    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials)
        ];

        this.state = {
            isModelOpen:false,
            poweredBy:'',
            pathName: '',
            hideSocialIcon: false
        };

        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const array = window.location.pathname.split("/");
        const pathName = array[array.length - 1];
        this.setState({ pathName: pathName })
        const countrySelected = await getStorageData('selectedCountry',true);
        if(countrySelected?.id){
            this.getServiceProvider(countrySelected.id)
        }
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const messageId = getName(MessageEnum.RestAPIResponceMessage);
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        if (messageId === message.id) {
            if (responseJson != null) {
                if (!responseJson.errors) {
                    if (apiRequestCallId === this.apiGetServiceProvider) {
                        this.setServiceProvider(responseJson);
                    }
                }else {
                    this.parseApiErrorResponse(responseJson);
                }
            }
            this.parseApiCatchErrorResponse(errorReponse);
        }
        // Customizable Area End
    }

    // Customizable Area Start

    setServiceProvider = (responseJson:any) => {
        const serviceProviderName:string = responseJson.data.attributes.service_provider_name || '';
        const poweredBy = serviceProviderName.trim().length > 0 ? `${t("footer.poweredBy")} ${serviceProviderName.trim()}` : ''
        this.setState({poweredBy})
        setStorageData("selectedCountry", JSON.stringify(responseJson.data));
    }

    getServiceProvider = (countryId:string) => {
        this.apiGetServiceProvider =callApi(
            {
              contentType: configJSON.jsonApiContentType,
              method: configJSON.GET,
              endPoint: configJSON.getServiceProviderEndPoint + `/${countryId}?language_code=${iLanguage}`,
              headers: {},
            },
            runEngine
          );
    }

    handleModelOpen = () => {
        this.setState({ isModelOpen: !this.state.isModelOpen })
    }

    handleHideSocialMediaIcon = (value: boolean = false) => {
        this.setState({ hideSocialIcon: !value })
    }
    // Customizable Area End
}
