import React from "react";
// Customizable Area Start
import { Grid, Box, Paper, TextField, Typography, FormControlLabel, Button, InputAdornment, IconButton, withStyles, Theme } from '@material-ui/core'
import { imgSignInLeft } from "./assets";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import Spinner from "../../../components/src/Spinner.web";

import WarrantyCheckBox from "../../../components/src/WarrantyCheckBox.web";
export const PasswordEndVisibilityToggleLogin = (props: { toggleVisibility: () => void, visibility: boolean }) => {
    return (<InputAdornment position="end">
        <IconButton
            onClick={props.toggleVisibility}
            data-testId="passwordToggleBtn"
        >
            {props.visibility ? (
                <VisibilityOutlinedIcon htmlColor="#004D74" />
                ) : (
                <VisibilityOffOutlinedIcon htmlColor="#004D74" />
            )}
        </IconButton>
    </InputAdornment>);
}
import RegisterButton from "./RegisterButton.web";
import { InputField, flexBetween, flexCenter, inputCheckbox, mainContainer, rtlOffInputClasses } from "../../../components/src/CommonStyles";
import SearchEngineOptimisationseo from "../../SearchEngineOptimisationseo/src/SearchEngineOptimisationseo.web";
// Customizable Area End

import LoginController, {
    Props
} from "./LoginController.web";
import LeftImageGrid from "./LeftImageGrid.web";
import { iLanguage, t } from "../../../components/src/i18n";

export class Login extends LoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { showLoader } = this.state;
        const { classes } = this.props;
        return (
            <Box>
                <SearchEngineOptimisationseo title={t("common.signIn")!} navigation={this.props.navigation} id={this.props.id} />
                <Spinner showLoader={showLoader} />
                <Grid container className={classes.MainContainerLI}>

                    <LeftImageGrid imgSrc={imgSignInLeft} />

                    <Grid item xs={12} md={6}>
                        {/* Signin Form */}
                        <Box className={classes.LoginFormBox}>

                            <Paper elevation={2} className="LINFormPaper">
                                <Box className="LINTitleContainer">
                                    <Typography className={`LogInTitle`}>{t("login.signinTitle")}</Typography>
                                    <Typography className={`LogInSubTitle`}>{t("login.signinSubtitle")}</Typography>
                                </Box>
                                <form onSubmit={this.onSubmit}>
                                    <TextField
                                        data-testId="EmailInput"
                                        placeholder={t("common.emailAddress")!}
                                        label={t("common.emailAddress")}
                                        className="InputField RTLOFF"
                                        variant="outlined"
                                        value={this.state.email}
                                        onChange={this.handleEmailChange}
                                        onBlur={this.handleEmailBlur}
                                        error={this.state.emailError}
                                        helperText={this.state.emailErrorText}
                                        InputProps={{
                                            classes:{
                                                input:`${iLanguage == 'ar' && "RTLPlaceholder"}`,
                                                root:`${iLanguage == 'ar' && "RTLFieldSet"}`
                                            }
                                        }}
                                    />

                                    <TextField
                                        data-testId="PasswordInput"
                                        placeholder={t("common.password")!}
                                        label={t("common.password")}
                                        className="InputField"
                                        type={this.state.passwordVisible ? "text" : "password"}
                                        variant="outlined"
                                        value={this.state.password}
                                        onChange={this.handlePasswordChange}
                                        error={this.state.passwordError}
                                        helperText={this.state.passwordErrorText}
                                        InputProps={{
                                            endAdornment: (<PasswordEndVisibilityToggleLogin toggleVisibility={this.togglePasswordVisibility} visibility={this.state.passwordVisible} />)
                                        }}
                                    />
                                    <Box className={classes.RememberBox}>
                                        <FormControlLabel
                                            control={<WarrantyCheckBox checked={this.state.remeberMeCheck} onChange={this.handleRememberMe} />}
                                            label={t("login.rememberMe")}
                                            className="InputCheckbox checkIconBox"
                                            data-testId="TermCheckbox"
                                        />
                                        <Button
                                            variant="text"
                                            className={classes.ForgotPasswodBtn}
                                            onClick={this.onForgotPasswordClick}
                                        >
                                            <Typography className="ForgotPasswordText">
                                                {t("login.forgotPassword")}
                                            </Typography>
                                        </Button>
                                    </Box>

                                    <Grid container className="LogInButtonGrid">
                                        <Grid item xs={12} sm={6} className="LoginButtonGridItem">
                                            <Button
                                                variant="text"
                                                className="LogInOTPButton"
                                                onClick={this.onClickLoginWithOtp}
                                            >
                                                <Typography className="LoginText">
                                                    {t("login.signinWithOtp")}
                                                </Typography>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={6} className="LoginButtonGridItem">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className="LogInButton"
                                                type="submit"
                                                data-testId="LogInBtn"
                                            >
                                                <Typography className="LogInText">
                                                    {t("common.signIn")}
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <RegisterButton onRegisterNowClick={this.onRegisterNowClick} />

                                </form>
                            </Paper>

                        </Box>
                    </Grid>
                </Grid>
            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start

export const loginStyle = (theme:Theme) => ({
    MainContainerLI:{
        ...mainContainer,
        "& .LeftImageGridL":{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            "& .LeftImageL": {
                height: '70%',
                width: '80%',
                objectFit: 'contain',
                marginBottom: 48
            }
        }
    },
    LoginFormBox:{
        display: "flex",
        justifyContent: "flex-start",
        padding: "24px",
        [theme.breakpoints.down('md')]:{
            justifyContent: "center",
        },
        "& .LINFormPaper":{
            width: "100%",
            maxWidth: "598px",
            padding: "48px",
            borderRadius: "20px",
            boxShadow: "0px 1px 10px #EBEBEB",
            marginTop: "48px",
            [theme.breakpoints.down('md')]:{
                padding:24
            },
            "& .LINTitleContainer":{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginBottom: "16px",
                "& .LogInTitle":{
                    fontFamily: "'Manrope', sans-serif",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "28px",
                    lineHeight: 1.5,
                    letterSpacing: 2,
                    color: "#515151"
                },
                "& .LogInSubTitle":{
                    fontFamily: "'Manrope', sans-serif",
                    fontStyle: "normal",
                    fontWeight: "700",
                    fontSize: "16px",
                    lineHeight: 1.5,
                    color: "#01BEFE",
                    mixBlendMode: "normal",
                    opacity: 0.5
                }
            },
            "& .InputField":InputField,
            "& .InputCheckbox":inputCheckbox(theme),
            "& .checkIconBox": {
                marginLeft: 0
            },
            "& .LogInButtonGrid":{
                marginTop: 32,
                "& .LogInOTPButton":{
                    "& .LoginText":{
                        fontFamily: "'Poppins', sans-serif",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "20px",
                        lineHeight: 1.2,
                        textTransform: "initial",
                        color: theme.palette.text.primary,
                        whiteSpace: "nowrap"
                    }
                },
                "& .LoginButtonGridItem":{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "8px",
                    marginBottom: "8px",
                    "& .LogInButton": {
                        backgroundColor: theme.palette.primary.main,
                        color: "#FFFFFF",
                        height: "64px",
                        width: "90%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50px"
                    }
                },
                "& .LoginText":{
                    fontFamily: "'Manrope', sans-serif",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: 1.2,
                    textTransform: "initial",
                    color: "#FFFFFF",
                    whiteSpace: "nowrap"
                },
                "& .LogInButtonOtp":{
                    ...flexCenter,
                    backgroundColor: theme.palette.primary.main,
                    color: "#FFFFFF",
                    height: 64,
                    width: 360,
                    borderRadius: '50px',
                    "& :hover":{
                        backgroundColor: theme.palette.primary.main,
                    }
                }
            },
            ...rtlOffInputClasses
        }
    },
    RememberBox:{
        ...flexBetween,
        flexWrap:'wrap' as const
    },
    ForgotPasswodBtn:{
        "& .ForgotPasswordText":{
            fontFamily: "'Poppins', sans-serif",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: 1.2,
            textTransform: "initial",
            color: theme.palette.text.primary,
            [theme.breakpoints.down('sm')]:{
                fontSize: 16,
            }
        }
    }
})

export default withStyles(loginStyle)(Login);
// Customizable Area End