import React, { Component } from 'react';
import { Box, Typography, withStyles, WithStyles, Modal, Button, Theme } from '@material-ui/core';
import { iDirection, t } from './i18n';
import OTPInput from 'react-otp-input';

interface Props extends WithStyles {
    isOpen: boolean;
    hasError: boolean;
    errorText: string;
    setError: (hasError: boolean, errorText: string) => void;
    defaultMinutes: number;
    defaultSeconds: number;
    resendOtp: () => void;
    otpResendCount: number;
    verifyOtp: (passedOtp: string) => void;
    closeModal?: () => void;
}

interface S {
    otp: string;
    seconds: number;
    minutes: number;
    timer:any;
}

interface SS {

}
class OtpVerificationModal extends Component<Props, S, SS> {

    constructor(props: Props) {
        super(props);
        
        this.state = {
            otp: "",
            seconds: props.defaultSeconds,
            minutes: props.defaultMinutes,
            timer:null
        }
        
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isOpen && !prevProps.isOpen) {
            clearInterval(this.state.timer)
            this.setState({
                otp: '',
                minutes: this.props.defaultMinutes,
                seconds: this.props.defaultSeconds
            }, () => {
                this.startTimer();
            })
        }
        if (this.props.otpResendCount !== prevProps.otpResendCount) {
            this.setState({
                minutes: this.props.defaultMinutes,
                seconds: this.props.defaultSeconds
            }, () => {
                this.startTimer();
            })
        }
    }

    startTimer = () => {
        this.setState({
                timer:setInterval(() => {
                const { seconds, minutes } = this.state;
                if (seconds > 0) {
                    this.setState((prevState) => ({
                        seconds: prevState.seconds - 1,
                    }));
                }

                if (seconds === 0) {
                    if (minutes === 0) {
                        clearInterval(this.state.timer);
                    } else {
                        this.setState((prevState) => ({
                            seconds: 59,
                            minutes: prevState.minutes - 1
                        }));
                    }
                }
            }, 1000)
        })
    }

    handleOtpChange = (newOtp: string) => {
        this.setState({
            otp: newOtp
        })
        this.validateOtp(newOtp, false)
    }
    handleResendOtp = () => {
        this.props.resendOtp();
    }
    handleVerifyOtp = () => {
        this.validateOtp(this.state.otp, true);
    }
    validateOtp = (unkownOtp: string, verify: boolean) => {
        if (unkownOtp.length < 6) {
            this.props.setError(true, t("otpVerification.validations.minOtpLengthValidation"))
        } else {
            this.props.setError(false, '')
            if (verify) this.props.verifyOtp(this.state.otp);
        }
    }
    render() {
        const { classes, isOpen, hasError, errorText } = this.props;
        const { otp, minutes, seconds } = this.state;
        return (
            <Modal
                open={isOpen}
                className={classes.VOMBoxModal}
                dir={iDirection}
                data-test-id="OTPModal"
            >
                <Box className={classes.VOMBox}>
                    <Box style={{ padding: "0px 16px" }}>
                        <Box className={classes.VOTitleBox}>
                            <Typography className="Title">{t("otpVerification.title")} </Typography>
                            <Typography className="SubTitle">{t("otpVerification.subTitle")} </Typography>
                            <Typography className="OTPLabel">{t("otpVerification.otpLabel")} </Typography>
                        </Box>
                        <Box className={classes.VOTPBox}>
                            <Box className={`VOTPWrapper ${hasError && 'ErrorOtp'}`}>
                                <OTPInput
                                    numInputs={6}
                                    shouldAutoFocus
                                    onChange={this.handleOtpChange}
                                    value={otp}
                                    inputStyle="OTP"
                                    renderInput={(props: any) => <input {...props} />}
                                    inputType="password"
                                    data-test-id="OTPInput"
                                    containerStyle={{ display: 'grid', gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr", columnGap: 8, direction: 'ltr' }}
                                />
                            </Box>
                        </Box>
                        {hasError &&
                            <Box className={classes.ErrorBox}>
                                <Typography className="ErrorText">{errorText}</Typography>
                            </Box>
                        }
                        <Box className={classes.SendOTPBox}>
                            {seconds > 0 || minutes > 0 ? (
                                <Typography className="ResendOtpText" data-test-id="ResendOtpText">{t("otpVerification.resendOtpIn")} : {minutes < 10 ? `0${minutes}` : minutes}:
                                    {seconds < 10 ? `0${seconds}` : seconds}</Typography>
                            ) : (
                                <Button
                                    variant="text"
                                    className="ResendOtpBtn"
                                    data-test-id="resendOtpBtn"
                                    onClick={this.handleResendOtp}
                                >
                                    <Typography className="ResendOtpText">
                                        {t("otpVerification.resendCode")}
                                    </Typography>
                                </Button>
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                className="CofirmButton"
                                data-test-id="ConfirmBtn"
                                onClick={this.handleVerifyOtp}
                            >
                                <Typography className="ConfirmText">
                                    {t("common.confirm")}
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        );
    }
}

const style = (theme: Theme) => ({
    VOMBoxModal: {
        display: 'flex',
        alignItems: 'center' as const,
        justifyContent: 'center',
    },
    VOMBox: {
        width: '498px',
        backgroundColor: '#FFFFFF',
        borderRadius: 20,
        [theme.breakpoints.down('xs')]: {
            width: '90%'
        }
    },
    VOTitleBox: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column' as const,
        alignItems: 'flex-start' as const,
        marginTop: 32,
        "& .Title": {
            fontFamily: 'Manrope, sans-serif',
            fontStyle: 'normal',
            fontWeight: 700,
            fontSize: 36,
            lineHeight: 1.5,
            color: '#35414F',
            padding: '0px 32px',
            [theme.breakpoints.down('xs')]: {
                padding: '0px 16px'
            }
        },
        "& .SubTitle": {
            fontFamily: 'Inter, sans-serif',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 16,
            color: '#747A80',
            padding: '0px 32px',
            [theme.breakpoints.down('xs')]: {
                padding: '0px 16px'
            }
        },
        "& .OTPLabel": {
            fontFamily: 'Inter, sans-serif',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 14,
            color: '#000000',
            padding: '0px 32px',
            marginTop: 32,
            marginBottom: 12,
            [theme.breakpoints.down('xs')]: {
                padding: '0px 16px'
            }
        }
    },
    VOTPBox: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column' as const,
        alignItems: 'flex-start' as const,
        "& .VOTPWrapper": {
            padding: '0 5%',
            "&.ErrorOtp": {
                "& input": {
                    color: theme.palette.error.main,
                }
            },
            [theme.breakpoints.down('xs')]: {
                padding: '0px 8px'
            }
        },
        "& .OTP": {
            width: '100% !important',
            height: '3.5rem',
            margin: '0 .5rem',
            fontSize: '1.5rem',
            border: '1px solid #CFDBD5',
            borderRadius: 8,
            [theme.breakpoints.down('xs')]: {
                height: '2.5rem',
                fontSize: '1rem',
            }
        }
    },
    ErrorBox: {
        "& .ErrorText": {
            fontFamily: 'Inter, sans-serif',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 14,
            lineHeight: 1.5,
            padding: '0 6%',
            color: theme.palette.error.main,
            marginTop: 4,
            textAlign: 'end' as const,
        }
    },
    SendOTPBox: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center' as const,
        margin: '32px 0px',
        "& .ResendOtpBtn": {
            "& .ResendOtpText": {
                color: '#004d74',
            }
        },
        "& .CofirmButton": {
            background: '#004D74',
            borderRadius: 50,
            padding: '12px 72px',
            marginTop: 16,
            "&:hover": {
                backgroundColor: '#004D74'
            }
        },
        "& .ConfirmText": {
            fontFamily: 'Manrope, sans-serif',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 20,
            lineHeight: 1.5,
            textAlign: 'center',
            textTransform: 'initial',
            color: '#FFFFFF',
        },
        "& .ResendOtpText": {
            fontFamily: 'Inter, sans-serif',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 16,
            lineHeight: 1.5,
            textDecorationLine: 'underline' as const,
            color: '#747A80',
            textTransform: 'initial' as const,
        }
    }
});
export default withStyles(style)(OtpVerificationModal);