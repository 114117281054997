import React from "react";
// Customizable Area Start
import {
    Box,  
    Theme,Button,
    withStyles, Divider
} from "@material-ui/core";
import { deviceBasedDynamicDimension } from "../../../components/src/Toolkit";
export const orderDetailsStyle = (theme: Theme) => ({
    orderDetailsMain: {
        width: "1192px",
        margin: "0px auto",
        padding: "0px 20px",
        paddingRight: "0px",
        backgroundColor: '#FFFFFF',
        boxShadow: "1px 1px 10px 0px rgba(0,0,0,0.2)",
        borderRadius: "20px",
       [theme.breakpoints.down('md')] : {
         width: 'auto',
         margin: 0,
         padding: 12,
         boxShadow: 'none'
       },
        "& .orderDetailsScroll": {
            height: "550px",
            overflow: 'scroll' as const,
            overflowX: 'auto' as const,
            overflowY: "auto" as const,
            margin: "20px auto",
            padding: "0px 20px 10px 30px",
            [theme.breakpoints.down('md')] : {
              height: '100%'
            },
            [theme.breakpoints.down('xs')] : {
              padding:8
            }
            
        },
    },
    orderDetailTitleContainer:{
        padding: "30px 30px 10px 30px",
        [theme.breakpoints.down('md')]:{
        padding: "20px 0px 10px 0px"
        }
    },
    orderDetailTitle: {
        fontWeight: 'bold' as const,
        color: 'rgba(0, 77, 116, 1)',
        textAlign: 'left' as const,
        fontFamily: 'Manrope',
        fontSize: deviceBasedDynamicDimension(28, true, 1),
        
        [theme.breakpoints.down('xs')]: {
          textAlign: "center" as const,
          borderBottom: "1px solid #bdbdbd",
          color: 'gray'
        },
        [theme.breakpoints.between('sm','md')]: {
          display: 'none'
        }
    },
    cardContainerMain: {
        boxSizing: 'border-box' as const,
        boxShadow: '0px 1px 10px #EBEBEB',
        padding: "20px",
        paddingRight: '15px',
        width: '100%',
        borderRadius: "13px",
        backgroundColor: '#f3f3f3',
        [theme.breakpoints.down('md')]:{
          padding: 12,
        },
        [theme.breakpoints.down('xs')]: {
          padding: 8
        }
    },
    orderCardTotalContainer: {
        maxWidth: '30%',
        width: "27%",
        padding: 12,
        paddingTop: 0,
      [theme.breakpoints.down('md')]: {
        maxWidth: "100%",
        width: "100%",
        padding: '12px 0px'
      },
        '& .cardTotalMain': {
            width: "100%",
            padding: "12px 8px",
            paddingTop: 0,
            boxShadow: 'none',
          [theme.breakpoints.down('md')]: {
            width: "auto",
            padding: '12px 0px',
            boxShadow: 'none'
          },
        },
        '& .cardCalculationRow': {
            display: 'flex',
            alignItems: "center",
            justifyContent: "space-between"
        }

    },
    orderDetailsCardContainer: {
        display: "flex",
        gap: "18px",
        [theme.breakpoints.between('sm' , 'md')]: {
          flexDirection: "column",
          padding: '0px 20px'
        },
        [theme.breakpoints.down('xs')]: {
          flexDirection: 'column'
        },
        "& .priceDetailText": {
            fontWeight: 'bold' as const,
            color: 'rgba(0, 77, 116, 1)',
            textAlign: 'left' as const,
            fontFamily: 'Manrope',
            fontSize: deviceBasedDynamicDimension(20, true, 1),
            [theme.breakpoints.down('md')]: {
              fontSize: 18
            }
        }
    },
    priceDetailsContainer: {
        display: 'flex',
        flexDirection: "column" as const,
        gap: "12px",
        margin: "30px auto",
        [theme.breakpoints.down('md')]: {
          '& .MuiDivider-root': {
            height: 2
          }
        }
    },
    orderCardContainer: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '30px',
        maxWidth: "70%",
        width: "68%",
        [theme.breakpoints.down('md')]:{
          maxWidth: "100%",
          width:  "100%",
        }
    },
    cardContainer: {
        display: "flex",
        gap: "30px",
        [theme.breakpoints.down('xs')]:{
          gap: 15
        }
    },
    warrantyPlanimg: {
        width: deviceBasedDynamicDimension(101, true, 1),
        opacity: 1,
        backgroundColor: 'rgba(0, 77, 116, 1)',
        height: "100px",
        display: "flex",
        justifyContent: "center",
        borderRadius: "20px",
        [theme.breakpoints.down('md')]: {
          width: 80,
          height: 80
        },
        [theme.breakpoints.down('xs')]:{
          height: "50px",
          width:  "50px",
          borderRadius: 10
        }

    },
    warrantyPlanTextlogo: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 'bold' as const,
        color: 'rgba(255, 255, 255, 1)',
        fontFamily: 'Manrope',
        fontSize: deviceBasedDynamicDimension(30, true, 1),
        [theme.breakpoints.down('xs')]:{
          fontSize: deviceBasedDynamicDimension(20, true, 1)
        }
    },
    warrantyPlancardText: {
        fontWeight: 'bold' as const,
        padding: deviceBasedDynamicDimension(0, true, 1),
        color: 'rgba(0, 77, 116, 1)',
        textAlign: 'left' as const,
        fontFamily: 'Manrope',
        fontSize: deviceBasedDynamicDimension(20, true, 1),
        [theme.breakpoints.down('xs')]:{
          fontSize: 16
        }
    },
    warrantyPlanInvoiceText: {
        color: 'rgba(137, 145, 164, 1)',
        textAlign: 'left' as const,
        fontFamily: 'Manrope',
        fontWeight: 600,
        letterSpacing: 1.1,
        fontSize: deviceBasedDynamicDimension(18, true, 1),
        [theme.breakpoints.down('xs')]:{
          fontSize: 16
        }
    },
    warrantyPlanCardDiscription: {
        display: "flex",
        flexDirection: "column" as const,
        width: '83%',
        gap: 10,
        [theme.breakpoints.down('md')]:{
          gap: 4
        },
        "& .QuantityText":{
          [theme.breakpoints.down('xs')]:{
            fontSize: 16
          },
        }
    },
    orderDatailBtnAction: {
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down('xs')]: {
          flexDirection: "row-reverse" as const
        },
        [theme.breakpoints.between('sm' , 'md')]: {
          marginTop: 8
        },
        '& .readPlanDetailsContainer': {
            display: 'flex',
            alignItems: "center",
            gap: 12
        },
        '& .readPlanDetailsText': {
            color: 'rgba(0, 77, 116, 1)',
            fontFamily: 'Inter',
            textDecoration: "underline",
            fontSize: deviceBasedDynamicDimension(14, true, 1),
            [theme.breakpoints.down('xs')]: {
              fontSize: 12
            }
        }
    },
    iconReadPlanDetail:{
      width: 24,
      height: 24,
      [theme.breakpoints.down('md')]: {
        width: 16,
        height: 16
      }
    },
    dividerContainer: {
      margin: "12px 0px 0px 0px" ,
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    placeOrderBtnContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: '25px',
        [theme.breakpoints.down('md')]: {
          paddingTop: 0
        },
        '& .MuiButton-root': {
            '& :hover': {
                backgroundColor: 'rgba(0, 77, 116, 1)'
            }
        }
    },
    placeButtonContainer: {
        width: '15%',
        padding: '8px 50px',
        fontFamily: 'Manrope',
        fontSize: "20px",
        fontStyle: 'normal',
        fontWeight: 'bold' as const,
        color: 'rgba(255, 255, 255, 1)',
        textTransform: "capitalize" as const,
        backgroundColor: 'rgba(0, 77, 116, 1)',
        borderRadius: 40,
        justifyContent: 'center',
        [theme.breakpoints.down('md')]:{
          width: 'auto'
        },
        '&:hover':{
            backgroundColor: 'rgba(0, 77, 116, 1)',
        }

    },
    totalDiscriptionText: {
        color: 'rgba(0, 77, 116, 1)',
        textAlign: 'left' as const,
        fontFamily: 'Manrope',
        fontSize: deviceBasedDynamicDimension(20, true, 1),
        [theme.breakpoints.down('md')]: {
          fontSize: 18
        }
    },
    totalDiscriptionAmountText: {
        display: "flex",
        color: 'rgba(5, 23, 32, 1)',
        textAlign: 'right' as const,
        fontFamily: 'Inter',
        fontSize: deviceBasedDynamicDimension(16, true, 1),
    },
    totalAmountText: {
        color: 'rgba(5, 23, 32, 1)',
        textAlign: 'left' as const,
        fontFamily: 'Inter',
        fontSize: deviceBasedDynamicDimension(22, true, 1),
        [theme.breakpoints.down('md')]: {
          fontSize: 20,
        }
    },
    totalAmountPriceText: {
        display:" flex",
        color: 'rgba(0, 77, 116, 1)',
        textAlign: 'right' as const,
        fontFamily: 'Inter',
        fontSize: deviceBasedDynamicDimension(22, true, 1),
    },
    warrantyPlanPriceText: {
        display: "flex",
        fontWeight: 'bold' as const,
        color: 'rgba(0, 132, 67, 1)',
        textAlign: 'right' as const,
        fontFamily: 'Inter',
        fontSize: deviceBasedDynamicDimension(20, true, 1),
        [theme.breakpoints.down('xs')]: {
          fontSize: 18
        }
    },
    CurrencySymbol:{
      margin:"0px 4px"
    },
    mainConatiner: {
        padding: "50px",
        boxShadow: "0px 0px 10px -5px",
        width: "50%",
        margin: "30px auto",
        borderRadius: "20px",
        display: "flex",
        gap: "50px",
        flexDirection: "column" as const,
        [theme.breakpoints.down('md')]: {
          width: 'auto',
          padding: 0,
          margin: 0,
          boxShadow: 'none'
        }
      },
      innerConatiner: {
        display: "flex",
        flexDirection: "column" as const,
        justifyContent: "center",
        marginLeft: "50px",
        [theme.breakpoints.down('xs')]: {
          marginLeft: 0,
          padding: 12
        },
        [theme.breakpoints.down('md')]: {
          marginLeft: 0,
          padding: '30px 50px 0px 30px'
        },
        "& .InputField": InputField,
        "& .InputCheckbox": {
          "&.MuiFormControlLabel-root": {
            marginLeft: "-5px",
            marginRight: "4px",
          },
          "& .MuiFormControlLabel-label": {
            fontFamily: "Inter",
            fontSize: "18px",
            lineHeight: 1.5,
            color: "rgba(5, 23, 32, 1)",
          },
        },
        "& .InputCheckboxInside": {
          "& .MuiSvgIcon-root": {
            color: " #004D74",
          },
        },
      },
      formRow: {
        display: "flex",
        gap: "20px",
        "& .InputField": InputField,
        "& .InputSelectField": {
          minWidth: "150px",
          width: "auto",
          margin: "12px 0",
        },
        "& .InputFieldPhone": {
          width: "410px",
        },
        "& .InputFieldSelect": {
          borderRadius: "12px",
          "& .MuiSelect-icon": {
            height: "36px",
            width: "36px",
            color: "#004D74",
            margin: "0px 12px",
            top: "auto",
          },
          "& .MuiOutlinedInput-input": {
            paddingLeft: "14px",
          },
        },
        "& .RenderedSelectItemBox": {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          "& img": {
            width: 36,
            objectFit: "contain",
          },
          "& .CountryName": {
            fontFamily: "Inter, sans-serif",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 18,
            lineHeight: 1.3,
            letterSpacing: 0.1875,
            color: "#051720",
            margin: "0px 8px",
          },
        },
      },
      ConfirmText: {
        fontFamily: "Manrope" as const,
        fontStyle: "normal",
        fontWeight: "bold" as const,
        fontSize: 20,
        lineHeight: 1.5,
        textAlign: "center" as const,
        textTransform: "capitalize" as const,
        color: "#FFFFFF",
      },
      buttonContainer: {
        display: "flex",
        justifyContent: "center",
        padding: "40px 0",
        borderTop: "1px solid #D8D8D8",
      },
      proceedButton: {
        maxWidth: "360px",
        width: "100%",
        borderRadius: "40px",
        padding: "12px 0",
        [theme.breakpoints.down('md')]:{
          width: '60%'
        }
      },
      MenuItemSelect: {
        "& img": {
          width: 36,
          objectFit: "contain",
        },
        "& .CountryNameInSelect": {
          fontFamily: "Inter, sans-serif",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: 18,
          lineHeight: 1.3,
          letterSpacing: "0.1875px",
          color: "#051720",
          margin: "4px 8px",
        },
      },
      title: {
        color: "rgba(0, 77, 116, 1)",
        fontSize: "28px",
        textAlign: "left" as const,
        fontWeight: "bold" as const,
        margin: "0 0 10px 10px",
      },
      subTitle: {
        color: "rgba(0, 77, 116, 1)",
        fontSize: "22px",
        textAlign: "left" as const,
        fontWeight: "bold" as const,
        margin: "0 0 10px 10px",
      },
      VOMBoxModal: {
        display: 'flex',
        alignItems: 'center' as const,
        justifyContent: 'center',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        flexDirection: 'column' as const,
        fontFamily: 'Inter',
      },
      viewSurveyBoxContainer: {
        width: '100%',
        height: '100%',
        background: "#fff",
        overflowY: 'scroll' as const,
        fontFamily: 'Inter',
      },
      view_BackgroundCopy:
      {
        position: "relative" as const,
        width: 1048,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderRadius: 24,
        boxShadow: "0px 0px 8px -6px",
        padding: 20,
        margin: '40px auto',
        "& .InputField": InputField,
        '& .closeIconButton': {
          position: 'absolute' as const, 
          top: 20, 
          right: 20,
          [theme.breakpoints.down('xs')]: {
            top: 10,
            right: 10,
            padding: 0
          }
        },
        [theme.breakpoints.down('sm')]: {
          width: 'auto',
          padding: 12,
        },
        [theme.breakpoints.down('xs')]: {
          width: 'auto',
          padding: 12,
          margin: 'auto',
          borderRadius: 0
        }
      },
      confirmContainermain: {
        position: "relative" as const,
        width: 500,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderRadius: 20,
        boxShadow: "0px 0px 10px -5px",
        padding: 70,
        margin: '100px auto',
        display: 'flex',
        flexDirection: "column" as const,
        gap: '30px',
        [theme.breakpoints.down('xs')]: {
          width: 'fit-content',
          padding: 0,
          boxShadow: 'none',
          borderRadius: 0
        }
      },
      confirmInnerBox: {
        display: "flex",
        justifyContent: "center",
      },
      coongrateMessage: {
        display: "flex",
        justifyContent: "center",
        flexDirection: 'column' as const
      },
      congrateText: {
        color: 'rgba(28, 28, 28, 1)',
        textAlign: 'center' as const,
        fontWeight: 'bold' as const,
        fontFamily: 'Inter',
        fontSize: 36,
        [theme.breakpoints.down('md')]: {
          fontSize: 24,
        }
      },
      buyAnotherBtn: {
        padding: '8px 80px',
        fontFamily: 'Manrope',
        fontSize: 20,
        fontStyle: 'normal',
        fontWeight: 'bold' as const,
        color: 'rgba(255, 255, 255, 1)',
        textTransform: "capitalize" as const,
        backgroundColor: 'rgba(0, 77, 116, 1)',
        borderRadius: 40,
        justifyContent: 'center',
        '&:hover': {
          backgroundColor: 'rgba(0, 77, 116, 1)',
        }
      },
      confirmImg: {
        width: 146,
        height: 146,
        opacity: 1,
        objectFit: 'contain' as const,
        [theme.breakpoints.down('md')]: {
          width: 100,
          height: 130
        }
      },
      buttonContainerMain: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '95%',
        [theme.breakpoints.down('xs')]: {
          justifyContent: 'center'
        }
      },
      placeButtonContainerSurvey: {
        padding: '12px 96px',
        fontFamily: 'Manrope',
        fontSize: "18px",
        fontStyle: 'normal',
        fontWeight: 'bold' as const,
        color: 'rgba(255, 255, 255, 1)',
        textTransform: "capitalize" as const,
        backgroundColor: 'rgba(0, 77, 116, 1)',
        borderRadius: 40,
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
          width: 'auto'
        },
        '&:hover': {
          backgroundColor: 'rgba(0, 77, 116, 1)',
        }
    
      },
      surveyHeading: {
        color: "rgba(0, 77, 116, 1)",
        textAlign: 'center' as const,
        fontFamily: "Manrope",
        fontSize: '1.75em',
        fontWeight: 900,
        letterSpacing: 1.1
      },
      surveyQuestionListContainer: {
        paddingLeft: 56,
        paddingRight: 40,
        color: "rgba(0, 77, 116, 1)",
        fontSize: '20px',
        fontFamily: "Manrope",
        fontWeight: "bold" as const,
        marginTop: 50, 
        '& .questionContent': {
          fontFamily: "Manrope",
          color: "rgba(0, 77, 116, 1)",
          letterSpacing: 1.2 as const

        },
        '& .optionButtonAnswer': {
          boxShadow: "0px 0px 8px -6px", 
          padding: '12px 16px' ,
          fontFamily: "Manrope",
          justifyContent: "flex-start",
          borderRadius: 12,
          marginRight: 16,
          textTransform: 'initial' as const,
          [theme.breakpoints.down('sm')]: {
            marginTop: 8,
            marginBottom: 8
          }
        },
        '& .optionText': {
          fontFamily: "Manrope",
          color: "rgba(0, 77, 116, 1)",
          fontSize: '18px'
        }
      },
      questionTypeMsqlist: {
        padding: 0,
        gap: 20,
        marginLeft: '-20px'
      },
      optionButtonContainer: {
        margin: 0,
        display: 'flex',
        gap: 16,
        alignItems: "center" as const,
        color: "rgba(0, 77, 116, 1)",
        justifyContent: 'flex-start',
        minWidth: 100,
        borderRadius: 8,
        fontFamily: "Manrope",
      },
      optionButton: {
        padding: 18,
        borderRadius: 8,
        fontFamily: "Manrope",
        display: "flex", 
        alignItems: "center",
        width: 24,
        minWidth: 'auto',
        height: 24,
        fontSize: 18,
        fontWeight: 600
      },
      horizontalDivider: {
        marginTop: "15px",
        height: 2,
        marginLeft: -20
      },
      confirmContainer: {
        width: "773px",
        display: 'flex',
        flexDirection: "column" as const,
        margin: "0px auto 50px auto",
        padding: "100px 0px",
        paddingRight: "0px",
        backgroundColor: '#FFFFFF',
        boxShadow: "1px 1px 10px 0px rgba(0,0,0,0.2)",
        borderRadius: "20px",
        gap: '25px',
        [theme.breakpoints.down('md')]: {
          width: 'auto',
          boxShadow: 'none',
          padding: '50px 0px'
        }
      }
})
import {t} from "../../../components/src/i18n";
import OrderDetailsController, { Props } from "./OrderDetailsController.web";
import OrderDetailsStepper from "../../../components/src/orderDetailsStepper.web";
import { OrderDetailsView } from "./OrderDetailsView.web";
import OrderConfirmation from "../../../components/src/OrderConfirmation.web";
import { InputField } from "../../../components/src/CommonStyles";
import CustomerInformation from "./CustomerInformation.web";
import Spinner from "../../../components/src/Spinner.web";
import Surveys from "../../Surveys/src/Surveys.web";
// Customizable Area End


class OrdermanagementView extends OrderDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, id, navigation } = this.props;
        const { activeTab } = this.state;
     
        return (
            <>
                <OrderDetailsStepper classes={classes} activeTab={activeTab} handleBackButton={this.handleBackButton} />
                {activeTab === 1 &&
                <>
                 <OrderDetailsView navigation={this.props.navigation} id={this.props.id} classes={classes}/>
                 <Divider className={classes.dividerContainer} />
                <Box className={classes.placeOrderBtnContainer}>
                    <Button data-testId="placeorder_button" type="submit" variant="outlined" className={classes.placeButtonContainer} onClick={this.handleOrderDetails}>{t("orderDetails.placeOrderText")}</Button>
                </Box>
                </>
                } 
                {activeTab === 2 && 
                <>
                <CustomerInformation classes={classes} id={id} navigation={navigation} />
                </>
                }
                {activeTab === 3 && 
                <>
                <h1>Payment Information</h1>
                <Divider style={{ margin: "12px 0px 0px 0px" }} />
                <Box className={classes.placeOrderBtnContainer}>
                    <Button type="submit" variant="outlined" className={classes.placeButtonContainer} >{"Continue"}</Button>
                </Box>
                </>
                }
                {activeTab === 4 && 
                <>
                <Spinner showLoader={this.state.showLoader} />
              {this.state.paymentFailed ? (
                <OrderConfirmation
                  isFailed={true}
                  payment_id={this.state.payment_id ?? ""}
                  message={t("confirmation.paymentFailed") ?? ""}
                  subTitle={" "}
                  btnText={t("confirmation.tryAgain") ?? ''}
                  handleButtonAction={this.handlePaymentFailedTryAgainBtn}
                />
              ) : (
                <OrderConfirmation payment_id={this.state.payment_id || ""} />
              )}
                </>
                }
                <Surveys 
                navigation={this.props.navigation} 
                id={""} 
                classes={classes}
                isOpen={this.state.surveyForm} 
                surveyQuestions={this.state.surveyQuestion} 
                handleSurveyFormCloseBtn={this.handleSurveyFormCloseBtn}
                />
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(orderDetailsStyle)(OrdermanagementView);
export { OrdermanagementView }
// Customizable Area End
