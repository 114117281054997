// Customizable Area Start
import { getStorageData } from "../../framework/src/Utilities";
import { WithStyles } from "@material-ui/core";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { IBlock } from "../../framework/src/IBlock";
import { runEngine } from "../../framework/src/RunEngine";
import { Message } from "../../framework/src/Message";
import { iLanguage } from "./i18n";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  showLoader: boolean;
  carouselBannersData: any;
  // Customizable Area End
}

interface S {}

interface SS {
  id: any;
}

export default class MainCarouselController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleBannerClick = (navigationUrl : string) => {
    window.open(navigationUrl);
  };
  // Customizable Area End
}
