import React, { Component } from 'react';
import { Box, Typography, withStyles, WithStyles, Modal, Theme, Button} from '@material-ui/core';
import { iDirection, t } from './i18n';
import {
    InputField,
    rtlOffInputClasses
} from "./CommonStyles";
import { deviceBasedDynamicDimension } from './Toolkit';

interface Props extends WithStyles {
    isOpen: boolean;
    handleCancelNoplan?:any;
    handleContinueButton?:any;
    canclePlanDetails?:any;
}

interface S {

}

interface SS {

}
class CancelReconfirmation extends Component<Props, S, SS> {

    constructor(props: Props) {
        super(props);

        this.state = {

        }
    }
    render() {
        const { classes, isOpen ,handleCancelNoplan, handleContinueButton,canclePlanDetails} = this.props;
        return (
            <Modal
                open={isOpen}
                className={classes.VOMBoxModal}
                dir={iDirection}
                data-test-id="OTPModal"
            >
                <Box className={classes.view_BackgroundCopy}>

                    <Box style={{ display: 'flex', flexDirection: "column", padding: 50 }}>
                        <Box className={classes.messageContainer}>
                            <Typography data-testId='reconfimationMsg' className={classes.reConfirmationmessage}>{t("cancelWarrantyplan.reconfimationMsg")}</Typography>
                        </Box>

                        <Box className={classes.modelBtnContainer}>
                            <Box className='actionBtnCancelModel'>
                                <Button data-testId="reconfirmContinue" className={classes.continueButton} onClick={() => handleContinueButton(canclePlanDetails.id)}>{t("cancelWarrantyplan.confirm")}</Button>
                                <Button className={classes.noCancelButton} onClick={handleCancelNoplan}>{t("cancelWarrantyplan.no")}</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        );
    }
}

const style = (theme: Theme) => ({
    VOMBoxModal: {
        display: 'flex',
        alignItems: 'center' as const,
        justifyContent: 'center'
    },
    view_BackgroundCopy:
    {
        width: deviceBasedDynamicDimension(703, true, 1),
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderRadius: deviceBasedDynamicDimension(20, true, 1),
        [theme.breakpoints.down('xs')]:{
            width: 375,
            padding: 12
        }
    },
    messageContainer: {
    padding: "50px 100px",
    [theme.breakpoints.down('xs')]: {
        padding: 0
    }
    },
    reConfirmationmessage: {
        fontWeight: 'bold' as const,
        color: 'rgba(53, 65, 79, 1)',
        textAlign: 'center' as const,
        fontFamily: 'Manrope',
        fontSize: deviceBasedDynamicDimension(32, true, 1)
    },
    modelBtnContainer: {
        display: 'flex',
        justifyContent: 'center',
        padding: 16,
        '& .actionBtnCancelModel': {
            display: 'flex',
            flexDirection: "column" as const,
            gap: 20
        }
    },
    noCancelButton: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        border: "1px solid #004D74",
        width: deviceBasedDynamicDimension(358, true, 1),
        height: 70,
        borderRadius: deviceBasedDynamicDimension(40, true, 1),
        justifyContent: 'center',
        fontWeight: 'bold' as const,
        textAlign: 'center' as const,
        fontFamily: 'Manrope',
        fontSize: deviceBasedDynamicDimension(20, true, 1),
        textTransform: "capitalize" as const,
        [theme.breakpoints.down('xs')]: {
            width:200,
            height: 50
        },
    },
    continueButton: {
        backgroundColor: "#004D74",
        border: "1px solid #004D74",
        width: deviceBasedDynamicDimension(358, true, 1),
        height: 70,
        borderRadius: deviceBasedDynamicDimension(40, true, 1),
        justifyContent: 'center',
        fontWeight: 'bold' as const,
        textAlign: 'center' as const,
        fontFamily: 'Manrope',
        fontSize: deviceBasedDynamicDimension(20, true, 1),
        textTransform: "capitalize" as const,
        color: "#fff",
        [theme.breakpoints.down('xs')]: {
            width:200,
            height: 50
        },
        '&:hover': {
            backgroundColor: '#004D74',
        }
    }

});
export default withStyles(style)(CancelReconfirmation);