import React, { Component } from 'react';
import { Box, withStyles, WithStyles, Theme } from '@material-ui/core';
import TabButton from "./TabButton.web"
import { deviceBasedDynamicDimension } from '../Toolkit';
import { t } from '../i18n';

export type Tab = {
    id: string;
    name: string;
}
interface Props extends WithStyles {
    logoImage: any;
    onTabClick: (tabNumber?:number) => void;
    tabs: Array<Tab>;
    onClickLogout: () => void;
    activeTab: number;
}

interface S {

}

interface SS {

}
class ProfileLeftMenu extends Component<Props, S, SS> {
    render() {
        const { tabs, activeTab, logoImage, classes } = this.props;
        return (
            <Box className={classes.mainBox}>
                <img src={logoImage} alt="Warranty App Logo" className={classes.logo} />
                {tabs.map((tab: Tab, index: number) => {
                    return (
                        <TabButton
                            key={tab.id}
                            title={tab.name}
                            index={index}
                            onClick={this.props.onTabClick}
                            isActive={activeTab === index}
                        />
                    )
                })}

                <TabButton
                    title={t("common.logout")}
                    onClick={this.props.onClickLogout}
                    isActive={false}
                    customBtnClass={classes.logoutBtn}
                    customBtnTextClass={classes.logoutBtnText}
                />
            </Box>
        );
    }
}

const style = (theme:Theme) => ({
    mainBox: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderRadius: deviceBasedDynamicDimension(12, false, 1),
        boxShadow: "0px 1px 10px #EBEBEB",
        margin:`24px 0px`,
        [theme.breakpoints.down('sm')]:{
            flexDirection: 'row',
            overflowX:'auto',
            justifyContent:'initial'
        }
    },
    logo: {
        marginTop: deviceBasedDynamicDimension(32, false, 1),
        marginBottom: deviceBasedDynamicDimension(32, false, 1),
        width: 98,
        height: 98,
        objectFit: 'contain' as const,
        [theme.breakpoints.down('md')]:{
            height:76,
            width:76
        },
        [theme.breakpoints.down('sm')]:{
            margin:16,
            height:64,
            width:64
        }
    },
    logoutBtn:{
        borderColor:'rgba(243, 55, 55, 1)',
        marginTop:deviceBasedDynamicDimension(48, false, 1),
        [theme.breakpoints.down('sm')]:{
            margin:16,
        }
    },
    logoutBtnText:{
        color:'rgba(243, 55, 55, 1)'
    }
});
export default withStyles(style)(ProfileLeftMenu);