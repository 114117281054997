import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Link,
  Avatar,
  Paper,
  Theme,
  withStyles
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start

// Customizable Area End

import DeepLinkingController, {
  Props,
  configJSON,
} from "./DeepLinkingController";
import { facebookIcon, instagramIcon, twitterIcon } from "./assets";

export default class DeepLinking extends DeepLinkingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
        <Box className={this.props.classes?.SocialIconBox}>
            <Link href={this.state.links.facebook_url} target="_blank">
                <Avatar src={facebookIcon} component={Paper} elevation={2} className="SocialIcon" />
            </Link>
            <Link href={this.state.links.instagram_url} target="_blank" >
                <Avatar src={instagramIcon} component={Paper} elevation={2} className="SocialIcon" />
            </Link>
            <Link href={this.state.links.twitter_url} target="_blank">
                <Avatar src={twitterIcon} component={Paper} elevation={2} className="SocialIcon" />
            </Link>
        </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const deepLinkStyles = (theme: Theme) => ({
  SocialIconBox: {
      display: "flex",
      flexDirection: 'row' as const,
      alignItems: 'center',
      justifyContent: 'flex-start',
      height: "100%",
      "& .SocialIcon" :{
          height: "50px",
          width: "50px",
          objectFit: "contain" as const,
          margin: '0px 16px',
          boxShadow: "0px 9px 20px 10px #EBEBEB"
      },
      "& .SocialIcon .MuiAvatar-img" :{
              height: '14px',
              width: '14px',
              objectFit: 'contain' as const,
          },
      [theme.breakpoints.down('md')]:{
          justifyContent: 'center' as const,
          height: 'auto',
          margin: '16px 0px',
      }
  },
});

const StyledLinks = withStyles(deepLinkStyles)(DeepLinking);
export { StyledLinks, DeepLinking };
// Customizable Area End
