import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { callApi ,getNavigationMessage} from "../../../components/src/Toolkit";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { t, iLanguage } from "../../../components/src/i18n";
export const configJSON = require("./config");
import moment from "moment";
import { MixpanelTracking }  from "../../MixpanelIntegration/src/MixpanelIntegration.web";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  BasicPlan?: any;
  AccidentalPlan?: any;
  ComboPlan?: any;
  ExtendedPlan?: any;
  handleCetegoryPlans?:any;
  CategoryPlans?:any;
  Expanded?:boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  category: any;
  routeValue: string;
  storeList: any;
  itemList: any;
  WarrantyPlanForm: any;
  menuOpen: boolean;
  errors: {
    itemError: boolean;
    itemErrorText: string;
    storeError: boolean;
    storeErrorText: string
    invoiceNumberError: boolean;
    invoiceNumberErrorText: string;
    dateError: boolean;
    dateErrorText: string;
    priceError: boolean;
    priceErrorText: string;
    store_nameError: boolean;
    store_nameErrosText: string;
  };
  item_name:string;
  store_name: string;
  isOtherStoreSelect: string;
  themeValue: any;
  WarrantyPlanPurchase: boolean;
  CategoryPlans: any;
  ExpandedValue: any;
  Expanded: boolean;
  BasicPlan: any;
  ExtendedPlan: any;
  AccidentalPlan: any;
  ComboPlan: any;
  showLoader: boolean;
  currency_symbol:any;
  selectedCountry:any;
  item_list_warranty_id:any;
  EditItemId: number;
  disableSubmitButton:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class WarrantyPlanController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getStoreListApiCall: string = "";
  getItemListApiCall: string = "";
  purchaseWarrantyApiCall: string = "";
  purchaseWarrantyPlanApi: string = "";
  editWarrantyPlanApi: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    this.state = {
      themeValue: {},
      category: [],
      selectedCountry:{},
      routeValue: "",
      storeList: [],
      itemList: [],
      WarrantyPlanForm: {
        item: "",
        store: "",
        date: "",
        price: "",
        invoiceNumber: ""
      },
      menuOpen: false,
      showLoader: false,
      errors: {
        itemError: false,
        itemErrorText: '',
        storeError: false,
        storeErrorText: '',
        invoiceNumberError: false,
        invoiceNumberErrorText: "",
        dateError: false,
        dateErrorText: '',
        priceError: false,
        priceErrorText: '',
        store_nameError: false,
        store_nameErrosText: ''
      },
      WarrantyPlanPurchase: false,
      CategoryPlans: {},
      ExpandedValue: {},
      Expanded: false,
      BasicPlan: [],
      ExtendedPlan: [],
      AccidentalPlan: [],
      ComboPlan: [],
      currency_symbol:'',
      item_list_warranty_id:'',
      item_name: "",
      store_name: '',
      isOtherStoreSelect: '',
      EditItemId : 0,
      disableSubmitButton:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if(message.id === getName(MessageEnum.NavigationPayLoadMessage))
    {
      const cartItemData = message.properties.SessionResponseData; 
      if(cartItemData.store_name && cartItemData.store_name !== null )
      {
        this.setState({isOtherStoreSelect : "Other", store_name: cartItemData.store_name})
      }
      this.setState({WarrantyPlanForm : { ...this.state.WarrantyPlanForm, invoiceNumber : cartItemData.details.item_invoice_id, 
        store: cartItemData.store_list_id , date: cartItemData.purchase_date, item : cartItemData.details.item_id, quantity:cartItemData.quantity },
        EditItemId : cartItemData.id, item_name:cartItemData.details.item_name
        })
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.getStoreListApiCall: {
            this.handleStoreResponse(responseJson);
            break;
          }
          case this.getItemListApiCall: {
            this.handleItemResponse(responseJson);
            break;
          }
          case this.purchaseWarrantyApiCall: {
            this.handleWarrantyPlans(responseJson)
            break;
          }
          case this.purchaseWarrantyPlanApi: {
            this.handleWarrantyPlanOrder(responseJson);
            break;
          }
          case this.editWarrantyPlanApi: {
            this.handleWarrantyPlanOrder(responseJson);
            break;
          }
          default: {
            this.setState({ showLoader : false})
          }
        }
      }
      this.setState({ showLoader : false})
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    window.addEventListener('popstate', this.handleBackButton);

    const array = window.location.pathname.split("/");
    const id = array[array.length - 1];
    this.setState({ routeValue: id })
    const category = await getStorageData('category', true);
    const country = await getStorageData('selectedCountry', true);
    this.setState({
      category,
      selectedCountry: country,
      currency_symbol: country.attributes.currency_symbol
    });
    this.getStoreList(id);
    this.getItemList(id);
    this.getBackgroundColor(id);

  }

  handleBackButton = async() => {
    // Handle the back button event here
    const WarrantyPlanPurchase = await getStorageData("WarrantyPlanPurchase",true)
    if (WarrantyPlanPurchase) {
        const navigationMessage = getNavigationMessage('Category', this.props);
        navigationMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), WarrantyPlanPurchase)
        this.send(navigationMessage);
        return removeStorageData('WarrantyPlanPurchase')
    }
  };

  componentDidUpdate(prevProps: Props, prevState: S) {
    // since we have same component for all the category routes that why we added this
    const array = window.location.pathname.split("/");
    const id = array[array.length - 1];
  
    if (prevState.routeValue !== id) {
      this.getStoreList(id);
      this.getItemList(id);
      this.setState({
        routeValue: id,
        WarrantyPlanForm: { ...this.state.WarrantyPlanForm, item: "", store: "", date: "", price: "", invoiceNumber: ""
       },
        WarrantyPlanPurchase: false,
        Expanded: false,
        errors: {
          itemError: false,
          itemErrorText: '',
          storeError: false,
          storeErrorText: '',
          invoiceNumberError: false,
          invoiceNumberErrorText: "",
          dateError: false,
          dateErrorText: '',
          priceError: false,
          priceErrorText: '',
          store_nameError: false,
          store_nameErrosText: ''
        },
        store_name: '',
        isOtherStoreSelect: '',
        item_list_warranty_id: '',
        CategoryPlans: {}
      })
      this.getBackgroundColor(id);
      
    }
  }

  handleCetegoryPlans = async(value: any) => {
    this.setState({ CategoryPlans: value ,item_list_warranty_id:value.item_list_warranty_id, Expanded: true});
    const warrantyFormDetails = await getStorageData("mixpanelItemFormInformation", true);
    const warrantyItemName = await getStorageData("mixpanelItemName", false)
    
    MixpanelTracking.getInstance().eventTrack(configJSON.mixpanelPageViewed , 
     { 
      $itemName: warrantyItemName,
      $price: warrantyFormDetails.price,
      $invoiceNumber: warrantyFormDetails.invoiceNumber,
      $planName: value.name,
      $planPrice: value.discounted_price,
      $planDetails: value.details,
      $warrantyType: value.plan_type,
      $warrantyPrice: value.prices.discounted_price,
      $warrantyDiscount: value.prices.discount,
      value
    }

      )
  }

  handleStoreResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({ storeList: responseJson.data,showLoader:false })
    } else {
      return alert(responseJson.error[0].token)
    }
  }

  handleItemResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({ itemList: responseJson.data ,showLoader:false })
    } else {
      alert(responseJson.error[0].token)
    }
  }

  handleWarrantyPlanOrder = (responseJson:any) => {
    if (!responseJson.errors) {
      if(this.state.EditItemId === 0){
        MixpanelTracking.getInstance().addToCart(responseJson.data.order.data.attributes.order_items)
      }
      this.send(getNavigationMessage('Ordermanagement', this.props));
    } else {
      window.alert(responseJson.errors);
    }
  }

  handleWarrantyPlans = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({
        BasicPlan: responseJson.basic?.data || [],
        AccidentalPlan: responseJson.accidental?.data || [],
        ExtendedPlan: responseJson.extended?.data || [],
        ComboPlan: responseJson.combo?.data || [],
        showLoader:false 
      }, () => {
        setStorageData("WarrantyPlanPurchase",this.state.themeValue.attributes.id)
        this.setState({ WarrantyPlanPurchase: true })
      });
    }
  }

  handleWarrantySubmit = () => {
    const { WarrantyPlanForm} = this.state;
    let newDate = moment(WarrantyPlanForm.date).format('DD-MM-YYYY');
    if (this.isValidRequest() && this.isOtherStoreNameValid()) {
      // Perform form submission or other actions here
      setStorageData('mixpanelItemFormInformation', JSON.stringify(WarrantyPlanForm));
      setStorageData('mixpanelItemName', this.state.item_name)
      this.setState({showLoader:true})
      this.purchaseWarrantyApiCall = callApi({
        contentType: configJSON.jsonApiContentType,
        method: configJSON.validationApiMethodType,
        endPoint: configJSON.purchanseWarrantyPlan + `?item_id=${parseInt(WarrantyPlanForm.item)}&item_price=${parseInt(WarrantyPlanForm.price)}&language_code=${iLanguage}&store_id=${WarrantyPlanForm.store}&purchase_date=${newDate}`,
        headers: {}
      }, runEngine);
    }
  }
  
  handleWarrantyPlanSubmit = async() => {
    const { item_list_warranty_id } = this.state;
    
    if(item_list_warranty_id !== ''){
      this.setState({
        disableSubmitButton:true
      })
      const userToken = await getStorageData('token');
      if(userToken){
        this.handleUserWarrantyPlan()
      }else{
        this.handleGuestWarrantyPlanSubmit()
      }
    }else{
      window.alert("Please Select WarrantyPlan")
    }
   
 
  }

  handleUserWarrantyPlan = async () => {
    const { WarrantyPlanForm,item_list_warranty_id ,selectedCountry} = this.state;
    const userToken = await getStorageData('token');

    let newDate = moment(WarrantyPlanForm.date).format('DD-MM-YYYY');

    if(this.state.EditItemId === 0) {
      this.purchaseWarrantyPlanApi = callApi({
        contentType: configJSON.validationApiContentType,
        method: configJSON.exampleAPiMethod,
        endPoint: configJSON.warrantyPlanPurchaseEndPoint + `?item_list_warranty_id=${item_list_warranty_id}&old_unit_price=${parseInt(WarrantyPlanForm.price)}&item_invoice_id=${WarrantyPlanForm.invoiceNumber}&store_list_id=${WarrantyPlanForm.store}&purchase_date=${newDate}&country_id=${selectedCountry.id}${this.state.store_name !== "" ? "&store_name=" + this.state.store_name : ""}&quantity=1`,
        headers: {token:userToken},
    }, runEngine)
  }
    else {
      this.editWarrantyPlanApi = callApi({
        contentType: configJSON.validationApiContentType,
        method: configJSON.PUT,
        endPoint: configJSON.warrantyPlanEditEndPoint + `/${this.state.EditItemId}` + `?item_list_warranty_id=${item_list_warranty_id}&old_unit_price=${parseInt(WarrantyPlanForm.price)}&item_invoice_id=${WarrantyPlanForm.invoiceNumber}&store_list_id=${WarrantyPlanForm.store}&purchase_date=${newDate}&country_id=${selectedCountry.id}${this.state.store_name !== "" ? "&store_name=" + this.state.store_name : ""}&quantity=${WarrantyPlanForm.quantity}`,
        headers: {token:userToken},
    }, runEngine)
  }
  }

  handleGuestWarrantyPlanSubmit = async () => {
    const { WarrantyPlanForm,item_list_warranty_id ,routeValue, CategoryPlans,item_name ,themeValue, store_name} = this.state;
    let storedCart = await getStorageData('storedCart',true) || [];
      
    // find existing warranty logic : - matching item_list_warranty_id, item_price, purchase_date, store & invoice no.
    let existingCartItemIndex = storedCart.findIndex((item:{attributes:{store_name:string, store_list_id:string | number, item_list_warranty_id: string| number,purchase_date:string,old_unit_price:string | number,  details:{item_invoice_id:string | number}}}) => {
        return (
            (item.attributes.store_name ? item.attributes.store_name == store_name : item.attributes.store_list_id == WarrantyPlanForm.store) &&
            item_list_warranty_id == item.attributes.item_list_warranty_id &&
            WarrantyPlanForm.invoiceNumber.trim() == item.attributes.details.item_invoice_id &&
            WarrantyPlanForm.price == item.attributes.old_unit_price &&
            WarrantyPlanForm.date == item.attributes.purchase_date
        );
    });
    if(existingCartItemIndex !== -1){
      const updatedQuantity = (storedCart[existingCartItemIndex].attributes.quantity || 1) + 1;
      const totalPrice: number =
          storedCart[existingCartItemIndex].attributes.unit_price * updatedQuantity;
      const totalDiscount: number =
        storedCart[existingCartItemIndex].attributes.unit_discount * updatedQuantity;
      const subTotal: number =
        storedCart[existingCartItemIndex].attributes.unit_subTotal * updatedQuantity;
      storedCart[existingCartItemIndex].attributes.quantity = updatedQuantity;
      storedCart[existingCartItemIndex].attributes.total_price =
        totalPrice.toFixed(2);
      storedCart[existingCartItemIndex].attributes.discount =
        totalDiscount.toFixed(2);
      storedCart[existingCartItemIndex].attributes.subTotal = subTotal.toFixed(2);
      await setStorageData("storedCart", JSON.stringify(storedCart))
    }else{
      const id = storedCart.length ? storedCart[storedCart.length - 1].id + 1 : storedCart.length + 1;
      const quantity = WarrantyPlanForm.quantity ?? 1;
      let localCartData = {
          "id": id,
          "attributes": {
              "id": id,
              "quantity": quantity,
              "total_price": CategoryPlans.prices.discounted_price * quantity,
              "unit_price":CategoryPlans.prices.discounted_price,
              "unit_discount":CategoryPlans.prices.discount,
              "unit_subTotal":CategoryPlans.prices.amount,
              "old_unit_price": WarrantyPlanForm.price,
              "discount": CategoryPlans.prices.discount * quantity,
              "subTotal": CategoryPlans.prices.amount * quantity,
              "item_list_warranty_id": item_list_warranty_id,
              "purchase_date": WarrantyPlanForm.date,
              "store_list_id": WarrantyPlanForm.store,
              "details": {
                  "item_name": item_name,
                  "item_id": WarrantyPlanForm.item,
                  "category_id": routeValue,
                  "category_bg_color": themeValue?.attributes?.bg_color,
                  "category_text_color": themeValue?.attributes?.text_color,
                  "warranty_name": CategoryPlans.name,
                  "warranty_id": CategoryPlans.id,
                  "warranty_type": CategoryPlans.plan_type,
                  "plan_details": CategoryPlans.details.plan_details,
                  "item_invoice_id": WarrantyPlanForm.invoiceNumber
              },
              ...(this.state.store_name !== "" ? { "store_name": this.state.store_name } : {})
          }
      }
      const newData = [...storedCart, localCartData]
      const updatedOrderItems = storedCart.filter(
        (item: { attributes: { id: number } }) => item.attributes.id !== this.state.EditItemId
      );
      const updatedData = [...updatedOrderItems, localCartData]
      await setStorageData("storedCart",JSON.stringify(this.state.EditItemId === 0 ? newData : updatedData));
      MixpanelTracking.getInstance().eventTrack(configJSON.mixpanelGuestAddedWarranty, localCartData)
      MixpanelTracking.getInstance().eventTrack(configJSON.mixpanelGuestCartItems, updatedData)
    }
    this.send(getNavigationMessage('Ordermanagement', this.props));
  }

  isValidInvoice = (value: string) => {
    let isValid: boolean = true;
    if (value === "") {
      this.setState(prevState => ({ errors: { ...prevState.errors, invoiceNumberError: true, invoiceNumberErrorText: t("warrantyForm.invoiceNumberErrorText") } }))
      isValid = false;
    } else {
      this.setState(prevState => ({ errors: { ...prevState.errors, invoiceNumberError: false, invoiceNumberErrorText: "" } }))
    }
    return isValid;
  }
  isValidPrice = (value: string) => {
    const { themeValue } = this.state;
    let isValid: boolean = true;
    if (value === "") {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          priceError: true,
          priceErrorText: themeValue.attributes.min_price && themeValue.attributes.max_price
            ? `${t("warrantyForm.priceRangeErrorText")} ${themeValue.attributes.min_price} ${t("warrantyForm.priceRangeErrorAnd")} ${themeValue.attributes.max_price}`
            : 'Invalid price range information',
        },
      }));
      isValid = false;
    } else if (!(themeValue.attributes.max_price >= parseFloat(value) && parseFloat(value) >= themeValue.attributes.min_price)) {
      this.setState(prevState => ({
        errors: {
          ...prevState.errors,
          priceError: true,
          priceErrorText: themeValue?.attributes.min_price && themeValue?.attributes.max_price
            ? `${t("warrantyForm.priceRangeErrorText")} ${themeValue?.attributes.min_price} ${t("warrantyForm.priceRangeErrorAnd")} ${themeValue?.attributes.max_price}`
            : 'Please Enter Price',
        },
      }));
      isValid = false;
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          priceError: false,
          priceErrorText: ``,
        },
      });
    }

    return isValid;
  }
  isValidDate = (value: string) => {
    let isValid: boolean = true;
    if (value.length === 0) {
      this.setState(prevState => ({ errors: { ...prevState.errors, dateError: true, dateErrorText: t("warrantyForm.dateErrorText") } }))
      isValid = false;
    } else {
      this.setState(prevState => ({ errors: { ...prevState.errors, dateError: false, dateErrorText: "" } }))
    }
    return isValid
  }
  isValidItem = (value: string) => {
    let isValid: boolean = true;
    if (value.length === 0) {
      this.setState(prevState => ({ errors: { ...prevState.errors, itemError: true, itemErrorText: t("warrantyForm.itemErrorText") } }))
      isValid = false;
    } else {
      this.setState(prevState => ({ errors: { ...prevState.errors, itemError: false, itemErrorText: "" } }))
    }
    return isValid;
  }
  isValidStore = (value: string) => {
    let isValid: boolean = true;
    if (value.length === 0) {
      this.setState(prevState => ({ errors: { ...prevState.errors, storeError: true, storeErrorText: t("warrantyForm.storeErrorText") } }))
      isValid = false;
    } else {
      this.setState(prevState => ({ errors: { ...prevState.errors, storeError: false, storeErrorText: "" } }))
    }
    return isValid;
  }

  isOtherStoreNameValid = ():boolean => {
    const {store_name, isOtherStoreSelect} = this.state;
    let isValid = true;
    if(isOtherStoreSelect === "Other") {
      if (store_name.length === 0) {
        this.setState(prevState => ({ errors: { ...prevState.errors, store_nameError: true, store_nameErrosText: t("warrantyForm.StoreNameErrorText") } }))
        isValid = false;
      } else {
        this.setState(prevState => ({ errors: { ...prevState.errors, store_nameError: false, store_nameErrosText: "" } }))
      }
      return isValid;
    }
    else{
      return isValid;
    }
  }

  isValidRequest = (): boolean => {
    const { WarrantyPlanForm } = this.state;
    const {item, store, date, invoiceNumber, price} = WarrantyPlanForm;
    let isValid = true;
    if (!this.isValidPrice(price)) {
      isValid = false;
    }
    if (!this.isValidItem(item)) {
      isValid = false;
    }
    if (!this.isValidStore(store)) {
      isValid = false;
    }
    if (!this.isValidDate(date)) {
      isValid = false;
    }
    if (!this.isValidInvoice(invoiceNumber)) {
      isValid = false;
    }
    return isValid;
  }
  
  handleWarrantyItem = (event:any) => {
    this.setState(prevState => ({ WarrantyPlanForm: { ...prevState.WarrantyPlanForm,item: event.id } ,item_name:event.attributes.name}))
  }

  handleWarrantyStore = (event:any) => {
    this.setState(prevState => ({ WarrantyPlanForm: { ...prevState.WarrantyPlanForm,store: event.id } ,isOtherStoreSelect:event.attributes.store_name}))
  }

  handleWarrantyOtherStoreName = (event:any) => {
    this.setState({store_name: event.target.value},() => 
    this.isOtherStoreNameValid()
    );
  }
  
  handlewarrantyPlanDate = (event:any) => {
    const convertDate = moment(event).format('YYYY-MM-DD')
    return this.setState(prevState => ({ 
      WarrantyPlanForm: { 
        ...prevState.WarrantyPlanForm, 
        date: convertDate },
        errors: {
        ...this.state.errors, 
        dateError:false,
        dateErrorText: ""
      }}))
  }

  handleWarrnatyPlan = (event: any) => {
    const { name, value } = event.target;
    this.setState(prevState => ({ WarrantyPlanForm: { ...prevState.WarrantyPlanForm, [name]: value } }))
    this.setState({
      errors: {
        itemError: false,
        itemErrorText: '',
        storeError: false,
        storeErrorText: '',
        invoiceNumberError: false,
        invoiceNumberErrorText: "",
        dateError: false,
        dateErrorText: '',
        priceError: false,
        priceErrorText: '',
        store_nameError: false,
        store_nameErrosText: ''
      }
    })
  }
  handlePriceInput = (event: any) => {
    const { value, name } = event.target;
    this.setState({ WarrantyPlanForm: { ...this.state.WarrantyPlanForm, [name]: value } }, () => { this.isValidPrice(this.state.WarrantyPlanForm.price) });
  };

  getBackgroundColor = (route: any) => {
    const categoryValue = this.state.category.find((item: any) => item.id === route)
    this.setState({ themeValue: categoryValue })
  };
  getStoreList = (categoryId:any) => {
    this.setState({showLoader :true});
    this.getStoreListApiCall = callApi({
      contentType: configJSON.jsonApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.storeListDetails + `?category_id=${categoryId}&language_code=${iLanguage}`,
      headers: {}
    }, runEngine);
  }
  getItemList = (categoryId:any) => {
    this.setState({showLoader:true})
    this.getItemListApiCall = callApi({
      contentType: configJSON.jsonApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: configJSON.getItemListDetails + `?category_id=${categoryId}&language_code=${iLanguage}`,
      headers: {}
    }, runEngine);
  }

  // Customizable Area End
}