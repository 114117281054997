import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Button,
  Typography,
  Theme,
  withStyles,
  Divider,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import WarrantyPlanController, { Props } from "./WarrantyPlanController.web";
import { t } from "../../../components/src/i18n"; 
import { InputField ,rtlOffInputClasses} from "../../../components/src/CommonStyles";
import WarrantyCategoryForm from "../../../components/src/WarrantyCategoryForm.web";
import { WarrantyPlanPurchase } from "./WarrantyPlanPurchase.web";
import { deviceBasedDynamicDimension } from "../../../components/src/Toolkit";
import Spinner from "../../../components/src/Spinner.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },

});

export const CategoryStyles = (theme: Theme) => ({
  // Customizable Area Start
  CategoryFormContainerMain: {
    margin: "20px 0px"
  },
  CategoryFormContainer: {
    padding: '50px 50px 0px 100px',
    [theme.breakpoints.down('sm')]: {
      padding: 10

    },
    [theme.breakpoints.down('md')]: {
      padding: 10

    }
  },
  formSection: {
    // padding: '50px 0px 0px 100px',
    display: "flex",
    flexGrow: 1,
    // padding: '0px 100px 0px 100px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      padding: 0
    },
  },
  formGrid: {
    // padding: '50px 0px 0px 150px',
    padding: "0px 24px",
    height: "100%",
    display: 'flex',
    flexDirection:"column" as const,
    [theme.breakpoints.down('xs')] : {
      padding: 0,
    }
  },
  titleColorCategory: {
    color: '#fff',
    fontWeight: 'bold' as const,
    fontFamily: 'Manrope',
    fontStyle: 'normal',
    fontSize: "50px",
    [theme.breakpoints.down('xs')]: {
      fontSize: "40px"
    }
	
  },
  subtitleColorCategory: {
    color: '#fff',
    fontSize:"26px",
    fontFamily: 'Manrope',
    backgroundColor: 'transparent',
		fontStyle: 'normal',
		fontWeight: 'bold' as const,
    [theme.breakpoints.down('xs')]: {
      fontSize: "20px"
    }

  },
  dividerColor: {
    background: '#B3E5FF !important',
    // marginTop : '24px'
  },
  buttonContainerMain: {
    display: 'flex',
    justifyContent: "center",
    padding: "12px"
  },
  NoplanAvailableConatiner: {
    display: 'none'
  },
  buttonContainer: {
    width: '15%',
    background: '#fff',
    borderRadius: '25px',
    padding: '8px 50px',
    fontFamily: 'Manrope',
    fontSize:"20px",
    backgroundColor: '#fff',
		fontStyle: 'normal',
		fontWeight: 'bold' as const,
    color: 'rgba(255, 255, 255, 1)',
    textTransform: "capitalize" as const,
    [theme.breakpoints.down('md')]: {
      width: 'auto'
    },
    "&.Mui-disabled":{
      backgroundColor: '#c1c1c1',
      opacity:0.9,
      cursor:'progress' as const
    }
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },

  imgRight: {
    width: '100%',
    height: '100%',
    objectFit: 'contain' as const,
    maxWidth: "700px",
    maxHeight: '686px'
  },
  borderedField: {
    border: '1px solid #ccc',
    borderRadius: 4,
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  button: {
    margin: theme.spacing(1),
  },
  customIconColor: {
    // Apply your custom icon color class here
    '& .MuiInputAdornment-root .MuiSvgIcon-root': {
      fill: 'red',
    },
  },

  formContainer: {
    borderRadius:"12px",
    display: 'flex',
    transform: "none",
    flexDirection: 'column' as const,
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingBottom: '40px',
    backgroundColor: '#fff',
    [theme.breakpoints.down('xs')]: {
      padding: 10,
      paddingBottom: 20
    },
    '& .InputField': InputField,
     ...rtlOffInputClasses,

    '& InputDate': {
      position: 'relative'
    },
  
    '& input[type="date"]::-webkit-calendar-picker-indicator': {
      width:'5%',
      filter: 'invert(1)',
      cursor: 'pointer'

    },
   
      '& .RTLInput': {
        direction: "rtl",
      },
    "& .InputFieldSelect": {
      width: '100%',
      borderRadius: '12px',
      "& .MuiSvgIcon-root": {
        height: '36px',
        width: '36px',
        color: '#004D74',
        margin: '0px 12px',
        top: 'auto',
      },
      "& .MuiOutlinedInput-input": {
        paddingLeft: '14px'
      },
      "& .MuiIconButton-sizeSmall.MuiIconButton-edgeEnd": {
        top: 7

      },
  
    }
  },
  IconColor: {
    position:'absolute' as const,
    pointerEvents: 'none' as const,
  },
  divider: {
    margin: theme.spacing(2),
  },
  purchaseContainer:{ 
    display:"flex",
    flexDirection:"column" as const,
    justifyContent:"center",
    alignItems:"center",
    position: 'relative' as const,
    boxSizing: 'border-box' as const,
    boxShadow: "0px 0px 4px #EBEBEB",
    minWidth: deviceBasedDynamicDimension(360, true, 1),
    width:"min-content",
    minHeight:164,
    height: '100%',
    border:"2px solid rgba(255, 255, 255, 1)",
    cursor: "pointer",
    marginLeft: deviceBasedDynamicDimension(0, true, 1),
				marginTop: deviceBasedDynamicDimension(0, false, 1),
				opacity: 1,
				backgroundColor: 'rgba(255, 255, 255, 1)',
				borderColor: 'rgba(255, 255, 255, 1)',
				borderRadius: deviceBasedDynamicDimension(8, true, 1),
				borderWidth: deviceBasedDynamicDimension(2, true, 1),
        [theme.breakpoints.down('md')]: {
          minWidth: '330px !important'
        },
        [theme.breakpoints.down('xs')] :{
          minWidth: '300px !important'
        }
  },
  extendedPlanContainer: {
    display:'grid',
    gap:'20px',
    
    [theme.breakpoints.between('sm','md')]: {
      gridTemplateColumns: "1fr 1fr !important"
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: "1fr !important"
    },
  },
  planDiscountContainer:{
    position: 'absolute' as const,
    top:0,
    left:0,
    clipPath: 'polygon(0 0, 0% 100%, 100% 0)',
    width: 113.51,
    height: 92,
    fontStyle: 'normal',
    fontWeight: 'bold' as const,
    fontFamily: 'Manrope',
    fontSize: deviceBasedDynamicDimension(18, true, 1),
    borderTopLeftRadius:"5px"
  },
  expandedPlanOffContainerArabic: {
    clipPath: 'polygon(0 0, 100% 100%, 100% 0)'
  },
  percentageText:{
    transform: 'rotate(321deg)' ,
    color:"#fff",
    fontWeight: 'bold' as const,
    fontFamily: 'Manrope',
    fontSize: deviceBasedDynamicDimension(18, true, 1),
  },
  expandedPercentageText: {
    transform: 'rotate(321deg)',
    fontSize: deviceBasedDynamicDimension(26, true, 1),
  },
  warrantyPriceMainContainer:{
    display: "flex",
    backgroundColor: 'transparent',
    fontStyle: 'normal',
    fontWeight: 'bold' as const,
    padding: 0,
    color: 'rgba(0, 77, 116, 1)',
    fontFamily: 'Manrope',
    fontSize: 56,
    [theme.breakpoints.down('md')]: {
      fontSize: 35
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center'
    },

  },
  expandedPurchaseCOntainer: {
    borderRadius: deviceBasedDynamicDimension(20, true, 1),
	  borderWidth: deviceBasedDynamicDimension(2, true, 1),
    minWidth: '500px',
    [theme.breakpoints.down('md')]: {
    minWidth: 'auto'
    }
  },
  planExpandedContainer:{
    display: 'flex',
    justifyContent: "center",
    margin: 'auto',
    marginTop:"150px",
    maxWidth:"50%",
    width:"33%",
    transition: '0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)',
    [theme.breakpoints.down('md')]: {
      maxWidth:"100%",
      width:"90%",
      margin: '15px auto',
    }
  },
  expanedPlanOfferText:{
    fontWeight: 'normal' as const,
    padding: deviceBasedDynamicDimension(0, true, 1),
    color: 'rgba(105, 116, 121, 1)',
    fontFamily: 'Inter',
    fontSize: deviceBasedDynamicDimension(20, true, 1),
    wordBreak: "break-word" as const,
    hyphens: 'auto' as const,
  },
  percentOffContainer:{
    display: 'flex',
    backgroundColor: 'transparent',
    fontStyle: 'normal',
    fontWeight: 'bold' as const,
    padding: 0,
    color: 'rgba(0, 77, 116, 1)',
    fontFamily: 'Manrope',
    fontSize: deviceBasedDynamicDimension(18, true, 1),
    textDecoration: 'line-through',
    
  },
  expanedPlanContent:{
    backgroundColor: 'transparent',
    fontStyle: 'normal',
    padding: deviceBasedDynamicDimension(0, true, 1),
    color: 'rgba(105, 116, 121, 1)',
    fontFamily: 'Inter',
    fontSize: deviceBasedDynamicDimension(20, true, 1),
    margin: "20px 0px",
    wordWrap: 'break-word' as const,
    '::marker': { color: 'red' }
  },
  planValidDate:{
    wordBreak: "break-word" as const,
    hyphens: 'auto' as const,
    color: 'rgba(71, 78, 82, 1)',
    textAlign: 'center' as const,
    fontFamily: 'Manrope',
    fontSize:deviceBasedDynamicDimension(18, true, 1),
  },
  expandedPlanValidDate:{
        wordBreak: "break-word" as const,
        hyphens: 'auto' as const,
         padding: deviceBasedDynamicDimension(0, true, 1),
				color: 'rgba(71, 78, 82, 1)',
        textAlign: 'center' as const,
				fontFamily: 'Manrope',
				fontSize: deviceBasedDynamicDimension(32, true, 1),
        [theme.breakpoints.down('md')]: {
          fontSize: 20
        }
  },
  planPriceContainer:{
    display: "flex", 
    alignItems: "center", 
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column !important'
    }
  },
  expandedPlanOffContainer:{
    width: 194.95,
    height: 158,
    fontSize: deviceBasedDynamicDimension(26, true, 1),
    borderTopLeftRadius:"20px",
  },
  expandedPriceText: {
        color: 'rgba(0, 77, 116, 1)',
				fontFamily: 'Manrope',
				fontSize: deviceBasedDynamicDimension(80, true, 1),
        [theme.breakpoints.down('md')]: {
          fontSize: 50
        }
  },
  expandedOffPrice: {
    fontSize: deviceBasedDynamicDimension(22, true, 1)
  },
  downloadPravicyPolicyBtnMain:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  downloadPravicyPolicyBtn: {
    padding: deviceBasedDynamicDimension(0, true, 1),
				color: 'rgba(0, 77, 116, 1)',
				textAlign: 'center' as const,
				fontFamily: 'Inter',
				fontSize: deviceBasedDynamicDimension(18, true, 1),
        textTransform:"capitalize" as const,
        textDecoration:"underline",
  
        '.MuiButton-root':{
        textDecoration:"underline !important",

        }
  },
  warrantyPlaSubTitleContainer:{
    display: "flex", 
    flexDirection: "column" as const, 
    gap: "15px"
  },
  warrantyPurchaseMainContainer: {
    margin: "30px 0px",
    minHeight: '60vh'
  },
  noPlanAvailabelemainContainer:{
    minHeight: '70vh',
    [theme.breakpoints.down('sm')]: {
      minHeight: "45vh"
    }
  },
  warrantyPurchaseGridContainer:{
    display: "flex", 
    flexDirection: "column" as const, 
    padding: "0% 6%", 
    gap: "20px"
  },
  warranrtPlanMapContainer: {
    display: "flex", 
    flexDirection: "column" as const, 
    gap: "35px"
  },
  expandedContaiinerDiscription:{
    padding: '25px',
    paddingBottom:"50px" 
  },
  planDescriptionOverFlow:{
    maxHeight: '250px',
    overflow: 'scroll' as const,
    overflowX: 'hidden' as const,
    overflowY: "auto" as const,
    marginBottom: 12
  },
  expandedDivider:{
    margin: "2% 12%"
  },
  expandedTagMain:{
    display: 'grid',
     gap: '20px', 
     gridTemplateColumns: "1fr 1fr"
  },
  expandedTagContainer:{
    display: 'flex', 
    alignItems: "center", 
    padding: "5px 10px 3px 12px", 
    gap: "10px"
  },
  noPlanAvailableContainer: {
    position: 'absolute' as const,
    top: '47%',
    display: 'flex',
    justifyContent: "center",
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      top: '35%'
    }
  },
  noPlanAvailableText: {
    color: '#fff',
    fontFamily: "Manrope, sans-sarif",
    fontSize: 48,
    lineHeight: 1.5,
    fontWeight: 600,
    letterSpacing: 1,
    textAlign: 'center' as const,
    [theme.breakpoints.down('sm')]:{
      fontSize:35
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 22
    }
  },
  continueBtnHidden: {
    display: 'none'
  },
  CurrencySymbol:{
    margin:"0px 6px"
  }
  // Customizable Area End
})

class WarrantyPlan extends WarrantyPlanController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { WarrantyPlanForm, storeList, itemList,store_name,isOtherStoreSelect,Expanded ,errors,themeValue ,BasicPlan,AccidentalPlan ,ComboPlan ,CategoryPlans,ExtendedPlan,showLoader } = this.state;
    const isNoplanAvailable = BasicPlan?.length === 0 && AccidentalPlan?.length === 0 && ComboPlan?.length === 0 && ExtendedPlan?.length === 0;
    const { classes } = this.props;
    return (
        <div style={{ background: themeValue?.attributes?.bg_color }} className={classes.CategoryFormContainerMain}>
         <Spinner showLoader={showLoader} />
          {this.state.WarrantyPlanPurchase ?
          <WarrantyPlanPurchase 
            navigation={undefined} 
            id={""} classes={classes} 
            BasicPlan={BasicPlan} 
            AccidentalPlan={AccidentalPlan}
            ExtendedPlan={ExtendedPlan}
            ComboPlan={ComboPlan}
            CategoryPlans={CategoryPlans}
            Expanded={Expanded}
            handleCetegoryPlans={this.handleCetegoryPlans}
            />
          : 
          <Box className={classes.CategoryFormContainer}>
          <Grid className={classes.formSection}>
            <Grid container item xs={12} md={12} className={classes.formGrid}>
                <Box>
                  <Typography className={classes.titleColorCategory} data-testId="titleWarranty">
                    {t("warrantyForm.title")}
                  </Typography>
                  <Typography className={classes.subtitleColorCategory}>{`${t("warrantyForm.subTitle")} ${themeValue?.attributes?.name} ${t("warrantyForm.Purchases")}`}</Typography>
                </Box>
                
                <WarrantyCategoryForm
                    themeColor={themeValue}
                    errors={errors} 
                    classes={classes} 
                    storeList={storeList}
                    itemList={itemList} 
                    store_name={store_name}
                    isOtherStoreSelect={isOtherStoreSelect}
                    handleWarrnatyPlan={this.handleWarrnatyPlan} 
                    handlePriceInput={this.handlePriceInput}
                    handleWarrantyItem={this.handleWarrantyItem}
                    handleWarrantyStore={this.handleWarrantyStore}
                    handleWarrantyOtherStoreName={this.handleWarrantyOtherStoreName}
                    handleWarrantyPlanDate={this.handlewarrantyPlanDate}
                    WarrantyPlanForm={WarrantyPlanForm} 
                 />
            </Grid>
            <Grid item xs={12} md={12} className={`${classes.imgContainer}`}>
              <img src={themeValue?.attributes?.category_logo } className={classes.imgRight} alt="Category Logo"/>
            </Grid>
          </Grid>
        </Box>
          }

          {/* Divider */}
          <Divider className={`${classes.dividerColor} ${this.state.WarrantyPlanPurchase && !this.state.item_list_warranty_id && classes.continueBtnHidden}`}/>

          {/* Submit Button */}
          <div className={`${classes.buttonContainerMain} ${(this.state.WarrantyPlanPurchase && isNoplanAvailable) && classes.NoplanAvailableConatiner} ${this.state.WarrantyPlanPurchase && !this.state.item_list_warranty_id && classes.continueBtnHidden}`}>
            <Button disabled={this.state.disableSubmitButton} data-test-id="submit_form" type="submit" variant="contained" className={classes.buttonContainer} style={{ color: themeValue?.attributes?.text_color }} onClick={this.state.WarrantyPlanPurchase ? this.handleWarrantyPlanSubmit : this.handleWarrantySubmit}>
              {t("warrantyForm.continue")}
            </Button>
          </div>
        </div>
    );
  // Customizable Area End

  }
}

// Customizable Area Star
export default withStyles(CategoryStyles)(WarrantyPlan);
export { WarrantyPlan }
// Customizable Area End
