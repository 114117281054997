import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { callApi, sendCartItemListChanges, getNavigationMessage } from "./Toolkit";
import { getStorageData, setStorageData } from "../../framework/src/Utilities";
import { WithStyles } from "@material-ui/core";
export const configJSON = require("./config");
// Customizable Area End

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  index: number;
  CardData: {
    id: string;
    details: {
      warranty_name: string;
      item_name: string;
      item_invoice_id: string;
      category_bg_color: string;
      category_text_color: string;
      item_id: string;
      warranty_type: string;
      category_id: string;
    };
    total_price: number;
    quantity:number;
  };
  cartData : Array<{
    attributes: {
    id: string;
    details: {
      warranty_name: string;
      item_name: string;
      item_invoice_id: string;
      category_bg_color: string;
      category_text_color: string;
      item_id: string;
      warranty_type: string;
    };
    total_price: number;
    quantity:number;
  }}>
  updateCartData: (cartData: any) => void;
  updateInputQuantity: (_index:number, quantity:number) => void
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isModelOpen: boolean;
  selectedCartId: string;
  currency_symbol: string,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CartCardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  deleteCartCallId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.deleteCartCallId = "";

    this.state = {
      isModelOpen: false,
      selectedCartId: "",
      currency_symbol: ""
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const selectedCountry = await getStorageData('selectedCountry', true);
    this.setState({
      currency_symbol: selectedCountry.attributes.currency_symbol,
    });
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Token", message);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      runEngine.debugLog("API Message Recived", JSON.stringify(message));

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson != null) {
        if (!responseJson.errors) {
          if (apiRequestCallId === this.deleteCartCallId) {
            const updatedOrderItems  = this.props.cartData.filter(
              (item: { attributes: { id: string } }) => item.attributes.id !== this.state.selectedCartId
            );
            this.props.updateCartData(updatedOrderItems);
            let user = await getStorageData("user", true)
            user = {...user,attributes:{...user.attributes, cart_items_count : updatedOrderItems.length}}
            await setStorageData("user", JSON.stringify(user))
            this.setState({selectedCartId : ""})
            sendCartItemListChanges("UPDATED_API_CART")
          }
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      }
    }
    this.setState({ isModelOpen: false });
    // Customizable Area End
  }

  // Customizable Area Start

  toggleModal = () => {
    this.setState({ isModelOpen: !this.state.isModelOpen });
  };

  handleEdit = () => {
    const navigationMessage = getNavigationMessage('Category', this.props, this.props.CardData);
    navigationMessage.addData(getName(MessageEnum.NavigationScreenNameMessage), this.props.CardData.details.category_id)
    this.send(navigationMessage);
  }

  deleteCartItem = async () => {
    const token = await getStorageData("token");
    this.deleteCartCallId = callApi(
      {
        contentType: configJSON.apiContentType,
        method: configJSON.httpDeleteMethod,
        endPoint: configJSON.deleteCartItemAPiEndPoint + `/${this.state.selectedCartId}`,
        headers: {
          token: token,
        },
      },
      runEngine
    );
  };

  deleteCartItemFromLocal = async () => {
    const cartData = await getStorageData("storedCart", true);
    const updatedOrderItems = cartData.filter(
      (item: { attributes: { id: string } }) => item.attributes.id !== this.state.selectedCartId
    );
    this.setState({ isModelOpen: false });
    this.props.updateCartData(updatedOrderItems);
    await setStorageData("storedCart", JSON.stringify(updatedOrderItems));
    sendCartItemListChanges("UPDATED_LOCAL_CART")
  };

  handleDeleteCartItem = async (id: string) => {
    const token = await getStorageData("token");
    this.setState({selectedCartId : id})
    if (token) {
      this.deleteCartItem();
    } else {
      this.deleteCartItemFromLocal();
    }
  };

  shortWarrantyTitle = (input: string) => {
    let modifyString = input + " " + "Warranty";
    const words = modifyString.split(" ");
    const acronym = words.map((word) => word[0].toUpperCase()).join("");
    return acronym;
  }
  // Customizable Area End
}
