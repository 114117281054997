// Customizable Area Start
import React from 'react'
import { Paper, Box, Typography } from '@material-ui/core';
import OtpInput from "react-otp-input";
import {HelperBtnGroup, SubmitButton, TitleContainer} from './Reusables.web';
import { t } from '../../../components/src/i18n';
// Customizable Area End

// Customizable Area Start
interface OtpProps {
    title: string,
    subTitle: string,
    onSubmit: (event: any) => void,
    otp: string,
    otpError: boolean,
    otpErrorText: string,
    onChangeOtp: (newOtp: string) => void,
    isTimerRunning: boolean,
    minutes: number,
    seconds: number,
    resendOtp: () => void,
}
// Customizable Area End

// Customizable Area Start
export default function ForgotPasswordOtpPanel({ title, subTitle, onSubmit, otp, otpError, otpErrorText, onChangeOtp, isTimerRunning, minutes, seconds, resendOtp }: OtpProps) {
    return (
        <Paper elevation={2} className="FPFormPaper">
            <TitleContainer title={title} subTitle={subTitle}/>
            <Box className="LVOTPBox">
                <Box className={`${otpError && 'FPErrorOtp'}`}>
                    <OtpInput
                        numInputs={6}
                        shouldAutoFocus
                        onChange={onChangeOtp}
                        value={otp}
                        inputStyle="OTP"
                        renderInput={(props: any) => <input {...props} />}
                        inputType="text"
                        data-testId="ForgotPasswordOTPInput"
                        containerStyle={{display:'grid', gridTemplateColumns:"1fr 1fr 1fr 1fr 1fr 1fr", columnGap:8, direction:'ltr' }}
                    />
                </Box>
            </Box>
            {otpError &&
                <Box className="FPErrorBox">
                    <Typography className="FPErrorText">{otpErrorText}</Typography>
                </Box>
            }
            {isTimerRunning && (
                <Box className="FPOTPTimerBox">
                    <Typography className="ResendOtpText" >{t("forgotPassword.resentOtpIn")}</Typography>
                    <Typography className="FPOTPTimerText"> {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</Typography>
                </Box>
            )}
            <SubmitButton submitText={t("forgotPassword.submitOTP")} onSubmit={onSubmit}/>

            <HelperBtnGroup helperText={t("forgotPassword.noOtpRecieved")} buttonText={t("common.resend")} disabled={isTimerRunning} onClick={resendOtp}/>
            
        </Paper>
    )
}
// Customizable Area End
