import React, { Component } from 'react';
import { WithStyles, withStyles, Theme, Box, Typography } from "@material-ui/core"
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { t } from "../i18n"
import { toast } from 'react-toastify';
interface Props extends WithStyles {
    itemIndex: number;
    quantity: number;
    updateQuantity: (index: number, quantity: number) => void;
    onDeleteItemClick: () => void;
}

interface S {

}

class QuantityBox extends Component<Props, S> {


    onQuantityInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue: number = parseInt(event.target.value);
        let quantity: number = !isNaN(inputValue) ? inputValue : 1;
        if (quantity < 1) {
            quantity = 1;
        }
        this.checkPlanPurchaseQuantityLimit(this.props.itemIndex, quantity - 1)
    }
    checkPlanPurchaseQuantityLimit = (itemIndex: any,quantity: number) => {
        if (quantity < 99) {
            this.props.updateQuantity(itemIndex, quantity + 1)
        }else{
            toast.warning(t("shoppingCart.moreThanQuantityMsg"))
        }
    }

    renderMinus = (quantity: number) => {
        if (quantity > 1) {
            return <Box className={`minus minusBorder`} data-test-id="MinusIcon" onClick={() => this.props.updateQuantity(this.props.itemIndex, quantity - 1)}><RemoveIcon /></Box>
        }

        return <Box className={`minus minusBorder`} data-test-id="MinusIcon" onClick={this.props.onDeleteItemClick}><DeleteOutlineIcon /></Box>
    }
    render() {
        const { itemIndex, quantity, classes } = this.props;
        return (
            <Box className={classes.QuantityBox}>
                <Typography className="QuantityText">{t("shoppingCart.quantity")}&nbsp;:&nbsp;</Typography>
                {
                    this.renderMinus(quantity)
                }
                <input type="number" value={quantity} data-test-id="QuantityInput" onChange={this.onQuantityInputChange} />
                <Box className={`plus plusBorder`} data-test-id="PlusIcon" onClick={() => this.checkPlanPurchaseQuantityLimit(itemIndex, quantity)}><AddIcon /></Box>
            </Box>
        );
    }
}

const styles = (theme: Theme) => ({
    QuantityBox: {
        display: "flex",
        marginTop: 6,
        alignItems: "center" as const,
        "& .QuantityText": {
            fontFamily: "manrope",
            fontWeight: "bold" as const,
            fontSize: "18px",
            color: "rgba(137, 145, 164, 1)",
            [theme.breakpoints.down("xs")]: {
                fontSize: "12px",
            },
        },
        "& .minus, .plus": {
            width: 28,
            height: 26,
            background: "#f2f2f2",
            borderRadius: 4,
            borderTop: "1px solid #ddd",
            borderBottom: "1px solid #ddd",
            display: "flex",
            justifyContent: "center",
            alignItems: "center" as const,
            cursor: "pointer" as const,
            [theme.breakpoints.down("xs")]: {
                height: 22,
                width: 26,
            },
            "& svg": {
                height: "0.90em",
                width: "0.90em",
                [theme.breakpoints.down("xs")]: {
                    height: "0.75em",
                    width: "0.75em",
                },
            },
        },
        "& .minusBorder": {
            borderLeft: "1px solid #ddd",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        },
        "& .plusBorder": {
            borderRight: "1px solid #ddd",
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
        },
        "& input": {
            height: 28,
            width: 36,
            textAlign: "center",
            fontSize: 16,
            borderTop: "1px solid #ddd",
            borderBottom: "1px solid #ddd",
            borderLeft: "none",
            borderRight: "none",
            display: "inline-block",
            verticalAlign: "middle",
            "-moz-appearance": "textfield",
            "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button": {
                "-webkit-appearance": "none",
                margin: 0,
            },
            [theme.breakpoints.down("xs")]: {
                height: 24,
                width: 32,
                fontSize: 12,
            },
        },
    }
});

export default withStyles(styles)(QuantityBox);