import React, { Component } from 'react';
import { WithStyles, withStyles, Theme, Box, Typography, Modal, Button } from "@material-ui/core"
import { t } from "../i18n"
interface Props extends WithStyles {
    itemId: string;
    removeItem: (itemId: string) => void;
    isModalOpen: boolean;
    closeModal: () => void;
}

interface S {

}

class RemoveItemModal extends Component<Props, S> {
    render() {
        const { itemId, removeItem, isModalOpen, closeModal, classes } = this.props;
        return (
            <Modal
                open={isModalOpen}
                onClose={closeModal}
                className={classes.modalRoot}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={classes.mainBox}>
                    <Box className={classes.textContainer}>
                        <Typography
                            className={classes.successText}
                            align="center"
                            variant="h4"
                            data-test-id="remove-warning"
                        >
                            {t("shoppingCart.removeWarning")}
                        </Typography>
                    </Box>
                    <Box className={classes.ButtonBox}>
                        <Button
                            data-test-id="btnRemoveConfirm"
                            variant="contained"
                            color="primary"
                            className={classes.removeButton}
                            onClick={() => removeItem(itemId)}
                        >
                            <Typography className={classes.ConfirmText}>{t("shoppingCart.remove")}</Typography>
                        </Button>
                        <Button
                            data-test-id="btnCancel"
                            variant="contained"
                            color="primary"
                            className={classes.cancelButton}
                            onClick={closeModal}
                        >
                            <Typography className={classes.CancelText}>
                                {t("shoppingCart.cancel")}
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Modal>
        );
    }
}

const styles = (theme: Theme) => ({
    modalRoot: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    mainBox: {
        width: "465px",
        backgroundColor: "#FFFFFF",
        borderRadius: "20px",
        padding: 32,
        [theme.breakpoints.down("xs")]: {
            width: "80%",
            padding: 24,
        },
    },

    ButtonBox: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column" as const,
        alignItems: "center",
        width: "100%",
        marginTop: 32,
        gap: "10px",
    },
    textContainer: {
        display: "flex",
        flexDirection: "column" as const,
        alignItems: "center",
    },

    removeButton: {
        background: "#004D74",
        borderRadius: 50,
        padding: "12px 0",
        width: "65%",
        marginTop: 4,
        "&:hover": {
            background: "#004D74",
        },
    },

    successText: {
        fontWeight: "bold" as const,
        fontSize: "28px",
        fontFamily: "Manrope" as const,
        color: "rgba(53, 65, 79, 1)",
        width: "80%",
    },
    cancelButton: {
        background: "white",
        borderRadius: 50,
        padding: "12px 0",
        width: "65%",
        border: `${theme.palette.primary.main} solid 1px`,
        marginTop: 4,
        "&:hover": {
                color: "#004D74" ,
                backgroundColor: 'rgba(0, 77, 116, 0.04)',
        },
    },

    ConfirmText: {
        fontFamily: "Manrope" as const,
        fontStyle: "normal",
        fontWeight: "bold" as const,
        fontSize: 20,
        lineHeight: 1.5,
        textAlign: "center" as const,
        textTransform: "capitalize" as const,
        color: "#FFFFFF",
    },
    CancelText: {
        fontFamily: "Manrope" as const,
        fontStyle: "normal",
        fontWeight: "bold" as const,
        fontSize: 20,
        width: "100%",
        lineHeight: 1.5,
        textAlign: "center" as const,
        textTransform: "capitalize" as const,
        color: "#004D74",
        "&:hover":{
            color:"#004D74"
        }
    },
});

export default withStyles(styles)(RemoveItemModal);