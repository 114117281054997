import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  Button,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  withStyles
} from "@material-ui/core";
import ContactusController, { Props } from "./ContactusController";
import { t, iDirection, iLanguage } from "../../../components/src/i18n";
import { closeIcon } from "./assets";
import Spinner from "../../../components/src/Spinner.web";
// Customizable Area End


class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    return (
      // Customizable Area Start
      <>
        <Spinner showLoader={this.state.showLoader} />
        <Modal
          open={this.props?.isModalOpen??false}
          onClose={this.props.handleClose}
          className={this.props.classes.root}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={this.props.classes.mainBox}>
            <Box className={this.props.classes.modalHeader}>
              <Typography className={this.props.classes.typography} variant="h4" data-test-id="title">
                {t("contactUsModel.contactTitle")}
              </Typography>
              <img src={closeIcon} alt="closeIcon" onClick={this.props.handleClose} />
            </Box>

            <Box className={this.props.classes.OptionBox} dir={iDirection}>
              <TextField
                data-test-id="txtName"
                id="outlined-basic"
                className={this.props.classes.InputField}
                label={t("common.fullName")}
                variant="outlined"
                lang={iLanguage}
                onChange={this.setContactUsName}
                error={this.state.nameError}
                helperText={this.state.nameErrorText}
                
              />

              <TextField
                data-test-id="txtEmail"
                id="outlined-basic"
                className={this.props.classes.InputField}
                label={t("common.emailAddress")}
                variant="outlined"
                onChange={this.setContactUsEmail}
                error={this.state.emailError}
                helperText={this.state.emailErrorText}
              />

              <FormControl
                variant="outlined"
                className={this.props.classes.InputFieldCuntryCode}
              >
                <InputLabel htmlFor="countrySelect">
                  {t("contactUsModel.code")}
                </InputLabel>

                <Select
                  data-testId="CountrySelect"
                  variant="outlined"
                  label={t("contactUsModel.code")}
                  className={this.props.classes.InputFieldSelect}
                  onChange={this.handleCountryChange}
                  value={this.state.selectedCountry}
                >
                    {this.state.countryCodeList.map((country: any) => (
                      <MenuItem
                        value={country.country_code}
                        key={country.country_code}
                        className={this.props.classes.MenuItemSelect}
                        dir={iDirection}
                      >
                        <img src={country.image} height={24} width={36} crossOrigin="anonymous" alt={country.name} />
                        <span className={this.props.classes.CountryNameInSelect}>{country.country_code + " " + country.name}</span>
                      </MenuItem>
                    ))}
                </Select>

                <TextField
                  value={this.state.phoneNumber}
                  data-test-id="txtPhoneNumber"
                  id="outlined-basic"
                  className={this.props.classes.inputFieldSelectType}
                  label={t("common.phoneNumber")}
                  variant="outlined"
                  color="secondary"
                  onChange={this.setContactUsPhoneNumber}
                  error={this.state.phoneNumberError}
                  helperText={this.state.phoneNumberErrorText}
                />
              </FormControl>

              <TextField
                data-test-id="txtComments"
                id="outlined-textarea"
                className={this.props.classes.InputField}
                label={t("contactUsModel.Message")}
                variant="outlined"
                multiline
                rows={4}
                onChange={this.setMessage}
                value={this.state.comments}
              />
            </Box>

            <Box className={this.props.classes.ButtonBox}>
              <Button
                data-test-id="btnSubmit"
                variant="contained"
                color="primary"
                className={this.props.classes.CofirmButton}
                onClick={this.addContactApi}
              >
                <Typography className={this.props.classes.ConfirmText}>
                  {t("contactUsModel.submit")}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={this.state.isConfirmM}
          onClose={this.props.handleClose}
          className={this.props.classes.root}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={this.props.classes.mainBox}>
            <Box className={this.props.classes.confirmModalClose}>
              <img
                src={closeIcon}
                alt="closeIcon"
                onClick={this.btnBackProps.onPress}
              />
            </Box>
            <Box className={this.props.classes.textContainer}>
              <Typography className={this.props.classes.successText} align="center" variant="h4">
                {t("contactUsModel.SuccessSubmitText")}
              </Typography>
            </Box>
            <Box className={this.props.classes.ButtonBox}>
              <Button
                data-test-id="btnSubmitConfirm"
                variant="contained"
                color="primary"
                className={this.props.classes.CofirmButton}
                onClick={this.btnBackProps.onPress}
              >
                <Typography className={this.props.classes.ConfirmText}>
                  {t("contactUsModel.backtoHome")}
                </Typography>
              </Button>
              <Button
                data-test-id="btnCancel"
                variant="contained"
                color="primary"
                className={this.props.classes.cancelButton}
                onClick={this.btnBackProps.onPress}
              >
                <Typography className={this.props.classes.CancelText}>
                  {t("contactUsModel.Cancel")}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Modal>
      </>
      //Merge Engine End DefaultContainer
      // Customizable Area End
      );
  }
}

// Customizable Area Start

export const ModalStyles: any = {
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mainBox: {
    width: "465px",
    backgroundColor: "#FFFFFF",
    borderRadius: "20px",
    padding: 32,
  },
  "@media (max-width: 599.95px)": {
    mainBox: {
      width: "80%",
      padding: 24,
    },
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 20px",
    cursor: "pointer",
    direction: iDirection, 
  },
  confirmModalClose: {
    display: "flex",
    justifyContent: "end",
    cursor: "pointer",
    marginBottom: "10px"
  },
  OptionBox: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    margin: 24,
  },
  ButtonBox: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: 32,
    gap: "10px",
  },
  InputFieldCuntryCode: {
    width: "100%",
    margin: "8px 24px",
    borderRadius: 10,
    color: "black",
    display: "flex" as "flex",
    flexDirection: "row",
    gap: "10px",
  },
  typography: {
    fontWeight: 600,
    color: "#717171",
    fontSize:"26px"
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  successText: {
    fontWeight: "bold",
    fontSize: "28px",
    fontFamily: "Manrope",
    color: "rgba(53, 65, 79, 1)",
    width: "80%"
  },
  CofirmButton: {
    background: "#004D74",
    borderRadius: 50,
    padding: "12px 0",
    width: "65%",
    marginTop: 4,
    "&:hover": {
      background: "#004D74",
    },
  },
  cancelButton: {
    background: "#fff",
    color: "#004d74",
    borderRadius: 50,
    padding: "12px 0",
    width: "65%",
    border: "#004D74 solid 1px",
    marginTop: 4,
    boxShadow: 'none',
    "&:hover": {
      background: "rgba(0, 77, 116, 0.04)",
      color: "#004d74",
      boxShadow: 'none'
    },
  },
  ConfirmText: {
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 20,
    lineHeight: 1.5,
    textAlign: "center",
    textTransform: "capitalize",
    color: "#FFFFFF",
  },
  CancelText: {
    fontFamily: "Manrope",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 20,
    width: "65%",
    lineHeight: 1.5,
    textAlign: "center",
    textTransform: "capitalize",
    color: "#004d74",
  },
  InputField: {
    width: "100%",
    margin: "8px 24px",
    borderRadius: 15,
    color: "black",
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      color: "black",
    },
  },
  InputFieldSelect: {
    width: "35%",
    borderRadius: 12,
    color: "black",
    height: "100%",
    "& .MuiSelect-select": {
      padding: "13px 32px 13px 14px",
      display:"flex",
      alignItems:"center" as const,
    },
    "& .MuiOutlinedInput-root": {
      padding: "14px 0px",
      color: "black",
    },
  },
  inputFieldSelectType: {
    width: "80%",
    color: "black",
    "& .MuiOutlinedInput-root": {
      borderRadius: 10,
      color: "black",
    },
  },
  MenuItemSelect: {
    "& img": {
      width: 32,
      objectFit: "contain",
    }
  },
  CountryNameInSelect: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 18,
    lineHeight: 1.5,
    letterSpacing: 0.1878,
    marginLeft: "5px",
    color: "#051720",
    overflow: "hidden" as const,
    textOverflow:"ellipsis"
  },
};

export default withStyles(ModalStyles)(Contactus);
export { Contactus };

// Customizable Area End
