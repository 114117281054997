import { Theme } from '@material-ui/core'
export const InputField = {
    width: '100%',
    margin: '12px 0px',
    borderRadius: '12px',
    "& .MuiInputLabel-outlined[data-shrink='false']" : {
        transform:"translate(14px, 22px) scale(1)"
    },
    "& .MuiOutlinedInput-input": {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 18,
        lineHeight: 1.3,
        letterSpacing: '0.1875px',
        color: '#051720'
    },
    "& .MuiOutlinedInput-root": {
        borderRadius: 12
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-input": {
        color: '#FF2121'
    },
    "& .MuiOutlinedInput-adornedStart": {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: 1.3,
        letterSpacing: '0.1875px',
        color: '#051720'
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 16,
        lineHeight: 1
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink.Mui-focused": {
        color: '#004D74'
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: 'rgb(192, 192, 192)'
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: '#004D74'
    },
    "& .MuiFormHelperText-root.Mui-error": {
        fontFamily: 'Manrope',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.25,
        color: '#FF2121',
        textAlign: 'end' as const,
        marginTop: 8,
    },
    "&.RTLOFF": {
        "& .MuiInputBase-adornedStart": {
            flip: false,
            direction: 'ltr' as 'ltr',
            paddingLeft: 12
        },
        "& .MuiOutlinedInput-root": {
            flip: false,
            direction: 'ltr' as 'ltr',
        }
    }
}

export const rtlOffInputClasses = {
    "& .RTLPlaceholder": {
        "&::placeholder": {
            flip: false,
            textAlign: 'right'
        }
    },
    "& .RTLInput": {
        flip: false,
        paddingRight: 12,
        paddingLeft: 0
    },
    "& .RTLFieldSet": {
        "& fieldset": {
            flip: false,
            direction: 'rtl'
        }
    },
}

export const mainContainer = {
    backgroundColor: '#FAFAFA'
}

export const flexBetween = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}

export const flexCenter = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}


export const inputCheckbox = (theme: Theme) => ({
    "& .MuiFormControlLabel-label": {
        fontFamily: 'Inter, sans-serif',
        fontSize: 18,
        lineHeight: 1.5,
        color: '#8991A4',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        }
    }
})

export const flexEnd = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
}

export const forgotPasswordSubmitGrid = (theme: Theme) => ({
    marginTop: 24,
    "& .SubmitBtnGridItem": {
        display: "flex",
        justifyContent: "center",
        marginTop: 8,
        marginBottom: 8,
        "& .SubmitBtn": {
            backgroundColor: "#004D74",
            color: "white",
            height: "64px",
            width: "360px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50px",
            [theme.breakpoints.down('sm')]: {
                width: "70%"
            },
            "&:hover": {
                backgroundColor: "#004D74",
            },
            "& .SubmitBtnText": {
                fontFamily: "'Manrope', sans-serif",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: "20px",
                lineHeight: 1.3,
                textAlign: "center",
                color: "#FFFFFF",
                textTransform: "initial" as const,
                letterSpacing: 1
            }
        }
    }
})

export const warrantyPagination = (theme: Theme) => ({
    "& .MuiPaginationItem-root": {
        height: 54,
        width: 54,
        margin: 0,
        fontSize: 20,
        fontWeight: 500,
        "&.MuiPaginationItem-ellipsis": {
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end' as const
        }
    },
    "& .MuiPaginationItem-page": {
        borderRadius: 0,
        color:'#212121',
        fontFamily: 'Manrope, sans-serif',
        fontWeight:600,
    },
    '& .MuiPaginationItem-page[aria-label="Go to previous page"]': {
        backgroundColor: theme.palette.primary.main,
        opacity: 0.9,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        "&.Mui-disabled": {
            opacity: 0.36,
        },
        "& .MuiPaginationItem-icon": {
            color: '#FFF',
            height: '2rem',
            width: '2rem'
        }
    },
    '& .MuiPaginationItem-page[aria-label="Go to next page"]': {
        backgroundColor: theme.palette.primary.main,
        opacity: 0.9,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        "&.Mui-disabled": {
            opacity: 0.36,
        },
        "& .MuiPaginationItem-icon": {
            color: '#FFF',
            height: '2rem',
            width: '2rem'
        }
    }
})
