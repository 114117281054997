export const electronicImg = require("../assets/electronicLogo.png");
export const sportCategoryImg = require("../assets/sportLogo.png");
export const furnitureImg = require("../assets/furnitureLogo.png");
export const fashionImg = require("../assets/fashionCategoryLogo.png");
export const secureIcon = require("../assets/secure_icon.png");
export const professionalsIcon = require("../assets/professionals_icon.png");
export const supportIcon = require("../assets/support_icon.png");
export const discountsIcon = require("../assets/discounts_icon.png");
// Customizable Area Start
export const aboutUsWarrantyImage = require("../assets/625d5665931e3c7d7bdd3ec02b7fb3d0a87b68f6.png");
export const aboutUsLocationIcon = require("../assets/a6cb7269c4caa91012d944d64557cc7a3b80d465.png");
// Customizable Area End
