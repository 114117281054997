import React from "react";
// Customizable Area Start
import {
  Container,
  Box,
  TextField,
  IconButton,
  Theme,
  withStyles
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { InputField } from "../../../components/src/CommonStyles";
import { t } from "../../../components/src/i18n";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { searchQuery, searchEnabled } = this.state;
    const { classes } = this.props
    return (
        <>
          {/* Customizable Area Start */}
            <Box className={classes?.SearchBox}>
              <TextField
                  placeholder={t("common.search")!}
                  label={t("common.search")}
                  className="InputField"
                  type={"text"}
                  variant="outlined"
                  value={searchQuery}
                  onChange={this.onQueryChange}
                  onKeyUp={this.onSearchEnter}
                  InputProps={{
                      endAdornment: (
                        <>
                          <IconButton  color="primary" onClick={this.handleSearch}>
                            <SearchIcon />
                          </IconButton>
                          {
                            searchEnabled && (
                              <IconButton  color="secondary" onClick={this.clearSearch}>
                                <CloseIcon />
                              </IconButton>
                            )
                          }
                        </>
                    )
                  }}
              />
            </Box>
          {/* Customizable Area End */}
        </>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const searchStyle = (theme:Theme) => ({
  SearchBox:{
    maxWidth:440,
    width:'100%',
    margin:"16px 0px",
    "& .InputField":InputField,
    "& .MuiInputLabel-outlined[data-shrink='false']" : {
      fontSize: "18px",
      fontFamily: 'Manrope',
      fontStyle: 'normal',
      fontWeight: '500',
      lineHeight: 0.7,
  },
  }
});
export default withStyles(searchStyle)(AdvancedSearch);
// Customizable Area End
