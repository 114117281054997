import React, { Component } from 'react';
import { Button, Typography, withStyles, WithStyles, Theme } from '@material-ui/core';
import { deviceBasedDynamicDimension } from '../Toolkit';
interface Props extends WithStyles {
    isActive: boolean;
    onClick: (index?:number) => void;
    title: string;
    index?:number;
    customBtnClass?:any;
    customBtnTextClass?:any;
}

interface S {

}

interface SS {

}
class TabButton extends Component<Props, S, SS> {
    handleBtnClick = () => {
        const { index, onClick } = this.props
        if(index){
            onClick(index);
        }else{
            onClick();
        }
    }
    render() {
        return (
            <Button variant='outlined' className={`${this.props.classes.Button} ${this.props.isActive ? 'active' : ''} ${this.props.customBtnClass || ''}`} onClick={this.handleBtnClick} disabled={this.props.isActive}>
                <Typography className={`${this.props.classes.ButtonText} ${this.props.customBtnTextClass}`}> {this.props.title}</Typography>
            </Button>
        );
    }
}

const style = (theme:Theme) => ({
    Button: {
        borderRadius: deviceBasedDynamicDimension(6, true, 2),
        width: "80%",
        height: deviceBasedDynamicDimension(72, true, 1),
        margin:`${deviceBasedDynamicDimension(24, true, 1)} 0px`,
        "&.active":{
            backgroundColor:'rgba(221, 231, 237, 1) !important'
        },
        [theme.breakpoints.down('sm')]:{
            margin:16,
            width: "100%",
            minWidth: "auto",
            height: deviceBasedDynamicDimension(48, true, 1),
        }
    },
    ButtonText: {
        fontStyle: 'normal' as const,
        fontWeight: 600,
        padding: deviceBasedDynamicDimension(0, true, 1),
        color: 'rgba(0, 77, 116, 1)',
        textAlign: 'center' as const,
        textAlignVertical: 'top',
        fontFamily: 'Manrope',
        fontSize: deviceBasedDynamicDimension(24, true, 1),
        textTransform: 'initial' as const,
        [theme.breakpoints.down('sm')]:{
            whiteSpace:'nowrap' as const,
            fontSize: deviceBasedDynamicDimension(20, true, 1),
        }
    }
});
export default withStyles(style)(TabButton);