import React from "react";
// Customizable Area Start
import { BlockComponent } from "../../framework/src/BlockComponent";
import { Box, Theme ,Button, withStyles, IconButton,Hidden,Stepper,Step,StepButton, StepLabel, StepConnector} from "@material-ui/core";
import {  iLanguage ,t} from "./i18n";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Link } from "react-router-dom";
import { deviceBasedDynamicDimension } from "./Toolkit";
import ClearIcon from '@material-ui/icons/Clear';
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
    // Customizable Area Start
    classes?:any;
    handleBackButton?:any;
    activeTab?:any;
    // Customizable Area End
  }
  
  interface S { }
  
  interface SS {
    id: any;
  }

  const QontoConnector = withStyles({
    alternativeLabel: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    active: {
      '& $line': {
        borderColor: '#004d74',
      },
    },
    completed: {
      '& $line': {
        borderColor: '#004d74',
      },
    },
    line: {
      borderColor: 'rgba(137, 145, 164, 1)',
      borderTopWidth: 2,
      borderRadius: 1,
    },
  })(StepConnector);

  const customStepperBar = (theme: Theme) => ({
    tabContainer: {
      position: "relative" as const,
      display: "flex",
      alignItems: " center",
      justifyContent: "space-around" as const,
      height: "140px"
  },
  backButtonContainer: {
      display: " flex",
      alignItems: "center",
      position: 'absolute' as const,
      gap: '10px',
      left: '5%',
      color: 'rgba(92, 92, 92, 1)',
      fontFamily: 'Inter',
      fontSize: deviceBasedDynamicDimension(20, true, 1),
      cursor: "pointer",
      [theme.breakpoints.down('md')] : {
        top: 10
      }
  },
  Tab: {
      margin: '0 auto',
      '& .tabInner': {
          height: '56px',
          display: 'flex',
          border: '1px solid #000000',
          borderRadius: '28px',
          '& .confirmTab': {
            '& .MuiButtonBase-root': {
            width: '100%',
            borderTopRightRadius: '28px !important',
            borderBottomRightRadius: '28px !important'
            }
          },
          '& .customerInfoTab':{
            borderRadius: '0px !important',
            // marginLeft: -18,
            // width: '314px !important',
            '& .MuiButtonBase-root': {
                '& .MuiButton-label': {
                    color: "#004D74 !important"
                },
                backgroundColor: "#D8EBF5 !important",
            borderRadius: '0px !important',
            },
            '& .arrowContainer': {
                "& .arrow": {
                backgroundColor: "#D8EBF5 !important"
                }
            }
          },
          '& .tabChild': {
              '& .MuiButtonBase-root': {
                  '& .MuiButton-label': {
                      fontWeight: 'bold',
                      color: 'rgba(47, 47, 47, 1)',
                      textAlign: 'center' as const,
                      fontFamily: 'Manrope',
                      fontSize: deviceBasedDynamicDimension(16, true, 1),
                      textTransform: "capitalize"
                  }
              },
              width: '305px',
              display: 'flex',
              position: "relative" as const,
              borderRadius: '28px',
          },
          '& .stepperLink':{
            textDecoration: "none",
            color: "inherit",
            width: '100%'
          },
          '& .active': {
              '& .MuiButtonBase-root': {
                  '& .MuiButton-label': {
                      color: "#004D74 !important"
                  }
              }
          },
          "& .arrowContainer": {
              overflow: 'hidden',
              position: "absolute" as const,
              right: -25,
              width: '66px',
              height: '100%',
              zIndex: 9
          },
          "& .arrow": {
              width: '52px',
              height: '100%',
              transform: 'rotate(45deg)',
              border: '1px solid black',
              borderRadius: '7px',
              borderLeft: 'none',
              borderBottom: 'none',
          },
      },
      '& button': {
          boxShadow: 'none !important',
          backgroundColor: 'transparent !important',
          width: '100%',
          borderTopLeftRadius: '28px',
          borderBottomLeftRadius: '28px'
      },
  },
  stepperContainer:{
    padding: '20px 100px',  
    '& .MuiStepIcon-active': {
      [theme.breakpoints.down('md')]: {
        width: 28,
        height: 28
      }
    },
    '& .MuiStepLabel-iconContainer':{
      paddingRight: 0
    },
    '& .MuiButtonBase-root.Mui-disabled':{
      '& .MuiSvgIcon-root':{
        border: '1.8px solid black',
        borderRadius: '50%',
        color: '#fff',
        '& .MuiStepIcon-text':{
          fill: "black",
          fontSize: '14px'
        },
       },
    },
    '& .MuiStep-horizontal ':{
      padding: 0,
      '& .MuiStepLabel-root':{
        flexDirection: "column" as const
      }
    },
    '& .MuiStepConnector-alternativeLabel' : {
      top: 12,
      left: 'calc(-53% + 20px)',
      right: 'calc(45% + 20px)',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '50px 50px 10px 50px',
    },
    [theme.breakpoints.between('sm' ,'md')]: {
      padding: '50px 0px 10px 0px'
    }
  }
  })
  const steppetOptions = [t("orderDetails.title"),t("orderDetails.customerInformation"),t("orderDetails.payment"),t("orderDetails.Confirmation")]
  // Customizable Area End
  

class OrderDetailsStepper  extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
  }

  render() {

    const { classes ,handleBackButton,activeTab } = this.props;
    return (
      <>
        {/* Customizable Area Start */}
        <Hidden only={['xs', 'sm', 'md']}>
          <Box className={classes.tabContainer}>
            {activeTab === 4 ? (
                <Box style={{position: 'absolute', right: "50px"}}>
               <Link to="/">
                <IconButton>
                    <ClearIcon style={{color: "rgba(47, 47, 47, 1)"}}/>
                </IconButton>
                </Link>
              </Box>
            ) : (
                <div data-testId="order_details_backbtn" className={classes.backButtonContainer} onClick={handleBackButton}>
                <KeyboardBackspaceIcon style={iLanguage === "ar" ? {transform: "rotate(180deg)"} : {}}/> {t("orderDetails.backBtnText")}
               </div>
            )}
        <section className={classes.Tab}>
        
            <Box className={'tabInner'}>
                <Box data-testId="order_details" className={'tabChild active'} style={{ display: 'flex' ,backgroundColor: "#D8EBF5"}}><Button variant="contained" disabled>{t("orderDetails.title")}</Button>
                    <div className={'arrowContainer'} >
                        <div className={'arrow'} style={{backgroundColor: "#D8EBF5"}}></div>
                    </div>
                </Box>
                <Box className={`tabChild ${[2,4,3].includes(activeTab) && 'customerInfoTab'}`} style={{ display: 'flex' }}><Button variant="contained" disabled>{t("orderDetails.customerInformation")}</Button>
                    <div className={'arrowContainer'} >
                        <div className={'arrow'}></div>
                    </div></Box>
                <Box className={`tabChild ${[3,4].includes(activeTab) && 'customerInfoTab'}`} sx={{ display: 'flex' }}><Button variant="contained" disabled>{t("orderDetails.payment")}</Button>
                    <div className={'arrowContainer'}>
                        <div className={'arrow'}></div>
                    </div></Box>
                <Box className={`tabChild ${[4].includes(activeTab) && 'customerInfoTab confirmTab'}`} sx={{ display: 'flex' }}><Button variant="contained" disabled>{t("orderDetails.Confirmation")}</Button></Box>
              </Box>
            </section>
          </Box>
        </Hidden>
        <Hidden only={['xs', 'xl', 'lg']}>
          <Stepper className={classes.stepperContainer} activeStep={activeTab - 1} alternativeLabel connector={<QontoConnector />} >
            {steppetOptions.map((label, index) => (
              <Step key={index}>
                <StepButton >
                  <StepLabel>{label}</StepLabel>
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Hidden>
        <Hidden only={['sm', 'md', 'lg', 'xl']}>
          {activeTab === 4 ? (null) :
            <div className={classes.backButtonContainer} onClick={handleBackButton}>
              <KeyboardBackspaceIcon style={iLanguage === "ar" ? { transform: "rotate(180deg)" } : {}} />
            </div>}
          <Stepper className={classes.stepperContainer} activeStep={activeTab - 1} connector={<QontoConnector />}>
            {[1, 2, 3, 4].map((label, index) => (
              <Step key={index}>
                <StepButton >
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Hidden>
        {/* Customizable End Start */}
      </>
    );
  }
}

// Customizable Area Start

export default withStyles(customStepperBar)(OrderDetailsStepper);

// Customizable Area End
