import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Modal,
  FormControl, 
  Select, 
  MenuItem, 
  FormHelperText,
  MenuProps
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { iDirection, iLanguage, t } from "../../../components/src/i18n";
import Spinner from "../../../components/src/Spinner.web";

const selectMenuProps: Partial<MenuProps> = {
  anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
  },
  transformOrigin: {
      vertical: "top",
      horizontal: "left"
  },
  getContentAnchorEl: null
};
const theme = createTheme({
  direction: iDirection,
  overrides: {
      MuiPopover: {
          paper: {
              border: `1px solid rgba(0, 0, 0, 0.23)`,
              marginTop: 8,
              borderRadius: 12,
              boxShadow: 'none'
          }
      },
      MuiSvgIcon: {
          root: {
              width: 28,
              height: 28,
          },
      },
      MuiSelect: {
          icon: {
              color: "#212121",
              top: "auto"
          },
          iconOutlined: {
              right: 18
          }
      },
      MuiFormLabel: {
          root: {
              fontFamily: 'Inter'
          }
      },
      MuiInputLabel: {
          outlined: {
              '&$shrink': {
                  transform: 'translate(16px, -6px) scale(0.75)',
                  background: '#FFFFFF',
                  padding: '0px 12px'
              }
          }
      },
      MuiOutlinedInput: {
          notchedOutline: {
              padding: '0px 16px',
          },
          root: {
              '&$error $notchedOutline': {
                  borderColor: 'rgba(0, 0, 0, 0.23)', // Change the border color for error state
              },
          },
      },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import LanguageSupportController, {
  Props,
  configJSON,
} from "./LanguageSupportController";

export default class LanguageSupport extends LanguageSupportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
            <Spinner showLoader={this.state.showLoader} />
            <Modal
                open={this.props?.isModalOpen ?? false}
                className={this.props.classes.root}
            >
                <Box className={this.props.classes.mainBox}>
                    <Box className={this.props.classes.OptionBox}>
                        <FormControl variant="outlined"
                            className={this.props.classes.InputField}
                            error={this.state.countryError}
                        >
                            <InputLabel htmlFor="countrySelect">{t("languageSelectionModal.selectCountry")}</InputLabel>
                            <Select
                                data-testId="CountrySelect"
                                variant="outlined"
                                className={`${this.props.classes.InputFieldSelect} ${iLanguage == 'ar' && 'RTLFieldset'}`}
                                labelId="countrySelect"
                                label={t("languageSelectionModal.selectCountry")}
                                onChange={this.handleCountryChange}
                                value={this.state.selectedCountry}
                                IconComponent={ExpandMoreIcon}
                                renderValue={(value) => {
                                    const country = this.getSelectedCountry(value as string);
                                    return (
                                        <Box className={this.props.classes.RenderedCountryBox} dir={iDirection}>
                                            <img src={country?.attributes.logo} height={24} width={24} crossOrigin="anonymous" />
                                            <Typography className="CountryName">{iLanguage === 'en' ? country?.attributes?.country_name : country?.attributes?.country_name_arabic}</Typography>
                                        </Box>
                                    );
                                }}
                                MenuProps={selectMenuProps}

                            >
                                {this.state.countriesData.map((country: any) => {
                                    return (<MenuItem value={country.id} key={country.id} className={this.props.classes.MenuItemSelect} dir={iDirection}>
                                        <img src={country.attributes.logo} height={24} width={24} crossOrigin="anonymous" />
                                        <Typography className="DisplayName Country">{iLanguage === 'en' ? country?.attributes?.country_name : country?.attributes?.country_name_arabic}</Typography>
                                    </MenuItem>);
                                })}

                            </Select>
                            {this.state.countryError && <FormHelperText data-testId="languageErrors">{t("languageSelectionModal.countryValidation")}</FormHelperText>}
                        </FormControl>

                        <FormControl variant="outlined"
                            className={this.props.classes.InputField}
                            error={this.state.languageError}
                        >
                            <InputLabel htmlFor="languageSelect">{t("languageSelectionModal.selectLanguage")}</InputLabel>
                            <Select
                                data-testId="LanguageSelect"
                                variant="outlined"
                                className={`${this.props.classes.InputFieldSelect} ${iLanguage == 'ar' && 'RTLFieldset'}`}
                                labelId="languageSelect"
                                label={t("languageSelectionModal.selectLanguage")}
                                onChange={this.handleLanguageChange}
                                value={this.state.selectedLanguage}
                                renderValue={(value) => {
                                    const selectedLanguage = this.getSelectedLanguage(value as string);
                                    return (
                                        <Box className={this.props.classes.SelectedLanguageBox} dir={iDirection}>
                                            <Typography className="LanguageText">{selectedLanguage.name}</Typography>
                                        </Box>
                                    );
                                }}
                                IconComponent={ExpandMoreIcon}
                                MenuProps={selectMenuProps}
                            >
                                {configJSON.languageOptions.map((language: any) => {
                                    return (<MenuItem value={language.identifier} key={language.identifier} className={this.props.classes.MenuItemSelect} dir={iDirection}>
                                        <Typography className="DisplayName">{language.name}</Typography>
                                    </MenuItem>);
                                })}

                            </Select>
                            {this.state.languageError && <FormHelperText>{t("languageSelectionModal.languageValidation")}</FormHelperText>}
                        </FormControl>
                    </Box>
                    <Box className={this.props.classes.ButtonBox}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={this.props.classes.CofirmButton}
                            data-testId="ConfirmBtn"
                            onClick={this.confirmChanges}
                        >
                            <Typography className={this.props.classes.ConfirmText}>
                                {t("common.confirm")}
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            </Modal>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
