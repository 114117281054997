import React from "react";
// Customizable Area Start
import { Grid, Box, Paper, TextField, Typography, Button, withStyles } from '@material-ui/core'
import { imgSignInLeft } from "./assets";
import Spinner from "../../../components/src/Spinner.web";
import LoginController, {
    Props
} from "./LoginController.web";
import LeftImageGrid from "./LeftImageGrid.web";
import { iLanguage, t } from "../../../components/src/i18n";
import RegisterButton from "./RegisterButton.web";
import { loginStyle } from "./Login.web";
// Customizable Area End


export class LoginWithOtp extends LoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { showLoader } = this.state;
        const { classes } = this.props;
        return (
            <Box>
                <Spinner showLoader={showLoader} />
                <Grid container className={classes.MainContainerLI}>

                    <LeftImageGrid imgSrc={imgSignInLeft} />

                    <Grid item xs={12} md={6}>
                        {/* SignIn with otp Form */}
                        <Box className={classes.LoginFormBox}>

                            <Paper elevation={2} className="LINFormPaper">
                                <Box className="LINTitleContainer">
                                    <Typography className={`LogInTitle`}>{t("login.signinTitle")}</Typography>
                                    <Typography className={`LogInSubTitle`}>{t("login.signinSubtitleOtp")}</Typography>
                                </Box>
                                <form onSubmit={this.onSubmitOtpLogin}>
                                    <TextField
                                        data-testId="EmailInput"
                                        placeholder={t("common.emailAddress")!}
                                        label={t("common.emailAddress")}
                                        className="InputField RTLOFF"
                                        variant="outlined"
                                        value={this.state.email}
                                        onChange={this.handleEmailChange}
                                        onBlur={this.handleEmailBlur}
                                        error={this.state.emailError}
                                        helperText={this.state.emailErrorText}InputProps={{
                                            classes:{
                                                input:`${iLanguage == 'ar' && "RTLPlaceholder"}`,
                                                root:`${iLanguage == 'ar' && "RTLFieldSet"}`
                                            }
                                        }}
                                    />
                                    <Grid container className="LogInButtonGrid">
                                        <Grid item xs={12} className="LoginButtonGridItem">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className="LogInButtonOtp"
                                                type="submit"
                                                data-testId="LogInBtn"
                                            >
                                                <Typography className="LogInText">
                                                    {t("common.submit")}
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    
                                    <RegisterButton onRegisterNowClick={this.onRegisterNowClick} />

                                </form>
                            </Paper>

                        </Box>
                    </Grid>
                </Grid>
            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
export default withStyles(loginStyle)(LoginWithOtp);
// Customizable Area End