import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start

// Customizable Area End

export interface Props {
    // Customizable Area Start
    onRegisterNowClick: () => void,
    classes?:{
        ButtomBox:string
    }
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class RegisterButtonController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    // Customizable Area End  

    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials)
        ];

        this.state = {
        };
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

}
