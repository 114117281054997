import React, { Component } from 'react';
import { Box, Typography, withStyles, WithStyles, Modal, FormControl, InputLabel, Select,FormHelperText, Theme, IconButton, Button,MenuItem,TextField } from '@material-ui/core';
import { iDirection, t } from './i18n';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    InputField,
    rtlOffInputClasses
} from "./CommonStyles";
import { deviceBasedDynamicDimension } from './Toolkit';

interface Props extends WithStyles {
    isOpen: boolean;
    canclePlanDetails?:any;
    handleCancelNoplan?:any;
    handleContinueButton?:any;
    countryCurrency?: string;
    cancellationReason?:string;
    handleCancellationReason?:any;
    handleAdditionalRemark?:any;
    additional_remark?:string;
    errorCancellationReason?:string;
}

interface S {

}

interface SS {

}
class WarrantiesPopupModel extends Component<Props, S, SS> {

    constructor(props: Props) {
        super(props);

        this.state = {

        }
    }
    shortWarrantyTitle(input: string) {
        let modifyString = input + " " + "Warranty";
        const words = modifyString.split(" ");
        const acronym = words.map((word) => word[0].toUpperCase()).join("");
        return acronym;
      }
    render() {
        const { classes, isOpen, canclePlanDetails, handleCancelNoplan, handleContinueButton,countryCurrency,handleCancellationReason ,cancellationReason, additional_remark,errorCancellationReason,handleAdditionalRemark} = this.props;
        return (
            <Modal
                open={isOpen}
                className={classes.VOMBoxModal}
                dir={iDirection}
                data-test-id="OTPModal"
            >
                <Box className={classes.view_BackgroundCopy}>
                    <Box className={classes.cancelReasonMain}>
                        <Box style={{ display: "flex" }}>
                            <Box className='WarrantyTypeName' style={{background:canclePlanDetails?.details?.category_bg_color}}>
                                <Typography className={classes.warrantyShortName}>{this.shortWarrantyTitle(canclePlanDetails?.details?.warranty_type)}</Typography>
                            </Box>
                            <Box className={classes.warrantyNameContainer}>
                                <Box className='warrantyPlanName' data-testId="cancleWarrantyName">
                                    {canclePlanDetails?.details?.warranty_name}
                                </Box>
                                <Box className={classes.textlabel_ItemFridgeText}>
                                {t("warrantiesPlans.item")} - {canclePlanDetails?.details?.item_name}
                                </Box>
                            </Box>

                        </Box>
                        <Box style={{ padding: 25 }}>
                            <Box className={classes.warrantyPriceContainer
                            }><div className={classes.CurrencySymbol}>{canclePlanDetails?.currency_symbol}</div><div>{Number(canclePlanDetails?.total_price).toFixed(2)}</div></Box>
                        </Box>
                    </Box>
                        <Box className={classes.cancellationReasonContainer}>
                            <Typography className={classes.cancellationReasonText}>{t("cancelWarrantyplan.pleaseTellReason")}</Typography>
                            <Box>
                            </Box>
                            <Box>
                                <Typography className={classes.textlabel_ReasonForCancellatText}>{t("cancelWarrantyplan.reasonCancelation")}</Typography>
                                <FormControl variant="outlined"
                                    className="InputField"
                                    error={errorCancellationReason !== ""}
                                    fullWidth
                                >
                                    <InputLabel htmlFor="itemSelect">{t("cancelWarrantyplan.reasonCancelation")}</InputLabel>
                                    <Select
                                        data-testId="cancellation_Reason"
                                        variant="outlined"
                                        className="InputFieldSelect"
                                        labelId="itemSelect"
                                        name="cancellationReason"
                                        value={cancellationReason}
                                        onClick={handleCancellationReason}
                                        label="Cancellation"
                                        IconComponent={(props: any) => (
                                            <IconButton
                                                style={{top:10}}
                                                edge="end"
                                                size="small"
                                                aria-label="toggle menu"
                                                aria-haspopup="true"
                                                {...props}
                                            >
                                                <ExpandMoreIcon style={{  width: 35 , height: 35}} />
                                            </IconButton>)}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        <MenuItem value={1}>{"Returned the original product"}</MenuItem>
                                        <MenuItem value={2}>{"Changed my mind"}</MenuItem>
                                        <MenuItem value={3}>{"Bought it by mistake"}</MenuItem>
                                        <MenuItem value={4}>{"Leaving country"}</MenuItem>
                                        <MenuItem value={5}>{"Others, please specify"}</MenuItem>
                                    </Select>
                                    <FormHelperText>{errorCancellationReason}</FormHelperText>
                                </FormControl>
                            </Box>
                            <Box>
                                <Typography className={classes.textlabel_ReasonForCancellatText}>{t("cancelWarrantyplan.additionalRemark")}</Typography>
                                <TextField 
                                 data-testId="additionalRemark"
                                 id="outlined-basic" 
                                 variant="outlined" 
                                 className="InputField"
                                 name="additional_remark"
                                 value={additional_remark}
                                 onChange={handleAdditionalRemark}
                                 placeholder={t("cancelWarrantyplan.additionalRemark") ?? ''}
                                 multiline
                                 minRows={4} // You can adjust the number of visible rows as needed
                                 maxRows={4}
                                 fullWidth
                                />
                            </Box>
                            <Box className={classes.modelBtnContainer}>
                                <Box className='actionBtnCancelModel'>
                                <Button data-testId="canclePlanContinuebtn" className={classes.continueButton} onClick={handleContinueButton}>{t("cancelWarrantyplan.continue")}</Button>
                                <Button data-testId="canclePlanNobtn" className={classes.noCancelButton} onClick={handleCancelNoplan}>{t("cancelWarrantyplan.no")}</Button>
                                </Box>
                            </Box>
                    </Box>
                </Box>
            </Modal>
        );
    }
}

const style = (theme: Theme) => ({
    VOMBoxModal: {
        display: 'flex',
        alignItems: 'center' as const,
        justifyContent: 'center',
        "& .InputField": InputField,
        ...rtlOffInputClasses,
        "& .InputField .MuiInputLabel-outlined.MuiInputLabel-shrink.Mui-focused":{
            background: "#fff",
        },
        "& .InputFieldSelect .MuiOutlinedInput-input": {
            color: '#004d74 !important'
        }
    },
    view_BackgroundCopy:
    {
        width: deviceBasedDynamicDimension(703, true, 1),
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderRadius: deviceBasedDynamicDimension(20, true, 1),
        [theme.breakpoints.down('xs')]: {
            margin: 10,
            width: '92%',
            padding: "40px 12px"
        }
    },
    cancelReasonMain: {
        display: "flex",
        justifyContent: "space-between",
        padding: 24,
        backgroundColor: '#F9F9F9',
        borderTopRightRadius: deviceBasedDynamicDimension(20, true, 1),
        borderTopLeftRadius: deviceBasedDynamicDimension(20, true, 1),
        [theme.breakpoints.down('xs')]:{
            padding: '0px 16px'
        },
        '& .WarrantyTypeName': {
            borderRadius: deviceBasedDynamicDimension(20, true, 1),
            width: 100,
            height: 100,
            backgroundColor: 'rgba(0, 77, 116, 1)',
            [theme.breakpoints.down('xs')]:{
                width: 60,
                height: 60,
            },
            [theme.breakpoints.down('xs')]:{
                width: 60,
                height: 60,
                marginTop: '10%'
            }
        },
        '& .warrantyPlanName': {
            fontWeight: 'bold' as const,
            color: 'rgba(0, 132, 67, 1)',
            textAlign: 'left' as const,
            fontFamily: 'Manrope',
            fontSize: deviceBasedDynamicDimension(20, true, 1),
        }
    },
    warrantyNameContainer: {
        padding: '16px',
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '16px'
    },
    textlabel_ItemFridgeText:
    {
        backgroundColor: 'transparent',
        fontStyle: 'normal',
        fontWeight: 'bold' as const,
        color: 'rgba(105, 116, 121, 1)',
        textAlign: 'left' as const,
        fontFamily: 'Manrope',
        fontSize: deviceBasedDynamicDimension(18, true, 1),
        [theme.breakpoints.down('xs')]:{
            fontSize: 15
        }
    },
    textlabel_ReasonForCancellatText:
    {
        color: 'rgba(5, 23, 32, 1)',
        fontFamily: 'Inter',
        fontSize: deviceBasedDynamicDimension(18, true, 1),
        [theme.breakpoints.down('xs')]:{
            fontSize: 14
        }
    },
    warrantyShortName: {
        fontStyle: 'normal',
        fontWeight: 'bold' as const,
        color: 'rgba(255, 255, 255, 1)',
        textAlign: 'center' as const,
        fontFamily: 'Manrope',
        fontSize: deviceBasedDynamicDimension(36, true, 1),
        padding: 25,
        [theme.breakpoints.down('xs')]:{
            fontSize: 24,
             padding : 12
        }
    },
    warrantyPriceContainer:
    { 
        display: "flex",
        backgroundColor: 'transparent',
        fontStyle: 'normal',
        fontWeight: 'bold' as const,
        color: 'rgba(0, 132, 67, 1)',
        textAlign: 'right' as const,
        fontFamily: 'Inter',
        fontSize: deviceBasedDynamicDimension(30, true, 1),
        [theme.breakpoints.down('xs')]:{
            fontSize: 22
        }
    },
    cancellationReasonContainer:{
        display: 'flex', 
        flexDirection: "column" as const, 
        gap: 10, 
        padding : 32,
        [theme.breakpoints.down('md')]:{
            gap: 5,
            padding: 24
        },
        [theme.breakpoints.down('xs')]:{
            gap: 2,
            padding: 10
        },
        [theme.breakpoints.only('md')]: {
            padding: '0px 24px'
          }
    },
    cancellationReasonText: {
        fontWeight: 'bold' as const,
        color: 'rgba(5, 23, 32, 1)',
        textAlign: 'left' as const,
        fontFamily: 'Manrope',
        fontSize: deviceBasedDynamicDimension(20, true, 1),
        [theme.breakpoints.down('xs')]:{
            fontSize: 16
        }
    },
    modelBtnContainer:{
        display: 'flex',
        justifyContent: 'center',
        padding: 16,
        '& .actionBtnCancelModel': {
            display: 'flex',
            flexDirection: "column" as const,
            gap: 20,
            [theme.breakpoints.only('md')]: {
                flexDirection: "row" as const,
            }
        }
    },
    noCancelButton: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        border: "1px solid #004D74",
        width:  deviceBasedDynamicDimension(358, true, 1),
        height: 70,
        borderRadius: deviceBasedDynamicDimension(40, true, 1),
        justifyContent: 'center',
        fontWeight: 'bold' as const,
        textAlign: 'center' as const,
        fontFamily: 'Manrope',
        fontSize: deviceBasedDynamicDimension(20, true, 1),
        textTransform: "capitalize" as const,
        [theme.breakpoints.down('xs')]:{
            width: 200,
            height: 40
          },
          [theme.breakpoints.only('md')]: {
            width: 200,
            height: 45
        }
      },
      continueButton: {
        backgroundColor: "#004D74",
        border: "1px solid #004D74",
        width: deviceBasedDynamicDimension(358, true, 1),
        height: 70,
        borderRadius: deviceBasedDynamicDimension(40, true, 1),
        justifyContent: 'center',
        fontWeight: 'bold' as const,
        textAlign: 'center' as const,
        fontFamily: 'Manrope',
        fontSize: deviceBasedDynamicDimension(20, true, 1),
        textTransform: "capitalize" as const,
        color: "#fff",
        '&:hover':{
          backgroundColor: '#004D74',
      },
      [theme.breakpoints.down('xs')]:{
        width: 200,
        height: 40
      },
      [theme.breakpoints.only('md')]: {
        width: 200,
        height: 45
      }
      },
    CurrencySymbol:{
        margin:"0px 4px"
    }

});
export default withStyles(style)(WarrantiesPopupModel);