import React from "react";
// Customizable Area Start
import { withStyles, Theme, Grid, Box, Typography } from "@material-ui/core";
import RefundPolicyController, { Props } from "./RefundPolicyController.web";
import { t } from "../../../components/src/i18n";
import { flexCenter } from "../../../components/src/CommonStyles";
import Spinner from "../../../components/src/Spinner.web";
import SearchEngineOptimisationseo from "../../SearchEngineOptimisationseo/src/SearchEngineOptimisationseo";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export class RefundPolicy extends RefundPolicyController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { refundPolicyData, isLoading } = this.state;
    const metaDetail = [{
      name: "description",
      content: refundPolicyData.attributes.description
    },
    {
      name: "keywords",
      content: t("seo.keywords")
    }
  ]
    return (
      <>
        <SearchEngineOptimisationseo
            title={t("refundPolicy.title")!}
            metaTags={metaDetail}
            navigation={this.props.navigation} id={this.props.id} />
        <Spinner showLoader={isLoading} />
        <Grid container className={classes.Container}>
          <Grid xs={12} item className={`${classes.flexCenter} ${classes.titleGrid}`}>
            <Typography component={'h1'} className={classes.policyTitleText} data-test-id="title">{t("refundPolicy.title")}</Typography>
          </Grid>
          <Grid item xs={12} md={2} />
          <Grid item xs={12} md={8}>
            <Box className={classes.policyBox}>
              <Typography
                className="policyDescription"
                dangerouslySetInnerHTML={{
                  __html: `${refundPolicyData.attributes.description || ''}`
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={2} />
        </Grid>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const refundPolicyStyle = (theme: Theme) => ({
  Container: {
    minHeight: "60vh",
    margin: "32px auto"
  },
  policyBox: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      maxWidth: "100%",
      padding: 24
    },
    [theme.breakpoints.down("xs")]: {
      padding: 8
    },
    "& .policyDescription": {
      color: "rgba(5, 23, 32, 1)",
      fontFamily: "'Manrope', sans-serif",
      fontStyle: "normal",
      fontSize: 20,
      lineHeight: 1.5,
      letterSpacing: 1,
      marginTop: 8,
      opacity: 1,
      wordBreak: 'break-word' as const,
      hyphens: 'auto' as const,
      [theme.breakpoints.down("md")]: {
        fontSize: 18
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 16
      }
    }
  },
  flexCenter: flexCenter,
  titleGrid: {
    margin: "32px auto"
  },
  policyTitleText: {
    color: "rgba(0, 77, 116, 1)",
    fontFamily: "'Manrope', sans-serif",
    fontStyle: "normal",
    fontWeight: "bold" as const,
    fontSize: 48,
    lineHeight: 1,
    letterSpacing: 1.5,
    textDecoration: 'underline' as const,
    textDecorationThickness: 2,
    textUnderlineOffset: '4px',
    [theme.breakpoints.down('md')]: {
      fontSize: 40,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 28,
    }
  }
});
export default withStyles(refundPolicyStyle)(RefundPolicy);
// Customizable Area End
