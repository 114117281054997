import React from "react";
// Customizable Area Start
import { Grid, Hidden, Box, Paper, TextField, Typography, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Avatar, Button, InputAdornment, IconButton, FormHelperText,Theme, withStyles } from '@material-ui/core'
import SignUpController, {
    Props
} from "./SignUpController.web";
import { imgSignUpLeft, passwordValidCheck } from "./assets";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import OtpVerificationModalWeb from "../../../components/src/OtpVerificationModal.web";
import Spinner from "../../../components/src/Spinner.web";
import WarrantyCheckBox from "../../../components/src/WarrantyCheckBox.web";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { t, iDirection, iLanguage } from '../../../components/src/i18n';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { handleKeyPressNumber, passwordLowerCaseReg, passwordOneNumbereReg, passwordUpperCaseReg } from "../../../components/src/Toolkit";
import { InputField, rtlOffInputClasses } from "../../../components/src/CommonStyles";
import TermsAndConditionsModalWeb from "./TermsAndConditionsModal.web";
import SearchEngineOptimisationseo from "../../SearchEngineOptimisationseo/src/SearchEngineOptimisationseo.web";
// Customizable Area End

// Customizable Area Start
export const PasswordEndVisibilityToggle = (props: { toggleVisibility: () => void, visibility: boolean }) => {
    return (<InputAdornment position="end">
        <IconButton
            onClick={props.toggleVisibility}
            data-testId="passwordToggleBtn"
        >
            {props.visibility ? (
                <VisibilityOutlinedIcon htmlColor="#004D74" />
            ) : (
                <VisibilityOffOutlinedIcon htmlColor="#004D74" />
            )}
        </IconButton>
    </InputAdornment>);
}

export const PasswordChecks = (props: { condition: boolean, message: string, mainClass:string }) => {
    return (
        <Box className={props.mainClass}>
            {props.condition ? <Avatar src={passwordValidCheck} /> : <HighlightOffIcon htmlColor="red" />}
            <Typography className={`PasswordMessage ${!props.condition && 'InvalidPassword'}`}>{props.message}</Typography>
        </Box>
    );
}
export const signupStyles = (theme: Theme) => ({
    MainContainerSU:{
        backgroundColor: '#FAFAFA'
    },
    LeftImageGrid:{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        "& .LeftImage" : {
            height: '60%',
            width: '80%',
            objectFit: 'contain',
            marginBottom: '48px'
        }
    },
    SignupFormBox:{
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '24px',
        [theme.breakpoints.down('md')]:{
            justifyContent: 'center',
        },
        "& .SUPFormPaper": {
            width: '100%',
            maxWidth: '598px',
            padding: '48px 64px',
            borderRadius: '20px',
            boxShadow: '0px 1px 10px #EBEBEB',
            marginTop: '48px',
            [theme.breakpoints.down('md')]:{
                padding: '24px'
            },
            "& .SUPTitleContainer":{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
            },
            "& .SignUpTitle": {
                fontFamily: 'Manrope, sans-serif',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: 26,
                lineHeight: 1.5,
                color: '#515151',
            },
            "& .SignUpSubTitle": {
                fontFamily: 'Manrope, sans-serif',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: 20,
                lineHeight: 1.3,
                color: '#01BEFE',
                mixBlendMode: 'normal',
                opacity: 0.5,
            },
            "& .InputField": InputField,
            "& .InputFieldSelect": {
                width: '100%',
                borderRadius: '12px',
                "& .MuiSelect-icon":{
                    height: '36px',
                    width: '36px',
                    color: '#004D74',
                    margin: '0px 12px',
                    top: 'auto',
                },
                "& .MuiOutlinedInput-input": {
                    paddingLeft: '14px'
                }
            },
            "& .InputCheckbox":{
                "&.MuiFormControlLabel-root": {
                    marginLeft: '-5px',
                    marginRight: '4px'
                },
                "& .MuiFormControlLabel-label": {
                    fontFamily: 'Inter',
                    fontSize: '18px',
                    lineHeight: 1.5,
                    color: '#8991A4'
                }
            },
            "& .InputCheckboxInside":{
                "& .MuiSvgIcon-root": {
                    color:' #004D74'
                }
            },
            "& .SignupButtonBox": {
                marginTop: '32px',
                display: 'flex',
                justifyContent: 'center',
                width: '100%'
            },
            "& .SignupButton":{
                backgroundColor: '#004D74',
                color: "#FFF",
                height: '64px',
                width: '360px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50px',
                "& ::hover": {
                    backgroundColor: '#004D74'
                }
            },
            "& .SignUpText": {
                fontFamily: 'Manrope',
                fontStyle: 'normal',
                fontWeight: 600,
                fontSize: '20px',
                lineHeight: 1.2,
                textAlign: 'center',
                color: '#FFFFFF',
                textTransform: 'initial',
            },
            "& .SignInBox":{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '32px',
                flexWrap: 'wrap',
                [theme.breakpoints.down('xs')]: {
                    flexDirection: 'column'
                },
                "& .AlreadyAccountText": {
                    fontFamily: 'Poppins,sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: '20px',
                    lineHeight: 1.2,
                    textAlign: 'center',
                    color: '#000000',
                },
                "& .SignInButton": {
                    marginLeft: '8px',
                    "& .SignInText": {
                        fontFamily: 'Poppins,sans-serif',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        fontSize: 20,
                        lineHeight: 1.2,
                        textTransform: 'initial',
                        color: theme.palette.primary.main
                    }
                }
            },
            ...rtlOffInputClasses,
            "& .DrowpDownIcon": {
                marginRight: '26px',
                height: '20px',
                width: '20px',
                objectFit: 'contain',
            },
            "& .RenderedSelectItemBox":{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                "& img":{
                    width: 36,
                    objectFit: 'contain'
                },
                "& .CountryName": {
                    fontFamily: 'Inter, sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    fontSize: 18,
                    lineHeight: 1.3,
                    letterSpacing: 0.1875,
                    color: '#051720',
                    margin: '0px 8px',
                }
            }
        }
    },
    TermBox:{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginTop: '8px',
        flexWrap:'wrap' as const,
        "& .TermAndConditionBtn": {
            padding: '2px 0px',
            "& .TermText": {
                fontFamily: 'Inter',
                fontSize: '18px',
                lineHeight: 1.5,
                color: '#004D74',
                textDecoration: 'underline',
                textTransform: 'none',
                fontWeight: 600,
                "&.arabic":{
                    margin:'0px 12px'
                }
            }
        }
        
    },
    MenuItemSelect:{
        "& img":{
            width: 36,
            objectFit: 'contain',
        },
        "& .CountryNameInSelect": {
            fontFamily: 'Inter, sans-serif',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 18,
            lineHeight: 1.3,
            letterSpacing: '0.1875px',
            color: '#051720',
            margin: '4px 8px',
        }
    },
    PasswordChecksBox:{
        display: 'flex',
        alignItems: 'center',
        margin:4,
        "& .PasswordMessage": {
            fontFamily: 'Manrope, sans-serif',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 14,
            lineHeight: 1.2,
            color: '#008443',
            marginLeft: 12,
            "&.InvalidPassword":{
                color:theme.palette.error.main
            },
        },
        "& .MuiAvatar-circular":{
            height: 24,
            width: 24,
        }
    },
    PasswordChecksRow:{
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'flex-start',
    }
})
// Customizable Area End

export class SignUp extends SignUpController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { showLoader, passwordVisible, password, passwordError } = this.state;
        const { id, navigation, classes } = this.props;
        const passwordUCTest: boolean = passwordUpperCaseReg.test(password);
        const passwordLCTest: boolean = passwordLowerCaseReg.test(password);
        const passwordNumberTest: boolean = passwordOneNumbereReg.test(password);
        
        return (
            <Box>
                <SearchEngineOptimisationseo title={t("common.signUp")!} navigation={this.props.navigation} id={this.props.id} />
                <Spinner showLoader={showLoader} />
                <Grid container className={classes.MainContainerSU}>

                    <Hidden smDown>
                        <Grid item md={6} className={classes.LeftImageGrid} >
                            <img src={imgSignUpLeft} className="LeftImage" />
                        </Grid>
                    </Hidden>

                    <Grid item xs={12} md={6}>
                        {/* Signup Form */}
                        <Box className={classes.SignupFormBox}>

                            <Paper elevation={2} className="SUPFormPaper">
                                <Box className="SUPTitleContainer">
                                    <Typography className={`SignUpTitle`}>{t('signUp.title')}</Typography>
                                    <Typography className={`SignUpSubTitle`}>{t('signUp.subTitle')}</Typography>
                                </Box>
                                <form onSubmit={this.onSubmit} data-testId="SignupForm">
                                    <TextField
                                        data-testId="NameInput"
                                        placeholder={t('common.fullName')!}
                                        label={t('common.fullName')}
                                        className="InputField"
                                        type="text"
                                        variant="outlined"
                                        lang={iLanguage}
                                        value={this.state.name}
                                        onChange={this.handleNameChange}
                                        onBlur={this.handleNameBlur}
                                        error={this.state.nameError}
                                        helperText={this.state.nameErrorText}
                                    />
                                    <TextField
                                        data-testId="EmailInput"
                                        placeholder={t('common.emailAddress')!}
                                        label={t('common.emailAddress')}
                                        className="InputField RTLOFF"
                                        type="email"
                                        variant="outlined"
                                        onChange={this.handleEmailChange}
                                        onBlur={this.handleEmailBlur}
                                        error={this.state.emailerror}
                                        helperText={this.state.emailerrorText}
                                        InputProps={{
                                            classes:{
                                                input:`${iLanguage == 'ar' && "RTLPlaceholder "}`,
                                                root:`${iLanguage == 'ar' && "RTLFieldSet"}`
                                            }
                                        }}
                                    />

                                    <FormControl variant="outlined"
                                        className="InputField"
                                        error={this.state.selectedCountryError}

                                    >
                                        <InputLabel htmlFor="countrySelect">{t('common.country')}</InputLabel>
                                        <Select
                                            data-testId="CountrySelect"
                                            variant="outlined"
                                            className="InputFieldSelect"
                                            labelId="countrySelect"
                                            label={t('common.country')}
                                            value={this.state.selectedCountry}
                                            renderValue={(value) => {
                                                const country = this.state.countriesData.find((country: any) => country.id == value);
                                                return (
                                                    <Box className="RenderedSelectItemBox" dir={iDirection}>
                                                        <img src={country.attributes.logo} height={24} width={36} crossOrigin="anonymous" alt={country.attributes.country_name}/>
                                                        <Typography className="CountryName">{country.attributes.country_name}</Typography>
                                                    </Box>
                                                );
                                            }}
                                            onChange={this.handleCountryChange}
                                            IconComponent={ExpandMoreIcon}
                                            MenuProps={{
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left"
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left"
                                                },
                                                getContentAnchorEl: null
                                            }}

                                        >
                                            {this.state.countriesData.map((country: any) => {
                                                return (<MenuItem value={country.id} key={country.id} className={classes.MenuItemSelect} dir={iDirection}>
                                                    <img src={country.attributes.logo} height={24} width={36} crossOrigin="anonymous" alt={country.attributes.country_name}/>
                                                    <Typography className="CountryNameInSelect">{country.attributes.country_name}</Typography>
                                                </MenuItem>);
                                            })}

                                        </Select>
                                        <FormHelperText>{this.state.selectedCountryErrorText}</FormHelperText>
                                    </FormControl>

                                    <TextField
                                        data-testId="PhoneNumberInput"
                                        placeholder={t('common.phoneNumber')!}
                                        label={t('common.phoneNumber')}
                                        className="InputField RTLOFF"
                                        type="number"
                                        variant="outlined"
                                        value={this.state.phoneNumber}
                                        InputProps={{
                                            startAdornment: `${this.state.phoneNumberPrefix}-`,
                                            classes:{
                                                input:`${iLanguage == 'ar' && "RTLPlaceholder RTLInput"}`,
                                                root:`${iLanguage == 'ar' && "RTLFieldSet"}`
                                            }
                                        }}
                                        onChange={this.handlePhoneNumberChange}
                                        onKeyPress={handleKeyPressNumber}
                                        error={this.state.phoneNumberError}
                                        helperText={this.state.phoneNumberErrorText}
                                    />
                                    <TextField
                                        data-testId="PasswordInput"
                                        placeholder={t("common.password")!}
                                        label={t("common.password")}
                                        className="InputField"
                                        type={passwordVisible ? "text" : "password"}
                                        variant="outlined"
                                        value={password}
                                        onChange={this.handlePasswordChange}
                                        error={passwordError}
                                        helperText={this.state.passwordErrorText}
                                        InputProps={{
                                            endAdornment: (<PasswordEndVisibilityToggle toggleVisibility={this.togglePasswordVisibility} visibility={this.state.passwordVisible} />),
                                        }}
                                    />
                                    {password.length > 0 && (
                                        <Grid container>
                                            <Grid xs={12} sm={6} item className={classes.PasswordChecksRow}>
                                                <PasswordChecks condition={password.length >= 8} message={t("signUp.passworMin8Validation")} mainClass={classes.PasswordChecksBox}/>
                                                <PasswordChecks condition={passwordNumberTest} message={t("signUp.passwordOneNumberValidation")} mainClass={classes.PasswordChecksBox}/>
                                            </Grid>
                                            <Grid item xs={12} sm={6} className={classes.PasswordChecksRow}>
                                                <PasswordChecks condition={passwordUCTest} message={t("signUp.passwordValidationUppercase")} mainClass={classes.PasswordChecksBox}/>
                                                <PasswordChecks condition={passwordLCTest} message={t("signUp.passwordValidationLowercase")} mainClass={classes.PasswordChecksBox}/>
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Box className={classes.TermBox}>
                                        <FormControlLabel
                                            control={<WarrantyCheckBox
                                                checked={this.state.termsCheck}
                                                onChange={this.handleTermsCheckbox}
                                                error={this.state.termsCheckError}
                                            />}
                                            label={t("signUp.iAgreeTo")}
                                            className="InputCheckbox"
                                            data-testId="TermCheckbox"
                                        />
                                        <Button
                                            variant="text"
                                            className="TermAndConditionBtn"
                                            onClick={this.onTermsClick}
                                        >
                                            <Typography className={`TermText ${iLanguage === "ar" && 'arabic'}`}>
                                                {t("common.termsNCondition")}
                                            </Typography>
                                        </Button>
                                    </Box>


                                    <Box className="SignupButtonBox">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className="SignupButton"
                                            type="submit"
                                            data-testId="SignupBtn"
                                        >
                                            <Typography className="SignUpText">
                                                {t("common.signUp")}
                                            </Typography>
                                        </Button>
                                    </Box>
                                    <Box className="SignInBox">
                                        <Typography className="AlreadyAccountText">{t("signUp.alreadyHaveAccount")}</Typography>
                                        <Button
                                            variant="text"
                                            className="SignInButton"
                                            onClick={this.onSignInClick}
                                        >
                                            <Typography className="SignInText">
                                                {t("common.signIn")}
                                            </Typography>
                                        </Button>
                                    </Box>

                                </form>
                            </Paper>
                        </Box>
                    </Grid>
                </Grid>
                <OtpVerificationModalWeb 
                    isOpen={this.state.openOtpModal} 
                    hasError={this.state.otpHasError} 
                    errorText={this.state.otpErrorText} 
                    setError={this.setOtpError} 
                    defaultMinutes={2} 
                    defaultSeconds={0} 
                    otpResendCount={this.state.otpResendCount}
                    resendOtp={this.resendOtp} 
                    verifyOtp={this.verifyOtp}        
                />
                <TermsAndConditionsModalWeb handleClose={this.handleTermAndConditionModelOpen} isModalOpen={this.state.isTermAndConditionModelOpen} id={id} navigation={navigation}/>
            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
export default withStyles(signupStyles)(SignUp);
// Customizable Area End