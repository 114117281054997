import { IBlock } from "../../framework/src/IBlock";
import { Message } from "../../framework/src/Message";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, removeStorageData } from "../../framework/src/Utilities";
import { WithStyles} from '@material-ui/core'
import { iLanguage } from "./i18n";
import { callApi, getNavigationMessage } from "./Toolkit";
export const configJSON = require("./config");

// Customizable Area End

export interface Props extends WithStyles{
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    drawerStatus: boolean;
    cartItemCount: number;
    isLanguageModalOpen: boolean;
    selectedCountry: any;
    selectedLanguage: {
        name: string,
        identifier: string,
        direction: string,
    };
    customerLogin:boolean;
    categoryArr: Array<{
        name: string,
        path: string,
        backgroundColor: string,
        logo: string,
        color: string,
      }>;
    categoryTabHover: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class HeaderController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    cartCallId: string = "";
    // Customizable Area End    

    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.PostEntityMessage),
            getName(MessageEnum.PostDetailDataMessage)
        ];

        this.state = {
            drawerStatus: false,
            cartItemCount: 0,
            isLanguageModalOpen: false,
            selectedCountry: {
                id: "",
                attributes: {
                  tax_details: {
                    type:configJSON.TRANSACTION_FEES,
                    value:0
                  }
                }
              },
            selectedLanguage: {
                name: 'English',
                identifier: 'en',
                direction: 'ltr'
            },
            customerLogin:false,
            categoryArr:[],
            categoryTabHover:''
        };

        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        // Customizable Area Start
        const selectedLanguage = await getStorageData('selectedLanguageDetail', true);
        const selectedCountry = await getStorageData('selectedCountry', true);
        const category = await getStorageData('category', true);
        this.setState({categoryArr: category})
        const token = await getStorageData('token',false);
        if(token){
            this.getCartData()
            this.setState({ customerLogin: true  })
        }
        else {
            const cartList = await getStorageData('storedCart', true);
            this.setState({ cartItemCount: cartList && cartList?.length ? cartList?.length: 0 })
        }
        if (!selectedLanguage || !selectedCountry) {
            this.setState({
                isLanguageModalOpen: true
            })
        } else {
            this.setState({
                selectedLanguage,
                selectedCountry
            })
        }
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if(message.id === "PostEntityMessage")
        {
            if(message.properties.PostDetailDataMessage === "UPDATED_LOCAL_CART") {
                const cartList = await getStorageData('storedCart', true);
                this.setState({ cartItemCount: cartList && cartList?.length ? cartList?.length: 0 })
            } else if(message.properties.PostDetailDataMessage === "UPDATED_API_CART") {
                this.getCartData()
            }
        }
          let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
      
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
      
          if (responseJson != null && !responseJson.errors) {
            if (apiRequestCallId === this.cartCallId) {
              const cartList = responseJson?.data?.attributes?.order_items || []
              this.setState({
                cartItemCount: cartList.length || 0,
              });
            }
        }
        else {
            this.parseError(responseJson)
        }
        // Customizable Area End
    }

    // Customizable Area Start

    parseError = async (responseJson : any) => {
        if (Object.keys(responseJson.errors[0])[0] === "token") {
            await removeStorageData("token");
            await removeStorageData("user");
            this.send(getNavigationMessage("Login", this.props));
          }
    }

    getCartData = async () => {
        const token = await getStorageData("token", false);
        this.cartCallId = callApi(
          {
            contentType: configJSON.apiContentType,
            method: configJSON.GET,
            endPoint: configJSON.orderDetailsEndPoint + `?language_code=${iLanguage}&country_id=${this.state.selectedCountry.id}`,
            headers: {
              token: token,
            },
          },
          runEngine
        );
      };

    handleDrawerOpen = () => {
        this.setState({ drawerStatus: true })

    };
    handleDrawerClose = () => {
        this.setState({ drawerStatus: false })
    };
    onCartClick = () => {
        // todo
    }
    openLanguageSelectionModal = () => {
        this.setState({ isLanguageModalOpen: true })
    }
    handleUser = () => {
        (async () => {
            const token = await getStorageData('token');
            if (token) {
                this.props.navigation.navigate('Home')
            } else {
                this.props.navigation.navigate('Login')
            }
        })();
    }
    goToUserProfile = () => {
        this.props.navigation.navigate('UserProfile')
    }
    handleUserlogOut = async() => {
        await removeStorageData('token');
        this.props.navigation.navigate('Login')
    }
    navigateToHowItWorks = () => {
        this.props.navigation.navigate('HowItWorks')
    }
    // Customizable Area End
}
