// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import MixpanelIntegration from "../../blocks/MixpanelIntegration/src/MixpanelIntegration";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import AutomaticCheckoutCalculation2 from "../../blocks/AutomaticCheckoutCalculation2/src/AutomaticCheckoutCalculation2";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrdermanagementView from "../../blocks/ordermanagement/src/OrdermanagementView.web";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import OrderDetailsView from "../..//blocks/ordermanagement/src/OrderDetailsView.web"
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import PricingEngine2 from "../../blocks/PricingEngine2/src/PricingEngine2";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Testimonials from "../../blocks/Testimonials/src/Testimonials";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Customform from "../../blocks/customform/src/Customform";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import DeepLinking from "../../blocks/DeepLinking/src/DeepLinking";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import {TermsAndConditionsPage} from "../../blocks/TermsAndConditions3/src/TermsAndConditions3.web";
import CertificationTracking from "../../blocks/CertificationTracking/src/CertificationTracking";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import CfWhatsappIntegration71 from "../../blocks/CfWhatsappIntegration71/src/CfWhatsappIntegration71";
import LanguageSupport from "../../blocks/LanguageSupport/src/LanguageSupport";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Location from "../../blocks/location/src/Location";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Surveys from "../../blocks/Surveys/src/Surveys";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import SearchEngineOptimisationseo from "../../blocks/SearchEngineOptimisationseo/src/SearchEngineOptimisationseo";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import CfCheckoutIntegration from "../../blocks/CfCheckoutIntegration/src/CfCheckoutIntegration";
import Login from "../../blocks/mobile-account-login/src/Login.web";
import LoginWithOtp from "../../blocks/mobile-account-login/src/LoginWithOtp.web";
import VerifyLoginOtp from "../../blocks/mobile-account-login/src/VerifyLoginOtp.web";
import SignUp from "../../blocks/mobile-account-registration/src/SignUp.web";
import {default as ForgotPasswordWeb} from "../../blocks/forgot-password/src/ForgotPassword.web"; 
import ResetPassword from "../../blocks/forgot-password/src/ResetPassword.web"
import AboutUs from "../../blocks/landingpage/src/AboutUs.web";
import RefundPolicy from "../../blocks/landingpage/src/RefundPolicy.web";
import HowItWorks from "../../blocks/TermsAndConditions3/src/HowItWorks.web";
import WarrantyPlan from "../../blocks/landingpage/src/WarrantyPlan.web";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem.web";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders.web";
import UserProfile from "../../blocks/user-profile-basic/src/UserProfile.web"
import { MuiThemeProvider, StylesProvider, jssPreset, createTheme } from "@material-ui/core";
import { create } from 'jss';
import rtl from 'jss-rtl';
import '../../components/src/i18n';
import {iDirection} from '../../components/src/i18n';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const routeMap = {
MixpanelIntegration:{
 component:MixpanelIntegration,
path:"/MixpanelIntegration"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
AutomaticCheckoutCalculation2:{
 component:AutomaticCheckoutCalculation2,
path:"/AutomaticCheckoutCalculation2"},
Ordermanagement:{
 component:OrdermanagementView,
path:"/Ordermanagement"},
OrderDetails:{
 component:OrderDetailsView,
path:"/OrderDetails"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
PricingEngine2:{
 component:PricingEngine2,
path:"/PricingEngine2"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
Testimonials:{
 component:Testimonials,
path:"/Testimonials"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Customform:{
 component:Customform,
path:"/Customform"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
DeepLinking:{
 component:DeepLinking,
path:"/DeepLinking"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
TermsAndConditions:{
 component:TermsAndConditionsPage,
path:"/TermsAndConditions"},
CertificationTracking:{
 component:CertificationTracking,
path:"/CertificationTracking"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
CfWhatsappIntegration71:{
 component:CfWhatsappIntegration71,
path:"/CfWhatsappIntegration71"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Location:{
 component:Location,
path:"/Location"},
AdminConsole:{
 component:AdminConsole,
path:"/AdminConsole"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Surveys:{
 component:Surveys,
path:"/Surveys"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
SearchEngineOptimisationseo:{
 component:SearchEngineOptimisationseo,
path:"/SearchEngineOptimisationseo"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
CfCheckoutIntegration:{
 component:CfCheckoutIntegration,
path:"/CfCheckoutIntegration"},

  Home: {
component:LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb", 
    modal: true
  },
  Login:{
    path:'/login',
    component:Login,
  },
  LoginWithOtp:{
    path:'/LoginWithOtp',
    component:LoginWithOtp,
  },
  VerifyLoginOtp:{
    path:'/VerifyLoginOtp',
    component:VerifyLoginOtp,
  },
  SignUp:{
    path:'/SignUp',
    component:SignUp,
  },
  ForgotPasswordWeb:{
    path:'/ForgotPasswordWeb',
    component:ForgotPasswordWeb,
  },
  ResetPassword:{
    path:'/ResetPassword',
    component:ResetPassword
  },
  AboutUs:{
    path:'/AboutUs',
    component:AboutUs
  },
  HowItWorks:{
    path:'/HowItWorks',
    component:HowItWorks
  },
  Category:{
    path:"/Category/:navigationBarTitleText",
    component:WarrantyPlan
  },
  AddShoppingCartOrderItem:{
    path:"/AddShoppingCartOrderItem",
    component:AddShoppingCartOrderItem
  },
  ShoppingCartOrders:{
    path:"/ShoppingCartOrders",
    component:ShoppingCartOrders
  },
  UserProfile:{
    path:"/UserProfile",
    component:UserProfile
  },
  OrderConfirmation: {
    path:'/orderconfirmation',
    component: OrdermanagementView
  },
  OrderStatus: {
    path: '/paymentfailed',
    component: OrdermanagementView
  },
  RefundPolicy: {
    path:'/RefundPolicy',
    component: RefundPolicy
  },
  RenewOrderStatusConfirm:{
    path:"/renewpaymentConfirm",
    component:UserProfile
  },
  RenewOrderStatus:{
    path:"/renewpaymentfailed",
    component:UserProfile
  },

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const RTL = (props) => {
  return (
    <StylesProvider jss={jss}>
      {props.children}
    </StylesProvider>
  );
}
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  // Configure JSS
 
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    const defaultTheme = createTheme({
      direction: iDirection,
      palette: {
        type: 'light',
        primary: { main: '#004d74', light: '#004d74' },
        secondary: { main: '#697479' },
        error: { main: '#ff0000' },
        text: {
          primary: '#004d74',
        }
      },
      overrides: {
        MuiInputBase: {
          input: {
            '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
              WebkitAppearance: 'none',
              margin: 0,
            },
            '&[type="number"]': {
              MozAppearance: 'textfield',
            },
          },
        },
        MuiPopover: {
          paper :{
            border: '2px solid rgba(0, 0, 0, 0.23)',
            marginTop: 8,
            borderRadius: 12,
            boxShadow: 'none'
          }
        }
      },
    });
    return (
      <RTL>
      <div dir={iDirection} style={{overflow: "auto", maxWidth:1920, margin:'auto'}}>
        <MuiThemeProvider theme={defaultTheme}>
          <View >
            {WebRoutesGenerator({ routeMap })}
          </View>
          <ToastContainer 
            position={`top-${iDirection === 'rtl' ? 'right' : 'left'}`}
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            rtl={iDirection === 'rtl'}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </MuiThemeProvider>
      </div>
      </RTL>
    );
  }
}

export default App;
