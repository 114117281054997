import React from "react";
// Customizable Area Start
import { withStyles, Box, Paper, Grid, TextField, Theme , Table, TableHead, TableRow, TableCell, TableBody, Typography, Button, TableContainer, Tooltip, FormControl, Select,FormHelperText, InputLabel,MenuItem } from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import UserProfileController, {
  PersonalInformation,
  UserAddressInformation,
  OrderHistoryItem
} from "./UserProfileController.web";
import { comboPasswordValidations, deviceBasedDynamicDimension, handleKeyPressNumber } from "../../../components/src/Toolkit";
import ProfileLeftMenu from "../../../components/src/Profile/ProfileLeftMenu.web";
import { editIcon, imgLogo , filesDownload} from "./assets";
import InfoWrapper from "../../../components/src/Profile/InfoWrapper.web";
import InfoBox from "../../../components/src/Profile/InfoBox.web";
import { iLanguage, t } from "../../../components/src/i18n";
import {
  InputField,
  rtlOffInputClasses,
  warrantyPagination
} from "../../../components/src/CommonStyles";
import Spinner from "../../../components/src/Spinner.web";
import OtpVerificationModalWeb from "../../../components/src/OtpVerificationModal.web";
import { PasswordErrorChecks, PasswordVisibility } from "../../forgot-password/src/Reusables.web";
import MessageModalWeb from "../../../components/src/Common/MessageModal.web";
import WarrantiesPopupModelWeb from "../../../components/src/WarrantiesPopupModel.web";
import CancelReconfirmationWeb from "../../../components/src/CancelReconfirmation.web";
import OrderConfirmationWeb from "../../../components/src/OrderConfirmation.web";
import RenewableWarrantyPlanWeb from "../../../components/src/RenewableWarrantyPlan.web";
import AdvancedSearch from "../../advancedsearch/src/AdvancedSearch.web";
import SearchEngineOptimisationseo from "../../SearchEngineOptimisationseo/src/SearchEngineOptimisationseo.web";
// Customizable Area End

// Customizable Area Start
 
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#fff",
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[2],
    fontSize: 11,
  },
  arrow: {
    color: "#fff",
  }
}))(Tooltip);

// Customizable Area End

export class UserProfile extends UserProfileController {
  // Customizable Area Start
  getUserInfo = (info: PersonalInformation) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <InfoBox label={t("common.fullName")} value={info.fullname} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoBox
            label={t("common.emailAddress")}
            value={info.email_address}
            dir="ltr"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoBox
            label={t("common.phoneNumber")}
            value={`+${info.country_code}-${info.phone_number}`}
            dir="ltr"
          />
        </Grid>
      </Grid>
    );
  };

  getUserInfoForm = (info: PersonalInformation) => {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            data-test-id="NameInput"
            placeholder={t("common.fullName")!}
            label={t("common.fullName")}
            className="InputField"
            type="text"
            variant="outlined"
            lang={iLanguage}
            value={info.fullname}
            name="fullname"
            error={info.formHelpers.nameHasError}
            helperText={info.formHelpers.nameErrorMessage}
            onChange={this.onPersonalInformationChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            data-test-id="EmailInput"
            placeholder={t("common.emailAddress")!}
            label={t("common.emailAddress")}
            className="InputField RTLOFF"
            type="email"
            variant="outlined"
            value={info.email_address}
            name={"email_address"}
            error={info.formHelpers.emailHasError}
            helperText={info.formHelpers.emailErrorMessage}
            onChange={this.onPersonalInformationChange}
            InputProps={{
              classes: {
                input: `${iLanguage == "ar" && "RTLPlaceholder "}`,
                root: `${iLanguage == "ar" && "RTLFieldSet"}`
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            data-test-id="PhoneNumberInput"
            placeholder={t("common.phoneNumber")!}
            label={t("common.phoneNumber")}
            className="InputField RTLOFF"
            type="number"
            variant="outlined"
            value={info.phone_number}
            name={"phone_number"}
            error={info.formHelpers.phoneHasError}
            helperText={info.formHelpers.phoneErrorMessage}
            onChange={this.onPersonalInformationChange}
            onKeyPress={handleKeyPressNumber}
            InputProps={{
              startAdornment: `+${info.country_code}-`,
              classes: {
                input: `${iLanguage == "ar" && "RTLPlaceholder RTLInput"}`,
                root: `${iLanguage == "ar" && "RTLFieldSet"}`
              }
            }}
          />
        </Grid>
      </Grid>
    );
  };

  getUserAddressInfo = (address: UserAddressInformation) => {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <InfoBox label={t("userProfile.area")} value={address.area} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoBox label={t("userProfile.city")} value={address.city} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoBox label={t("userProfile.state")} value={address.state} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoBox
            label={t("userProfile.zipcode")}
            value={address.postal_code.toString()}
            dir="ltr"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <InfoBox label={t("userProfile.country")} value={address.country} />
        </Grid>
      </Grid>
    );
  };

  getUserAddressInfoForm = (address: UserAddressInformation) => {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            data-test-id="AreaInput"
            placeholder={t("userProfile.area")!}
            label={t("userProfile.area")}
            className="InputField"
            type="text"
            variant="outlined"
            name="area"
            value={address.area}
            error={address.formHelpers.areaHasError}
            helperText={address.formHelpers.areaErrorMessage}
            onChange={this.onUserAddressChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            placeholder={t("userProfile.city")!}
            label={t("userProfile.city")}
            className="InputField"
            type="text"
            variant="outlined"
            name="city"
            value={address.city}
            error={address.formHelpers.cityHasError}
            helperText={address.formHelpers.cityErrorMessage}
            onChange={this.onUserAddressChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            placeholder={t("userProfile.state")!}
            label={t("userProfile.state")}
            className="InputField"
            type="text"
            variant="outlined"
            name="state"
            value={address.state}
            error={address.formHelpers.stateHasError}
            helperText={address.formHelpers.stateErrorMessage}
            onChange={this.onUserAddressChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            placeholder={t("userProfile.zipcode")!}
            label={t("userProfile.zipcode")}
            className="InputField RTLOFF"
            type="number"
            variant="outlined"
            name="postal_code"
            value={address.postal_code}
            error={address.formHelpers.postalHasError}
            helperText={address.formHelpers.postalErrorMessage}
            onChange={this.onUserAddressChange}
            InputProps={{
              classes: {
                input: `${iLanguage == "ar" && "RTLPlaceholder RTLInput"}`,
                root: `${iLanguage == "ar" && "RTLFieldSet"}`
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl
            variant="outlined"
            className="InputField InputSelectField"
            style={{ width: '100%' }}
            error={address.formHelpers.countryHasError}
          >
            <InputLabel htmlFor="countrySelect">
              {t("userProfile.country")}
            </InputLabel>
            <Select
              data-testId="countryInput"
              variant="outlined"
              className="InputFieldSelect"
              labelId="codeSelect"
              placeholder={t("userProfile.country")!}
              label={t("userProfile.country")}
              name="country"
              value={address.country}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              onChange={(event: any) => this.handleCountryChange(event)}
            >
              {this.state.countriesData.map((country: any) => {
                return (
                  <MenuItem
                    value={country?.attributes.country_name}
                    key={country.id}
                  >
                    <Typography className="CountryNameInSelect">
                      {country.attributes.country_name}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>{address.formHelpers.countryErrorMessage}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  profileTab = () => {
    const {
      profileEditMode,
      personalInformation,
      addressInformation
    } = this.state;
    return (
      <Box component={"div"} className={this.props.classes.profileBox}>
        <InfoWrapper
          title={t("userProfile.personalInformation")}
          buttonType={profileEditMode.personalInfo ? "normal" : "icon"}
          buttonIcon={editIcon}
          onIconButtonClick={this.toggleEditModePI.bind(this, true)}
          onButtonClick={this.updatePersonalInformation}
          childrenNode={
            <>
              {profileEditMode.personalInfo
                ? this.getUserInfoForm(personalInformation)
                : this.getUserInfo(personalInformation)}
            </>
          }
        />

        <InfoWrapper
          title={t("userProfile.address")}
          buttonType={profileEditMode.address ? "normal" : "icon"}
          buttonIcon={editIcon}
          onIconButtonClick={this.toggleEditModeAddress.bind(this, true)}
          onButtonClick={this.updateUserAddress}
          childrenNode={
            <>
              {profileEditMode.address
                ? this.getUserAddressInfoForm(addressInformation)
                : this.getUserAddressInfo(addressInformation)}
            </>
          }
        />
      </Box>
    );
  }

  getResetPasswordValidation = (newPassword:string, confirmPassword:string, passwordEqual:boolean, min8LengthPassword:boolean, passwordNumberTest:boolean, passwordUCTest:boolean, passwordLCTest:boolean) => {
    return (newPassword.length > 0 && confirmPassword.length > 0) && (
        <>
            <Grid xs={12} sm={6} lg={(confirmPassword.length > 0 && passwordEqual) ? 6 : 4} item className={"passwordChecksRow"}>
                <PasswordErrorChecks condition={min8LengthPassword} message={t("forgotPassword.validations.eightCharMin")} />
                <PasswordErrorChecks condition={passwordNumberTest} message={t("forgotPassword.validations.oneNumber")} />
            </Grid>
            <Grid item xs={12} sm={6} lg={(confirmPassword.length > 0 && passwordEqual) ? 6 : 4} className={"passwordChecksRow"}>
                <PasswordErrorChecks condition={passwordUCTest} message={t("forgotPassword.validations.oneUppercase")} />
                <PasswordErrorChecks condition={passwordLCTest} message={t("forgotPassword.validations.oneLowercase")} />
            </Grid>
            {confirmPassword.length > 0 && !passwordEqual &&
                <Grid item xs={12} sm={6} lg={4} className={"passwordChecksRow"}>
                    <Box className="PasswordChecksBox">
                        <Typography className="PasswordMessage InvalidPassword">{t("forgotPassword.validations.passwordNotMatched")}</Typography>
                    </Box>
                </Grid>
            }
        </>
    )
  }

  tooltipContent = (planDetails:any) => {
    return(
      planDetails.attributes.cancelled_at && planDetails.attributes.refunded_at ?
          <div className={this.props.classes.tooltipHtmlContainer}>
            <div className="dateSection">
              <div>{t("warrantiesPlans.cancellationDate")}</div>
              <div>{planDetails.attributes.cancelled_at}</div>
            </div>
            <div className="dateSection">
              <div>{t("warrantiesPlans.redundDate")}</div>
              <div>{planDetails.attributes.refunded_at}</div>
            </div>
          </div>
        : ''
    )
  }
  
  planTab = () => {
    const {
      purchaseWarrantiesPlans,
      countryCurrency
    } = this.state;
    const {classes} = this.props;
    return purchaseWarrantiesPlans && purchaseWarrantiesPlans.length > 0 ? 
      <> 
      {purchaseWarrantiesPlans.map((planDetails:any, index: number) => {
        const isComboPlan = planDetails.attributes.details.warranty_type === "Combo";
        return (
        <Paper className={classes.mainPaperWarrantiesPlan} key={index}>
           <Box className='warrantiesPlanContainer'>
             <Table className='warrantyplanTable'>
               <TableHead className='warrantyTableHead'>
                 <TableRow>
                   <TableCell data-testId="invoiceNumber" className={classes.tableHeaderText} style={{width: '28%'}}>{t("warrantiesPlans.warranties")} # : {planDetails.attributes.details.item_invoice_id}</TableCell>
                   <TableCell className={classes.filesTab}>{t("warrantiesPlans.files")}</TableCell>
                   <TableCell className={classes.headerTabColor} style={{width: '14%'}}>{t("warrantiesPlans.status")}</TableCell>
                   <TableCell className={classes.headerTabColor} style={{width: '14%'}}>{t("warrantiesPlans.purchaseDate")}</TableCell>
                   <TableCell className={classes.headerTabColor} style={{width: '14%'}}>{t("warrantiesPlans.warrantiesStart")}</TableCell>
                   <TableCell className={classes.headerTabColor} style={{width: '14%'}}>{t("warrantiesPlans.warrantiesEnd")}</TableCell>
                 </TableRow>
               </TableHead>
               <TableBody className='warrantyTableBody'>
                 <TableRow className='warrantyTableRow1'>
                   <TableCell style={{paddingTop: 0}}>
                     <Typography data-testId="warratyplan_name" className={classes.textlabel_1YearWarrantyText} style={isComboPlan ? {fontWeight: 'bold'} : {}}>{planDetails.attributes.details.warranty_name}</Typography>
                   </TableCell>
                   <TableCell className='warrantyTableCell'>
                     <Box><a href={planDetails.attributes.details.plan} className='warrantyDetailsCell' target="_blank"><img src={filesDownload} width={"20px"} height={"20px"} /><Typography className={classes.textlabel_PolicyDetailsText}>{t("warrantiesPlans.warrantiesPolicyPlan")}</Typography></a></Box>
                     <Typography data-testId="warranties_cartification" style={{display: "flex", gap: 12}} className={classes.textlabel_PolicyDetailsText} onClick={() => this.handleDownloadWarrantiesCertificate(planDetails.id)}>
                      {this.conditionalRendering(isComboPlan , 
                      <><img src={filesDownload} width={"20px"} height={"20px"} className={classes.downloadIcon} />
                      {t("warrantiesPlans.warrantiesCertificate")}</> ,'')}</Typography>
                   
                   </TableCell>
                   <TableCell>
                   <LightTooltip title={this.tooltipContent(planDetails)} aria-label={planDetails.attributes.status_and_dates.status} arrow>
                    <Box className={`${classes.textlabel_ActiveText} ${planDetails.attributes.status_and_dates.status === 'Active' && classes.activeWarrantiesStatus}`} style={isComboPlan ? {display: "none"} : {}}>
                      {planDetails.attributes.status_and_dates.status}
                    </Box>
                    </LightTooltip>
                  </TableCell>
                   <TableCell className={classes.warrantiesDates}>{planDetails.attributes.status_and_dates.warranty_plan_purchase_date}</TableCell>
                   <TableCell className={classes.warrantiesDates}>{planDetails.attributes.status_and_dates.warranty_starts}</TableCell>
                   <TableCell className={classes.warrantiesDates}>{planDetails.attributes.status_and_dates.warranty_ends}</TableCell>
                 </TableRow>
                 {isComboPlan &&
                 <>
                 <TableRow className='warrantyTableRow1'>
                 <TableCell className={classes.textlabel_1YearWarrantyText} style={{paddingBottom: 16}}>{planDetails.attributes.status_and_dates.accidental.name}</TableCell>
                 <TableCell className='dowloadWarrantyContainer'></TableCell>
                   <TableCell>
                   <LightTooltip title={this.tooltipContent(planDetails)} aria-label={planDetails.attributes.status_and_dates.accidental.status} arrow>
                    <Box className={`${classes.textlabel_ActiveText} ${planDetails.attributes.status_and_dates.accidental.status === 'Active' && classes.activeWarrantiesStatus}`}>{planDetails.attributes.status_and_dates.accidental.status}</Box>
                   </LightTooltip>
                  </TableCell>
                   <TableCell  className={classes.warrantiesDates}>{planDetails.attributes.status_and_dates.accidental.warranty_plan_purchase_date}</TableCell>
                   <TableCell  className={classes.warrantiesDates}>{planDetails.attributes.status_and_dates.accidental.warranty_starts}</TableCell>
                   <TableCell  className={classes.warrantiesDates}>{planDetails.attributes.status_and_dates.accidental.warranty_ends}</TableCell>
                 </TableRow>
                 <TableRow className='warrantyTableRow1'>
                 <TableCell className={classes.textlabel_1YearWarrantyText} style={{paddingBottom: 16}}>{planDetails.attributes.status_and_dates.extended.name}</TableCell>
                   <TableCell className='dowloadWarrantyContainer'><Typography data-testId="warranties_cartification" className={classes.textlabel_PolicyDetailsText}></Typography></TableCell>
                   <TableCell>
                  <LightTooltip title={this.tooltipContent(planDetails)} aria-label={planDetails.attributes.status_and_dates.extended.status} arrow>
                    <Box className={`${classes.textlabel_ActiveText} ${planDetails.attributes.status_and_dates.extended.status === 'Active' && classes.activeWarrantiesStatus}`}>{planDetails.attributes.status_and_dates.extended.status}</Box>
                  </LightTooltip>  
                  </TableCell>
                   <TableCell  className={classes.warrantiesDates}>{planDetails.attributes.status_and_dates.extended.warranty_plan_purchase_date}</TableCell>
                   <TableCell  className={classes.warrantiesDates}>{planDetails.attributes.status_and_dates.extended.warranty_starts}</TableCell>
                   <TableCell  className={classes.warrantiesDates}>{planDetails.attributes.status_and_dates.extended.warranty_ends}</TableCell>
                 </TableRow>
                 </>}
                 <TableRow >
                 <TableCell className={classes.textlabel_ItemCoffeeAndEsText} style={{padding: 16}}>
                  {t("warrantiesPlans.item")} - {planDetails.attributes.details.item_name}
                  <br/>
                  {t("warrantiesPlans.itemPurchaseDate")} - {planDetails.attributes.status_and_dates?.purchased_at || planDetails.attributes.status_and_dates?.accidental?.purchased_at}
                  <br />
                  {t("warrantiesPlans.quantity")} - {planDetails.attributes.quantity }
                  </TableCell>
                   <TableCell className='dowloadWarrantyContainer'>
                    <Typography 
                       data-testId="warranties_cartification" 
                       className={classes.textlabel_PolicyDetailsText} 
                       style={{display: "flex", gap: 12}}
                       onClick={() => this.handleDownloadWarrantiesCertificate(planDetails.id)}
                       >{this.conditionalRendering(!isComboPlan ,
                       <>
                       <img src={filesDownload} width={"20px"} height={"20px"} className={classes.downloadIcon}/>
                       {t("warrantiesPlans.warrantiesCertificate")}
                       </> , '')}
                       </Typography>
                   </TableCell>
                   <TableCell></TableCell>
                   <TableCell></TableCell>
                   <TableCell></TableCell>
                   <TableCell></TableCell>
                 </TableRow>
               </TableBody>
             </Table>
           </Box>
           <Box className={classes.warrantiesPlanButtonMain}>
             <Box className={classes.warrantiesPriceText}>
               <div className={classes.CurrencySymbol}>{planDetails.attributes.currency_symbol}</div><div>{Number(planDetails.attributes.total_price).toFixed(2)}</div>
             </Box>
             <Box className='cartButtonContainer'>
               {(planDetails.attributes.status_and_dates.is_cancellable) && <Box><Button data-test-id="CancelButton" className={classes.buttonContainer} onClick={() => this.handleCancleWarrantyPlan(planDetails)}>{t("warrantiesPlans.cancleBtn")}</Button></Box>}
               {(planDetails.attributes.status_and_dates.is_renewable && planDetails.attributes.quantity == 1 && planDetails.attributes.renew_count === 0) && <Box className={classes.renewButtonContainerMain}><Button data-test-id="RenewButton" className={classes.renewButtonContainer} onClick={() => this.handleRenewableWarrantyPlan(planDetails)}>{t("warrantiesPlans.renewButton")}</Button></Box>}
             </Box>
           </Box>
         </Paper>
        )
      })}
         <Box className={this.props.classes.paginationParent}>
            <Pagination 
              onChange={this.handleWarrantyPlanPageChange}
              count={this.state.warrantyPlanPagination.total_pages} 
              page={this.state.warrantyPlanPagination.current_page} 
              siblingCount={1}
              variant="outlined" shape="rounded"
            />
        </Box>
      </>
       : 
           <Box className={classes.continueBtnWrapper}>
            <Typography className={classes.noPlanAvailable}>{t("warrantiesPlans.noPlansAvailable")}</Typography>
             <Button className={classes.renewButtonContainer} style={{width: 220}} onClick={this.handleBuyNewButton}>{t("warrantiesPlans.buyNewPlan")}</Button>
           </Box>
  }

  securityTab = () => {
    const { isOtpVerifiedResetPassword, newPasswordVisible, newPassword, confirmPassword, confirmPasswordVisible, newPasswordError, confirmPasswordError, openSuccessModal } = this.state;
    const { passwordUCTest, passwordLCTest, passwordNumberTest, min8LengthPassword, passwordEqual, allConditionSatified } = comboPasswordValidations(newPassword, confirmPassword);
    const noPasswordCondition = (newPassword.length > 0 && confirmPassword.length > 0) ? !allConditionSatified : false;
    const buttonText = isOtpVerifiedResetPassword ? t("common.submit") : t("userProfile.sendOtp");
    return (
      <Box component={"div"} className={this.props.classes.securityBox}>
        <InfoWrapper
          title={t("userProfile.resetPassword")}
          buttonType={'normal'}
          buttonText={buttonText}
          onButtonClick={this.handleSecurityAction}
          childNodeBoxStyle={{maxWidth:600}}
          childrenNode={
            isOtpVerifiedResetPassword &&
            <Grid container spacing={4}>
              <Grid item xs={12}>
                  <TextField
                      placeholder={t("common.newPasswordLabel")!}
                      label={t("common.newPasswordLabel")}
                      className="InputField"
                      type={newPasswordVisible ? "text" : "password"}
                      variant="outlined"
                      value={newPassword}
                      error={noPasswordCondition || newPasswordError.trim() !== ''}
                      helperText={newPasswordError}
                      onChange={this.handleNewPasswordChange}
                      InputProps={{
                          endAdornment: (<PasswordVisibility toggleVisibility={this.toggleNewPasswordVisibility} visibility={newPasswordVisible} />),
                      }}
                  />
              </Grid>
              <Grid item xs={12}>
                  <TextField
                      placeholder={t("common.confirmPasswordLabel")!}
                      label={t("common.confirmPasswordLabel")}
                      className="InputField"
                      type={confirmPasswordVisible ? "text" : "password"}
                      variant="outlined"
                      value={confirmPassword}
                      onChange={this.handleConfirmPasswordChange}
                      error={((newPassword.length > 0 && confirmPassword.length > 0) && !(passwordEqual && !noPasswordCondition)) || confirmPasswordError.trim() !== ''}
                      helperText={confirmPasswordError}
                      InputProps={{
                          endAdornment: (<PasswordVisibility toggleVisibility={this.toggleConfirmPasswordVisibility} visibility={confirmPasswordVisible} />),
                      }}
                  />
              </Grid>
              {
                this.getResetPasswordValidation(newPassword, confirmPassword, passwordEqual, min8LengthPassword, passwordNumberTest, passwordUCTest, passwordLCTest)
              }
            </Grid>
          }
        />
        <MessageModalWeb
            isOpen={openSuccessModal}
            title={t("userProfile.resetPasswordSuccessTitle")}
            subTitle={t("userProfile.resetPasswordSuccessSubTitle")}
            onConfirm={this.gotoHome}
            confirmBtnText={t("userProfile.backToHome")}
        />
      </Box>
    );
  }

  orderHistoryTab = () => {
    const { classes } = this.props;
    const { orderHistoryData, orderHistoryPagination, searchEnabled } = this.state;
    const showSearchBar = orderHistoryData.length > 0 || searchEnabled;
    return (
      <Box className={classes.orderHistoryBox}>
        {
          showSearchBar && (
            <AdvancedSearch performSearch={this.handleOrderHistorySearch} {...this.props}/>
          )
        }
        { orderHistoryData.length > 0 ? 
        (<>
          
          <TableContainer component={Paper} className={classes.orderHistoryTableContainer}>
            <Table>
              <TableHead>
                  <TableRow className={classes.orderHistoryHeader}>
                    <TableCell><Typography className={`${classes.historyCellText} header`}>{t("orderHistory.orderId")}</Typography></TableCell>
                    <TableCell><Typography className={`${classes.historyCellText} header`}>{t("orderHistory.purchaseDate")}</Typography></TableCell>
                    <TableCell><Typography className={`${classes.historyCellText} header`}>{t("orderHistory.invoiceNo")}</Typography></TableCell>
                    <TableCell><Typography className={`${classes.historyCellText} header`}>{t("orderHistory.transactionNo")}</Typography></TableCell>
                    <TableCell><Typography className={`${classes.historyCellText} header`}>{t("common.download")}</Typography></TableCell>
                  </TableRow>
              </TableHead>
              <TableBody>
                {orderHistoryData.map((history:OrderHistoryItem) => (
                  <TableRow key={history.id}>
                    <TableCell className={classes.historyBodyCell}>
                      <Tooltip title={history.attributes.order_number} aria-label={history.attributes.order_number}>
                        <Typography className={`${classes.historyCellText} body`}>
                        {history.attributes.order_number}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={classes.historyBodyCell}>
                      <Tooltip title={history.attributes.purchase_date} aria-label={history.attributes.purchase_date}>
                        <Typography className={`${classes.historyCellText} body`}  dir="ltr">
                        {history.attributes.purchase_date}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={classes.historyBodyCell}>
                      <Tooltip title={history.attributes.invoice_id} aria-label={history.attributes.invoice_id}>
                        <Typography className={`${classes.historyCellText} body`}  dir="ltr">
                        {history.attributes.invoice_id}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={classes.historyBodyCell}>
                      <Tooltip title={history.attributes.payment_id} aria-label={history.attributes.payment_id}>
                        <Typography className={`${classes.historyCellText} body`} dir="ltr">
                        {history.attributes.payment_id}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell className={classes.historyBodyCell}><Typography data-test-id={`InvoiceDownload-${history.id}`} className={`${classes.historyCellText} invoice`} onClick={this.downloadInvoice.bind(this,history.id)}>{t("orderHistory.invoicePdf")}&nbsp;<img src={filesDownload} width={"22px"} height={"22px"} /></Typography></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box className={classes.paginationParent}>
            <Pagination 
              onChange={this.handleOrderHistoryPageChange}
              count={orderHistoryPagination.total_pages} 
              page={orderHistoryPagination.current_page} 
              siblingCount={1}
              variant="outlined" shape="rounded"
            />
          </Box>
        </>) 
        :
        (
          <Box className={classes.noOrdersBox}>
            <Typography className={classes.noOrdersText}>
            {searchEnabled ? t("orderHistory.noOrdersFound") : t("orderHistory.noOrdersAvailable")}
            </Typography>
          </Box>
        )}
      </Box>
    )
  }
  getSelectedTab = (selectedTab: number) => {
    const {
      purchaseWarrantiesPlans,
      searchEnabledWp
    } = this.state;
    const {classes} = this.props;
    const showSearchBar = purchaseWarrantiesPlans?.length > 0 || searchEnabledWp;
    switch (selectedTab) {
      case 0:
        return this.profileTab()
      case 1:
        let component;
        if (!this.state.cancellationSubmitted) {
          component = <div
            data-test-id="refDiv"
            ref={this.listInnerRef}
            className="orderDetailsScroll">
            <>
              <Box className={classes.searchBox}>
                {showSearchBar &&
                  <AdvancedSearch performSearch={this.handleSearchPlan} {...this.props} />}
              </Box>
              {this.planTab()}
            </>
          </div>
        } else if (this.state.isPaymentFailed) {
          component = <OrderConfirmationWeb
            isFailed={true}
            isBorder={true}
            payment_id={this.state.renewablePlanId ?? ""}
            message={t("confirmation.paymentFailed") ?? ""}
            subTitle={" "}
            btnText={t("confirmation.tryAgain") ?? ''}
            handleButtonAction={this.handleCheckStatus}
          />;
        } else {
          component = <><OrderConfirmationWeb
            payment_id={this.conditionalRendering(this.state.renewableSubmitted, this.state.renewablePlanId, this.state.cancellationPlanId)}
            message={this.conditionalRendering(this.state.renewableSubmitted, "", t("cancelWarrantyplan.cancellationMsg") ?? "")}
            orderTypeMsg={this.conditionalRendering(this.state.renewableSubmitted, "", t("cancelWarrantyplan.orderRefferenceNo") ?? "")}
            subTitle={this.conditionalRendering(this.state.renewableSubmitted, t("cancelWarrantyplan.renewConfirmationMsg") ?? "", " ")}
            btnText={t("cancelWarrantyplan.checkStatus") ?? ""}
            handleButtonAction={this.handleCheckStatus}
          /></>;
        }
        return component;
      case 2:
        return this.orderHistoryTab();
      case 3:
        return this.securityTab();
    }
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { tabs, selectedTab, isCancelPlanWarranty, cancelReconfirmation } = this.state;
    return (
      <Box className={classes.rootBox}>
        <SearchEngineOptimisationseo title={t("userProfile.profile")!} navigation={this.props.navigation} id={this.props.id} />
        <Spinner showLoader={this.state.isLoading} />
        <Paper className={classes.rootPaper}>
          <Grid container spacing={4} className={classes.contentContainer}>
            <Grid item xs={12} md={3}>
              <ProfileLeftMenu
                logoImage={imgLogo}
                onTabClick={this.handleTabClick}
                tabs={tabs}
                onClickLogout={this.handleLogout}
                activeTab={selectedTab}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              {this.getSelectedTab(selectedTab)}
            </Grid>
          </Grid>
        </Paper>
        <OtpVerificationModalWeb 
          isOpen={this.state.openVerificationModal} 
          hasError={this.state.otpHasError} 
          errorText={this.state.otpError} 
          setError={this.handleOtpError} 
          defaultMinutes={2} 
          defaultSeconds={0} 
          otpResendCount={this.state.otpResendCount}
          resendOtp={this.resendEmailVerificationOtp} 
          verifyOtp={this.verifyEmailVerificationOtp}        
        />
        <WarrantiesPopupModelWeb 
            isOpen={isCancelPlanWarranty} 
            canclePlanDetails={this.state.cancelWarrantyPlanData} 
            cancellationReason={this.state.cancellationReason}
            handleCancellationReason={this.handleCancellationReason}
            handleCancelNoplan={this.handleCancelNoplan}
            handleContinueButton={this.handleCanclePopupContinueButton}
            countryCurrency= {this.state.countryCurrency}
            handleAdditionalRemark={this.handleAdditionalRemark}
            additional_remark={this.state.additional_remarks}
            errorCancellationReason={this.state.errorCancellationReason}
        />
       <CancelReconfirmationWeb 
          isOpen={cancelReconfirmation} 
          canclePlanDetails={this.state.cancelWarrantyPlanData}
          handleCancelNoplan={this.handleCancelNoplan}
          handleContinueButton={this.handleCanclePopupConfirmButton}
        />
        <RenewableWarrantyPlanWeb 
           isOpen={this.state.isRenewablePlans} 
           renewablePlanDetails={this.state.renewablePlanDetails}
           PriceDetails={this.state.renewWarrantyPlanPriceDetails}
           handleGobackButton={this.handleGoBackButton}
           filesDownloadImg={filesDownload}
           handleContinueButton={this.renewPaymentGetwayHandle}
           countryCurrency= {this.state.countryCurrency}
           />
           
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const userProfileStyle = (theme:Theme) => ({
  rootBox: {
    backgroundColor: "#FAFAFA"
  },
  rootPaper: {
    margin: deviceBasedDynamicDimension(64, true, 1),
    padding: deviceBasedDynamicDimension(24, true, 1),
    opacity: 1,
    backgroundColor: "rgba(255, 255, 255, 1)",
    borderRadius: deviceBasedDynamicDimension(12, true, 1),
    boxShadow: "0px 1px 10px #EBEBEB",
    [theme.breakpoints.down('sm')]:{
      margin: deviceBasedDynamicDimension(24, true, 1),
      padding: deviceBasedDynamicDimension(12, true, 1),
    },
  },
  contentContainer: {
    height: "100%"
  },
  profileBox: {
    display: "flex",
    flexDirection: "column" as const,
    "& .InputField": InputField,
    ...rtlOffInputClasses,
    "& .RTLInput": {
      paddingLeft: 12,
      paddingRight: 12
    },
  },
  securityBox:{
    "& .InputField": {
      ...InputField,
    },
    "& .passwordChecksRow":{
      display: 'flex',
      flexDirection: 'column' as const,
      alignItems: 'flex-start',
      "& .PasswordChecksBox":{
          display: 'flex',
          alignItems: 'center',
          margin:4,
          "& .PasswordMessage": {
              fontFamily: 'Manrope, sans-serif',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: 14,
              lineHeight: 1.2,
              color: '#008443',
              marginLeft: 12,
              "&.InvalidPassword":{
                  color:theme.palette.error.main
              },
          },
          "& .MuiAvatar-circular":{
              height: 24,
              width: 24,
          }
      },
    }
  },
  searchBox:{
   maxWidth: '440px',
   width: '100%',
   margin: '16px 0px',
   '& .InputField': InputField
  },
  mainPaperWarrantiesPlan: {
    margin: `24 0`,
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderRadius: deviceBasedDynamicDimension(12, true, 1),
    boxShadow: "0px 1px 10px #EBEBEB",
    [theme.breakpoints.down('sm')]:{
     overflowX: 'scroll'
    },
    '& .warrantiesPlanContainer': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    '& .warrantyplanTable': {
      borderRadius: '20px'
    },
    '& .warrantyTableHead': {
      background: "#F9F9F9"
    },
    '& .warrantyTableBody': {
      background: "#FFFFFF"
    },
    '& .warrantyTableRow1': {
      borderBottom: "hidden"
    },
    '& .warrantyTableCell': {
      display: "flex",
      gap: 12,
      flexDirection: 'column' as const,
      borderBottom: "hidden"

    },
    '& .warrantyDetailsCell': {
      alignItems: 'center',
      display: 'flex',
      gap: '12px',
      textDecoration: 'none'
    },
    '& .dowloadWarrantyCell': {
      alignItems: 'baseline',
      display: 'flex',
      gap: '12px',
      width: '150px',
    },
    '& .dowloadWarrantyContainer': {
      padding: "10px 0px 16px 16px !important",
    },
    '& .dowloadIcon': {
      height: '15px',
      width: '15px'
    },
    '& .MuiTableBody-root': {
      '& .MuiTableCell-root': {
        paddingBottom: "0px"
      }
    }
  },
  downloadIcon:{
   marginTop: '4px'
  },
  tableHeaderText: {
    backgroundColor: 'transparent',
    fontWeight: 'bold' as const,
    color: 'rgba(0, 77, 116, 1)',
    fontFamily: 'Manrope',
    fontSize: deviceBasedDynamicDimension(18, true, 1)
  },
  headerTabColor: {
    fontFamily: 'Manrope',
    fontSize: deviceBasedDynamicDimension(18, true, 1),
    color: "rgba(105, 116, 121, 1)",
    backgroundColor: 'transparent',
    fontWeight: 'bold' as const,
  },
  filesTab: {
  //  display: 'flex',
   alignItems: 'center',
  //  gap:15,
   fontFamily: 'Manrope',
   fontSize: deviceBasedDynamicDimension(18, true, 1),
   color: "rgba(105, 116, 121, 1)",
   backgroundColor: 'transparent',
   fontWeight: 'bold' as const,
  },
  textlabel_1YearWarrantyText:
  {
    backgroundColor: 'transparent',
    color: 'rgba(0, 77, 116, 1)',
    textAlign: 'left' as const,
    fontFamily: 'Inter',
    fontSize: deviceBasedDynamicDimension(20, true, 1),
  },
  warrantiesPriceText: {
    display: "flex",
    fontWeight: 'bold' as const,
    color: 'rgba(0, 132, 67, 1)',
    textAlign: 'right' as const,
    fontFamily: 'Inter',
    fontSize: deviceBasedDynamicDimension(30, true, 1),
  },
  textlabel_ItemCoffeeAndEsText:
  {
    paddingTop: "0px",
    backgroundColor: 'transparent',
    fontWeight: 'bold' as const,
    color: 'rgba(116, 122, 128, 1)',
    textAlign: 'left' as const,
    fontFamily: 'Manrope',
    fontSize: deviceBasedDynamicDimension(18, true, 1),
  },
  warrantiesDates:
  {
    color: 'rgba(5, 23, 32, 1)',
    fontFamily: 'Inter',
    fontSize: deviceBasedDynamicDimension(18, true, 1),
  },
  textlabel_ActiveText:
  {
    borderRadius: "32px",
    background: "rgba(255, 0, 0, 0.2121121066433566)",
    padding: "6px 8px",
    color: 'rgba(255, 0, 0, 1)',
    textAlign: 'center' as const,
    fontFamily: 'Inter',
    fontSize: deviceBasedDynamicDimension(16, true, 1),
  },
  activeWarrantiesStatus: {
    backgroundColor: "rgba(88, 194, 125, 0.5021853146853147)",
    color: "rgba(0, 102, 36, 1)"
  },
  textlabel_PolicyDetailsText:
  {
    cursor: 'pointer',
    backgroundColor: 'transparent',
    color: 'rgba(0, 77, 116, 1)',
    textAlign: 'left' as const,
    fontFamily: 'Inter',
    fontSize: deviceBasedDynamicDimension(18, true, 1),
    textDecoration: "underline",
    textDecorationColor: "rgba(0, 77, 116, 1)"
  },
  warrantiesPlanButtonMain: {
    display: "flex", 
    justifyContent: 'space-between', 
    alignItems: 'center', 
    gap: "30px", 
    padding: '20px',
    '& .cartButtonContainer': {
      display: "flex", 
      justifyContent: '', 
      gap: "30px",
      '& .MuiButton-root.Mui-disabled': {
        border: "1px solid rgba(0, 0, 0, 0.26)",
        opacity: 0.5,
        cursor: 'not-allowed',
        pointerEvents: 'auto'
      },
    }
  },
  buttonContainer: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    border: "1px solid #004D74",
    width: deviceBasedDynamicDimension(176, true, 1),
    height: 48,
    borderRadius: deviceBasedDynamicDimension(40, true, 1),
    justifyContent: 'center',
    fontWeight: 'bold' as const,
    textAlign: 'center' as const,
    fontFamily: 'Manrope',
    fontSize: deviceBasedDynamicDimension(20, true, 1),
    textTransform: "capitalize" as const
  },
  continueBtnWrapper:{
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    gap:10,
    alignItems: "center",
    height: '100%'
  },
  renewButtonContainerMain:{
    '& .MuiButton-root.Mui-disabled': {
      color: "#fff",
      opacity: 0.5,
      cursor: 'not-allowed',
      pointerEvents: 'auto',
      '&:hover':{
        backgroundColor: "#004D74"
      }
    }
  },
  renewButtonContainer: {
    backgroundColor: "#004D74",
    border: "1px solid #004D74",
    width: deviceBasedDynamicDimension(176, true, 1),
    height: 48,
    borderRadius: deviceBasedDynamicDimension(40, true, 1),
    justifyContent: 'center',
    fontWeight: 'bold' as const,
    textAlign: 'center' as const,
    fontFamily: 'Manrope',
    fontSize: deviceBasedDynamicDimension(20, true, 1),
    textTransform: "capitalize" as const,
    color: "#fff",
    '&:hover':{
      backgroundColor: '#004D74',
  }
  },
  orderHistoryBox:{
    margin: `24px 0px`,
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
  orderHistoryTableContainer:{
    borderRadius: '12px',
    boxShadow: "0px 1px 10px #EBEBEB",
  },
  orderHistoryHeader:{
    backgroundColor:"#F9F9F9",
    minHeight:76,
    height:'auto'
  },
  historyCellText:{
    textAlign: 'start' as const,
    fontFamily: 'Manrope, sans-serif',
    fontSize: 20,
    lineHeight: 1.5,
    fontWeight:600,
    whiteSpace: 'nowrap' as const,
    textOverflow: 'ellipsis' as const,
    overflow:'hidden' as const,
    width:'100%',
    maxWidth:192,
    margin:"8px 0px",
    [theme.breakpoints.down('md')]: {
        fontSize: 18,
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: 16,
    },
    "&.header":{
      color: theme.palette.secondary.main,
    },
    "&.body":{
      color: 'rgba(5, 23, 32, 1)'
    },
    "&.invoice":{
      display:'flex',
      alignItems:'center' as const, 
      color: theme.palette.primary.main,
      cursor:'pointer'
    }
  },
  noPlanAvailable:{
    color: theme.palette.secondary.main,
    fontFamily: "Manrope, sans-sarif",
    fontSize: 48,
    lineHeight: 1.5,
    fontWeight: 600,
    letterSpacing: 1,
    textAlign: 'center' as const,
    [theme.breakpoints.down('md')]:{
    fontSize:44
    },
    [theme.breakpoints.down('sm')]:{
      fontSize:40
    }
  },
  paginationParent:{
    display:'flex', 
    justifyContent:'flex-end', 
    marginTop:24,
    ...warrantyPagination(theme)
  },
  noOrdersBox:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    height:'100%'
  },
  noOrdersText:{
    color:theme.palette.secondary.main,
    fontFamily: 'Manrope, sans-serif',
    fontSize: 48,
    lineHeight: 1.5,
    fontWeight:600,
    letterSpacing:1,
    textAlign:'center' as const,
    [theme.breakpoints.down('md')]: {
        fontSize: 44,
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: 40,
    },
  },
  tooltipHtmlContainer: {
    display: 'flex', 
    flexDirection: 'column' as const, 
    gap: 16,
    padding: 16,
    "& .dateSection": {
      display: 'flex', 
      justifyContent: 'space-between', 
      gap: 20,
      fontSize: 18,
      color: 'rgba(0, 77, 116, 1)'
    }
  },
  CurrencySymbol:{
    margin:"0px 4px"
  }

});
export default withStyles(userProfileStyle)(UserProfile);
// Customizable Area End
