Object.defineProperty(exports, "__esModule", {
    value: true
});
// Customizable Area Start
exports.languageOptions = [
    {
        name: "English",
        identifier: "en",
        direction: 'ltr',
    },
    {
        name: "عربي",
        identifier: "ar",
        direction: 'rtl',
    }
];
exports.GET = "GET";
exports.httpDeleteMethod = "DELETE"
exports.apiContentType = "application/json";
exports.getCountriesListEndPoint = "bx_block_country/countries";
// static strings
exports.deleteMethod = "DELETE"
exports.selectCountry = "Select Country";
exports.pleaseSelectCountry = "Please Select Country";
exports.selectLanguage = "Select Language";
exports.pleaseSelectLanguage = "Please Select Language";
exports.confirm = "Confirm";
exports.categoryEndPoint = "/bx_block_landingpage/show_categories";
exports.refreshCartEndPoint = "bx_block_order_management/orders/refresh_cart";
exports.getServiceProviderEndPoint = "bx_block_country/countries";
exports.deleteCartItemAPiEndPoint = "/bx_block_order_management/order_items"
exports.orderDetailsEndPoint = "bx_block_order_management/orders/cart";
exports.TRANSACTION_FEES = "Transaction Fee"
// static strings END

// Customizable Area End