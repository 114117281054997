import React from "react";
// Customizable Area Start
import Slider from "react-slick";
import { withStyles, Box, Theme } from "@material-ui/core";
import MainCarouselController, { Props } from "./MainCarouselController.web";
import { iDirection, iLanguage } from "./i18n";
// Customizable Area End

// Customizable Area Start
export const CarouselStyles = (theme: Theme) => ({
  BannerImage: {
    width: "100%",
    height:"95%",
    cursor: "pointer",
    objectFit: 'cover' as const,
    "&.fullImage":{
      height:"100%",
    }
  },
  CarouselContainer: {
   "& .slick-slide":{
    height:'auto'
   }
  },
  carouselDot:{
    "& li button::before": {
      color:"#004D74 !important",
      fontSize:"12px"
    },
    "& li.slick-active button::before": {
    color:"#004D74 !important",
    fontSize:"12px"
    },
  }
});
// Customizable Area End

class MainCarousel extends MainCarouselController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000,
      rtl: iDirection === 'rtl'
    };
    const { classes, carouselBannersData } = this.props;
    return (
      <>
        {/* Customizable Area Start */}
        <Box className={classes.CarouselContainer}>
          <Slider {...settings} dotsClass={`slick-dots ${classes.carouselDot}`}>
            {carouselBannersData.map((item: any, index: number) => {
              return (
                <Box key={item.id} component={'div'}>
                  <img
                    alt="BannerImage"
                    className={`${classes.BannerImage} ${carouselBannersData.length === 1 && 'fullImage'}`}
                    src={
                      iLanguage === "en"
                        ? item.attributes.english_banner
                        : item.attributes.arabic_banner
                    }
                    onClick={ () => this.handleBannerClick(item?.attributes?.redirect_url)}
                  />
                </Box>
              );
            })}
          </Slider>
        </Box>
        {/* Customizable End Start */}
      </>
    );  
  }
}

// Customizable Area Start

export default withStyles(CarouselStyles)(MainCarousel);

// Customizable Area End
