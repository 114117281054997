import React from "react";
// Customizable Area Start
import { BlockComponent } from "../../framework/src/BlockComponent";
import {
    Box,
    withStyles,
    WithStyles,
    Theme,
    Typography
} from "@material-ui/core";
import { deviceBasedDynamicDimension } from "./Toolkit";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props extends WithStyles {
    // Customizable Area Start
    policyContent?: Array<{
        title: string,
        description: string
    }>;
    setDescriptionRef: (ref: React.RefObject<any>, index: number) => void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area End

export class PolicyContent extends BlockComponent<Props, S, SS> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        // Customizable Area Start
        const { classes, policyContent } = this.props;
        // Customizable Area End
        return (
            <>
                {/* Customizable Area Start */}
                <Box className={classes.MainBox}>
                    {
                        policyContent?.map((content: { title: string, description: string }, index: number) => {
                            const ref = React.createRef<any>();
                            this.props.setDescriptionRef(ref, index);
                            return (
                                <div key={index} ref={ref} data-test-id="refDiv">
                                    <Box className={classes.contentBox} >
                                        <Typography className={classes.tableOfContentText}>{content.title}</Typography>
                                        {/* the description is comming as html content from rich text area */}
                                        <Typography
                                            className={classes.tableOfContentItemText}
                                            dangerouslySetInnerHTML={{
                                                __html: `${content.description}`
                                            }}
                                        />
                                    </Box>
                                </div>
                            )
                        })
                    }
                </Box>
                {/* Customizable End Start */}
            </>
        );
    }
}

// Customizable Area Start
const styles = (theme: Theme) => ({
    MainBox: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'flex-start' as const,
        padding: "0px 8px",
        [theme.breakpoints.down('xs')]: {
            alignItems: 'center' as const
        }
    },
    contentBox: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'flex-start' as const,
        [theme.breakpoints.down('xs')]: {
            alignItems: 'center' as const
        }
    },
    tableOfContentText: {
        width: "100%",
        fontStyle: 'normal',
        fontWeight: 'bold' as const,
        color: 'rgba(0, 77, 116, 1)',
        textAlign: 'left' as const,
        textAlignVertical: 'top',
        fontFamily: 'Manrope',
        wordBreak: "break-word" as const,
        hyphens: 'auto' as const,
        fontSize: deviceBasedDynamicDimension(32, true, 1),
        padding: deviceBasedDynamicDimension(0, true, 1),
        [theme.breakpoints.down('xs')]: {
            fontSize: deviceBasedDynamicDimension(22, true, 1),
        }
    },
    tableOfContentItemText: {
        width: "100%",
        fontStyle: 'normal',
        fontWeight: 'normal' as const,
        color: 'rgba(5, 23, 32, 1)',
        textAlign: 'left' as const,
        textAlignVertical: 'top',
        fontFamily: 'Manrope',
        wordBreak: "break-word" as const,
        hyphens: 'auto' as const,
        fontSize: deviceBasedDynamicDimension(20, true, 1),
        padding: deviceBasedDynamicDimension(0, true, 1),
        [theme.breakpoints.down('xs')]: {
            fontSize: deviceBasedDynamicDimension(18, true, 1),
        }
    }
})
export default withStyles(styles)(PolicyContent);
// Customizable Area End
