import React from "react";
// Customizable Area Start
import { Typography, Divider, Box, WithStyles } from "@material-ui/core";
import { BlockComponent } from "../../framework/src/BlockComponent";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { iLanguage, t } from "./i18n";
// Customizable Area End

// Customizable Area Start

export interface Props extends WithStyles {
  // Customizable Area Start
  bg_color: any;
  plans: any;
  expand?: boolean;
  handleCetegoryPlans: (value: any) => void;
  CategoryPlans: any;
  text_color?: any;
  currency_symbol?: string;
  // Customizable Area End
}

interface S { }

interface SS {
  id: any;
}

// Customizable Area End

class WarrantyPlanPurchaseCard extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  render() {

    const { bg_color, classes, plans, expand, handleCetegoryPlans, CategoryPlans, text_color, currency_symbol } = this.props;
    const currentVeiwPlan = plans.id === CategoryPlans.id && !expand;
    const IsNoDiscount = plans.prices.amount === plans.prices.discounted_price;
    return (
      <>
        {/* Customizable Area Start */}
        <Box data-testId="purchaseContainerCard" style={ { background: currentVeiwPlan ? bg_color : "", minWidth:expand ? 550 : 360 }} className={`${classes.purchaseContainer} ${expand && classes.expandedPurchaseCOntainer}`} onClick={() => handleCetegoryPlans(plans)}>
          <div style={plans.prices.discount === 0 ? { display: "none" } : { background: bg_color }} className={`${classes.planDiscountContainer} ${expand && classes.expandedPlanOffContainer} ${iLanguage === "ar" && classes.expandedPlanOffContainerArabic}`} >
            <Typography variant="h6" className={`${classes.percentageText} ${expand && classes.expandedPercentageText}`}>
              {plans.prices.discount.toFixed(0)}% {t("common.off")}
            </Typography>
          </div>
          <Box className={classes.planPriceContainer} style={ { marginTop: expand ? '33px' : 0,flexDirection:"column" , padding: !IsNoDiscount ? "15px" : "5px", paddingBottom: 0}}>
            
            <div className={`${classes.warrantyPriceMainContainer} ${expand && classes.expandedPriceText}`} style={{ direction: 'ltr',marginLeft: 0, color: currentVeiwPlan ? "#fff" : text_color , gap: 5}}>
            {!IsNoDiscount && <div style={{width:expand ? 75 : 60}}></div>}
              <div style={{fontSize: expand ? 48 : 35}}>{currency_symbol}</div>
              <div className="priceText">{Number(plans.prices.discounted_price).toFixed(2)}</div>
              </div>
            {!IsNoDiscount && <div dir="ltr" className={`${classes.percentOffContainer} ${expand && classes.expandedOffPrice}`} style={currentVeiwPlan ? { color: "#fff" } : { color: text_color }}><div className={classes.CurrencySymbol}>{currency_symbol}</div><div className="priceText">{Number(plans.prices.amount).toFixed(2)}</div></div>}
          </Box>
          <Box style={{ padding: '3px 15px'}}>
            <Typography variant="h6" className={expand ? classes.expandedPlanValidDate : classes.planValidDate} style={currentVeiwPlan ? { color: "#fff" } : {}}>{plans.name}</Typography>
          </Box>
          {expand && (
            <Box>
              <Divider className={classes.expandedDivider} />
              <Box className={classes.expandedContaiinerDiscription} data-testId="expandedPlan">
                <div className={classes.expandedTagMain}>
                  {plans.details.tags && plans.details.tags.length > 0 && (
                    plans.details.tags.map((item: any) => (
                      <div className={classes.expandedTagContainer} key={item}>
                        <CheckCircleOutlineIcon style={{ color: bg_color }} /> <span className={classes.expanedPlanOfferText}>{item}</span>
                      </div>
                    ))
                  )}
                </div>
                <div className={`${classes.planDescriptionOverFlow} orderDetailsScroll`}>
                  {plans.details.description && plans.details.description.length > 0 ? (
                    <p className={classes.expanedPlanContent}
                      dangerouslySetInnerHTML={{
                        __html: plans.details.description
                      }}>
                    </p>) : null}
                </div>
                <Box className={classes.downloadPravicyPolicyBtnMain}>
                  <a href={plans.details.plan_details} data-testId="downloadPolicyBtn" className={classes.downloadPravicyPolicyBtn} style={{ color: text_color }} target="_blank" rel="noopener noreferrer">{t("WarrantyPlanPurchase.downloadPolicyText")}</a>
                </Box>
              </Box>
            </Box>
          )}

        </Box>
        {/* Customizable End Start */}
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

export default WarrantyPlanPurchaseCard;

// Customizable Area End
