Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.getUserProfileApiEndPoint = "account_block/accounts/show";
exports.getCountriesListEndPoint = "bx_block_country/countries";
exports.updateUserPersonalInformationEndPoint = "account_block/accounts/update"
exports.updateUserAddressEndPoint = "bx_block_profile/profiles/update_details"
exports.apiUpdateUserAddressType = "PATCH";
exports.apiPostType = "POST";
exports.sendEmailOtpEndPoint = "account_block/accounts/send_otp"
exports.resendEmailOtpEndPoint = "account_block/accounts/resend_otp"
exports.verifyEmailOtpEndPoint = "account_block/accounts/verify_otp"
exports.allPurchaseOrderitemsEndPoint = "bx_block_order_management/order_items"
exports.resetPasswordEndPoint = "account_block/accounts/reset_password";
exports.getOrderItemsEndPoint = "bx_block_order_management/orders"
exports.cancelWarrantiesPlan = "bx_block_order_management/cancel_warranty";
exports.warrantyCertification = "bx_block_order_management/order_items";
exports.renewWarrantyPlan = "bx_block_order_management/renew_warranty";
exports.paymentEndPoint = "bx_block_order_management/payments";
exports.orderStatusEndPoint = "bx_block_order_management/payments/fetch_payment_id";
exports.myFatoraahPaymentEndPoint = "bx_block_order_management/myfatoorah_payments";
exports.myFatoraahOrderStatusEndPoint = "bx_block_order_management/myfatoorah_payments/fetch_payment_id";
exports.mixpanelRenewPlanEvent = "Click to Renew Warranty Plan";
exports.purchasedWarrantyPlan = "Purchased Warranty Plan";
exports.mixpanelCancelPlanEvent = "Cancel Warranty plan";
exports.mixpanelRenewPlanStausEvent = "Renewed Warranty Plan"
// Customizable Area End

