import React from "react";
// Customizable Area Start
import { Typography, Card, CardContent, Button } from "@material-ui/core";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { WithStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { t } from './i18n';
// Customizable Area End

// Customizable Area Start

export interface Props extends WithStyles {
  // Customizable Area Start
  index: number,
  isHovered: string | null,
  handleMouseEvent: (itemId: string) => void,
  CardData: {
    name: string,
    id: string,
    category_logo: string,
    bg_color: string,
    text_color: string,
  },
  // Customizable Area End
}

interface S { }

interface SS {
  id: any;
}

// Customizable Area End

class CategoryCard extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleEnterEvent = () => {
    this.props.handleMouseEvent(this.props.CardData.name)
  }

  handleLeaveEvent = () => {
    this.props.handleMouseEvent("")
  }

  render() {
    const { classes, CardData, index, isHovered } = this.props;
    return (
      <>
        {/* Customizable Area Start */}
        <Card
          data-test-id={`categoryCard${index}`}
          className={`${classes.CardContainer}`}
          style={isHovered === CardData?.name ? { background: CardData?.bg_color } : { background: "transparent" }}
          onMouseEnter={this.handleEnterEvent}
          onMouseLeave={this.handleLeaveEvent}
        >
          <img src={CardData?.category_logo} alt="Card Image" className={`${classes.cardImage}`} />
          <CardContent>
            <Typography variant="h4" className={`${classes.category_name}`} style={isHovered === CardData?.name ? { color: "#fff" } : {}}>
              {CardData?.name}
            </Typography>
          </CardContent>

          <Button variant="contained" className={`${classes.CardButtonAction} CardButton`} >
            <Link to={`category/${CardData?.id}`} data-test-id={`cardButton${index}`} className={classes.linkText} style={isHovered === CardData?.name ? { color: CardData?.text_color } : {}}>{t("landingPage.buyWarrantyPlan")}</Link>
          </Button>

        </Card>
        {/* Customizable End Start */}
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

export default CategoryCard;

// Customizable Area End
