import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { callApi, getNavigationMessage } from "../../../components/src/Toolkit";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { iLanguage, t } from "../../../components/src/i18n";

// Customizable Area End

const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes:{
        MainContainerLI:string,
        VerifyOtpFormBox:string,
        LVOTPBox:string,
        SignInButtonGrid:string,
        LOResendBox:string
    }
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    showLoader: boolean;
    otp: string;
    email_token: string;
    otpError: boolean;
    otpErrorText: string;
    seconds: number;
    minutes: number;
    otpTimer: any;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class VerifyLoginOtpController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    apiVerifyOtp: string = "";
    apiResendOtp: string = "";
    loginOtpGuestCartAPICallId: string = "";
    // Customizable Area End    

    constructor(props: Props) {
        super(props);

        // Customizable Area Start
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials)
        ];

        this.state = {
            showLoader: false,
            otp: '',
            email_token: '',
            otpError: false,
            otpErrorText: '',
            seconds: 0,
            minutes: 2,
            otpTimer: null
        };

        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const tokenParam = await getStorageData('email_otp_token');
        if (tokenParam) {
            this.setState({
                email_token: tokenParam
            })
        }else{
            this.send(getNavigationMessage('LoginWithOtp', this.props));
        }
        this.startOtpTimer();
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (responseJson != null) {
                if (!responseJson.errors) {
                    if (apiRequestCallId === this.apiVerifyOtp) {
                        await setStorageData('token', responseJson.meta.token);
                        await setStorageData('token_expiry', responseJson.meta.expiration_time);
                        await setStorageData('user_id', responseJson.meta.id);
                        await removeStorageData('email_otp_token');
                        await setStorageData('user', JSON.stringify(responseJson.data));
                         // moving guest cart to users cart
                        this.handleGuestStoredCart(responseJson.meta.token);
                    } else if (apiRequestCallId === this.apiResendOtp) {
                        this.setState({
                            email_token: responseJson.meta.email_otp_token[0]
                        }, () => {
                            this.setState({
                                seconds: 0,
                                minutes: 2
                            }, () => {
                                this.startOtpTimer();
                            })
                        })
                    } else if(apiRequestCallId === this.loginOtpGuestCartAPICallId){
                        await removeStorageData('storedCart');
                        this.handleLoginOtpGobackNavigation();
                      }
                } else {
                    this.parseApiError(responseJson)
                }
            }
            this.setState({ showLoader: false })
            this.parseApiCatchErrorResponse(errorReponse);

        }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentWillUnmount() {
        this.stopOtpTimer();
    }
    startOtpTimer = () => {
        this.setState({
            otpTimer: setInterval(this.tick, 1000)
        })
    }
    stopOtpTimer = () => {
        clearInterval(this.state.otpTimer);
    }
    tick = () => {
        const { seconds, minutes } = this.state;
        if (seconds > 0) {
            this.setState(prevState => ({
                seconds: prevState.seconds - 1
            }), () => {
                if (this.state.seconds <= 0 && this.state.minutes <= 0) {
                    this.stopOtpTimer();
                }
            });
        }

        if (seconds === 0) {
            if (minutes === 0) {
                this.stopOtpTimer()
            } else {
                this.setState(prevState => ({
                    seconds: 59,
                    minutes: prevState.minutes - 1
                }));
            }
        }
    }

    resendOtp = () => {
        this.setState({
            showLoader:true
        })
        const httpBody: any = {
            language_code:iLanguage,
            data: { email_otp_token: this.state.email_token }
        }

        this.apiResendOtp = callApi({
            contentType: configJSON.loginApiContentType,
            method: configJSON.POST,
            endPoint: configJSON.loginResendOtpApiEndPoint,
            headers: {},
            body: JSON.stringify(httpBody)
        }, runEngine);
    }
    parseApiError = (response: any) => {
        let errors = response.errors;
        let values: any = Object.values(errors[0]);
        this.setState({
            otpError: true,
            otpErrorText: values[0]
        })
    }

    isOtpValid = (otp: string): boolean => {
        if (otp.length < 6) {
            this.setState({
                otpError: true,
                otpErrorText: t("login.minOtpLengthValidation")
            })
            return false;
        }else{
            this.setState({
                otpError: false,
                otpErrorText: ''
            })
            return true;
        }

    }
    onOtpChange = (newOtp: string) => {
        this.setState({
            otp: newOtp
        })
        this.isOtpValid(newOtp)
    }
    onSubmitOtp = (event: any) => {
        event.preventDefault();
        if (this.isOtpValid(this.state.otp)) {
            this.setState({
                showLoader: true
            });
            const httpBody: any = {
                language_code:iLanguage,
                data: { otp: this.state.otp, email_otp_token: this.state.email_token }
            }

            this.apiVerifyOtp = callApi({
                contentType: configJSON.loginApiContentType,
                method: configJSON.PATCH,
                endPoint: configJSON.loginVerifyOtpApiEndPoint,
                headers: {},
                body: JSON.stringify(httpBody)
            }, runEngine);
        }
    }

    handleGuestStoredCart = async (token:string) => {
        const storedCart = await getStorageData("storedCart", true);
        const selectedCountry = await getStorageData('selectedCountry', true);
        const countryId = selectedCountry.id
        if(storedCart){
          this.GuestAddToCartLocalCartData(storedCart, countryId, token)
        }else{
          this.handleLoginOtpGobackNavigation();
        }
      }

    handleLoginOtpGobackNavigation = async () => {
        const goBackToOrdermanagement = await getStorageData("comeToOrder", true);
    
        if (goBackToOrdermanagement) {
          this.send(getNavigationMessage('Ordermanagement', this.props))
          await removeStorageData("comeToOrder");
        } else {
          this.send(getNavigationMessage("Home", this.props));
        }
      };

    GuestAddToCartLocalCartData = (storedCart: any, selectedCountryId:string, token:string) => {
        let guestCartData = storedCart.map((item: any) => {
          return {
            item_list_warranty_id: item.attributes.item_list_warranty_id,
            old_unit_price: item.attributes.old_unit_price,
            item_invoice_id: item.attributes.details.item_invoice_id,
            purchase_date: item.attributes.purchase_date,
            store_list_id: item.attributes.store_list_id,
            quantity: item.attributes.quantity,
            ...(item.attributes?.store_name ? {store_name: item.attributes.store_name} : {})
          };
        });
        this.setState({ showLoader: true });
        const httpParams = {
          country_id: selectedCountryId,
          data: guestCartData,
        };
    
        this.loginOtpGuestCartAPICallId = callApi(
          {
            contentType: configJSON.loginApiContentType,
            method: configJSON.loginAPiMethod,
            endPoint: configJSON.addToCartEndPoint,
            headers: { token },
            body: JSON.stringify(httpParams),
          },
          runEngine
        );
      };
    // Customizable Area End
}
