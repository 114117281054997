// Customizable Area Start
import React from 'react'
import { Paper, TextField } from '@material-ui/core';
import { TitleContainer, SubmitButton, HelperBtnGroup } from './Reusables.web';
import { iLanguage, t } from '../../../components/src/i18n';
// Customizable Area End

// Customizable Area Start
interface SendOtpProps {
    title: string,
    subTitle: string,
    email: string,
    emailError: boolean,
    emailErrorText: string,
    onSubmit: (event: any) => void,
    handleEmailBlur: () => void,
    handleEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    goToLogin: () => void,
}
// Customizable Area End

// Customizable Area Start
export const SendOtpPanel = ({ title, subTitle, email, emailError, emailErrorText, handleEmailBlur, handleEmailChange, onSubmit, goToLogin }:SendOtpProps) => {
    return (
        <Paper elevation={2} className="FPFormPaper">
            <TitleContainer title={title} subTitle={subTitle}/>
            <TextField
                data-testId="EmailInput"
                placeholder={t("common.emailAddress")!}
                label={t("common.emailAddress")}
                className="InputField RTLOFF"
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                error={emailError}
                helperText={emailErrorText}
                InputProps={{
                    classes:{
                        input:`${iLanguage == 'ar' && "RTLPlaceholder"}`,
                        root:`${iLanguage == 'ar' && "RTLFieldSet"}`
                    }
                }}
            />
            <SubmitButton submitText={t("common.submit")} onSubmit={onSubmit}/>
            <HelperBtnGroup helperText={t("forgotPassword.goBackTo")} buttonText={t("forgotPassword.loginPage")} disabled={false} onClick={goToLogin}/>
        </Paper>)
}
// Customizable Area End
