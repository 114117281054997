// Customizable Area Start
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { responsiveWidth } from 'react-native-responsive-dimensions';
import { Platform, Dimensions, PixelRatio } from 'react-native';
import { iLanguage, t } from "./i18n";
import { runEngine } from "../../framework/src/RunEngine";
// Customizable Area End

// Customizable Area Start
export const getTestStyleRecord = (styles:any) => {

    type StyleKeys = keyof typeof styles;

    return {} as Record<StyleKeys, ''>;

}
export const isValidEmail = (email: string) : {emailError:boolean; emailErrorText:string}=> {
    if (email.trim().length === 0) {
        return {
            emailError: true,
            emailErrorText: t("common.validations.emailRequired"),
        };
    } else if (!emailReg.test(email)) {
        return {
            emailError: true,
            emailErrorText:  t("common.validations.invalidEmail"),
        }
    }

    return {
        emailError: false,
        emailErrorText: '',
    }
}
export const isValidName = (value: string):{nameError:boolean; nameErrorText:string} => {
    if (value.trim().length === 0) {
        return {
            nameError: true,
            nameErrorText: t("common.validations.nameIsRequired"),
        }
    } else if (!nameReg.test(value)) {
        return {
            nameError: true,
            nameErrorText: t("common.validations.invalidName"),
        }
    } 
    
    return {
        nameError: false,
        nameErrorText: '',
    }
}
export const isValidPhoneNumber = (value: string) => {
    if (value.trim().length === 0) {
        return {
            phoneNumberError: true,
            phoneNumberErrorText: t("common.validations.phoneNumberRequired"),
        };
    } 
    return {
        phoneNumberError: false,
        phoneNumberErrorText: '',
    };
}

export const callApi = (payload: any, runEngine: any) => {
    const { contentType, method, endPoint, body, headers } = payload;
    const header = {
        "Content-Type": contentType,
        ...headers
    };
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
    );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
    );
    body &&
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            body
        );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
}

export const getNavigationMessage = (route: string, props: any, payload?: any): Message => {

    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);

    message.addData(getName(MessageEnum.NavigationPropsMessage), props);

    if (payload) {
        const raiseMessage: Message = new Message(
            getName(MessageEnum.NavigationPayLoadMessage)
        );
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), payload);
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    }
    return message;
}

export const hideTawkToWidget = () => {
    // @ts-ignore
    const tawkAPI = window.Tawk_API;
    if (tawkAPI) {
        if(tawkAPI.isChatMaximized()){
            tawkAPI.minimize();
        }
        tawkAPI.hideWidget();
    }
}

export const showTawkToWidget = () => {
    // @ts-ignore
    const tawkAPI = window.Tawk_API;
    if (tawkAPI) {
        tawkAPI.showWidget();
    }
}
let screenWidth = Dimensions.get('window').width; 
let screenHeight = Dimensions.get('window').height; 
//Artboard Dimension 
let artBoardHeightOrg = 1080; 
let artBoardWidthOrg = 1920; 
//Re calculated Artboard Dimension 
let artBoardWidth =  isSameRatio() ? artBoardWidthOrg : screenWidth; 
let artBoardHeight =  isSameRatio() ? artBoardHeightOrg : screenHeight; 
//Top or Bottom nav spaces or any extra space occupied by os e.g Status bar, Notch 
let extraSpace = 0; 
function isSameRatio(): boolean {
    return artBoardWidthOrg / artBoardHeightOrg < 1 && screenWidth / screenHeight < 1 
}
export function deviceBasedDynamicDimension(originalDimen: number, compareWithWidth: boolean, resizeFactor: number): number | undefined 
{ 
	if (originalDimen != null)
	{ 
		if (resizeFactor != null)
		{ 
			originalDimen *= resizeFactor;
		} 
		const compareArtBoardDimenValue = compareWithWidth ? artBoardWidth : artBoardHeight; 
		const artBoardScreenDimenRatio = (originalDimen * 100) / compareArtBoardDimenValue; 
		let compareCurrentScreenDimenValue = compareWithWidth ? screenWidth : screenHeight - extraSpace; 
		if (Platform.OS === 'web') 
		{ 
			return(responsiveWidth(originalDimen/compareCurrentScreenDimenValue)*100); 
		} 
		return PixelRatio.roundToNearestPixel( (artBoardScreenDimenRatio * compareCurrentScreenDimenValue) / 100,); 
	} 
	return undefined; 
} 
export const emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export const arabicCharacterRegex = /[\u0600-\u06FF]/;
export const nameReg = /^[^\d]+$/;
export const englishArabicCharacterRegex = /^[\p{Script=Arabic}\p{L}\s]*$/u;
export const passwordLowerCaseReg = /[a-z]/;
export const passwordUpperCaseReg = /[A-Z]/;
export const passwordOneNumbereReg = /\d/;
export const comboPasswordValidations = (firstPassword: string, secondPassword:string): { passwordUCTest: boolean, passwordLCTest: boolean, passwordNumberTest: boolean, min8LengthPassword: boolean, passwordEqual: boolean, allConditionSatified: boolean } => {
    const passwordUCTest: boolean = passwordUpperCaseReg.test(firstPassword) && passwordUpperCaseReg.test(secondPassword);
    const passwordLCTest: boolean = passwordLowerCaseReg.test(firstPassword) && passwordLowerCaseReg.test(secondPassword);
    const passwordNumberTest: boolean = passwordOneNumbereReg.test(firstPassword) && passwordOneNumbereReg.test(secondPassword);
    const min8LengthPassword: boolean = firstPassword.length >= 8 && secondPassword.length >= 8;
    const passwordEqual: boolean = firstPassword === secondPassword;
    const allConditionSatified: boolean = passwordUCTest && passwordLCTest && passwordNumberTest && min8LengthPassword;
    return { passwordUCTest, passwordLCTest, passwordNumberTest, min8LengthPassword, passwordEqual, allConditionSatified }
}

export const sendCartItemListChanges = (message : string) => {
    const requestMessage = new Message(
        getName(MessageEnum.PostEntityMessage)
    );
    requestMessage.addData(
        getName(MessageEnum.PostDetailDataMessage),
        message
    );
    runEngine.sendMessage(requestMessage.id, requestMessage)
}

export const getTaxTitle = (taxType:string, taxValue:number) => {
    return taxType === "Transaction Fee" ? t("common.transactionFees") : `${taxType} (${taxValue}%) `
}
export const  waitFor = (callback:() => void,duration:number) => {
    return new Promise<void>(resolve => {
        setTimeout(() => {
            callback();
            resolve();
          }, duration);
    });
}

export const handleKeyPressNumber = (event: any) => {
    if (event.key === 'e' || event.key === '-' || event.key === '+' || event.key === 'E') {
        event.preventDefault();
      }
}
// Customizable Area End