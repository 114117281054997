import React from "react";
// Customizable Area Start
import { Grid, Box, Paper, Typography, Button, withStyles, Theme } from '@material-ui/core'
import Spinner from "../../../components/src/Spinner.web";
import VerifyLoginOtpController, {
    Props
} from "./VerifyLoginOtpController.web";
import OtpInput from "react-otp-input";
import LeftImageGrid from "./LeftImageGrid.web";
import { imgVerifyOtpLeft } from "./assets";
import { t } from "../../../components/src/i18n";
import { loginStyle } from "./Login.web";
import { flexCenter } from "../../../components/src/CommonStyles";
// Customizable Area End


export class VerifyLoginOtp extends VerifyLoginOtpController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { showLoader, otp, otpError, otpErrorText, seconds, minutes } = this.state;
        const {classes} = this.props;
        const isTimerRunning = seconds > 0 || minutes > 0;
        return (
            <Box >
                <Spinner showLoader={showLoader} />
                <Grid container className={classes.MainContainerLI}>

                    <LeftImageGrid imgSrc={imgVerifyOtpLeft} />

                    <Grid item xs={12} md={6}>
                        <Box className={classes.VerifyOtpFormBox}>

                            <Paper elevation={2} className="VOTPFormPaper">
                                <Box className="OtpTitleContainer">
                                    <Typography className={`OtpTitle`}>{t("login.otpTitle")}</Typography>
                                    <Typography className={`OtpSubTitle`}>{t("login.otpSubtitle")}</Typography>
                                </Box>
                                <form onSubmit={this.onSubmitOtp}>
                                    <Box className={classes.LVOTPBox}>
                                        <Box className={`${otpError && 'ErrorOtp'}`}>
                                            <OtpInput
                                                numInputs={6}
                                                shouldAutoFocus
                                                onChange={this.onOtpChange}
                                                value={otp}
                                                inputStyle="OTP"
                                                renderInput={(props: any) => <input {...props} />}
                                                inputType="text"
                                                data-testId="LoginOTPInput"
                                                containerStyle={{display:'grid', gridTemplateColumns:"1fr 1fr 1fr 1fr 1fr 1fr", columnGap:8, direction:'ltr'}}
                                            />
                                        </Box>
                                    </Box>
                                    {otpError &&
                                        <Box className="LOErrorBox">
                                            <Typography className="LOErrorText">{otpErrorText}</Typography>
                                        </Box>
                                    }
                                    {isTimerRunning && (
                                        <Box className="OTPTimerBox">
                                            <Typography className="ResendOtpText" >{t("login.resendOtpIn")}</Typography>
                                            <Typography className="OTPTimerText"> {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</Typography>
                                        </Box>
                                    )}
                                    <Grid container className={classes.SignInButtonGrid}>
                                        <Grid item xs={12} className="SingInButtonGridItem">
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className="SubmitOtpButton"
                                                type="submit"
                                                data-testId="LoginSubmitOtpBtn"
                                            >
                                                <Typography className="SubmitOtpText">
                                                    {t("login.submitOtp")}
                                                </Typography>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Box className={classes.LOResendBox}>
                                        <Typography className="NotRecievedOtpText">{t("login.didntRecievedOtp")}</Typography>
                                        <Button
                                            variant="text"
                                            className="ResendButton"
                                            disabled={isTimerRunning}
                                            onClick={this.resendOtp}
                                            data-testId="ResendButton"
                                        >
                                            <Typography className="ResendText">
                                                {t("common.resend")}
                                            </Typography>
                                        </Button>
                                    </Box>

                                </form>
                            </Paper>

                        </Box>
                    </Grid>
                </Grid>
            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
const VerifyLoginOtpStyle = (theme:Theme) => {
    const loginStyles = loginStyle(theme);
    return ({
        "@-moz-document url-prefix()":{
            LVOTPBox:{
                "& .OTP":{
                    "-moz-appearance": "textfield",
                    "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button":{
                        "-webkit-appearance": "none",
                        margin: 0
                    }
                }
            }
        },
        MainContainerLI:loginStyles.MainContainerLI,
        VerifyOtpFormBox:{
            display: 'flex',
            justifyContent: 'flex-start',
            padding: 24,
            [theme.breakpoints.down('md')]:{
                justifyContent: "center",
            },
            "& .VOTPFormPaper":{
                width: "100%",
                maxWidth: "598px",
                padding: "48px",
                borderRadius: "20px",
                boxShadow: "0px 1px 10px #EBEBEB",
                marginTop: "48px",
                [theme.breakpoints.down('md')]:{
                    padding:24
                },
                "& .OtpTitleContainer":{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginBottom: 16,
                    paddingLeft: 8,
                    "& .OtpTitle":{
                        fontFamily: "'Manrope', sans-serif",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: 28,
                        lineHeight: 1.5,
                        letterSpacing: 2,
                        color: "#515151"
                    },
                    "& .OtpSubTitle":{
                        fontFamily: "'Manrope', sans-serif",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: 16,
                        lineHeight: 1.5,
                        color: "#01BEFE",
                        mixBlendMode: "normal",
                        opacity: 0.5
                    }
                },
                "& .LOErrorText":{
                    fontFamily: "'Inter', sans-serif",
                    fontStyle: "normal",
                    fontWeight: "400",
                    fontSize: 14,
                    lineHeight: 1.5,
                    color: "red",
                    marginTop: "4px",
                    textAlign: "end",
                    paddingRight: "12px"
                },
                "& .OTPTimerBox":{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginTop: "16px",
                    "& .ResendOtpText":{
                        fontFamily: "'Inter', sans-serif",
                        fontStyle: "normal",
                        fontWeight: "500",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#212121",
                        textTransform: "initial",
                        marginRight: "4px"
                    },
                    "& .OTPTimerText":{
                        fontFamily: "'Inter', sans-serif",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "21px",
                        color: "#004D74",
                        textTransform: "initial",
                        marginLeft: "4px",
                        paddingRight: "12px"
                    }
                }
            }
        },
        LVOTPBox:{
            "& .OTP":{
                width: "100% !important",
                height: "82px",
                margin: "0.5rem",
                fontSize: "2rem",
                border: "1px solid #CFDBD5",
                borderRadius: "8px",
                [theme.breakpoints.down('xs')]:{
                    height: 48
                }
            },
            "& .ErrorOtp":{
                "& input":{
                    color:theme.palette.error.main
                }
            } 
        },
        SignInButtonGrid:{
            marginTop: 32,
            "& .SingInButtonGridItem":{
                display: "flex",
                justifyContent: "center",
                marginTop:"8px",
                marginBottom:"8px",
                "& .SubmitOtpButton": {
                    backgroundColor: "#004D74",
                    color: "white",
                    height: "64px",
                    width: "360px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50px",
                    "&:hover":{
                        backgroundColor: "#004D74"
                    },
                    "& .SubmitOtpText":{
                        fontFamily: "'Manrope', sans-serif",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "20px",
                        lineHeight: 1.3,
                        textAlign: "center",
                        color: "#FFFFFF",
                        textTransform: "initial"
                    }
                }
            }
        },
        LOResendBox:{
            ...flexCenter,
            flexWrap:'wrap' as const,
            marginTop: 32,
            "&  .NotRecievedOtpText": {
                fontFamily: "'Poppins', sans-serif",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: 20,
                lineHeight: 1,
                textAlign: "center",
                color: "#000000",
                whiteSpace: "nowrap",
            },
            "& .ResendButton": {
                marginLeft: 4,
                "&.Mui-disabled":{
                    "& .ResendText": {
                        color: "#747A80"
                    }
                },
                "& .ResendText": {
                    fontFamily: "'Inter', sans-serif",
                    fontStyle: "normal",
                    fontWeight: 900,
                    fontSize: 18,
                    textTransform: "initial" as const ,
                    color: "#004D74",
                    whiteSpace: "nowrap",
                }
            }
        },
        

    })
}
export default withStyles(VerifyLoginOtpStyle)(VerifyLoginOtp);
// Customizable Area End