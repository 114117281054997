import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import mixpanel from "mixpanel-browser";
export class MixpanelTracking {
  private static  _instance : MixpanelTracking;

  public static getInstance() :  MixpanelTracking {
    if(MixpanelTracking._instance == null) {
      MixpanelTracking._instance = new MixpanelTracking()
      return MixpanelTracking._instance;
    }
    return this._instance
  }

  public constructor() {
    if(MixpanelTracking._instance) 
      throw new Error(
      configJSON.mixpanelError
      )

    mixpanel.init(configJSON.mixpanelCrediantial, {
      debug: true,
      ignore_dnt: true,
      track_pageview: true,
      persistence: 'localStorage'
    });
    
  }

  protected track(name: string, data: object = {}) {
    mixpanel.track(name, data);
  }
  public page_views() {
    this.track(configJSON.mixpanelPageView)
  }

  public userIdentify(id: any) {
    mixpanel.identify(id)
  }

  public pageviewed() {
    this.track(configJSON.pageViewed)
  }


  public productviewed(product: any) {
    this.track(configJSON.productViewed, product)
  }

  public addToCart(product: any) {
    this.track(configJSON.AddedToCart, product)
  }

  public eventTrack(name:string, data:any) {
  this.track(name, data)
  }

  public peopleDetails(data: object = {$name: '', $email: ''}) {
    mixpanel.people.set(data)
  }

}
// Customizable Area End

import MixpanelIntegrationController, {
  configJSON,
  Props,
} from "./MixpanelIntegrationController";

export default class MixpanelIntegration extends MixpanelIntegrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
     <>
     </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
