import React from 'react';
// Customizable Area Start
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    InputCheckboxInside:{
        "& .MuiSvgIcon-root":{
            color:'#004D74'
        }
    }
  });

const WarrantyCheckBox = (props: any) => {
    const classes = useStyles();
    return <Checkbox {...props} classes={{ checked: classes.InputCheckboxInside }} data-testId="WarrantyCheckbox"
        icon={
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="22" height="22" style={{overflow: "inherit"}}>
                <rect width="100%" height="100%" fill="#F3F3F3" rx="5" ry="5" stroke={`${props?.error ? 'red' : '#979797'}`} strokeWidth="1" />
            </svg>
        } />;
};

export default WarrantyCheckBox;
// Customizable Area End