import React, { Component } from "react";
// Customizable Area Start
import { withStyles, WithStyles, Box, Theme, Typography, Tooltip } from "@material-ui/core";
// Customizable Area End

// Customizable Area Start
export const InfoCardStyles = (theme: Theme) => ({
  card: {
    width: "240px",
    maxWidth: "235px !important",
    display: "flex !important",
    border: "2px solid #EBF0F0",
    padding: "48px 8px",
    height: "200px",
    flexDirection: "column" as const,
    justifyContent: "space-between",
    textAlign: "center" as const,
    alignItems: "center",
    borderRadius: "32px",
    [theme.breakpoints.down('sm')]: {
      padding: "40px 8px",
      width: 200,
    },
    [theme.breakpoints.down('xs')]: {
      padding: "32px 8px",
      width: 184,
    },
    ["@media (min-width: 1280px) and (max-height: 680px)"]:{
      height: 148,
      padding:"42px 8px"
    }
  },
  cardTitle: {
    color: "#245377",
    fontSize: 20,
    fontWeight: 600,
    textOverflow: "ellipsis",
    overflow: "hidden",
    margin:"4px 0px",
    wordBreak: "break-word" as const,
    whiteSpace: "nowrap" as const,
    ["@media (min-width: 1280px) and (max-height: 680px)"]:{
      fontSize: 18,
    }
  },
  cardSubTitle: {
    color: "#697479",
    fontSize: 14,
    textOverflow: "ellipsis",
    overflow: "hidden",
    margin:"8px 0px",
    wordBreak: "break-word" as const,
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 2,
  },
  cardContent: {
    width: "100%"
  },
  cardImage:{
    height:72,
    width:72,
    objectFit:'contain' as const,
    margin:"8px 0px"
  }
});


export interface Props extends WithStyles {
  // Customizable Area Start
  CarouselData: {
    image: string,
    title: string,
    description: string,
  };
  // Customizable Area End
}

interface S { }

interface SS {
  id: any;
}

// Customizable Area End

class InfoCard extends Component<Props, S, SS> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, CarouselData } = this.props;
    return (
      <>
        {/* Customizable Area Start */}
        <Box className={classes.card}>
          <img className={classes.cardImage} src={CarouselData.image} alt={CarouselData.title}/>
          <Box className={classes.cardContent}>
          <Tooltip title={CarouselData.title.length > 18 ? CarouselData.title : ""} arrow>
            <Typography variant="h3" className={classes.cardTitle}>
              {CarouselData.title}
            </Typography>
          </Tooltip>
          <Tooltip title={CarouselData.description.length > 72 ? CarouselData.description : ""} arrow>
            <Box className={classes.cardSubTitle}>{CarouselData.description}</Box>
          </Tooltip>
          </Box>
        </Box>
        {/* Customizable End Start */}
      </>
    );
  }
}

// Customizable Area Start

export default withStyles(InfoCardStyles)(InfoCard);

// Customizable Area End
