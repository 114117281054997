import React from "react";
// Customizable Area Start
import { Typography, Card, CardContent, Button, Theme, withStyles, WithStyles} from "@material-ui/core";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { t } from '../i18n';
// Customizable Area End

// Customizable Area Start
type category = {
    name: string,
    id: string,
    category_logo: string,
    bg_color: string,
    text_color: string,
}
export interface Props extends WithStyles {
    // Customizable Area Start
    category: category,
    onClick: (category: category) => void
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    hovered: boolean
    // Customizable Area End
}

interface SS {
    id: any;
}

// Customizable Area End

class CategoryCard extends BlockComponent<Props, S, SS> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
            hovered: false
        }
        // Customizable Area End
    }

    // Customizable Area Start
    handleEnterEvent = () => {
        this.setState({
            hovered: true
        })
    }

    handleLeaveEvent = () => {
        this.setState({
            hovered: false
        })
    }

    handleCategoryClick = () => {
        this.props.onClick(this.props.category)
    }

    render() {
        const { classes, category } = this.props;
        const { hovered } = this.state;
        return (
            <>
                {/* Customizable Area Start */}
                <Card
                    data-test-id={`categoryCard${category.id}`}
                    className={`${classes.CardContainer}`}
                    style={hovered ? { background: category?.bg_color } : { background: "transparent" }}
                    onMouseEnter={this.handleEnterEvent}
                    onMouseLeave={this.handleLeaveEvent}
                    onClick={this.handleCategoryClick}
                >
                    <img src={category?.category_logo} alt="Card Image" className={`${classes.cardImage}`} />
                    <CardContent>
                        <Typography variant="h4" className={`${classes.category_name}`} style={hovered ? { color: "#fff" } : {}}>
                            {category?.name}
                        </Typography>
                    </CardContent>

                    <Button variant="contained" data-test-id="cardButton" className={`${classes.CardButtonAction} CardButton`} onClick={this.handleCategoryClick}>
                        <Typography className={classes.linkText} style={hovered ? { color: category?.text_color } : {}}>{t("landingPage.buyWarrantyPlan")}</Typography>
                    </Button>

                </Card>
                {/* Customizable End Start */}
            </>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
const style = (theme: Theme) => ({
    CardContainer: {
        display: "flex",
        flexDirection: 'column' as const,
        padding: "20px",
        paddingTop: "25px",
        height: "max-content",
        borderRadius: '32px',
        boxShadow: "0px 1px 10px #EBEBEB",
        cursor:'pointer',
        [theme.breakpoints.down('md')]: {
            padding: "12px",
            borderRadius: '16px',
            marginLeft: 0
        }

    },
    cardImage: {
        width: '100%',
        height: '70%',
        objectFit: 'contain' as const,
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
    },
    CardButtonAction: {
        width: "85%",
        fontFamily: "Manrope",
        fontSize: "20px",
        margin: "5px auto",
        borderRadius: "31px",
        backgroundColor: "#fff",
        padding: "10px",
        color: '#004d74',
        boxShadow: 'none',
        border: '1px solid #004d74',
        marginBottom: "10px",
        textTransform: "capitalize" as const,
        "&:hover": {
            boxShadow: 'none',
            backgroundColor: "#fff"
        },
        [theme.breakpoints.between('md', 'lg')]: {
            fontSize: "12px !important",

        },
        [theme.breakpoints.down('md')]: {
            margin: '0 auto',
            padding: 5,
            marginTop: -5
        }
    },
    category_name: {
        fontFamily: "Manrope",
        fontSize: "1.8rem",
        color: '#004d74',
        textAlign: "center" as const,
        fontWeight: 'bold' as const,
        opacity: "0.9",
        [theme.breakpoints.down('md')]: {
            fontSize: "16px !important"
        }
    },
    linkText: {
        fontFamily: "Manrope",
        color: '#004d74',
        textDecoration: 'none',
        fontWeight: 600,
        opacity: 0.8,
        fontSize: "18px",
        [theme.breakpoints.down('md')]: {
            fontSize: "9px !important"
        },
    },
})
export default withStyles(style)(CategoryCard);
// Customizable Area End
