import React from "react";
// Customizable Area Start
import { BlockComponent } from "../../framework/src/BlockComponent";
import {
    Box,
    withStyles,
    WithStyles,
    Theme,
    Typography
} from "@material-ui/core";
import { deviceBasedDynamicDimension } from "./Toolkit";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props extends WithStyles {
    // Customizable Area Start
    description: string
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area End

class GenericDescription extends BlockComponent<Props, S, SS> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {}
        // Customizable Area End
    }
    render() {
        // Customizable Area Start
        const { classes, description } = this.props;
        // Customizable Area End
        return (
            <>
                {/* Customizable Area Start */}
                <Box className={classes.MainBox}>
                    <Typography
                        className={classes.descriptionText}
                        dangerouslySetInnerHTML={{
                            __html: `${description}`
                        }}
                    />
                </Box>
                {/* Customizable End Start */}
            </>
        );
    }
}

// Customizable Area Start
const styles = (theme: Theme) => ({
    MainBox: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        maxWidth: '1400px'
    },
    descriptionText: {
        width: "100%",
        color: "#697479",
        fontFamily: "'Manrope', sans-serif",
        fontStyle: "normal",
        fontSize: deviceBasedDynamicDimension(20, true, 1),
        lineHeight: 1.4,
        letterSpacing: 1.75,
        textAlign: 'left' as const,
        wordBreak:'break-word' as const,
        hyphens:'auto' as const,
        padding: deviceBasedDynamicDimension(0, true, 1),
        [theme.breakpoints.down('xs')]: {
            fontSize: deviceBasedDynamicDimension(18, true, 1),
        }
    },
})
export default withStyles(styles)(GenericDescription);
// Customizable Area End
