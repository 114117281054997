import React, { Component } from 'react';
import { Box, Typography, withStyles, WithStyles, Modal, Grid, Card,Theme, Divider, Button } from '@material-ui/core';
import { iDirection, t } from './i18n';
import {
    InputField,
    rtlOffInputClasses
} from "./CommonStyles";
import { deviceBasedDynamicDimension } from './Toolkit';

interface Props extends WithStyles {
    isOpen: boolean;
    renewablePlanDetails?:any;
    handleGobackButton?:any;
    filesDownloadImg?:any;
    handleContinueButton?:any;
    PriceDetails?:any;
    countryCurrency?:string;
}

interface S {

}

interface SS {

}
class RenewableWarrantyPlan extends Component<Props, S, SS> {

    constructor(props: Props) {
        super(props);

        this.state = {

        }
    }
    shortWarrantyTitle(input: string) {
        let modifyString = input + " " + "Warranty";
        const words = modifyString.split(" ");
        const acronym = words.map((word) => word[0].toUpperCase()).join("");
        return acronym;
      }

    render() {
        const { classes, isOpen,renewablePlanDetails,handleGobackButton,filesDownloadImg ,handleContinueButton,PriceDetails,countryCurrency} = this.props;
        return (
            <Modal
                open={isOpen}
                className={classes.VOMBoxModal}
                dir={iDirection}
                data-test-id="OTPModal"
            >
                <Box className={classes.view_BackgroundCopy}>
                    <Box>
                        <Typography data-testId="renew_model_title" className={classes.textlabel_RenewDetailsText}>{t("warrantiesPlans.renewDetails")}</Typography>
                    </Box>
                    <Box className={classes.renewproductDetails}>
                        <Grid className={classes.orderDetailsCardContainer}>
                            <Grid
                                container
                                item
                                xs={12}
                                md={9}
                                className={classes.orderCardContainer}
                            >
                                <Card
                                    className={classes.cardContainerMain}
                                >
                                    <Box className={classes.cardContainer}>
                                        <Box
                                            className={classes.warrantyPlanimg}
                                            style={{
                                                background:
                                                    renewablePlanDetails?.details?.category_bg_color
                                            }}
                                        >
                                            <Typography
                                                className={classes.warrantyPlanTextlogo}
                                            >
                                                {this.shortWarrantyTitle(renewablePlanDetails?.details?.warranty_type)}
                                            </Typography>
                                        </Box>
                                        <Box
                                            className={classes.warrantyPlanCardDiscription}
                                        >
                                            <Typography
                                                className={classes.warrantyPlancardText}
                                            >
                                                {renewablePlanDetails?.details?.warranty_name}
                                            </Typography>
                                            <Typography
                                                className={classes.warrantyPlanInvoiceText}
                                                data-testId="orderDetails_name"
                                            >
                                                {t("warrantiesPlans.item")} -  {renewablePlanDetails?.details?.item_name}
                                            </Typography>
                                            <Typography
                                                className={classes.warrantyPlanInvoiceText}
                                            >
                                                Warranty # :
                                                {renewablePlanDetails?.details?.item_invoice_id}
                                            </Typography>
                                            <Box className={classes.orderDatailBtnAction}>
                                                <div
                                                    className={classes.warrantyPlanPriceText}
                                                >
                                                    <div className={classes.CurrencySymbol}>{countryCurrency}</div>
                                                    <div>{Number(renewablePlanDetails?.total_price).toFixed(2)}</div>
                                                </div>
                                                <a
                                                    href={renewablePlanDetails?.details?.plan}
                                                    className="readPlanDetailsContainer"
                                                >
                                                    <div className="readPlanDetailsText">
                                                        {t("orderDetails.readPlanDetailsText")}
                                                    </div>
                                                    <div>
                                                        <img
                                                            src={filesDownloadImg}
                                                            width={"24px"}
                                                            height={"24px"}
                                                        />
                                                    </div>
                                                </a>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Card>

                            </Grid>
                            <Grid
                                xs={12}
                                md={3}
                                className={classes.orderCardTotalContainer}
                            >
                                <Card className="cardTotalMain">
                                    <Box>
                                        <Typography className="priceDetailText">
                                            {t("orderDetails.priceDetails")}(
                                            {1} {t("orderDetails.items")}):
                                        </Typography>
                                    </Box>
                                    <Box className={classes.priceDetailsContainer}>
                                        <Box className="cardCalculationRow">
                                            <Typography className={classes.totalDiscriptionText}>
                                                {t("orderDetails.total")}
                                            </Typography>
                                            <Typography
                                                className={classes.totalDiscriptionAmountText}
                                            >
                                                <div  className={classes.CurrencySymbol}>{countryCurrency}</div>
                                                <div>{Number(PriceDetails.sub_total).toFixed(2)}</div>
                                            </Typography>
                                        </Box>
                                        <Box className="cardCalculationRow">
                                            <Typography className={classes.totalDiscriptionText}>
                                                {t("orderDetails.discount")}
                                            </Typography>
                                            <Typography
                                                className={classes.totalDiscriptionAmountText}
                                            >
                                                -&nbsp;<div className={classes.CurrencySymbol}>{countryCurrency}</div>
                                                <div>{Number(PriceDetails.applied_discount).toFixed(2)}</div>
                                            </Typography>
                                        </Box>
                                        <Box className="cardCalculationRow">
                                            <Typography className={classes.totalDiscriptionText} style={{ whiteSpace: 'nowrap'}}>
                                                {PriceDetails.tax_details?.type} {PriceDetails.tax_details?.type !== "Transaction Fee" ?  <>({PriceDetails.tax_details?.value}%)</> : null}{" "}
                                            </Typography>
                                            <Typography
                                                className={classes.totalDiscriptionAmountText}
                                            >
                                                <div className={classes.CurrencySymbol}>{countryCurrency}</div>
                                                <div>{Number(PriceDetails.total_tax ?? "0").toFixed(2)}</div>
                                            </Typography>
                                        </Box>
                                        <Divider />
                                        <Box className="cardCalculationRow">
                                            <Typography className={classes.totalAmountText}>
                                                {t("orderDetails.totalAmount")}
                                            </Typography>
                                            <Typography className={classes.totalAmountPriceText}>
                                                {" "}
                                                <div className={classes.CurrencySymbol}>{countryCurrency}</div>
                                                <div>{Number(PriceDetails.total).toFixed(2)}</div>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className={classes.modelBtnContainer}>
                        <Box className='actionBtnCancelModel'>
                            <Button data-testId="canclePlanContinuebtn" className={classes.continueButton} onClick={handleContinueButton}>{t("warrantiesPlans.proceedToPay")}</Button>
                            <Button data-testId="canclePlanNobtn" className={classes.noCancelButton} onClick={handleGobackButton}>{t("warrantiesPlans.goBackButton")}</Button>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        );
    }
}

const style = (theme: Theme) => ({
    VOMBoxModal: {
        display: 'flex',
        alignItems: 'center' as const,
        justifyContent: 'center',
        "& .InputField": InputField,
        ...rtlOffInputClasses,
        "& .InputField .MuiInputLabel-outlined.MuiInputLabel-shrink.Mui-focused": {
            background: "#fff",
        },
        "& .InputFieldSelect .MuiOutlinedInput-input": {
            color: '#004d74 !important'
        }
    },
    view_BackgroundCopy:
    {
        width: deviceBasedDynamicDimension(1100, true, 1),
        padding: 40,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        borderRadius: deviceBasedDynamicDimension(20, true, 1),
        [theme.breakpoints.down('xs')]: {
            width: '92%',
            padding: "40px 12px"
        }
    },
    renewproductDetails:{
      margin: '12px 0px'
    },
    orderDetailsCardContainer: {
        display: "flex",
        gap: "20px",
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column' as const
        },
        "& .priceDetailText": {
            fontWeight: 'bold' as const,
            color: 'rgba(0, 77, 116, 1)',
            textAlign: 'left' as const,
            fontFamily: 'Manrope',
            fontSize: deviceBasedDynamicDimension(20, true, 1),
        }
    },
    priceDetailsContainer: {
        display: 'flex',
        flexDirection: "column" as const,
        gap: "12px",
        margin: "20px auto",
        [theme.breakpoints.down('xs')]: {
            gap: 4
        }
    },
    totalDiscriptionText: {
        color: 'rgba(0, 77, 116, 1)',
        textAlign: 'left' as const,
        fontFamily: 'Manrope',
        fontSize: deviceBasedDynamicDimension(20, true, 1),
    },
    totalDiscriptionAmountText: {
        display: "flex",
        color: 'rgba(5, 23, 32, 1)',
        textAlign: 'right' as const,
        fontFamily: 'Inter',
        fontSize: deviceBasedDynamicDimension(16, true, 1),
    },
    totalAmountText: {
        color: 'rgba(5, 23, 32, 1)',
        textAlign: 'left' as const,
        fontFamily: 'Inter',
        fontSize: deviceBasedDynamicDimension(22, true, 1),
    },
    totalAmountPriceText: {
        display: " flex",
        color: 'rgba(0, 77, 116, 1)',
        textAlign: 'right' as const,
        fontFamily: 'Inter',
        fontWeight: 'bold' as const,
        fontSize: deviceBasedDynamicDimension(22, true, 1),
    },
    orderCardContainer: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '30px',
        maxWidth: "70%",
        width: "68%",
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            maxWidth: '100%'
        }
    },
    cardContainer: {
        display: "flex",
        gap: "30px",
        [theme.breakpoints.down('xs')]: {
            gap: 10
        }
    },
    warrantyPlanimg: {
        width: deviceBasedDynamicDimension(101, true, 1),
        opacity: 1,
        backgroundColor: 'rgba(0, 77, 116, 1)',
        height: "100px",
        display: "flex",
        justifyContent: "center",
        borderRadius: "20px",
        [theme.breakpoints.down('xs')]: {
            width: 60,
            height: 60
        }
    },
    warrantyPlanTextlogo: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 'bold' as const,
        color: 'rgba(255, 255, 255, 1)',
        fontFamily: 'Manrope',
        fontSize: deviceBasedDynamicDimension(30, true, 1),
        [theme.breakpoints.down('xs')]: {
            fontSize: 22
        }
    },
    warrantyPlanCardDiscription: {
        display: "flex",
        flexDirection: "column" as const,
        gap: 10,
        width: '80%',
        [theme.breakpoints.down('xs')]: {
            gap: 2
        }
    },
    warrantyPlancardText: {
        fontWeight: 'bold' as const,
        padding: deviceBasedDynamicDimension(0, true, 1),
        color: 'rgba(0, 77, 116, 1)',
        textAlign: 'left' as const,
        fontFamily: 'Manrope',
        fontSize: deviceBasedDynamicDimension(20, true, 1)
    },
    warrantyPlanInvoiceText: {
        color: 'rgba(137, 145, 164, 1)',
        textAlign: 'left' as const,
        fontFamily: 'Manrope',
        fontWeight: "bold" as const,
        fontSize: deviceBasedDynamicDimension(18, true, 1),
    },
    orderDatailBtnAction: {
        display: "flex",
        justifyContent: "space-between",
        '& .readPlanDetailsContainer': {
            display: 'flex',
            alignItems: "center",
            gap: 12
        },
        '& .readPlanDetailsText': {
            color: 'rgba(0, 77, 116, 1)',
            fontFamily: 'Inter',
            textDecoration: "underline",
            fontSize: deviceBasedDynamicDimension(14, true, 1),
        }
    },
    orderCardTotalContainer: {
        maxWidth: '30%',
        width: "27%",
        [theme.breakpoints.down('xs')]: {
            width: '90%',
            maxWidth: '100%'
        },
        '& .cardTotalMain': {
            width: "100%",
            padding: "12px 18px",
            boxShadow: 'none',
        },
        '& .cardCalculationRow': {
            display: 'flex',
            alignItems: "center",
            justifyContent: "space-between"
        }

    },
    warrantyPlanPriceText: {
        display: "flex",
        fontWeight: 'bold' as const,
        color: 'rgba(0, 132, 67, 1)',
        textAlign: 'right' as const,
        fontFamily: 'Inter',
        fontSize: deviceBasedDynamicDimension(20, true, 1),
    },
    cardContainerMain: {
        boxSizing: 'border-box' as const,
        boxShadow: '0px 1px 10px #EBEBEB',
        padding: "20px",
        width: '100%',
        borderRadius: "13px",
        backgroundColor: '#f2f2f2',
        [theme.breakpoints.down('xs')]: {
            padding: 8
        }
    },
    textlabel_RenewDetailsText: {
        fontWeight: 'bold' as const,
        color: 'rgba(0, 77, 116, 1)',
        fontFamily: 'Manrope',
        fontSize: deviceBasedDynamicDimension(28, true, 1),
        [theme.breakpoints.down('xs')]: {
            fontSize: 22
        }
    },
    modelBtnContainer:{
        display: 'flex',
        justifyContent: 'center',
        padding: 16,
        '& .actionBtnCancelModel': {
            display: 'flex',
            flexDirection: "column" as const,
            gap: 20
        }
    },
    noCancelButton: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        border: "1px solid #004D74",
        width:  deviceBasedDynamicDimension(448, true, 1),
        height: 70,
        borderRadius: deviceBasedDynamicDimension(40, true, 1),
        justifyContent: 'center',
        fontWeight: 'bold' as const,
        textAlign: 'center' as const,
        fontFamily: 'Manrope',
        fontSize: deviceBasedDynamicDimension(20, true, 1),
        textTransform: "capitalize" as const,
        [theme.breakpoints.down('xs')]: {
            width: 250,
            margin: 'auto',
            height: 45
        },
      },
      continueButton: {
        backgroundColor: "#004D74",
        border: "1px solid #004D74",
        width: deviceBasedDynamicDimension(448, true, 1),
        height: 70,
        borderRadius: deviceBasedDynamicDimension(40, true, 1),
        justifyContent: 'center',
        fontWeight: 'bold' as const,
        textAlign: 'center' as const,
        fontFamily: 'Manrope',
        fontSize: deviceBasedDynamicDimension(20, true, 1),
        textTransform: "capitalize" as const,
        color: "#fff",
        [theme.breakpoints.down('xs')]: {
            width: 250,
            height: 45,
            margin: 'auto'
        },
        '&:hover':{
          backgroundColor: '#004D74',
      }
      },
      CurrencySymbol:{
        margin:"0px 4px"
      }

});
export default withStyles(style)(RenewableWarrantyPlan);