import React from "react";
// Customizable Area Start
import LandingPageController, { Props } from "./LandingPageController.web";
import MainCarousel from "../../../components/src/MainCarousel.web";
import { Typography, withStyles, Box, Theme, Hidden } from '@material-ui/core';
import { iDirection, t } from '../../../components/src/i18n';
import InfoCard from "../../../components/src/InfoCard.web"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LandingPageCategorySection from "../../../components/src/LandingPageCategorySection.web";
// added ts-ignore for @tawk.to/tawk-messenger-react as there is no support for typescript as of now ref - https://github.com/tawk/tawk-messenger-react/issues/13
// @ts-ignore
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import ReactPageScroller, { SectionContainer } from "react-page-scroller";
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer.web";
import SearchEngineOptimisationseo from "../../SearchEngineOptimisationseo/src/SearchEngineOptimisationseo";
// Customizable Area End
// Customizable Area Start

// Customizable Area End

export const CarouselStyles = (theme: Theme) => ({
  // Customizable Area Start
  categorySection: {
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
    [theme.breakpoints.down('md')]: {
      margin: "40px 0px",
    },
    "& .slick-track": {
      margin: "4px auto"
    }
  },
  CardContainer: {
    display: "flex",
    flexDirection: 'column' as const,
    padding: "20px",
    paddingTop: "25px",
    height: "max-content",
    borderRadius: '32px',
    boxShadow: "0px 1px 10px #EBEBEB",
    [theme.breakpoints.down('md')]: {
      padding: "12px",
      borderRadius: '16px',
      marginLeft: 0
    }

  },
  cardImage: {
    width: '100%',
    height: '70%',
    objectFit: 'contain' as const,
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
  },
  CardButtonAction: {
    width: "85%",
    fontFamily: "Manrope",
    fontSize: "20px",
    margin: "5px auto",
    borderRadius: "31px",
    backgroundColor: "#fff",
    padding: "10px",
    color: theme.palette.primary.main,
    boxShadow: 'none',
    border: `1px solid ${theme.palette.primary.main}`,
    marginBottom: "10px",
    textTransform: "capitalize" as const,
    "&:hover": {
      boxShadow: 'none',
      backgroundColor: "#fff"
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: "90%",
    },
    [theme.breakpoints.down('md')]: {
      margin: '0 auto',
      padding: 5,
      marginTop: -5
    },
    ["@media (min-width: 1280px) and (max-height: 660px)"]: {
      width: "95%",
    }
  },
  category_name: {
    fontFamily: "Manrope",
    fontSize: "1.8rem",
    color: theme.palette.primary.main,
    textAlign: "center" as const,
    fontWeight: 'bold' as const,
    opacity: "0.9",
    [theme.breakpoints.down('md')]: {
      fontSize: "1.6rem"
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: "1.4rem"
    },
    ["@media (min-width: 1280px) and (max-height: 660px)"]: {
      fontSize: '1.5rem',
    }
  },
  titleName: {
    fontFamily: "Manrope",
    fontWeight: 'bold' as const,
    fontSize: '2rem',
    ["@media (min-width: 1280px) and (max-height: 660px)"]: {
      fontSize: '1.75rem',
    }
  },
  linkText: {
    fontFamily: "Manrope",
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontWeight: 600,
    opacity: 0.8,
    fontSize: 18,
    [theme.breakpoints.down('md')]: {
      fontSize: 16
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    },
    ["@media (min-width: 1280px) and (max-height: 620px)"]: {
      fontSize: 16,
    }
  },

  MainContainer: {
    margin: "auto 0",
    fontFamily: "manrope",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column" as const,
    alignItems: "center"
  },
  title: {
    fontSize: "30px",
    fontWeight: 600,
    textAlign: "center" as const,
  },
  subTitle: {
    fontSize: 14,
    textAlign: "center" as const,
    color: theme.palette.primary.main,
    ["@media (min-width: 1280px) and (max-height: 620px)"]: {
      marginTop: 4,
    }
  },
  CarouselContainer: {
    margin: "24px 0",
    maxWidth: "1280px",
    width: "90%",
    ["@media (min-width: 1280px) and (max-height: 620px)"]: {
      margin: "8px 0px",
      maxWidth: "1152px",
    }
  },
  carousel: {
    "& .slick-slide": {
      height: "auto",
      display: "flex",
      justifyContent: "center",
    },
    "& .slick-track": {
      display: "flex",
      gap: "16px",
    },
    "& svg.slick-prev": {
      color: "#717171",
      border: "2px solid #EBF0F0",
      borderRadius: "50%",
      padding: iDirection === 'rtl' ? "10px" : "10px 5px 10px 15px",
      background: "#fff",
      zIndex: "1",
      left: "-5%"
    },
    "& svg.slick-next": {
      color: "#717171",
      border: "2px solid #EBF0F0",
      borderRadius: "50%",
      padding: iDirection === 'rtl' ? "10px 12px 10px 9px" : "10px 9px 10px 12px",
      background: "#fff",
      zIndex: "1",
      right: "-5%"
    },
  },
  carouselDot: {
    "& li button::before": {
      color: theme.palette.primary.main,
      fontSize: "15px"
    },
    "& li.slick-active button::before": {
      color: theme.palette.primary.main,
      fontSize: "15px"
    }
  },
  infoCarouselSection: {
    display: "flex",
    flexDirection: "column" as const,
    height: "100%",
    justifyContent: "space-between",
    ["@media (min-width: 1280px) and (max-height: 680px) "]: {
      "& .FooterLinkText": {
        fontSize: 18
      },
      "& .ReserveText": {
        fontSize: 14
      },
      "& .SocialIcon": {
        width: 42,
        height: 42
      },
      "& .slick-list": {
        margin: '16px 0px'
      },
      "& svg.slick-prev": {
        left: '-4%'
      },
      "& svg.slick-next": {
        right: '-4%'
      },
      "& .slick-dots": {
        bottom: -32,
        "& li": {
          "& button::before": {
            fontSize: 12
          }
        }
      },
      "& footer": {
        marginTop: 8
      },
    }
  },
  headerBoxLg: {
    height: '20vh',
    ["@media (min-height: 800px)"]: {
      height: '16vh'
    },
    ["@media (max-height: 680px) and (min-height: 620px)"]: {
      height: '22vh'
    }
  },
  normalSectionBox: {
    margin: '24px 4px',
    [theme.breakpoints.down('xs')]: {
      margin: '12px 4px'
    }
  }
  // Customizable Area End
});


class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  bannerSection = () => {
    const { id, navigation } = this.props;
    return (
      <MainCarousel
        carouselBannersData={this.state.carouselBannersData}
        showLoader={this.state.showLoader}
        id={id}
        navigation={navigation}
      />
    )
  }

  categorySection = () => {
    const { classes } = this.props;
    const { isHovered } = this.state;
    const categories = this.getCategories()
    const setting = this.getCarouselSetting(categories.length);
    return (categories && categories.length !== 0 ?
      <LandingPageCategorySection
        categoryArr={categories}
        isHovered={isHovered}
        handleMouseEvent={this.handleMouseEvent}
        classes={classes}
        settings={setting}
      />
      : null)
  }

  infoCarouselSection = () => {
    const { classes } = this.props;
    const { InfoCarousel } = this.state;
    const settings = this.getCarouselSetting(InfoCarousel.length);
    return (
      <Box className={classes.MainContainer}>
        <Typography variant="h2" className={classes.title}>{t("landingPage.WhatMakesUsDifferent")}</Typography>
        <Typography className={classes.subTitle}>{t("landingPage.InfoCarouselSubTitle")}</Typography>
        <Box className={classes.CarouselContainer}>
          <Slider {...settings} dotsClass={`slick-dots ${classes.carouselDot}`} className={classes.carousel}>
            {InfoCarousel && InfoCarousel.length > 0 && InfoCarousel.map((item: any, index: number) => {
              return <Box key={item.id}>
                <InfoCard
                  CarouselData={item.attributes}
                />
              </Box>
            })}
          </Slider>
        </Box>
      </Box>
    )
  }

  // Customizable Area Start

  render() {
    const { id, navigation, classes } = this.props;
    const { tawkToConfig } = this.state;
    const metaDetail = [{
        name: "description",
        content: t('seo.description')
      },
      {
        name: "keywords",
        content: t("seo.keywords")
      }
    ]
    const scrollerHeight = this.getPageScrollerHeight();
    return (
      <>
        {/* Customizable Area Start */}
        <SearchEngineOptimisationseo
          title={t("theWarrantyComany")!}
          metaTags={metaDetail}
          navigation={this.props.navigation} id={this.props.id} />
        <Hidden mdDown>
          <Box className={classes.headerBoxLg}>
            <Header navigation={navigation} id={id} />
          </Box>
          <ReactPageScroller
            pageOnChange={this.handlePageChange}
            customPageNumber={this.state.activePage}
            containerHeight={scrollerHeight}
            containerWidth={"100%"}
          >
            <SectionContainer height={100}>
              {this.bannerSection()}
            </SectionContainer>
            <SectionContainer height={100}>
              {this.categorySection()}
            </SectionContainer>
            <SectionContainer height={100}>
              <Box className={classes.infoCarouselSection}>
                {this.infoCarouselSection()}
                <Footer navigation={navigation} id={id} />
              </Box>
            </SectionContainer>
          </ReactPageScroller>
        </Hidden>
        <Hidden lgUp>
          <Header navigation={navigation} id={id} />
          <Box component={'div'}>
            {this.bannerSection()}
          </Box>
          <Box component={'div'} className={classes.normalSectionBox}>
            {this.categorySection()}
          </Box>
          <Box component={'div'} className={classes.normalSectionBox}>
            {this.infoCarouselSection()}
          </Box>
          <Box component={'div'} className={classes.normalSectionBox}>
            <Footer navigation={navigation} id={id} />
          </Box>
        </Hidden>
        {
          tawkToConfig.propertyId && (
            <TawkMessengerReact
              ref={this.tawkToRef}
              onLoad={this.onTawkToLoad}
              propertyId={tawkToConfig.propertyId}
              widgetId={tawkToConfig.widgetId} />
          )
        }

        {/* Customizable End Start */}
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

export default withStyles(CarouselStyles)(LandingPage);
export { LandingPage }

// Customizable Area End
