import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import React from 'react';
import { WithStyles } from "@material-ui/core";
import {
  callApi,
  comboPasswordValidations,
  getNavigationMessage,
  hideTawkToWidget,
  isValidEmail,
  isValidName,
  isValidPhoneNumber
} from "../../../components/src/Toolkit";
import {
  getStorageData,
  isEmpty,
  removeStorageData,
  setStorageData
} from "../../../framework/src/Utilities";
import { v4 as uuidv4 } from "uuid";
import { iLanguage, t } from "../../../components/src/i18n";
import { toast } from 'react-toastify';
import { MixpanelTracking } from "../../MixpanelIntegration/src/MixpanelIntegration.web";
export const configJSON = require("./config");
type Tab = {
  id: string;
  name: string;
};
export type AddressFormHelper = {
  cityHasError: boolean;
  cityErrorMessage: string;
  countryHasError: boolean;
  countryErrorMessage: string;
  areaHasError: boolean;
  areaErrorMessage: string;
  postalHasError: boolean;
  postalErrorMessage: string;
  stateHasError: boolean;
  stateErrorMessage: string;
};
export type PersonalInformationFormHelper = {
  nameHasError: boolean;
  nameErrorMessage: string;
  emailHasError: boolean;
  emailErrorMessage: string;
  phoneHasError: boolean;
  phoneErrorMessage: string;
};
export type PersonalInformation = {
  id: number;
  fullname: string;
  email_address: string;
  country_code: number | string;
  phone_number: number | string;
  formHelpers: PersonalInformationFormHelper;
};
export type UserAddressInformation = {
  id: number;
  city: string;
  state: string;
  country: string;
  area: string;
  postal_code: number | string;
  formHelpers: AddressFormHelper;
};

export type OrderHistoryItem = {
  id: number | string;
  type: string;
  attributes: {
      id: number | string;
      order_number: string;
      invoice_id: string;
      payment_id: string;
      purchase_date: string;
  };
}

export type Pagination = {
  per_page: undefined | number,
  current_page: undefined | number,
  next_page: undefined | number,
  prev_page: undefined | number,
  total_pages: undefined | number,
  total_count: undefined | number
}

const FLAG_EMAIL_CHANGE = "0";
const FLAG_RESET_PASSWORD = "1";
// Customizable Area End

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  personalInformation: PersonalInformation;
  countriesData: Array<{
    attributes: {
      id: string;
      logo: string;
      country_code: string;
      country_name: string;
    };
  }>;
  addressInformation: UserAddressInformation;
  tabs: Array<Tab>;
  selectedTab: number;
  profileEditMode: {
    personalInfo: boolean;
    address: boolean;
  };
  initialUserEmail:string;
  openVerificationModal:boolean;
  otpResendCount:number;
  token:string;
  email_otp_token:string;
  otpHasError:boolean;
  otpError:string;
  purchaseWarrantiesPlans:any;
  isOtpVerifiedResetPassword:boolean;
  newPassword:string;
  newPasswordVisible:boolean;
  confirmPassword:string;
  confirmPasswordVisible:boolean;
  newPasswordError:string;
  confirmPasswordError:string;
  countryCurrency:string;
  openSuccessModal:boolean;
  isCancelPlanWarranty:boolean;
  cancelWarrantyPlanData:{
    total_price: string;
    details: {
      item_name:string;
      warranty_name:string;
    }
  };
  cancelReconfirmation: boolean;
  cancellationSubmitted: boolean;
  renewableSubmitted: boolean;
  orderHistoryData:OrderHistoryItem[],
  orderHistoryPagination:Pagination,
  searchQueryOH:string,
  searchEnabled:boolean;
  warrantyPlanPagination:Pagination,
  cancellationReason: string;
  additional_remarks: string;
  cancellationPlanId: string;
  errorCancellationReason: string;
  isRenewablePlans: boolean;
  renewablePlanDetails: any;
  renewWarrantyPlanPriceDetails: any;
  searchQueryWP:string;
  searchEnabledWp: boolean;
  renewablePlanId: string;
  currency:string;
  gateway_type: string;
  isPaymentFailed: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class HowItWorksController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetUserProfile: string = "";
  apiGetCountries: string = "";
  apiUpdateUserPersonalInformation: string = "";
  apiUpdateUserAddress: string = "";
  apiSendEmailVerificationOtp: string = "";
  apiReSendEmailVerificationOtp: string = "";
  apiVerifyEmailVerificationOtp: string = "";
  allPurchaseWarrantiesApiCallId: string = "";
  apiGetOrderHistory: string = "";
  apiGetInvoiceUrl:string = "";
  dowloadWarrantiesCertification: string = "";
  cancellationWarrantiesPlanAPICalId: string = "";
  apiResetPassword: string = "";
  apiRenewWarrantyPlan: string = "";
  orderRenewPaymentApiCallId: string = "";
  orderRenewStatusApiCallId: string = "";
  otpVerificationFor:string = "";
  listInnerRef:React.RefObject<any>;
  defaultPersonalInformation: PersonalInformation = {
    id: 1,
    country_code: "",
    email_address: "",
    fullname: "",
    phone_number: "",
    formHelpers: {
      nameHasError: false,
      nameErrorMessage: "",
      emailHasError: false,
      emailErrorMessage: "",
      phoneHasError: false,
      phoneErrorMessage: ""
    }
  };
  defaultAddress: UserAddressInformation = {
    id: 1,
    area: "",
    city: "",
    country: "",
    postal_code: "",
    state: "",
    formHelpers: {
      cityHasError: false,
      cityErrorMessage: "",
      areaHasError: false,
      areaErrorMessage: "",
      countryHasError: false,
      countryErrorMessage: "",
      postalHasError: false,
      postalErrorMessage: "",
      stateHasError: false,
      stateErrorMessage: ""
    }
  };
  lastUpdatedPersonalInformation: PersonalInformation = this.defaultPersonalInformation;
  lastUpdatedAddress: UserAddressInformation = this.defaultAddress;
  defaultPagination:Pagination={
    per_page: 10,
    current_page: 1,
    next_page: undefined,
    prev_page: undefined,
    total_pages: undefined,
    total_count: undefined
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      isLoading: false,
      personalInformation: this.defaultPersonalInformation,
      countriesData: [],
      addressInformation: this.defaultAddress,
      profileEditMode: {
        personalInfo: false,
        address: false
      },
      tabs: [
        {
          id: uuidv4(),
          name: t("userProfile.profile")
        },
        {
          id: uuidv4(),
          name: t("userProfile.warranties")
        },
        {
          id: uuidv4(),
          name: t("userProfile.orderHistory")
        },
        {
          id: uuidv4(),
          name: t("userProfile.security")
        }
      ],
      selectedTab: 0,
      initialUserEmail:'',
      openVerificationModal:false,
      otpResendCount:0,
      token:'',
      email_otp_token:'',
      otpHasError:false,
      otpError:'',
      purchaseWarrantiesPlans:[],
      isOtpVerifiedResetPassword:false,
      newPassword:'',
      newPasswordVisible:false,
      confirmPassword:'',
      confirmPasswordVisible:false,
      newPasswordError:'',
      confirmPasswordError:'',
      countryCurrency: "$",
      currency: "USD",
      gateway_type: "",
      openSuccessModal:false,
      isCancelPlanWarranty: false,
      cancelWarrantyPlanData: {
        total_price: '',
    details: {
      item_name:'',
      warranty_name:''
    }
      },
      cancelReconfirmation:false,
      cancellationSubmitted: false,
      renewableSubmitted: false,
      orderHistoryData:[],
      orderHistoryPagination:this.defaultPagination,
      searchQueryOH:'',
      warrantyPlanPagination:this.defaultPagination,
      cancellationReason: '',
      additional_remarks: "",
      cancellationPlanId: "",
      errorCancellationReason: '',
      isRenewablePlans: false,
      renewablePlanDetails: {},
      renewWarrantyPlanPriceDetails: {},
      searchQueryWP: '',
     searchEnabledWp: false,
      searchEnabled:false,
      renewablePlanId: "",
      isPaymentFailed: false
    };
  this.listInnerRef  = React.createRef();

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const token = await getStorageData("token");
    const selectedCountry = await getStorageData('selectedCountry', true);
    
    if (!token) {
      this.send(getNavigationMessage("Login", this.props));
      return;
    }
    this.setState({
      token,
      countryCurrency: selectedCountry.attributes.currency_symbol,
      currency: selectedCountry.attributes.currency,
      gateway_type: selectedCountry.attributes.gateway_type
    }, () => {
      this.getUserProfile();
      this.getCountriesData();
    })
    hideTawkToWidget();
    if(window.location.pathname.includes("renewpaymentConfirm")){
      this.setState({
        isPaymentFailed: false
      });
      this.handleRenewOrderStatus()
    }
    if(window.location.pathname.includes("renewpaymentfailed")){
      this.setState({
        isPaymentFailed: true
      })
      this.handleRenewOrderStatus()
    }
    
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    const apiMessageId = getName(MessageEnum.RestAPIResponceMessage);
    if ( apiMessageId === message.id) {
      if (responseJson != null) {
        if (!responseJson.errors) {
          switch(apiRequestCallId){
            case this.apiGetUserProfile:
              this.setState({
                personalInformation: {
                  ...this.defaultPersonalInformation,
                  ...responseJson.personal_information
                },
                addressInformation: {
                  ...this.defaultAddress,
                  ...responseJson.address
                },
                initialUserEmail:responseJson.personal_information.email_address
              }, () => {
                this.lastUpdatedPersonalInformation = this.state.personalInformation;
                this.lastUpdatedAddress = this.state.addressInformation;
              });
              this.setLoadingState(false);
              break;
            case this.apiGetCountries: 
                this.handleGetCountriesData(responseJson)
              break;
            case this.apiUpdateUserPersonalInformation:
              this.setState(
                {
                  personalInformation: {
                    ...this.defaultPersonalInformation,
                    ...responseJson.personal_information
                  },
                  initialUserEmail:responseJson.personal_information.email_address
                },
                () => {
                  this.lastUpdatedPersonalInformation = this.state.personalInformation;
                  this.toggleEditModePI(false);
                  this.setLoadingState(false);
                  this.updateLocalUserData();
                  toast.success(responseJson.message);
                }
              );
              break;
            case this.apiUpdateUserAddress:
              this.setState(
                {
                  addressInformation: {
                    ...this.defaultAddress,
                    ...responseJson.address
                  }
                },
                () => {
                  this.lastUpdatedAddress = this.state.addressInformation;
                  this.toggleEditModeAddress(false);
                  this.setLoadingState(false);
                  toast.success(responseJson.message);
                }
              );
              break;
            case this.apiSendEmailVerificationOtp:
              this.setState({
                  email_otp_token: responseJson.email_otp_token
              }, () => {
                  this.setState({
                      openVerificationModal: true,
                      otpHasError: false,
                      otpError: '',
                  });
                  toast.success(responseJson.message);
              })
              this.setLoadingState(false);
              break;
            case this.apiReSendEmailVerificationOtp:
              this.setState(prevState => ({
                  email_otp_token: responseJson.meta.email_otp_token,
                  otpResendCount:prevState.otpResendCount + 1
              }))
              toast.success(responseJson.meta.message);
              this.setLoadingState(false);
              break;
            case this.apiVerifyEmailVerificationOtp:
              this.handleVerifyOtp(responseJson)
              break;
            case this.apiResetPassword:
              this.handleResetPasswordSuccess();
              break;
            case this.allPurchaseWarrantiesApiCallId:
              this.handleAllPurchaseWarrantySuccess(responseJson);
              break;
            case this.apiGetOrderHistory:
              this.handleOrderHistoryApiSuccess(responseJson);
              break;
            case this.apiGetInvoiceUrl:
              this.handleDownloadInvoiceSuccess(responseJson);
              break;
            case this.dowloadWarrantiesCertification:
                this.handleDownloadWarrantiesCertification(responseJson)
              break;
            case this.cancellationWarrantiesPlanAPICalId:
              this.handleCancellationWarrantiesPlan(responseJson)
              break;
            case this.apiRenewWarrantyPlan:
              this.handleRenewWarrantyPlanOrder(responseJson);
              break;
            case this.orderRenewPaymentApiCallId:
             this.handlePaymentResponse(responseJson)
              break;
            case this.orderRenewStatusApiCallId:
              this.handleRenewStatusApiResponse(responseJson)
              break;
          }
        } else {
          this.parseErrors(apiRequestCallId, responseJson);
          this.setLoadingState(false);
        }
      }
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  updateLocalUserData = async () => {
    const savedUserData = await getStorageData('user', true);
    savedUserData.attributes.full_name = this.lastUpdatedPersonalInformation.fullname;
    savedUserData.attributes.email = this.lastUpdatedPersonalInformation.email_address;
    savedUserData.attributes.phone_number = this.lastUpdatedPersonalInformation.phone_number;
    setStorageData('user', JSON.stringify(savedUserData));
  }
  handleVerifyOtp = (responseJson:any) => {
    this.setState({
        email_otp_token: '',
        otpResendCount:0,
        openVerificationModal:false
    });
    if(this.otpVerificationFor === FLAG_EMAIL_CHANGE){
      this.performPersonalInfoUpdate();
    }else if(this.otpVerificationFor === FLAG_RESET_PASSWORD){
      this.setState({
        isOtpVerifiedResetPassword:true
      }, () => {
        this.setLoadingState(false)
        toast.success(responseJson.message)
      })
    }
    this.otpVerificationFor = "";
  }
  parseErrors = async (apiRequestCallId: string, responseJson: any) => {
    if (Object.keys(responseJson.errors[0])[0] === "token") {
      await removeStorageData("token");
      await removeStorageData("user");
      this.send(getNavigationMessage("Login", this.props));
      return;
    }
    if (apiRequestCallId == this.apiUpdateUserPersonalInformation || apiRequestCallId == this.apiSendEmailVerificationOtp) {
      this.handleUpdatePersonalInformationError(responseJson);
    } else if(apiRequestCallId == this.apiVerifyEmailVerificationOtp || apiRequestCallId == this.apiReSendEmailVerificationOtp){
      this.handleOtpErrors(responseJson)
    }else{
      this.parseApiErrorResponse(responseJson);
    }
  };
  handleOtpErrors = (responseJson: any) => {
    let errors = responseJson.errors;
    let values: any = Object.values(errors[0]);
    this.handleOtpError(true, values[0]);
  }
  handleUpdatePersonalInformationError = (responseJson: any) => {
    let errors = responseJson.errors;
    let keys: any = Object.keys(errors[0]);
    let values: any = Object.values(errors[0]);

    this.setState(prevState => ({
      personalInformation: {
        ...prevState.personalInformation,
        formHelpers: {
          emailHasError: keys[0] === "email",
          emailErrorMessage: keys[0] === "email" ? values[0] : "",
          nameHasError: keys[0] === "full_name",
          nameErrorMessage: keys[0] === "full_name" ? values[0] : "",
          phoneHasError: keys[0] === "full_phone_number",
          phoneErrorMessage: keys[0] === "full_phone_number" ? values[0] : ""
        }
      }
    }));
  };
  setLoadingState = (loadingState: boolean) => {
    this.setState({
      isLoading: loadingState
    });
  };
  getUserProfile = () => {
    this.setLoadingState(true);
    const { token } = this.state;
    this.apiGetUserProfile = callApi(
      {
        contentType: configJSON.contentTypeApiGetUserProfile,
        method: configJSON.methodTypeApiGetUserProfile,
        endPoint: `${configJSON.getUserProfileApiEndPoint}`,
        headers: {
          token
        }
      },
      runEngine
    );
  };

  getCountriesData = () => {
    this.setState({ isLoading: true });
    this.apiGetCountries = callApi(
      {
        contentType: configJSON.contentTypeApiGetUserProfile,
        method: configJSON.methodTypeApiGetUserProfile,
        endPoint: configJSON.getCountriesListEndPoint,
        headers: {},
      },
      runEngine
    );
  };

  handleGetCountriesData = async(responseJson: any) => {
    if (!responseJson.errors) {
      const selectedCountry = await getStorageData('selectedCountry', true);
      const countriesList = responseJson?.data || [];
      const currentCountry = responseJson?.data.find((x:any) => x.id === selectedCountry.id);
      this.setState({
        countriesData: countriesList,
        gateway_type: currentCountry?.attributes.gateway_type || ""
      });
    }
  }

  getAllPurchaseWarranties = () => {
    this.setLoadingState(true);
    const {token, warrantyPlanPagination, searchQueryWP} = this.state;
    this.allPurchaseWarrantiesApiCallId = callApi({
      contentType: configJSON.contentTypeApiGetUserProfile,
      method: 'GET',
      endPoint: configJSON.allPurchaseOrderitemsEndPoint + `?page=${warrantyPlanPagination.current_page}&per_page=${warrantyPlanPagination.per_page}&value=${searchQueryWP.trim()}&language_code=${iLanguage}`,
      headers: {
        token
      }
    },runEngine)
  }

  handleSearchPlan = (query:any) => {
    this.setState({
      warrantyPlanPagination:this.defaultPagination,
      searchQueryWP:query
    }, () => {
      this.getAllPurchaseWarranties()
    }
    )
  }
  handleTabClick = (tabIndex: number = 0) => {
    removeStorageData('renewConfirm')
    const {selectedTab} = this.state;
    const selectNewTab = () => {
      this.setState({
        selectedTab: tabIndex
      }, () => {
        if(tabIndex === 1) {
          this.setState({purchaseWarrantiesPlans: [], warrantyPlanPagination: this.defaultPagination,
        searchQueryWP: ""},() => {
          this.handleCheckStatus()
          this.getAllPurchaseWarranties()
        });
        }else if(tabIndex === 2){
          this.setState({
            orderHistoryData:[],
            orderHistoryPagination:this.defaultPagination,
            searchQueryOH:'',
            searchEnabled:false
          }, () => {
            this.getOrderHistory();
          })
        }
      });
    }
    if(selectedTab === 0){
      this.setState({
        profileEditMode:{
          address:false,
          personalInfo:false
        },
        personalInformation:this.lastUpdatedPersonalInformation,
        addressInformation: this.lastUpdatedAddress
      }, () => {
        selectNewTab();
      })
    }else{
      selectNewTab()
    }
    
  };
  handleLogout = () => {
    (async () => {
      await removeStorageData("token");
      await removeStorageData("user");
      this.send(getNavigationMessage("Home", this.props));
    })();
  };
  toggleEditModePI = (toggle: boolean) => {
    this.setState(prevState => ({
      profileEditMode: {
        ...prevState.profileEditMode,
        personalInfo: toggle
      }
    }));
  };
  toggleEditModeAddress = (toggle: boolean) => {
    this.setState(prevState => ({
      profileEditMode: {
        ...prevState.profileEditMode,
        address: toggle
      }
    }));
  };

  validatePersonalInformation = (): boolean => {
    const { personalInformation } = this.state;
    // validations
    const emailValidation = isValidEmail(personalInformation.email_address);
    const phoneValidation = isValidPhoneNumber(
      personalInformation.phone_number.toString()
    );
    const nameValidation = isValidName(personalInformation.fullname);
    this.setState(prevState => ({
      personalInformation: {
        ...prevState.personalInformation,
        formHelpers: {
          emailHasError: emailValidation.emailError,
          emailErrorMessage: emailValidation.emailErrorText,
          nameHasError: nameValidation.nameError,
          nameErrorMessage: nameValidation.nameErrorText,
          phoneHasError: phoneValidation.phoneNumberError,
          phoneErrorMessage: phoneValidation.phoneNumberErrorText
        }
      }
    }));
    if (
      emailValidation.emailError ||
      phoneValidation.phoneNumberError ||
      nameValidation.nameError
    ) {
      return false;
    }

    return true;
  };
  onPersonalInformationChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      personalInformation: {
        ...prevState.personalInformation,
        [name]: value
      }
    }));
  };
  isUserEmailChanged = ():boolean => {
    const { personalInformation, initialUserEmail} = this.state;
    if(initialUserEmail.trim() !== personalInformation.email_address.trim()){
      return true;
    }
    return false;
  }
  performEmailVerification = (email:string) => {
    const { token } = this.state;
    const httpBody = {
        language_code:iLanguage,
        account: {
            email
        }
    }
    this.apiSendEmailVerificationOtp = callApi({
        contentType: configJSON.contentTypeApiUpdateUser,
        method: configJSON.apiPostType,
        endPoint: configJSON.sendEmailOtpEndPoint,
        headers: {
          token
        },
        body: JSON.stringify(httpBody)
    }, runEngine)
  }
  resendEmailVerificationOtp = () => {
    const { email_otp_token, token } = this.state;
    const httpBody: any = {
      language_code:iLanguage,
      data: { email_otp_token: email_otp_token }
    }
    this.setLoadingState(true);
    this.apiReSendEmailVerificationOtp = callApi({
        contentType: configJSON.contentTypeApiUpdateUser,
        method: configJSON.apiPostType,
        endPoint: configJSON.resendEmailOtpEndPoint,
        headers: {
          token
        },
        body: JSON.stringify(httpBody)
    }, runEngine);
  }
  verifyEmailVerificationOtp = (otp: string) => {
      const { email_otp_token, token } = this.state;
      const httpBody: any = {
          language_code:iLanguage,
          data: { otp: otp, email_otp_token: email_otp_token }
      }
      this.setLoadingState(true);
      this.apiVerifyEmailVerificationOtp = callApi({
          contentType: configJSON.contentTypeApiUpdateUser,
          method: configJSON.apiPostType,
          endPoint: configJSON.verifyEmailOtpEndPoint,
          headers: {
            token
          },
          body: JSON.stringify(httpBody)
      }, runEngine);
  }
  handleOtpError = (hasError:boolean, errorText:string) => {
    this.setState({
      otpHasError:hasError,
      otpError:errorText
    })
  }
  performPersonalInfoUpdate = () => {
    const { personalInformation, token } = this.state;
    const httpBody = {
      language_code: iLanguage,
      full_name: personalInformation.fullname,
      email: personalInformation.email_address,
      full_phone_number: `+${personalInformation.country_code}-${
        personalInformation.phone_number
      }`
    };

    this.apiUpdateUserPersonalInformation = callApi(
      {
        contentType: configJSON.contentTypeApiUpdateUser,
        method: configJSON.apiUpdateUserType,
        endPoint: configJSON.updateUserPersonalInformationEndPoint,
        headers: {
          token
        },
        body: JSON.stringify(httpBody)
      },
      runEngine
    );
  }
  updatePersonalInformation = () => {
    if (!this.validatePersonalInformation()) {
      return;
    }

    this.setLoadingState(true);
    if(this.isUserEmailChanged()){
      this.otpVerificationFor = FLAG_EMAIL_CHANGE;
      this.performEmailVerification(this.state.personalInformation.email_address);
    }else{
      this.performPersonalInfoUpdate()
    }
    
  };
  onUserAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      addressInformation: {
        ...prevState.addressInformation,
        [name]: value
      }
    }));
  };
  handleCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(prevState => ({
      addressInformation: {
        ...prevState.addressInformation,
        country: event.target.value
      }
    }));
  };
  validateAddress = (): boolean => {
    const { addressInformation } = this.state;
    let isAreaEmpty = isEmpty(addressInformation.area);
    let isCityEmpty = isEmpty(addressInformation.city);
    let isStateEmpty = isEmpty(addressInformation.state);
    let isCountryEmpty = isEmpty(addressInformation.country);
    let isPostalEmpty = isEmpty(addressInformation.postal_code);

    this.setState(prevState => ({
      addressInformation: {
        ...prevState.addressInformation,
        formHelpers: {
          areaHasError: isAreaEmpty,
          areaErrorMessage: isAreaEmpty
            ? t("common.validations.areaRequired")
            : "",
          cityHasError: isCityEmpty,
          cityErrorMessage: isCityEmpty
            ? t("common.validations.cityRequired")
            : "",
          stateHasError: isStateEmpty,
          stateErrorMessage: isStateEmpty
            ? t("common.validations.stateRequired")
            : "",
          countryHasError: isCountryEmpty,
          countryErrorMessage: isCountryEmpty
            ? t("common.validations.countryRequired")
            : "",
          postalHasError: isPostalEmpty,
          postalErrorMessage: isPostalEmpty
            ? t("common.validations.postalCodeRequired")
            : ""
        }
      }
    }));
    if (
      isAreaEmpty ||
      isCityEmpty ||
      isStateEmpty ||
      isCountryEmpty ||
      isPostalEmpty
    ) {
      return false;
    }

    return true;
  };
  updateUserAddress = () => {
    if (!this.validateAddress()) {
      return;
    }
    this.setLoadingState(true);
    const { addressInformation, token } = this.state;
    const httpBody = {
      language_code: iLanguage,
      area: addressInformation.area,
      city: addressInformation.city,
      state: addressInformation.state,
      postal_code: addressInformation.postal_code,
      country: addressInformation.country
    };

    this.apiUpdateUserAddress = callApi(
      {
        contentType: configJSON.contentTypeApiUpdateUser,
        method: configJSON.apiUpdateUserAddressType,
        endPoint: configJSON.updateUserAddressEndPoint,
        headers: {
          token
        },
        body: JSON.stringify(httpBody)
      },
      runEngine
    );
  };
  // Security Tab Start
  handleSecurityAction = () => {
    const { isOtpVerifiedResetPassword, initialUserEmail } = this.state;
    if(!isOtpVerifiedResetPassword){
      this.otpVerificationFor = FLAG_RESET_PASSWORD;
      this.setLoadingState(true)
      this.performEmailVerification(initialUserEmail);
    }else{
      this.performResetPassword()
    }
  }
  performResetPassword = () => {
    const { newPassword, confirmPassword, token} = this.state;
    const {passwordEqual, allConditionSatified} = comboPasswordValidations(newPassword, confirmPassword);
    if((passwordEqual) && (allConditionSatified)){
      this.setLoadingState(true);
      const payload = {
          language_code:iLanguage,
          account: {
              "password": newPassword,
              "password_confirmation": confirmPassword,
          }
      }
      this.apiResetPassword = callApi({
          contentType: configJSON.contentTypeApiUpdateUser,
          method: configJSON.apiUpdateUserAddressType,
          endPoint: configJSON.resetPasswordEndPoint,
          headers: {
            token
          },
          body: JSON.stringify(payload)
      }, runEngine)
    }else {
      this.setState({
        newPasswordError:newPassword.trim().length === 0 ? t("common.validations.passwordRequired") : '',
        confirmPasswordError:confirmPassword.trim().length === 0 ? t("common.validations.passwordRequired") : '',
      })
    }
  }
  handleNewPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      newPassword:value,
      newPasswordError:value.trim().length === 0 ? t("common.validations.passwordRequired") : ''
    })
  }
  toggleNewPasswordVisibility = () => {
    this.setState(prevState => ({ newPasswordVisible: !prevState.newPasswordVisible }));
  }
  handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({
      confirmPassword:value,
      confirmPasswordError:value.trim().length === 0 ? t("common.validations.passwordRequired") : '',
    })
  }
  toggleConfirmPasswordVisibility = () => {
    this.setState(prevState => ({ confirmPasswordVisible: !prevState.confirmPasswordVisible }));
  }
  handleResetPasswordSuccess = () => {
    this.setState({
      openSuccessModal:true
    },() => {
      this.setLoadingState(false);
    })
  }
  handleAllPurchaseWarrantySuccess = (responseJson:any) => {
    const {searchQueryWP} = this.state;
    this.setState({
      purchaseWarrantiesPlans:responseJson?.data,
      warrantyPlanPagination: responseJson?.meta?.pagination,
      searchEnabledWp: searchQueryWP.trim().length > 0
    },() => {
      this.setLoadingState(false)
    });
    MixpanelTracking.getInstance().eventTrack(configJSON.purchasedWarrantyPlan, responseJson?.data)
  }
  
  handleCancellationWarrantiesPlan = (responseJson:any) => {
    if(!responseJson.errors){
    this.setState({
      cancellationPlanId: responseJson.order_reference_no,
      cancellationSubmitted: true,
      isCancelPlanWarranty: false,
      cancelReconfirmation: false,
      cancellationReason: "", 
      additional_remarks: "",
      errorCancellationReason: ""
    })
    this.setLoadingState(false);
  }
  }
  
  conditionalRendering = (condition:boolean,value1:any, value2: any) => {
    return condition ? value1 : value2
  }

  handleDownloadWarrantiesCertification = (responseJson:any) => {
    if(!responseJson.errors){
     this.setLoadingState(false);
      window.open(responseJson.certificate_url, "_blank");
    }
  }
  gotoHome = () => {
    this.send(getNavigationMessage('Home', this.props));
  }

  handleCancleWarrantyPlan = (plan:any) => {
    this.setState({isCancelPlanWarranty: true, cancelWarrantyPlanData: plan.attributes})
  }
  
  handleRenewableWarrantyPlan = (plan:any) => {
    this.setLoadingState(true);
    const {token} = this.state;
    const payload = {
          "id": plan.id,
          "warranty_end": plan.attributes.status_and_dates.warranty_ends,
    }
    const payloadCombo = {
      "id": plan.id,
      "warranty_end": plan.attributes.status_and_dates?.accidental?.warranty_ends,
      "extendend_warranty_expiry":  plan.attributes.status_and_dates?.extended?.warranty_ends
    }
    this.apiRenewWarrantyPlan = callApi({
      contentType: configJSON.contentTypeApiUpdateUser,
      method: 'POST',
      endPoint: configJSON.renewWarrantyPlan,
      headers: {
        token
      },
      body: JSON.stringify(plan.attributes.details.warranty_type === "Combo" ? payloadCombo : payload)
  }, runEngine)
  }

  handleRenewWarrantyPlanOrder = (responseJson: any) => {
    if(responseJson.message === "Warranty renewal limit reached.") {
      toast.error(responseJson.message)
    }else{
      this.setState({ isRenewablePlans: true ,renewWarrantyPlanPriceDetails: responseJson.data.order.data.attributes, renewablePlanDetails: responseJson.data.order.data.attributes.order_items[0].attributes},() => 
      this.setLoadingState(false))
    }
    this.setLoadingState(false)
  }

  handlePaymentResponse = (responseJson:any) => {
    if(!responseJson.error) {
      setStorageData('order_id', this.state.renewWarrantyPlanPriceDetails.id)
      setStorageData('renewConfirm', true);
      setStorageData('renewOrderDetails', JSON.stringify(this.state.renewWarrantyPlanPriceDetails))
      window.location.href = responseJson.payment_link;
    }else{
      toast.error(responseJson.error);
      this.setState({isLoading: false})
      this.send(getNavigationMessage('UserProfile', this.props));
    }
  }

  handleGoBackButton = () => {
    this.setState({ isRenewablePlans: false})
  }
  
  handleCancelNoplan = () => {
    this.setState({isCancelPlanWarranty: false,cancelReconfirmation: false,cancellationReason: "", additional_remarks: "",errorCancellationReason: ""})
  }

  handleCanclePopupContinueButton = () => {
    if(this.state.cancellationReason !== '') {
      this.setState({isCancelPlanWarranty: false, cancelReconfirmation: true})
    }else{
      this.setState({
        errorCancellationReason: t("cancelWarrantyplan.cancellationErrorMsg")
      })
    }
  }
  
  handleCancellationReason = (event:any) => {
  this.setState({
    cancellationReason: event.target.value
  }, () => this.setState({errorCancellationReason: ""}))
  }
  handleAdditionalRemark = (event:any) => {
    this.setState({
      additional_remarks: event.target.value
    })
  }

  handleCanclePopupConfirmButton = (id:any) => {
    this.setLoadingState(true);
    const {token, cancellationReason, additional_remarks} = this.state;
    MixpanelTracking.getInstance().eventTrack(configJSON.mixpanelCancelPlanEvent,{
      "cancellation_plan" : this.state.cancelWarrantyPlanData,
      "reason": cancellationReason,
     "additional_remarks": additional_remarks
    })
   const httpBody = {
     "id": id,
     "reason": cancellationReason,
     "additional_remarks": additional_remarks
  }
    this.cancellationWarrantiesPlanAPICalId = callApi({
      contentType: 'application/json',
      method: 'POST',
      endPoint: configJSON.cancelWarrantiesPlan,
      body: JSON.stringify(httpBody),
      headers: {
        token
      }
    },runEngine)
    
  }

  renewPaymentGetwayHandle = () => {
    if(this.state.gateway_type === "my_fatoorah") {
      this.handleRenewMyFaturaahPayment()
    } else {
      this.handleRenewPopupConfirmButton()
    }
  }
  
  handleRenewPopupConfirmButton = () => {
    const {renewWarrantyPlanPriceDetails,currency,token} = this.state
    const httpBody = {
      "order_id": renewWarrantyPlanPriceDetails.id,
     "return_url": window.location.origin + "/renewpaymentConfirm",
     "amount": renewWarrantyPlanPriceDetails.total,
     "currency": currency
   }
   MixpanelTracking.getInstance().eventTrack(configJSON.mixpanelRenewPlanEvent,{
    "renew_plan" : this.state.renewablePlanDetails,
  });

 this.orderRenewPaymentApiCallId = callApi({
   contentType: configJSON.contentTypeApiGetUserProfile,
   method: configJSON.apiPostType,
   endPoint: configJSON.paymentEndPoint,
   headers: {token: token},
   body: JSON.stringify(httpBody)
 },runEngine)
  }
  
  handleRenewMyFaturaahPayment = async() => {
      const {renewWarrantyPlanPriceDetails,currency,token} = this.state
      const httpBody = {
        "order_id": renewWarrantyPlanPriceDetails.id,
        "callback_url": window.location.origin + "/renewpaymentConfirm",
        "error_url": window.location.origin + "/renewpaymentfailed",
       "amount": renewWarrantyPlanPriceDetails.total,
       "currency": currency
     }
     MixpanelTracking.getInstance().eventTrack(configJSON.mixpanelRenewPlanEvent,{
      "renew_plan" : this.state.renewablePlanDetails,
    });
    this.orderRenewPaymentApiCallId = callApi({
      contentType: configJSON.contentTypeApiGetUserProfile,
      method: configJSON.apiPostType,
      endPoint: configJSON.myFatoraahPaymentEndPoint,
      headers: {token: token},
      body: JSON.stringify(httpBody)
    },runEngine)
}
 
  handleCheckStatus = () => {
    removeStorageData('renewConfirm')
    this.setState({
      renewableSubmitted: false,
      cancellationSubmitted: false,
      isCancelPlanWarranty: false,
      cancelReconfirmation: false,
      purchaseWarrantiesPlans: []
    })
    this.getAllPurchaseWarranties()
  }

  handleBuyNewButton = () => {
    this.send(getNavigationMessage("Home", this.props));
  }

  handleDownloadWarrantiesCertificate = (id:any) => {
   this.setLoadingState(true);
   const {token} = this.state;
   this.dowloadWarrantiesCertification = callApi({
     contentType: 'application/pdf',
     method: 'GET',
     endPoint: configJSON.warrantyCertification + `/${id}/fetch_warranty_certificate?language_code=${iLanguage}`,
     headers: {
       token
     }
   },runEngine)
  }

  handleOrderStatus = async() => {
    const order_id = await getStorageData('order_id',true)
    const renewOrderDetails = await getStorageData('renewOrderDetails', true)
    const token = await getStorageData('token');
    this.setLoadingState(true);
    MixpanelTracking.getInstance().eventTrack(configJSON.mixpanelRenewPlanStausEvent,{
      "renewed_plan" : renewOrderDetails,
    })
    this.orderRenewStatusApiCallId = callApi({
      contentType: configJSON.ordersApiContentType,
      method: configJSON.methodTypeApiGetUserProfile,
      endPoint: configJSON.orderStatusEndPoint + `?order_id=${order_id}`,
      headers: {token:token},
    },runEngine)
  }

  handleRenewStatusApiResponse = (responseJson:any) => {
    if(!responseJson.errors){
      this.setState({selectedTab:1, cancellationSubmitted:true, renewableSubmitted:true,renewablePlanId: responseJson.payment_id})
      removeStorageData('renewConfirm')
      removeStorageData('renewOrderDetails')
      this.setLoadingState(false);
    }
  }

  handleWarrantyPlanPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    this.setState(prevState => ({
      warrantyPlanPagination:{
        ...prevState.warrantyPlanPagination,
        current_page:page
      }
    }), () => {
      this.getAllPurchaseWarranties()
    })
  }
  // Security Tab End
  // Order History Tab Start
  getOrderHistory = () => {
    this.setLoadingState(true)
    const {token, orderHistoryPagination, searchQueryOH} = this.state;
    this.apiGetOrderHistory = callApi({
      contentType: "application/json",
      method: 'GET',
      endPoint: configJSON.getOrderItemsEndPoint + `?page=${orderHistoryPagination.current_page}&per_page=${orderHistoryPagination.per_page}&language_code=${iLanguage}&value=${searchQueryOH.trim()}`,
      headers: {
        token
      }
    },runEngine)
  }
  handleOrderHistoryApiSuccess = (responseJson:any) => {
    const { searchQueryOH } = this.state
    this.setState({
      orderHistoryData:responseJson?.data,
      orderHistoryPagination: responseJson?.meta?.pagination,
      searchEnabled:searchQueryOH.trim().length > 0
    }, () => {
      this.setLoadingState(false)
    })
  }
  downloadInvoice = (orderId:number | string) => {
    this.setLoadingState(true)
    const {token} = this.state;
    this.apiGetInvoiceUrl = callApi({
      contentType: "application/json",
      method: 'GET',
      endPoint: configJSON.getOrderItemsEndPoint + `/${orderId}/fetch_invoice?language_code=${iLanguage}`,
      headers: {
        token
      }
    },runEngine)
  }
  handleDownloadInvoiceSuccess = (responseJson:any) => {
    this.setLoadingState(false);
    window.open(responseJson.invoice_url, "_blank");
  }
  handleOrderHistoryPageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    this.setState(prevState => ({
      orderHistoryPagination:{
        ...prevState.orderHistoryPagination,
        current_page:page
      }
    }), () => {
      this.getOrderHistory()
    })
  }
  handleOrderHistorySearch = (query:string) => {
    this.setState({
      orderHistoryPagination:this.defaultPagination,
      searchQueryOH:query
    }, () => {
      this.getOrderHistory()
    })
  }

  handleRenewOrderStatus = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const paymentId = searchParams.get('paymentId');
    this.setState({
      selectedTab:1, 
      cancellationSubmitted:true, 
      renewableSubmitted:true, 
      renewablePlanId : paymentId ?? ""
    });
    if(!paymentId) {
      this.handleOrderStatus();
    }
    const newURL = window.location.origin + "/UserProfile";
    window.history.replaceState({}, document.title, newURL);
  }
  // Order History Tab End
  // Customizable Area End
}
