import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { iLanguage } from "../../../components/src/i18n";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

// Customizable Area Start

import { callApi, getNavigationMessage } from "../../../components/src/Toolkit";
import {
  getStorageData,
  setStorageData,
} from "../../../framework/src/Utilities";
import { MixpanelTracking } from "../../MixpanelIntegration/src/MixpanelIntegration.web";
export interface ShoppingCartOrderItem {
  id: string | number;
  type?: string;
  attributes: {
    price: number;
    quantity: number;
    taxable: boolean;
    taxable_value: number;
    other_charges?: number;
    catalogue: {
      data?: object & {
        attributes?: object & {
          name?: string;
        };
      };
    };
  };
}

export interface ShoppingCartOrderCustomer {
  id: string | number;
  type: string;
  attributes: {
    activated?: boolean;
    country_code?: string | null;
    email?: string;
    first_name?: string;
    full_phone_number?: string;
    last_name?: string;
    phone_number?: string | null;
    type?: string;
    created_at?: string;
    updated_at?: string;
  };
}

export interface ShoppingCartOrder {
  id: string | number;
  type: string;
  attributes: {
    status?: string;
    total_fees?: number;
    total_items?: number;
    total_tax?: number;
    customer?: {
      data?: ShoppingCartOrderCustomer | null;
    };
    address?: {
      data?:
        | (object & {
            attributes?: object & {
              name?: string;
            };
          })
        | null;
    };
    order_items?: {
      data?: ShoppingCartOrderItem[] | null;
    };
  };
}

type categoryAttributes = {
  name: string;
  id: string;
  category_logo: string;
  bg_color: string;
  text_color: string;
};
export type Category = {
  attributes: categoryAttributes;
};
export type CartItem = {
  id: number | string;
  attributes: {
    id: string;
    details: {
      warranty_name: string;
      item_name: string;
      item_invoice_id: string;
      category_bg_color: string;
      category_text_color: string;
      item_id: string;
      warranty_type: string;
      category_id: string;
    };
    total_price: number;
    quantity: number;
  };
};
// Customizable Area End

interface S {
  // Customizable Area Start
  token: string;
  orderList: ShoppingCartOrder[];
  cartData: Array<CartItem>;
  showLoader: boolean;
  categoryArr: Category[];
  selectedCountry: {
    id: string;
    attributes:{
      tax_details: {
        type: string,
        value: number
      }
    },
  };
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ShoppingCartOrdersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrdersApiCallId?: string;
  cartCallId: string = "";
  updateCartApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      orderList: [],
      token: "",
      cartData: [],
      showLoader: false,
      categoryArr: [],
      selectedCountry: {
        id: "",
        attributes: {
          tax_details: {
            type:configJSON.TRANSACTION_FEES,
            value:0
          }
        }
      }
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const token = await getStorageData("token", false);
    const selectedCountry = await getStorageData('selectedCountry', true);
    this.setState({selectedCountry})
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    if (token) {
      this.getCartData();
    } else {
      const cartItemList = await getStorageData("storedCart", true);
      this.setState({ cartData: cartItemList || [] });
    }
    const categoryArr = await getStorageData("category", true);
    this.setState({ categoryArr });
    // Customizable Area End
  }

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
      if (!this.isPlatformWeb()) {
        this.getOrders(token);
      }
    }
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getOrdersApiCallId != null &&
      this.getOrdersApiCallId === apiRequestCallId
    ) {
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ orderList: responseJson.data });
      } else {
        this.showAlert("Alert", "No Data", "");
        this.setState({ orderList: [] });

        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if (responseJson != null && !responseJson.errors) {
      if (
        apiRequestCallId === this.cartCallId ||
        apiRequestCallId === this.updateCartApiCallId
      ) {
        const cartList = responseJson?.data?.attributes?.order_items || [];
        this.setState({
          cartData: cartList,
        });
      }
    }
    this.setState({ showLoader: false });
    // Customizable Area End
  };

  // Customizable Area Start
  getToken = () => {
    const tokenMessage: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMessage);
  };

  getOrders = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const params = { filter_by: "scheduled" };

    this.getOrdersApiCallId = requestMessage.messageId;
    let urlParams = new URLSearchParams(params).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrdersApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCartData = async () => {
    const token = await getStorageData("token", false);
    this.setState({ showLoader: true });
    this.cartCallId = callApi(
      {
        contentType: configJSON.apiContentType,
        method: configJSON.getApiMethod,
        endPoint:
          configJSON.orderDetailsEndPoint + `?language_code=${iLanguage}&country_id=${this.state.selectedCountry.id}`,
        headers: {
          token: token,
        },
      },
      runEngine
    );
  };

  handleAddMoreClick = () => {
    MixpanelTracking.getInstance().eventTrack("Click on add more button", null);
    this.send(getNavigationMessage("AddShoppingCartOrderItem", this.props));
  };

  handleProceed = () => {
    this.send(getNavigationMessage("Ordermanagement", this.props));
  };

  updateCartData = (data: CartItem[]) => {
    this.setState({ cartData: data || [] });
  };

  onCategoryClick = (category: categoryAttributes) => {
    const navigationMessage = getNavigationMessage("Category", this.props);
    navigationMessage.addData(
      getName(MessageEnum.NavigationScreenNameMessage),
      category.id
    );
    this.send(navigationMessage);
  };
  updateInputQuantity = (_index: number, _quantity: number) => {
    const itemData: CartItem[] = [...this.state.cartData];
    itemData[_index].attributes.quantity = _quantity;
    this.updateCartQuantityData(itemData, _index);
  };
  updateCartQuantityData = (itemData: CartItem[], _index: number) => {
    this.setState({ cartData: itemData }, () => {
      this.updateItemQuantity(
        itemData[_index].id,
        itemData[_index].attributes.quantity
      );
    });
  };
  updateItemQuantity = async (
    _orderItemId: string | number,
    _quantity: number
  ) => {
    const token = await getStorageData("token", false);
    if (token) {
      this.updateQuantityOnServer(_orderItemId, _quantity);
    } else {
      let storedCart = await getStorageData("storedCart", true);
      let matchingItemIndex = storedCart.findIndex(
        (cartItem: { id: string | number }) => cartItem.id === _orderItemId
      );
      if (matchingItemIndex !== -1) {
        const totalPrice: number =
          storedCart[matchingItemIndex].attributes.unit_price * _quantity;
        const totalDiscount: number =
          storedCart[matchingItemIndex].attributes.unit_discount * _quantity;
        const subTotal: number =
          storedCart[matchingItemIndex].attributes.unit_subTotal * _quantity;
        storedCart[matchingItemIndex].attributes.quantity = _quantity;
        storedCart[matchingItemIndex].attributes.total_price =
          totalPrice.toFixed(2);
        storedCart[matchingItemIndex].attributes.discount =
          totalDiscount.toFixed(2);
        storedCart[matchingItemIndex].attributes.subTotal = subTotal.toFixed(2);
      }
      await setStorageData("storedCart", JSON.stringify(storedCart));
      this.setState({
        cartData: storedCart,
      });
    }
  };

  updateQuantityOnServer = async (
    orderItemId: string | number,
    _quantity: number
  ) => {
    const token = await getStorageData("token", false);
    this.setState({ showLoader: true });
    this.updateCartApiCallId = callApi(
      {
        contentType: configJSON.apiContentType,
        method: configJSON.putApiMethod,
        endPoint: `${configJSON.orderItemsEndPoint}/${orderItemId}`,
        headers: {
          token,
        },
        body: JSON.stringify({ quantity: _quantity }),
      },
      runEngine
    );
  };
  // Customizable Area End
}
