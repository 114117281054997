import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { callApi, emailReg, getNavigationMessage, englishArabicCharacterRegex, nameReg, sendCartItemListChanges } from "../../../components/src/Toolkit";

// Customizable Area Start
import { iLanguage, t } from "../../../components/src/i18n";
import {
  getStorageData,
  setStorageData
} from "../../../framework/src/Utilities";
import { MixpanelTracking } from "../../MixpanelIntegration/src/MixpanelIntegration.web";
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showLoader:boolean;
  activeTab:number;
  categoryArr: any;
  token:any;
  isHovered: any;
  orderDetails: any;
  orderPriceDetails: any;
  TotalPrice: any;
  subTotalPrice:any;
  discountPrice: any;
  TotalAmountPrice: any;
  total_tax: any;
  currency_symbol: string;
  gateway_type: string;
  selectedCountry: {
    id: string;
    attributes:{
      tax_details: {
        type: string,
        value: number
      }
    },
  };
  countriesData: Array<{
    attributes: {
      id: string;
      logo: string;
      country_code: string;
      country_name: string;
    };
  }>;
  openRemoveItemModal:boolean;
  name: string;
  customerNameError: boolean;
  customerNameErrorText: string;
  email: string;
  customerEmailError: boolean;
  customerEmailErrorText: string;
  phoneNumber: string;
  phoneNumberError: boolean;
  phoneNumberErrorText: string;
  selectedCountryCode: string;
  addressLine1: string;
  addressLine1Error: boolean;
  addressLine1ErrorText: string;
  addressLine2: string;
  addressLine2Error: boolean;
  addressLine2ErrorText: string;
  country: string;
  countryError: boolean;
  countryErrorText: string;
  state: string;
  stateError: boolean;
  stateErrorText: string;
  city: string;
  cityError: boolean;
  cityErrorText: string;
  zip: string;
  zipError: boolean;
  zipErrorText: string;
  shippingAddressLine1: string;
  shippingAddressLine1Error: boolean;
  shippingAddressLine1ErrorText: string;
  shippingAddressLine2: string;
  shippingAddressLine2Error: boolean;
  shippingAddressLine2ErrorText: string;
  shippingCountry: string;
  shippingCountryError: boolean;
  shippingCountryErrorText: string;
  shippingState: string;
  shippingStateError: boolean;
  shippingStateErrorText: string;
  shippingCity: string;
  shippingCityError: boolean;
  shippingCityErrorText: string;
  shippingZip: string;
  shippingZipError: boolean;
  shippingZipErrorText: string;
  sameShippingAddress: boolean;
  order_id:string;
  currency: string;
  payment_id: string | null;
  surveyForm: boolean;
  surveyQuestion:any;
  paymentFailed: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OrderDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrdersAPICallId: any;
  getItemDetailAPICallId: any;
  cancelOrderAPICallId: any;
  rateOrderAPICallId: any;
  apiGetCountries: string = "";
  addCustomerInformationAPICallId: string = "";
  apiGetUserInformation: string = "";
  orderPaymentApiCallId: string = "";
  orderStatusApiCallId: string = "";
  surveyQuestionsApiCallId: string = "";
  deleteCartCallId:string = "";
  updateCartApiCallId:string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      showLoader:false,
      activeTab:1,
      categoryArr:[],
      token:"",
      isHovered: "",
      orderDetails: [],
      orderPriceDetails: {},
      TotalPrice: 0,
      subTotalPrice: 0,
      discountPrice: 0,
      TotalAmountPrice: 0,
      total_tax: 0,
      selectedCountry: {
        id: "",
        attributes: {
          tax_details: {
            type:configJSON.TRANSACTION_FEES,
            value:0
          }
        }
      },
      openRemoveItemModal:false,
      currency_symbol: "",
      gateway_type: "",
      countriesData: [],
      name: "",
      customerNameError: false,
      customerNameErrorText: "",
      email: "",
      customerEmailError: false,
      customerEmailErrorText: "",
      phoneNumber: "",
      phoneNumberError: false,
      phoneNumberErrorText: "",
      selectedCountryCode: "",
      addressLine1: "",
      addressLine1Error: false,
      addressLine1ErrorText: "",
      addressLine2: "",
      addressLine2Error: false,
      addressLine2ErrorText: "",
      country: "",
      countryError: false,
      countryErrorText: "",
      state: "",
      stateError: false,
      stateErrorText: "",
      city: "",
      cityError: false,
      cityErrorText: "",
      zip: "",
      zipError: false,
      zipErrorText: "",
      shippingAddressLine1: "",
      shippingAddressLine1Error: false,
      shippingAddressLine1ErrorText: "",
      shippingAddressLine2: "",
      shippingAddressLine2Error: false,
      shippingAddressLine2ErrorText: "",
      shippingCountry: "",
      shippingState: "",
      shippingCity: "",
      shippingZip: "",
      shippingCountryError: false,
      shippingCountryErrorText: "",
      shippingStateError: false,
      shippingStateErrorText: "",
      shippingCityError: false,
      shippingCityErrorText: "",
      shippingZipError: false,
      shippingZipErrorText: "",
      sameShippingAddress: false,
      order_id:'',
      currency: 'USD',
      payment_id: '',
      surveyForm: false,
      surveyQuestion: {},
      paymentFailed: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("API Message Recived", JSON.stringify(message))
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        if(!responseJson.errors){
        switch(apiRequestCallId){
          case this.getOrdersAPICallId: {
            this.handleOrderDetailsInformation(responseJson);
            break;
          }
          case this.deleteCartCallId:
          case this.updateCartApiCallId: {
            this.getOrderDetails();
            break;
          }
          case this.apiGetCountries: {
            this.handleGetCountriesData(responseJson)
            break;
          }
          case this.addCustomerInformationAPICallId : {
            this.paymentGetwayHandler()
            break;
          }
          case this.apiGetUserInformation: {
            this.handleGetUserInformation(responseJson)
            break;
          }
          case this.orderPaymentApiCallId: {
            this.handlePaymentApiResponse(responseJson)
            break;
          }
          case this.orderStatusApiCallId: {
            this.handleOrderStatusApiResponse(responseJson)
            break;
          }
          case this.surveyQuestionsApiCallId: {
            this.handleSurveyApiResponse(responseJson)
            break;
          }
           default:{
            this.setState({showLoader:false})
           }
          }
        }
        this.parseApiError(apiRequestCallId, responseJson, errorReponse)
        }

      // Customizable Area End
    }
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    if(window.location.pathname.includes('orderconfirmation')){
      const searchParams = new URLSearchParams(window.location.search);
      const paymentId = searchParams.get('paymentId');
      this.setState({showLoader: false,payment_id: paymentId,activeTab:4,});
      if (!paymentId) {
        this.handleOrderStatus();
      }
      this.handleGetSurveyQuestion();
      const mixpanelOrderDetails = await getStorageData('mixpanelOrderDetails', true)
      MixpanelTracking.getInstance().eventTrack(configJSON.warrantyPurchaseCompleted, { $orderDetails: mixpanelOrderDetails, $language: iLanguage })
    }else if( window.location.pathname.includes("/paymentfailed")){
      const searchParams = new URLSearchParams(window.location.search);
      const paymentId = searchParams.get('paymentId');
      this.setState({showLoader: false,payment_id: paymentId, paymentFailed: true, activeTab:4,})
    }else{
      const categoryArr = await getStorageData('category', true);
      const selectedCountry = await getStorageData('selectedCountry', true);
      const token = await getStorageData('token');
      this.setState({
        categoryArr,
        token,
        selectedCountry,
        currency_symbol: selectedCountry.attributes.currency_symbol,
        currency: selectedCountry.attributes.currency,
        gateway_type: selectedCountry.attributes.gateway_type
      });
        this.getCountriesData();
      if (token) {
          this.getUserInformation();
          this.loginUserCartDataHandle();
      } else {
        this.guestUserDataHandle();
      }
    }
  }

    parseApiError = (apiRequestCallId : any ,responseJson : any, errorReponse : any) => {
      if(apiRequestCallId === this.addCustomerInformationAPICallId) {
        this.handleCustomerInformationError(responseJson)
      }
      else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

  handleCustomerInformationError = (responseJson : any) => {
    let errors = responseJson.errors;
    let keys: any = Object.keys(errors);
    let values: any = Object.values(errors);

    this.setState({
          customerEmailError: keys[0] === "email",
          customerEmailErrorText: keys[0] === "email" ? values[0][0] : "",
          phoneNumberError: keys[0] === "phone_number",
          phoneNumberErrorText: keys[0] === "phone_number" ? values[0][0] : ""
        })
  }

  handleOrderDetailsInformation = (responseJson: any) => {
    if (!responseJson.errors) {
      if(responseJson.data?.attributes.order_items?.length > 0){
        this.setState(prevState => ({
          order_id: responseJson.data?.attributes?.id,
          orderDetails: responseJson.data?.attributes.order_items,
          orderPriceDetails: responseJson.data,
          TotalPrice: responseJson.data?.attributes.total,
          subTotalPrice: responseJson.data?.attributes.sub_total,
          discountPrice: responseJson.data?.attributes.applied_discount,
          total_tax: responseJson.data?.attributes.total_tax,
          showLoader: false,
          selectedCountry:{...prevState.selectedCountry, attributes:responseJson.data?.attributes}
        }));
        MixpanelTracking.getInstance().eventTrack(configJSON.cartItems,{ cart_items: responseJson.data?.attributes?.order_items})
      }else{
        this.send(getNavigationMessage("ShoppingCartOrders", this.props))
      }
    } else {
      window.alert(responseJson.errors);
      this.send(getNavigationMessage("Login", this.props));
    }
    this.closeRemoveItemModal();
  };

  handleGetCountriesData = async(responseJson: any) => {
    if (!responseJson.errors) {
      const selectedCountry = await getStorageData('selectedCountry', true);
      const countriesList = responseJson?.data || [];
      const currentCountry = responseJson?.data.find((x:any) => x.id === selectedCountry.id);
      this.setState({
        countriesData: countriesList,
        gateway_type: currentCountry?.attributes.gateway_type
      });
    }
  }

  handleGetUserInformation = (responseJson : any) =>{
    if (!responseJson.errors) {
      const userInformation = responseJson;
      this.setState({
        name: userInformation?.personal_information?.fullname || "",
        email: userInformation?.personal_information?.email_address || "",
        phoneNumber: userInformation?.personal_information?.phone_number || "",
        selectedCountryCode: userInformation?.personal_information?.country_id || "",
        addressLine1: userInformation?.address?.area || "",
        state:userInformation?.address?.state || "",
        country:userInformation?.address?.country || "",
        city:userInformation?.address?.city || ""
      });
    }
  }


  loginUserCartDataHandle = () => {
    this.getOrderDetails();
  };

  guestUserDataHandle = async () => {
    this.setState({ showLoader: true });
    const storedCart = await getStorageData("storedCart", true);
    this.setState({ orderDetails: storedCart });
    if (this.state.orderDetails && this.state.orderDetails.length > 0) {
      const { totalWithTax, totalTax } = this.totalAfterTaxCharge();
      this.setState({
        TotalPrice: totalWithTax.toFixed(2),
        subTotalPrice: this.subTotalPrice(),
        total_tax: totalTax.toFixed(2),
        discountPrice: this.totalDiscount(),
        showLoader: false,
      });
    }else{
      this.send(getNavigationMessage("ShoppingCartOrders", this.props))
    }
    this.setState({ showLoader: false });
  };

  shortWarrantyTitle(input: string) {
    let modifyString = input + " " + "Warranty";
    const words = modifyString.split(" ");
    const acronym = words.map((word) => word[0].toUpperCase()).join("");
    return acronym;
  }

  getOrderDetails = () => {
    this.setState({ showLoader: true });
    this.getOrdersAPICallId = callApi(
      {
        contentType: configJSON.ordersApiContentType,
        method: configJSON.httpGetMethod,
        endPoint:
          configJSON.orderDetailsEndPoint + `?language_code=${iLanguage}&country_id=${this.state.selectedCountry.id}`,
        headers: { token: localStorage.getItem("token") },
      },
      runEngine
    );
  };

  totalAfterTaxCharge = () => {
    const { selectedCountry } = this.state;
    const country = {
      tax_value: selectedCountry?.attributes?.tax_details.value || 10,
      default_tax_name: selectedCountry?.attributes?.tax_details.type,
    };
    const appliedTax = country.tax_value || 10;
    if (appliedTax) {
      const total = this.state.orderDetails.reduce(
        (sum: any, item: any) => sum + parseFloat(item.attributes.total_price),
        0
      );
      const taxCharge =
        country.default_tax_name == configJSON.TRANSACTION_FEES
          ? appliedTax
          : (total * appliedTax) / 100;
      const totalTax = taxCharge;
      const totalWithTax = total + totalTax;

      return { totalWithTax, totalTax };
    }

    return { totalWithTax: 0, totalTax: 0 };
  };

  subTotalPrice = () => {
    const totalItemsPrice = this.state.orderDetails.reduce(
      (sum: any, item: any) => sum + (parseFloat(item.attributes.subTotal)),
      0
    );
    return totalItemsPrice.toFixed(2);
  };

  totalDiscount = () => {
    const totalDiscountAmount = this.state.orderDetails.reduce(
      (sum: any, item: any) => sum + ((parseFloat(item.attributes.discount) / 100) * parseFloat(item.attributes.unit_subTotal)),
      0
    );
    return totalDiscountAmount.toFixed(2);
  };

  handleBackButton = () => {
    if(this.state.activeTab !== 1){
    this.setState({activeTab: this.state.activeTab - 1})
    }else{
      this.send(getNavigationMessage("ShoppingCartOrders", this.props))
    }
  }

  getCountriesData = () => {
    this.setState({ showLoader: true });
    this.apiGetCountries = callApi(
      {
        contentType: configJSON.ordersApiContentType,
        method: configJSON.httpGetMethod,
        endPoint: configJSON.getCountriesListEndPoint,
        headers: {},
      },
      runEngine
    );
  };

  postCustomerInformation = () =>{

    const { addressLine1, addressLine2, zip, state, country, city, 
      shippingAddressLine1, shippingAddressLine2, shippingCity, 
      shippingCountry, shippingState, shippingZip, sameShippingAddress, orderPriceDetails,
      name, email, phoneNumber, selectedCountryCode } = this.state;

    const countryCode = this.state.countriesData.find((country: any) => country.id == selectedCountryCode)?.attributes?.country_code || "";

    const differentShippingAddressBody = {
      order_id: orderPriceDetails?.id,
      customer_information:{
        name: name,
        email: email,
        phone_number: countryCode.slice(1) + phoneNumber,
      },
        billing: {
        address: addressLine1,
        address_line_2: addressLine2,
        zip_code: zip,
        state: state,
        country: country,
        city: city,
        address_for: "billing"
      },
      shipping: {
        address: shippingAddressLine1,
        address_line_2: shippingAddressLine2,
        zip_code: shippingZip,
        state: shippingState,
        country: shippingCountry,
        city: shippingCity,
        address_for: "shipping"
      }
  }

    const sameShippingAddressBody = {
        order_id: orderPriceDetails?.id,
        customer_information:{
          name: name,
          email: email,
          phone_number: countryCode.slice(1) + phoneNumber
        },
          billing: {
          address: addressLine1,
          address_line_2: addressLine2,
          zip_code: zip,
          state: state,
          country: country,
          city: city,
          address_for: "billing_and_shipping"
        },
    }
    MixpanelTracking.getInstance().eventTrack(configJSON.checkoutPaymentEvent, sameShippingAddress ? sameShippingAddressBody : differentShippingAddressBody)
    this.addCustomerInformationAPICallId = callApi(
      {
        contentType: configJSON.ordersApiContentType,
        method: configJSON.httpPostMethod,
        endPoint: configJSON.addCustomerInformationEndPoint,
        headers: { token: localStorage.getItem("token") },
        body: JSON.stringify(sameShippingAddress ? sameShippingAddressBody : differentShippingAddressBody),
      },
      runEngine
    );
  }

  getUserInformation = () => {
    this.setState({ showLoader: true });
    this.apiGetUserInformation = callApi(
      {
        contentType: configJSON.ordersApiContentType,
        method: configJSON.httpGetMethod,
        endPoint: configJSON.getUserInformationEndPoint,
        headers: { token: localStorage.getItem("token") },
      },
      runEngine
    );
  };

  handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.isValidCustomerName(event.target.value.trim());
    this.setState({ name: event.target.value });
  };
  handleNameBlur = () => {
    this.isValidCustomerName(this.state.name);
  };

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.isValidCustomerEmail(event.target.value.trim());
    this.setState({ email: event.target.value });
  };
  handleEmailBlur = () => {
    this.isValidCustomerEmail(this.state.email);
  };

  handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.isValidCustomerNumber(event.target.value.trim());
    this.setState({ phoneNumber: event.target.value });
  };

  handleCountryCodeChange = (event: React.ChangeEvent<{
    value: unknown;
}>) => {
    this.setState({
        selectedCountryCode: event.target.value as string,
    })
}

  handleAddressLine1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.isValidAddressLine1(event.target.value.trim());
    this.setState({ addressLine1: event.target.value });
  };
  handleAddressLine1Blur = () => {
    this.isValidAddressLine1(this.state.addressLine1);
  };

  handleAddressLine2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.isValidAddressLine2(event.target.value.trim());
    this.setState({ addressLine2: event.target.value });
  };

  handleAddressLine2Blur = () => {
    this.isValidAddressLine2(this.state.addressLine2);
  };

  handleCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.isValidCustomerCountry(event.target.value.trim());
    this.setState({ country: event.target.value });
  };

  handleCountryBlur = () => {
    this.isValidCustomerCountry(this.state.country);
  };

  handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.isValidState(event.target.value.trim());
    this.setState({ state: event.target.value });
  };

  handleStateBlur = () => {
    this.isValidState(this.state.state);
  };

  handleStateCityKeyPresssDown= (event:any) => {
      const pattern = /^[a-zA-Z0-9_ ]*$/;
      const char = event.key;
      if (!pattern.test(char)) {
        event.preventDefault();
      }
    }

  handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.isValidCity(event.target.value.trim());
    this.setState({ city: event.target.value });
  };

  handleCityBlur = () => {
    this.isValidCity(this.state.city);
  };

  handleZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.isValidZip(event.target.value.trim());
    this.setState({ zip: event.target.value });
  };

  handleZipBlur = () => {
    this.isValidZip(this.state.zip);
  };

  handleSameShippingAddressbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({
      sameShippingAddress: event.target.checked,
    });
  };

  isValidCustomerName = (value: string) => {
    let isValidName: boolean = true;
    if (value.length === 0) {
        this.setState({
            customerNameError: true,
            customerNameErrorText: t("signUp.nameIsRequired"),
        });
        isValidName = false;
    } else if ((iLanguage === 'en' && !nameReg.test(value)) || (iLanguage === 'ar' && !englishArabicCharacterRegex.test(value))) {
        this.setState({
            customerNameError: true,
            customerNameErrorText: t("signUp.invalidName"),
        });
        isValidName = false;
    } else {
        this.setState({
            customerNameError: false,
            customerNameErrorText: '',
        });
    }

    return isValidName;
}
isValidCustomerEmail = (email: string) => {
    let isValidEmail: boolean = true;
    if (email.length === 0) {
        this.setState({
            customerEmailError: true,
            customerEmailErrorText: t("signUp.emailRequired"),
        });
        isValidEmail = false;
    } else if (!emailReg.test(email)) {
        this.setState({
            customerEmailError: true,
            customerEmailErrorText: t("signUp.invalidEmail"),
        });
        isValidEmail = false;
    } else if (emailReg.test(email)) {
        this.setState({
            customerEmailError: false,
            customerEmailErrorText: '',
        });
    }

    return isValidEmail;
}

isValidCustomerNumber = (value: string) => {
  let isValid: boolean = true;
  if (value.length === 0) {
      this.setState({
          phoneNumberError: true,
          phoneNumberErrorText: t("signUp.phoneNumberRequired"),
      });
      isValid = false;
  } else {
      this.setState({
          phoneNumberError: false,
          phoneNumberErrorText: '',
      });
  }

  return isValid;
}

  isValidAddressLine2 = (value: string) => {
    let isValid: boolean = true;
    if (value.length === 0) {
      this.setState({
        addressLine2Error: true,
        addressLine2ErrorText: t("customerInformation.addressLine2Required"),
      });
      isValid = false;
    } else {
      this.setState({
        addressLine2Error: false,
        addressLine2ErrorText: "",
      });
    }

    return isValid;
  }

  isValidAddressLine1 = (value: string) => {
    let isValid: boolean = true;
    if (value.length === 0) {
      this.setState({
        addressLine1Error: true,
        addressLine1ErrorText: t("customerInformation.addressLine1Required"),
      });
      isValid = false;
    } else {
      this.setState({
        addressLine1Error: false,
        addressLine1ErrorText: "",
      });
    }

    return isValid;
  };

  isValidCustomerCountry = (value: string) => {
    let isValid: boolean = true;
    if (value.length === 0) {
      this.setState({
        countryError: true,
        countryErrorText: t("customerInformation.countryRequired"),
      });
      isValid = false;
    } else {
      this.setState({
        countryError: false,
        countryErrorText: "",
      });
    }
    return isValid;
  };

  isValidState = (value: string) => {
    let isValid: boolean = true;
    if (value.length === 0) {
      this.setState({
        stateError: true,
        stateErrorText: t("customerInformation.stateRequired"),
      });
      isValid = false;
    } else {
      this.setState({
        stateError: false,
        stateErrorText: "",
      });
    }
    return isValid;
  };

  isValidCity = (value: string) => {
    let isValid: boolean = true;
    if (value.length === 0) {
      this.setState({
        cityError: true,
        cityErrorText: t("customerInformation.cityRequired"),
      });
      isValid = false;
    } else {
      this.setState({
        cityError: false,
        cityErrorText: "",
      });
    }
    return isValid;
  };
  isValidZip = (value: string) => {
    let isValid: boolean = true;
    if (value.length === 0) {
      this.setState({
        zipError: true,
        zipErrorText: t("customerInformation.zipRequired"),
      });
      isValid = false;
    } else {
      this.setState({
        zipError: false,
        zipErrorText: "",
      });
    }
    return isValid;
  };

  isValidShippingAddressLine1 = (value: string) => {
    let isValid: boolean = true;
    if (value.length === 0) {
      this.setState({
        shippingAddressLine1Error: true,
        shippingAddressLine1ErrorText: t("customerInformation.addressLine1Required"),
      });
      isValid = false;
    } else {
      this.setState({
        shippingAddressLine1Error: false,
        shippingAddressLine1ErrorText: "",
      });
    }

    return isValid;
  };

  isValidShippingAddressLine2 = (value: string) => {
    let isValid: boolean = true;
    if (value.length === 0) {
      this.setState({
        shippingAddressLine2Error: true,
        shippingAddressLine2ErrorText: t("customerInformation.addressLine2Required"),
      });
      isValid = false;
    } else {
      this.setState({
        shippingAddressLine2Error: false,
        shippingAddressLine2ErrorText: "",
      });
    }

    return isValid;
  };

  isValidShippingCountry = (value: string) => {
    let isValid: boolean = true;
    if (value.length === 0) {
      this.setState({
        shippingCountryError: true,
        shippingCountryErrorText: t("customerInformation.countryRequired"),
      });
      isValid = false;
    } else {
      this.setState({
        shippingCountryError: false,
        shippingCountryErrorText: "",
      });
    }
    return isValid;
  };

  isValidShippingState = (value: string) => {
    let isValid: boolean = true;
    if (value.length === 0) {
      this.setState({
        shippingStateError: true,
        shippingStateErrorText: t("customerInformation.stateRequired"),
      });
      isValid = false;
    } else {
      this.setState({
        shippingStateError: false,
        shippingStateErrorText: "",
      });
    }
    return isValid;
  };

  isValidShippingCity = (value: string) => {
    let isValid: boolean = true;
    if (value.length === 0) {
      this.setState({
        shippingCityError: true,
        shippingCityErrorText: t("customerInformation.cityRequired"),
      });
      isValid = false;
    } else {
      this.setState({
        shippingCityError: false,
        shippingCityErrorText: "",
      });
    }
    return isValid;
  };
  isValidShippingZip = (value: string) => {
    let isValid: boolean = true;
    if (value.length === 0) {
      this.setState({
        shippingZipError: true,
        shippingZipErrorText: t("customerInformation.zipRequired"),
      });
      isValid = false;
    } else {
      this.setState({
        shippingZipError: false,
        shippingZipErrorText: "",
      });
    }
    return isValid;
  }
  handleOrderDetails = () => {
    const {token} = this.state;
    if(token){
       this.setState({activeTab: this.state.activeTab + 1})
    }else{
      setStorageData('comeToOrder',true)
      this.send(getNavigationMessage('Login', this.props));
    }
  };

  handleShippingAddressLine1Change = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.isValidShippingAddressLine1(event.target.value.trim());
    this.setState({ shippingAddressLine1: event.target.value });
  };
  handleShippingAddressLine1Blur = () => {
    this.isValidShippingAddressLine1(this.state.shippingAddressLine1);
  };

  handleShippingAddressLine2Change = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.isValidShippingAddressLine2(event.target.value.trim());
    this.setState({ shippingAddressLine2: event.target.value });
  };

  handleShippingAddressLine2Blur = () => {
    this.isValidShippingAddressLine2(this.state.shippingAddressLine2);
  };

  handleShippingCountryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.isValidShippingCountry(event.target.value.trim());
    this.setState({ shippingCountry: event.target.value });
  };

  handleShippingCountryBlur = () => {
    this.isValidShippingCountry(this.state.shippingCountry);
  };

  handleShippingStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.isValidShippingState(event.target.value.trim());
    this.setState({ shippingState: event.target.value });
  };

  handleShippingStateBlur = () => {
    this.isValidShippingState(this.state.shippingState);
  };

  handleShippingCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.isValidShippingCity(event.target.value.trim());
    this.setState({ shippingCity: event.target.value });
  };

  handleShippingCityBlur = () => {
    this.isValidShippingCity(this.state.shippingCity);
  };

  handleShippingZipChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.isValidShippingZip(event.target.value.trim());
    this.setState({ shippingZip: event.target.value });
  };

  handleShippingZipBlur = () => {
    this.isValidShippingZip(this.state.shippingZip);
  };

  isValidShippingAddress = () => {
    const {
      shippingAddressLine1,
      shippingAddressLine2,
      shippingCountry,
      shippingState,
      shippingCity,
      shippingZip,
    } = this.state;

    let isValid = true;
    if (!this.isValidShippingAddressLine1(shippingAddressLine1.trim())) {
      isValid = false;
    }
    if (!this.isValidShippingAddressLine2(shippingAddressLine2.trim())) {
      isValid = false;
    }
    if (!this.isValidShippingCountry(shippingCountry.trim())) {
      isValid = false;
    }
    if (!this.isValidShippingState(shippingState.trim())) {
      isValid = false;
    }
    if (!this.isValidShippingCity(shippingCity.trim())) {
      isValid = false;
    }
    if (!this.isValidShippingZip(shippingZip)) {
      isValid = false;
    }
    return isValid;
  }

  isValidRequest = () => {
    const {
      addressLine1,
      addressLine2,
      country,
      state,
      city,
      zip,
      sameShippingAddress,
      name,
      email,
      phoneNumber
    } = this.state;

    let isValid = true;
    if (!this.isValidCustomerName(name.trim())) {
      isValid = false;
    }
    if (!this.isValidCustomerEmail(email.trim())) {
      isValid = false;
    }
    if (!this.isValidCustomerNumber(phoneNumber)) {
      isValid = false;
    }
    if (!this.isValidAddressLine1(addressLine1.trim())) {
      isValid = false;
    }
    if (!this.isValidAddressLine2(addressLine2.trim())) {
      isValid = false;
    }
    if (!this.isValidCustomerCountry(country.trim())) {
      isValid = false;
    }
    if (!this.isValidState(state.trim())) {
      isValid = false;
    }
    if (!this.isValidCity(city.trim())) {
      isValid = false;
    }
    if (!this.isValidZip(zip)) {
      isValid = false;
    }
    if (!sameShippingAddress) {
     isValid = this.isValidShippingAddress()
    }
    return isValid;
  };

  handleSubmit = (event: any) => {
    event.preventDefault();
    if(this.isValidRequest())
    {
      this.postCustomerInformation()
    }
  };

  paymentGetwayHandler = () => {
    if(this.state.gateway_type === "my_fatoorah") {
      this.handleMyFaturaahPayment()
    } else {
      this.handlePayment()
    }
  }

  handlePayment = async() => {
    this.setState({showLoader : true});
     const httpBody = {
   	    "order_id": this.state.order_id,
        "return_url": window.location.origin + "/orderconfirmation",
        "amount": this.state.TotalPrice,
        "currency": this.state.currency,
    	}

    this.orderPaymentApiCallId = callApi({
      contentType: configJSON.ordersApiContentType,
      method: configJSON.httpPostMethod,
      endPoint: configJSON.paymentEndPoint,
      headers: {token: this.state.token},
      body: JSON.stringify(httpBody)
    },runEngine)
  }

  handleMyFaturaahPayment = async() => {
      this.setState({showLoader : true});
        const myFaturaahHttpBody = {
          "amount": this.state.TotalPrice,
          "currency": this.state.currency,
          "order_id": this.state.order_id,
          "callback_url": window.location.origin + "/orderconfirmation",
          "error_url": window.location.origin + "/paymentfailed"
        }
  
      this.orderPaymentApiCallId = callApi({
        contentType: configJSON.ordersApiContentType,
        method: configJSON.httpPostMethod,
        endPoint: configJSON.myFatoraahPaymentEndPoint,
        headers: {token: this.state.token},
        body: JSON.stringify(myFaturaahHttpBody)
      },runEngine)
  }

  handleOrderStatus = async() => {
    const order_id = await getStorageData('order_id',true)
    const token = await getStorageData('token');
    this.setState({showLoader: true})
    this.orderStatusApiCallId = callApi({
      contentType: configJSON.ordersApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.orderStatusEndPoint + `?order_id=${order_id}`,
      headers: {token:token},
    },runEngine)
  }

  handlePaymentApiResponse = (responseJson:any) => {
    if(!responseJson.error) {
      setStorageData('order_id', this.state.order_id)
      setStorageData('mixpanelOrderDetails',JSON.stringify(this.state.orderPriceDetails))
      window.location.href = responseJson.payment_link;
    }else{
      toast.error(responseJson.error);
      this.setState({showLoader: false})
      this.send(getNavigationMessage('Ordermanagement', this.props));
    }
  }

  handleOrderStatusApiResponse = async(responseJson: any) => {
    if(!responseJson.errors) {
      this.setState({payment_id:responseJson.payment_id, showLoader: false});
      const mixpanelOrderDetails = await getStorageData('mixpanelOrderDetails', true)
      MixpanelTracking.getInstance().eventTrack(configJSON.warrantyPurchaseCompleted, { $orderDetails: mixpanelOrderDetails, $language: iLanguage })
    }
  }

  handleGetSurveyQuestion = async() => {
    const token = await getStorageData('token');
    this.setState({showLoader: true})
    this.surveyQuestionsApiCallId = callApi({
      contentType: configJSON.ordersApiContentType,
      method: configJSON.httpGetMethod,
      endPoint: configJSON.surveyQuestionEndPoint + `?language_code=${iLanguage}`,
      headers: {token:token},
    },runEngine)
  }
  
  handleSurveyApiResponse = (responseJson: any) => {
    if(responseJson?.data) {
      setTimeout(() => {
        this.setState({surveyForm:true, surveyQuestion: responseJson?.data, showLoader: false})
      },[1000])
    }
  }
  handleSurveyFormCloseBtn = () => {
    this.setState({surveyForm: false})
  }
  handlePaymentFailedTryAgainBtn = () => {
    this.send(getNavigationMessage('Ordermanagement', this.props));
  }

  openRemoveItemModal = () => {
    this.setState({
      openRemoveItemModal:true
    })
  }

  updateInputQuantity = (_index: number, _quantity: number) => {
    this.updateItemQuantity(this.state.orderDetails[_index].id, _quantity);
  };

  closeRemoveItemModal = () => {
    this.setState({
      openRemoveItemModal:false
    })
  }

  updateItemQuantity = async (_orderItemId: string | number, _quantity: number ) => {
    if (this.state.token) {
      this.updateQuantityOnServer(_orderItemId, _quantity);
    } else {
      let storedCart = await getStorageData("storedCart", true);
      let searchIndex = storedCart.findIndex(
        (cartItem: { id: string | number }) => cartItem.id === _orderItemId
      );
      if (searchIndex !== -1) {
        const totalPrice: number =
          storedCart[searchIndex].attributes.unit_price * _quantity;
        const totalDiscount: number =
          storedCart[searchIndex].attributes.unit_discount * _quantity;
        const subTotal: number =
          storedCart[searchIndex].attributes.unit_subTotal * _quantity;
        storedCart[searchIndex].attributes.quantity = _quantity;
        storedCart[searchIndex].attributes.total_price =
          totalPrice.toFixed(2);
        storedCart[searchIndex].attributes.discount =
          totalDiscount.toFixed(2);
        storedCart[searchIndex].attributes.subTotal = subTotal.toFixed(2);
      }
      await setStorageData("storedCart", JSON.stringify(storedCart));
      await this.guestUserDataHandle();
    }
  };

  handleDeleteCartItem = async (id: string) => {
    if (this.state.token) {
      this.deleteCartItem(id);
    } else {
      this.deleteCartItemFromLocal(id);
    }
  };

  deleteCartItem = async (id:string) => {
    this.deleteCartCallId = callApi(
      {
        contentType: configJSON.ordersApiContentType,
        method: configJSON.httpDeleteMethod,
        endPoint: configJSON.orderItemsEndPoint + `/${id}`,
        headers: {
          token: this.state.token,
        },
      },
      runEngine
    );
  };

  updateQuantityOnServer = async (
    orderItemId: string | number,
    _quantity: number
  ) => {
    this.setState({ showLoader: true });
    this.updateCartApiCallId = callApi(
      {
        contentType: configJSON.ordersApiContentType,
        method: configJSON.httpPutMethod,
        endPoint: `${configJSON.orderItemsEndPoint}/${orderItemId}`,
        headers: {
          token:this.state.token
        },
        body: JSON.stringify({ quantity: _quantity }),
      },
      runEngine
    );
  };
  
  deleteCartItemFromLocal = async (id:string) => {
    const cartData = await getStorageData("storedCart", true);
    const updatedOrderItems = cartData.filter(
      (item: { attributes: { id: string } }) => item.attributes.id != id
    );
    await setStorageData("storedCart", JSON.stringify(updatedOrderItems));
    await this.guestUserDataHandle();
    this.closeRemoveItemModal();
    sendCartItemListChanges("UPDATED_LOCAL_CART");
  };
  // Customizable Area End
}
