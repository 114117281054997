Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.GET = "GET";
exports.PUT = "PUT";
exports.jsonApiContentType = "application/json";

exports.getCountriesListEndPoint = "bx_block_country/countries";
exports.carouselEndPoint = "bx_block_landingpage/show_banners";
exports.categoryEndPoint = "bx_block_landingpage/show_categories";
exports.storeListDetails= "bx_block_list/store_lists";
exports.getItemListDetails = "bx_block_item_list/item_lists";
exports.purchanseWarrantyPlan = "bx_block_warranty/warranties";
exports.infoEndPoint = "bx_block_landingpage/special_contents";
exports.aboutUsEndPoint = "bx_block_landingpage/about_us";
exports.warrantyPlanPurchaseEndPoint = "bx_block_order_management/orders"
exports.warrantyPlanEditEndPoint = "bx_block_order_management/order_items"
exports.refundPolicyEndPoint = "bx_block_landingpage/refund_policy";
exports.mixpanelGuestAddedWarranty = "Guest User Added to cart";
exports.mixpanelGuestCartItems = "Guest User Cart Items";
exports.mixpanelPageViewed = "Warranty Viewed"
// Customizable Area End