import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { callApi, getNavigationMessage } from "../../../components/src/Toolkit";
import { iLanguage } from "../../../components/src/i18n";
import { getStorageData } from "../../../framework/src/Utilities";
const configJSON = require("./config");
// Customizable Area End

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  refundPolicyData: {
      id: string | number,
      attributes: {
        description: string
      }
  };
  selectedCountry: {
    id: string | number;
    attributes: {
      address: string;
      country_name: string;
      email: string;
      phone_number: string;
      country_code: string;
    };
  };
  isLoading:boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class RefundPolicyController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetRefundPolicy: string = "";
  defaultPolicy = {
    id:"",
    attributes:{
      description:""
    }
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      refundPolicyData: this.defaultPolicy,
      selectedCountry: {
        id: "",
        attributes: {
          country_code: "",
          country_name: "",
          email: "",
          address: "",
          phone_number: ""
        }
      },
      isLoading:false
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const country = await getStorageData("selectedCountry", true);
    if (country) {
      this.setState({
        selectedCountry: country
      }, () => {
        this.getRefundPolicy();
      });
    } else {
      this.send(getNavigationMessage("Home", this.props));
    }
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    const apiMessage = getName(MessageEnum.RestAPIResponceMessage);
    if (message.id === apiMessage) {
      if (responseJson != null) {
        if (!responseJson.errors) {
          if (apiRequestCallId === this.apiGetRefundPolicy) {
            this.setState({
              refundPolicyData: responseJson.data || this.defaultPolicy
            });
          }
        } else {
          this.parseApiErrorResponse(responseJson);
        }
      }
      this.setState({
        isLoading:false
      })
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getRefundPolicy = () => {
    this.setState({
      isLoading:true
    })
    this.apiGetRefundPolicy = callApi({
      contentType: configJSON.jsonApiContentType,
      method: configJSON.GET,
      endPoint: `${configJSON.refundPolicyEndPoint}?language_code=${iLanguage}&country_id=${this.state.selectedCountry.id}`,
      headers: {}
    }, runEngine);
  };
  // Customizable Area End
}
