Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "MixpanelIntegration";
exports.labelBodyText = "MixpanelIntegration Body";

exports.btnExampleTitle = "CLICK ME";
exports.mixpanelCrediantial = "82b86a89f3f9bd65634e7909da97de6e";
exports.mixpanelPageView = "page view"
exports.pageViewed = "page_viewed";
exports.productViewed = "Warranty Viewed";
exports.AddedToCart = "Warranty Added to Cart";
exports.cartAdandoned = "Cart Abandoned";
exports.timeToSite = "Time on Site";
exports.mixpanelError = "Error: instance creation of MixpanelTracking is not allow. use MixpanelTracking._instance instead"
// Customizable Area End