import React from "react";
// Customizable Area Start
import { BlockComponent } from "../../framework/src/BlockComponent";
import { WithStyles } from "@material-ui/core";
import {
  IconButton
} from "@material-ui/core";
import { TextField, Paper, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import { DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; 
import { t ,iLanguage} from "./i18n";
import { handleKeyPressNumber } from "./Toolkit";
// Customizable Area End

// Customizable Area Start

export interface Props extends WithStyles {
  // Customizable Area Start
  handleWarrnatyPlan: any;
  handlePriceInput:any;
  itemList: any;
  storeList: any;
  WarrantyPlanForm: any;
  classes: any;
  errors: any;
  themeColor: any;
  handleWarrantyItem?:any;
  handleWarrantyStore?: any;
  store_name?:string;
  isOtherStoreSelect?:string;
  handleWarrantyOtherStoreName?: any;
  handleWarrantyPlanDate?:any;
  // Customizable Area End
}

interface S { }

interface SS {
  id: any;
}

// Customizable Area End

class WarrantyCategoryForm extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  render() {
    const { handleWarrnatyPlan, itemList,store_name,isOtherStoreSelect,handleWarrantyOtherStoreName,handleWarrantyPlanDate, storeList, WarrantyPlanForm, classes, errors, themeColor ,handlePriceInput,handleWarrantyItem,handleWarrantyStore} = this.props;
    return (
      <>
        {/* Customizable Area Start */}
        <Paper elevation={3} className={classes.formContainer}>
          <FormControl variant="outlined"
            className="InputField"
            fullWidth
            
            error={errors.itemError}
          >
            <InputLabel htmlFor="itemSelect">{t("warrantyForm.selectItem")}</InputLabel>
            <Select
              data-testId="itemSelect"
              variant="outlined"
              className="InputFieldSelect"
              labelId="itemSelect"
              name="item"
              value={WarrantyPlanForm.item}
              // onChange={handleWarrantyItem}
              label="Choose Your Store"
              IconComponent={(props: any) => (
                <IconButton
                  // style={{ position: 'absolute', right: 15 ,top: 8 }}
                  edge="end"
                  size="small"
                  aria-label="toggle menu"
                  aria-haspopup="true"
                  {...props}
                >
                  <ExpandMoreIcon style={{ color: themeColor?.attributes?.text_color ,width: 35 , height: 35}} />
                </IconButton>)}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
            >
              {itemList && itemList.length > 0 ? itemList.map((item: any,index:number) => <MenuItem data-testId={`itemSelect-${index}`} value={item.id} onClick={() => handleWarrantyItem(item)}>{item.attributes.name}</MenuItem>) :
              <MenuItem disabled>No Item is Available</MenuItem>
              }
            </Select>
            <FormHelperText>{errors.itemErrorText}</FormHelperText>
          </FormControl>
          <FormControl variant="outlined"
            className="InputField"
            fullWidth
            
            error={errors.storeError}
          >
            <InputLabel htmlFor="countrySelect">{t("warrantyForm.selectStore")}</InputLabel>
            <Select
              data-testId="storeSelect"
              variant="outlined"
              className="InputFieldSelect"
              labelId="countrySelect"
              name="store"
              value={WarrantyPlanForm.store}
              // onChange={handleWarrnatyPlan}
              label="Choose Your Store"
              IconComponent={(props: any) => (
                <IconButton
                  // style={{ position: 'absolute', right: 15,top: 8 }}
                  edge="end"
                  size="small"
                  aria-label="toggle menu"
                  aria-haspopup="true"
                  // onClick={() => this.setState({menuOpen:true})}
                  {...props}
                >
                  <ExpandMoreIcon style={{ color: themeColor?.attributes?.text_color ,width: 35 , height: 35}} />
                </IconButton>)}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null
              }}
            >
              {storeList && storeList.length > 0 ? storeList.map((item: any,index:number) => <MenuItem value={item.id} data-testId={`storeList-${index}`} onClick={() => handleWarrantyStore(item)}>{item.attributes.store_name}</MenuItem>): <MenuItem disabled>No Store is Available</MenuItem>}
            </Select>
            <FormHelperText>{errors.storeErrorText}</FormHelperText>
          </FormControl>
          {isOtherStoreSelect === "Other" && (
             <TextField
             data-testId="other_store_name"
             name="store_name"
             value={store_name}
             onChange={handleWarrantyOtherStoreName}
             type='text'
             label={t("warrantyForm.StoreNameText")}
             className={`InputField`}
             variant="outlined"
             fullWidth
             error={errors.store_nameError}
             helperText={errors.store_nameErrosText}
           />
          )}
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            data-testId="date_id"
            name="date"
            value={WarrantyPlanForm.date}
            onChange={handleWarrantyPlanDate}
            format="dd/MM/yyyy"
            inputVariant="outlined"
            variant="inline"
            autoOk
            disableFuture={true}
            PopoverProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              disablePortal: true,
            }}
            label={t("warrantyForm.purchaseDate")}
            className={`InputField InputDate ${iLanguage == 'ar' && "RTLPlaceholder RTLInput"}`}
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            InputProps={
              {
              endAdornment: (
                <IconButton
                  className={""}
                  style={{right: 12}}
                  edge="end"
                  size="small"
                  aria-label="toggle date picker"
                >
                  <DateRangeOutlinedIcon style={{ color: themeColor?.attributes?.text_color }} />
                </IconButton>
              )
            }}
            error={errors.dateError}
            helperText={errors.dateErrorText}
            lang={iLanguage}
            inputProps={
              {
              type:'date',
              value: WarrantyPlanForm.date
            }}
          />
      </MuiPickersUtilsProvider>
          <TextField
            data-test-id="invoice_number_id"
            className={`InputField`}
            name="invoiceNumber"
            value={WarrantyPlanForm.invoiceNumber}
            onChange={handleWarrnatyPlan}
            label={t('warrantyForm.invoiceNumber')}
            variant="outlined"
            fullWidth
            error={errors.invoiceNumberError}
            helperText={errors.invoiceNumberErrorText}
            InputProps={{
              classes:{
                input:`${iLanguage == 'ar' && "RTLPlaceholder RTLInput"}`,
                root:`${iLanguage == 'ar' && "RTLFieldSet"}`
            }
          }}
        
          />
          <TextField
            data-test-id="price_id"
            type='number'
            className={`InputField RTLOFF ${classes.priceInput}`}
            name="price"
            value={WarrantyPlanForm.price}
            onChange={handlePriceInput}
            onKeyPress={handleKeyPressNumber}
            inputProps={{
              pattern: "[0-9]*",
            }}
            label={t("warrantyForm.enterPrice")} 
            variant="outlined" fullWidth 
            error={errors.priceError}
            helperText={errors.priceError ? errors.priceErrorText : null}
            InputProps={{
              classes:{
                  input:`${iLanguage == 'ar' && "RTLPlaceholder RTLInput"}`,
                  root:`${iLanguage == 'ar' && "RTLFieldSet"}`
              }
          }}
          />
        </Paper>
        {/* Customizable End Start */}
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

export default WarrantyCategoryForm;

// Customizable Area End
