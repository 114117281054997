import React from "react";
// Customizable Area Start
import ForgotPasswordController, {
    Props
} from "./ForgotPasswordController.web";
import Spinner from "../../../components/src/Spinner.web";
import { Box, Grid, withStyles, Theme } from '@material-ui/core'
import { forgotPasswordLeftImage } from "./assets";
import ForgotPasswordOtpPanel from "./ForgotPasswordOtpPanel.web";
import { SendOtpPanel } from "./SendOtpPanel.web";
import { LeftImageCard } from "./Reusables.web";
import { t } from "../../../components/src/i18n";
import { mainContainer, flexEnd, InputField, flexCenter, rtlOffInputClasses, forgotPasswordSubmitGrid } from "../../../components/src/CommonStyles";
import SearchEngineOptimisationseo from "../../SearchEngineOptimisationseo/src/SearchEngineOptimisationseo.web";
// Customizable Area End

export class ForgotPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { showLoader, otpGenerated, otp, otpError, otpErrorText, isTimerRunning, seconds, minutes, email, emailError, emailErrorText } = this.state;
        const {classes} = this.props;
        return (
            <Box >
                <SearchEngineOptimisationseo title={t("forgotPassword.title")!} navigation={this.props.navigation} id={this.props.id} />
                <Spinner showLoader={showLoader} />
                <Grid container className={classes.FPMainContainer}>

                    <LeftImageCard image={forgotPasswordLeftImage} />

                    <Grid item xs={12} md={6} className={classes.FPRightGridItem}>
                        <Box className={classes.FPFormBox}>

                            {otpGenerated ? (
                                <ForgotPasswordOtpPanel
                                    title={t("forgotPassword.otpTitle")}
                                    subTitle={t("forgotPassword.otpSubTitle")}
                                    onSubmit={this.verifyOtp}
                                    otp={otp}
                                    otpError={otpError}
                                    otpErrorText={otpErrorText}
                                    onChangeOtp={this.onOtpChange}
                                    isTimerRunning={isTimerRunning}
                                    minutes={minutes}
                                    seconds={seconds}
                                    resendOtp={this.resendOtp}
                                />
                            ) :
                                <SendOtpPanel
                                    title={t("forgotPassword.title")}
                                    subTitle={t("forgotPassword.subTitle")}
                                    onSubmit={this.onSubmit}
                                    email={email}
                                    emailError={emailError}
                                    emailErrorText={emailErrorText}
                                    goToLogin={this.gotoLogin}
                                    handleEmailBlur={this.handleEmailBlur}
                                    handleEmailChange={this.handleEmailChange}
                                />
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
const forgotPasswordStyles = (theme:Theme) => ({
    FPMainContainer:{
        ...mainContainer,
        "& .FPLIGrid":{
            ...flexEnd,
            "& .FPLI": {
                height: "100%",
                width: "80%",
                objectFit: "contain",
                marginBottom: 48,
            }
        }
    },
    FPRightGridItem:{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        [theme.breakpoints.down('sm')]:{
            justifyContent: "center"
        }
    },
    FPFormBox:{
        display: "flex",
        justifyContent: "flex-start",
        padding: 24,
        "& .FPFormPaper": {
            width: "100%",
            maxWidth: "598px",
            padding: "48px",
            borderRadius: "20px",
            boxShadow: "0px 1px 10px #EBEBEB",
            marginTop: "48px",
            [theme.breakpoints.down('sm')]:{
                padding:24
            },
            "& .TitleContainer":{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                marginBottom: "16px",
                "& .FPTitle":{
                    fontFamily: "'Manrope', sans-serif",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "28px",
                    lineHeight: 1.7,
                    letterSpacing: 2,
                    color: "#515151"
                },
                "& .FPSubTitle":{
                    fontFamily: "'Manrope', sans-serif",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: 1.3,
                    color: "#01BEFE",
                    mixBlendMode: "normal",
                    opacity: 0.5,
                    letterSpacing: 1
                }
            },
            "& .InputField":InputField,
            ...rtlOffInputClasses,
            "& .FPSubmitBtnGrid":forgotPasswordSubmitGrid(theme),
            "& .FPGoBackBox":{
                ...flexCenter,
                marginTop: 32,
                "& .GobackText": {
                    fontFamily: "'Poppins', sans-serif",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "20px",
                    lineHeight: 1,
                    textAlign: "center",
                    color: "#000000",
                    whiteSpace: "nowrap"
                },
                "& .HelperButton": {
                    marginLeft: 4,
                    "& .ButtonText": {
                        fontFamily: "'Inter', sans-serif",
                        fontStyle: "normal",
                        fontWeight: 900,
                        fontSize: "18px",
                        textTransform: "initial" as const,
                        color: "#004D74",
                        whiteSpace: "nowrap",
                        "&.Mui-disabled":{
                            "& .ButtonText":{
                                color: "#747A80"
                            }   
                        }
                    }
                }
            },
            "& .FPErrorBox":{
                "& .FPErrorText": {
                    fontFamily: "'Inter', sans-serif",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: 1.5,
                    color: "red",
                    marginTop: 4,
                    textAlign: "end",
                    paddingRight: "12px"
                }
            },
            "& .OTP":{
                width: "100% !important",
                height: "82px",
                margin: "0.5rem",
                fontSize: "2rem",
                border: "1px solid #CFDBD5",
                borderRadius: "8px",
                [theme.breakpoints.down('xs')]:{
                    height: 48
                }
            },
            "& .ErrorOtp":{
                "& input":{
                    color:theme.palette.error.main
                }
            },
            "& .FPOTPTimerBox":{
                ...flexEnd,
                marginTop:16,
                "& .ResendOtpText":{
                    fontFamily: "'Inter', sans-serif",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: 1.2,
                    color: "#212121",
                    textTransform: "initial",
                    marginRight: "4px"
                },
                "& .FPOTPTimerText":{
                    fontFamily: "'Inter', sans-serif",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: 16,
                    lineHeight: 1.2,
                    color: "#004D74",
                    textTransform: "initial",
                    marginLeft: 4,
                    paddingRight: 12,
                }

            }
        }
    }

})
export default withStyles(forgotPasswordStyles)(ForgotPassword);
// Customizable Area End