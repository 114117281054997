import React from "react";
// Customizable Area Start
import { Typography, Box, WithStyles } from "@material-ui/core";
import { BlockComponent } from "../../framework/src/BlockComponent";
import { t } from './i18n';
import CategoryCard from "./CategoryCard.web";
import Slider from "react-slick";
// Customizable Area End

// Customizable Area Start

export interface Props extends WithStyles {
  // Customizable Area Start
  isHovered: string | null,
  handleMouseEvent: (itemId: string) => void,
  categoryArr: Array<{
    name: string,
    path: string,
    backgroundColor: string,
    logo: string,
    color: string,
  }>
  settings: any,
  // Customizable Area End
}

interface S { 
    // Customizable Area Start
    // Customizable Area End
}

interface SS {
  id: any;
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area End

class LandingPageCategorySection extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  render() {
    const { classes, isHovered, categoryArr, handleMouseEvent } = this.props;
    return (
      <>
        {/* Customizable Area Start */}
        <Box className={classes.categorySection}>
          <Typography variant='h2' style={{ textAlign: "center" }} className={classes.titleName}>{t("landingPage.CategoryTitle")}</Typography>
          <Box className={classes.CarouselContainer}>
            <Slider {...this.props.settings} dotsClass={`slick-dots ${classes.carouselDot}`} className={classes.carousel}>
              {categoryArr && categoryArr?.length > 0 && categoryArr.map((CardData: any, index: number) =>
                <CategoryCard
                  key={CardData.id}
                  CardData={CardData.attributes}
                  index={index}
                  isHovered={isHovered}
                  handleMouseEvent={handleMouseEvent}
                  classes={classes} />
              )}
            </Slider>
          </Box>
        </Box>

        {/* Customizable End Start */}
      </>
    );
  }
  // Customizable Area End
}

// Customizable Area Start

export default LandingPageCategorySection;

// Customizable Area End
