// Customizable Area Start
import React from 'react'
import { Hidden, Grid } from '@material-ui/core';
// Customizable Area End

// Customizable Area Start
export default function LeftImageGrid(props:{imgSrc:string}) {
    return (
        <Hidden smDown>
            <Grid item md={6} className="LeftImageGridL" >
                <img src={props.imgSrc} className="LeftImageL" />
            </Grid>
        </Hidden>
    )
}
// Customizable Area End
