import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Helmet } from "react-helmet"
// Customizable Area End

import SearchEngineOptimisationseoController, {
  Props,
  configJSON,
} from "./SearchEngineOptimisationseoController";

export default class SearchEngineOptimisationseo extends SearchEngineOptimisationseoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Helmet>
        <title>{this.props?.title}</title>
        {
          this.props.metaTags?.map((tag) => (
            <meta key={tag.name} name={tag.name} content={tag.content} />
          ))
        }

        {this.props.canonicalUrl && (<link rel="canonical" href={this.props.canonicalUrl} />)}
        {this.props.alternatUrls?.map(url => (
          <link key={url} rel="alternate" href={url} />
        ))}
      </Helmet>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End
