import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import {
  callApi,
  getNavigationMessage,
  hideTawkToWidget
} from "../../../components/src/Toolkit";
import {
  getStorageData,
  removeStorageData,
  setStorageData
} from "../../../framework/src/Utilities";
import { iLanguage, t } from "../../../components/src/i18n";
import { MixpanelTracking } from "../../MixpanelIntegration/src/MixpanelIntegration.web";

// Customizable Area End

const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    MainContainerLI: string;
    LoginFormBox: string;
    RememberBox: string;
    ForgotPasswodBtn: string;
  };
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showLoader: boolean;
  email: string;
  emailError: boolean;
  emailErrorText: string;
  passwordVisible: boolean;
  password: string;
  passwordError: boolean;
  passwordErrorText: string;
  remeberMeCheck: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class LoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  addToCartAPICallId: string = "";
  apiLogIn: string = "";
  apiLogInWithOtp: string = "";
  emailReg = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      showLoader: false,
      email: "",
      emailError: false,
      emailErrorText: "",
      passwordVisible: false,
      password: "",
      passwordError: false,
      passwordErrorText: "",
      remeberMeCheck: false
    };

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Hiding chat option
    hideTawkToWidget();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson != null) {
        if (!responseJson.errors) {
          if (apiRequestCallId === this.apiLogIn) {
            await setStorageData("token", responseJson.meta.token);
            await setStorageData(
              "token_expiry",
              responseJson.meta.expiration_time
            );
            await setStorageData("user_id", responseJson.meta.id);
            await setStorageData("user", JSON.stringify(responseJson.data));   
            MixpanelTracking.getInstance().page_views()
            MixpanelTracking.getInstance().userIdentify(responseJson.meta.id)
            MixpanelTracking.getInstance().eventTrack(configJSON.mixPanelUser, {
              full_name: responseJson.data?.attributes?.full_name,
              full_phone_number : responseJson.data?.attributes?.full_phone_number,
              cart_item_count : responseJson.data?.attributes?.cart_items_count,
              country_code: responseJson.data?.attributes?.country_code
            });
            MixpanelTracking.getInstance().peopleDetails({
              $name: responseJson.data?.attributes?.full_name,
              $email: responseJson.data?.attributes?.email,
              $language: iLanguage
            })
            // moving guest cart to users cart
            this.handleStoredCart(responseJson.meta.token);
          } else if (apiRequestCallId === this.apiLogInWithOtp) {
            await setStorageData(
              "email_otp_token",
              responseJson.meta.email_otp_token[0]
            );
            this.send(getNavigationMessage("VerifyLoginOtp", this.props));
          } else if(apiRequestCallId === this.addToCartAPICallId){
            await removeStorageData('storedCart');
            this.handleGobackNavigation();
          }
        } else {
          this.parseApiError(responseJson, apiRequestCallId);
        }
      }
      this.setState({ showLoader: false });
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  parseApiError = (apiResponse: any, apiRequestCallId: string) => {
    if (
      apiRequestCallId === this.apiLogIn ||
      apiRequestCallId === this.apiLogInWithOtp
    ) {
      this.parseLoginApiErrors(apiResponse);
    } else {
      this.parseApiErrorResponse(apiResponse);
    }
  };
  parseLoginApiErrors = (apiResponse: any) => {
    let errors = apiResponse.errors;
    let keys: any = Object.keys(errors[0]);
    let values: any = Object.values(errors[0]);
    if (
      keys[0] === "email" ||
      keys[0] === "failed_login" ||
      keys[0] === "account"
    ) {
      this.setState({
        emailError: true,
        emailErrorText: values[0]
      });
    }
  };
  handleStoredCart = async (token:string) => {
    const storedCart = await getStorageData("storedCart", true);
    const selectedCountry = await getStorageData('selectedCountry', true);
    const countryId = selectedCountry.id
    if(storedCart){
      this.AddToCartLocalCartData(storedCart, countryId, token)
    }else{
      this.handleGobackNavigation();
    }
  }
  handleGobackNavigation = async () => {
    const goBackToOrdermanagement = await getStorageData("comeToOrder", true);

    if (goBackToOrdermanagement) {
      this.send(getNavigationMessage('Ordermanagement', this.props))
      await removeStorageData("comeToOrder");
    } else {
      this.send(getNavigationMessage("Home", this.props));
    }
  };
  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trim();
    this.setState({ email: value });
    this.isValidEmail(value);
  };
  handleEmailBlur = () => {
    this.isValidEmail(this.state.email);
  };
  handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: event.target.value });
    this.isValidPassword(event.target.value);
  };
  togglePasswordVisibility = () => {
    this.setState({ passwordVisible: !this.state.passwordVisible });
  };
  handleRememberMe = () => {
    this.setState({
      remeberMeCheck: !this.state.remeberMeCheck
    });
  };
  onRegisterNowClick = () => {
    this.send(getNavigationMessage("SignUp", this.props));
  };

  isValidEmail = (email: string) => {
    let isValid: boolean = true;
    if (email.length === 0) {
      this.setState({
        emailError: true,
        emailErrorText: t("login.emailRequired")
      });
      isValid = false;
    } else if (!this.emailReg.test(email)) {
      this.setState({
        emailError: true,
        emailErrorText: t("login.invalidEmail")
      });
      isValid = false;
    } else if (this.emailReg.test(email)) {
      this.setState({
        emailError: false,
        emailErrorText: ""
      });
    }

    return isValid;
  };

  isValidPassword = (password: string) => {
    let isValid: boolean = true;
    const staticStrings = configJSON.staticStrings;
    if (password.length === 0) {
      this.setState({
        passwordError: true,
        passwordErrorText: t("login.passwordRequired")
      });
      isValid = false;
    } else if (password.length < 8) {
      this.setState({
        passwordError: true,
        passwordErrorText: t("login.password8Digit")
      });
      isValid = false;
    } else {
      this.setState({
        passwordError: false,
        passwordErrorText: staticStrings.emptyString
      });
    }

    return isValid;
  };

  isValid = (): boolean => {
    const { email, password } = this.state;

    let isValid = true;
    if (!this.isValidEmail(email)) {
      isValid = false;
    }
    if (!this.isValidPassword(password)) {
      isValid = false;
    }
    return isValid;
  };

  onForgotPasswordClick = () => {
    this.send(getNavigationMessage("ForgotPasswordWeb", this.props));
  };

  onSubmit = (event: any) => {
    event.preventDefault();
    if (this.isValid()) {
      this.setState({
        showLoader: true
      });
      const httpBody = {
        language_code: iLanguage,
        data: {
          attributes: {
            email: this.state.email,
            password: this.state.password,
            remember_me: this.state.remeberMeCheck
          },
          type: "email_account"
        }
      };
      this.apiLogIn = callApi(
        {
          contentType: configJSON.loginApiContentType,
          method: configJSON.POST,
          endPoint: configJSON.loginEndPoint,
          headers: {},
          body: JSON.stringify(httpBody)
        },
        runEngine
      );
    }
  };

  onClickLoginWithOtp = () => {
    this.send(getNavigationMessage("LoginWithOtp", this.props));
  };

  onSubmitOtpLogin = (event: any) => {
    event.preventDefault();
    if (this.isValidEmail(this.state.email)) {
      this.setState({
        showLoader: true
      });
      const httpBody = {
        language_code: iLanguage,
        data: {
          attributes: {
            email: this.state.email
          },
          type: "email_account"
        }
      };

      this.apiLogInWithOtp = callApi(
        {
          contentType: configJSON.loginApiContentType,
          method: configJSON.POST,
          endPoint: configJSON.loginOtpApiEndPoint,
          headers: {},
          body: JSON.stringify(httpBody)
        },
        runEngine
      );
    }
  };

  AddToCartLocalCartData = (storedCart: any, selectedCountryId:string, token:string) => {
    let newArrData = storedCart.map((item: any) => {
      return {
        item_list_warranty_id: item.attributes.item_list_warranty_id,
        old_unit_price: item.attributes.old_unit_price,
        item_invoice_id: item.attributes.details.item_invoice_id,
        purchase_date: item.attributes.purchase_date,
        store_list_id: item.attributes.store_list_id,
        quantity: item.attributes.quantity,
        ...(item.attributes?.store_name ? {store_name: item.attributes.store_name} : {})
      };
    });
    const httpParams = {
      country_id: selectedCountryId,
      data: newArrData,
    };

    this.setState({ showLoader: true });
    this.addToCartAPICallId = callApi(
      {
        contentType: configJSON.loginApiContentType,
        method: configJSON.loginAPiMethod,
        endPoint: configJSON.addToCartEndPoint,
        headers: { token },
        body: JSON.stringify(httpParams),
      },
      runEngine
    );
  };
  // Customizable Area End
}
