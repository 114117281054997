import React from "react";
// Customizable Area Start
import { withStyles, Theme, Grid, Box, Typography } from "@material-ui/core";
import HowItWorksController, {
    Props,
} from "./HowItWorksController.web";
import { flexCenter } from "../../../components/src/CommonStyles"
import { deviceBasedDynamicDimension } from "../../../components/src/Toolkit";
import Spinner from "../../../components/src/Spinner.web";
import { t } from "../../../components/src/i18n";
import GenericDescriptionWeb from "../../../components/src/GenericDescription.web";
import SearchEngineOptimisationseo from "../../SearchEngineOptimisationseo/src/SearchEngineOptimisationseo.web";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export class HowItWorks extends HowItWorksController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { content } = this.state;
        const meta = [{
            name: "keywords",
            content: t("seo.keywords")
          },
        {
            name:"description",
            content:content?.description
        }]
        return (
            <Box className={classes.mainContainer}>
                <SearchEngineOptimisationseo
                title={t("howItWorks.title")!}
                metaTags={meta}
                navigation={this.props.navigation} id={this.props.id} />
                <Spinner showLoader={this.state.isLoading} />
                <Grid container className={classes.titleContainer}>
                    <Grid xs={12} item className={`${classes.titleGrid}`}>
                        <Typography component={'h1'} className={classes.titleText} data-testId="title">{t("howItWorks.title")}</Typography>
                        <Box className={classes.descriptionBox}>
                            <GenericDescriptionWeb description={content?.description} />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container className={classes.pointContainer} spacing={5}>
                    {
                        content.details?.map((point: { image: string, title: string, description: string }, index: number) => {
                            return (
                                <React.Fragment  key={index}>
                                    <Grid item xs={12} md={4} className={classes.imageGrid}>
                                        <img src={point.image} alt="Importent" className="Image" />
                                    </Grid>
                                    <Grid item xs={12} md={8} className={classes.pointDescriptionGrid}>
                                        <Typography className={classes.pointTitle}>{point.title}</Typography>
                                        <GenericDescriptionWeb description={point.description} />
                                    </Grid>
                                </React.Fragment>
                            )
                        })
                    }
                </Grid>
            </Box>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export const howItWorksStyle = (theme: Theme) => ({
    titleContainer: {
        width:"100%",
        maxWidth: "1400px",
        padding:"0px 16px"
    },
    mainContainer:{
        display:'flex',
        flexDirection:'column' as const,
        alignItems:'center',
        minHeight:'60vh'
    },
    titleGrid: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center',
        marginTop: 32,
        marginBottom: 32,
    },
    descriptionBox: {
        ...flexCenter,
        marginTop: 64,
    },
    titleText: {
        color: "rgba(0, 77, 116, 1)",
        fontFamily: "'Manrope', sans-serif",
        fontStyle: "normal",
        fontWeight: "bold" as const,
        fontSize: deviceBasedDynamicDimension(48, true, 1),
        lineHeight: 1.5,
        letterSpacing: 1.5,
        textDecoration: 'underline' as const,
        textDecorationThickness: 2,
        textUnderlineOffset: 6,
        wordBreak: 'break-word' as const,
        hyphens: 'auto' as const,
        [theme.breakpoints.down('xs')]: {
            fontSize: deviceBasedDynamicDimension(32, true, 1),
        }
    },
    pointsGrid: {
        display: 'flex',
        flexDirection: 'column' as const,
    },
    pointContainer: {
        display: 'flex',
        alignItems: 'center',
        margin: '16px 0px',
        width:"100%",
        maxWidth: "1400px",
        marginBottom: 32,
        padding:"0px 16px",
        [theme.breakpoints.down('xs')]: {
            marginBottom: 64,
        }
    },
    imageGrid: {
        display: 'flex',
        justifyContent: 'center',
        maxHeight: deviceBasedDynamicDimension(400, true, 1),
        width: '100%',
        height: '100%',
        padding: deviceBasedDynamicDimension(12, true, 2),
        "& .Image": {
            width: '100%',
            objectFit: 'contain'
        }
    },
    pointDescriptionGrid: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        padding: deviceBasedDynamicDimension(12, true, 2),
        [theme.breakpoints.between('md', 'lg')]: {
            height: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            padding: deviceBasedDynamicDimension(4, true, 2),
        }
    },
    pointTitle: {
        color: "rgba(0, 77, 116, 1)",
        fontFamily: "'Manrope', sans-serif",
        fontStyle: "normal",
        fontWeight: "bold" as const,
        fontSize: deviceBasedDynamicDimension(28, true, 1),
        lineHeight: 1.5,
        letterSpacing: 1.5,
        wordBreak: 'break-word' as const,
        hyphens: 'auto' as const,
        [theme.breakpoints.down('xs')]: {
            fontSize: deviceBasedDynamicDimension(24, true, 1),
        }
    }
});
export default withStyles(howItWorksStyle)(HowItWorks);
// Customizable Area End
