import React from "react";
// Customizable Area Start
import {
  Box,
  Paper,
  Typography,
  Divider,
  Card,
  Grid,
} from "@material-ui/core";
import { readPlanDetailsIcon } from "./assets";
import { t } from "../../../components/src/i18n";
import OrderDetailsController, { Props } from "./OrderDetailsController.web";
import Spinner from "../../../components/src/Spinner.web";
import QuantityBox from "../../../components/src/Common/QuantityBox.web";
import RemoveItemModalWeb from "../../../components/src/Common/RemoveItemModal.web";
import { getTaxTitle } from "../../../components/src/Toolkit";
// Customizable Area End

class OrderDetailsView extends OrderDetailsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {
      showLoader,
      orderDetails,
      selectedCountry,
      TotalPrice,
      subTotalPrice,
      discountPrice,
      total_tax,
      currency_symbol,
    } = this.state;
    return (
      <>
        <Spinner showLoader={showLoader} />
            <Paper className={`${classes.orderDetailsMain}`}>
              <Box className={classes.orderDetailTitleContainer}>
                <Typography
                  className={classes.orderDetailTitle}
                  data-testId="orderDetails_title"
                >
                  {t("orderDetails.title")}
                </Typography>
              </Box>
              <Box className="orderDetailsScroll">
                <Grid className={classes.orderDetailsCardContainer}>
                  <Grid
                    container
                    item
                    xs={12}
                    md={9}
                    className={classes.orderCardContainer}
                  >
                    {orderDetails &&
                      orderDetails.length > 0 &&
                      orderDetails.map((item: any, index: number) => (
                          <Card
                            className={classes.cardContainerMain}
                            key={item.id}
                          >
                            <Box className={classes.cardContainer}>
                              <Box
                                className={classes.warrantyPlanimg}
                                style={{
                                  background:
                                    item.attributes.details.category_bg_color,
                                }}
                              >
                                <Typography
                                  className={classes.warrantyPlanTextlogo}
                                >
                                  {this.shortWarrantyTitle(
                                    item.attributes.details.warranty_type
                                  )}
                                </Typography>
                              </Box>
                              <Box
                                className={classes.warrantyPlanCardDiscription}
                              >
                                <Typography
                                  className={classes.warrantyPlancardText}
                                >
                                  {item.attributes.details.warranty_name}
                                </Typography>
                                <Typography
                                  className={classes.warrantyPlanInvoiceText}
                                  data-testId="orderDetails_name"
                                >
                                  {item.attributes.details.item_name}
                                </Typography>
                                <Typography
                                  className={classes.warrantyPlanInvoiceText}
                                >
                                  {t("shoppingCart.warrantyNo")} :
                                  {item.attributes.details.item_invoice_id}
                                </Typography>
                                <QuantityBox 
                                  itemIndex={index} 
                                  quantity={item.attributes.quantity} 
                                  updateQuantity={this.updateInputQuantity} 
                                  onDeleteItemClick={this.openRemoveItemModal}
                                />
                                <RemoveItemModalWeb 
                                itemId={item.id} 
                                removeItem={this.handleDeleteCartItem} 
                                isModalOpen={this.state.openRemoveItemModal} 
                                closeModal={this.closeRemoveItemModal} />
                                <Box className={classes.orderDatailBtnAction}>
                                  <div
                                    className={classes.warrantyPlanPriceText}
                                    dir="ltr"
                                  >
                                    <div className={classes.CurrencySymbol}>{currency_symbol}</div>
                                    <div>{Number(item.attributes.total_price).toFixed(2)}</div>
                                  </div>
                                  <a
                                    href={item.attributes.details.plan_details}
                                    className="readPlanDetailsContainer"
                                    target="_blank"
                                  >
                                    <div className="readPlanDetailsText">
                                      {t("orderDetails.readPlanDetailsText")}
                                    </div>
                                    <div>
                                      <img
                                        src={readPlanDetailsIcon}
                                        className={classes.iconReadPlanDetail}
                                        alt="readPlanDetailsIcon"
                                      />
                                    </div>
                                  </a>
                                </Box>
                              </Box>
                            </Box>
                          </Card>
                      ))}
                  </Grid>
                  <Grid
                    xs={12}
                    md={3}
                    className={classes.orderCardTotalContainer}
                  >
                    <Card className="cardTotalMain">
                      <Box>
                        <Typography className="priceDetailText">
                          {t("orderDetails.priceDetails")}(
                          {orderDetails?.length} {t("orderDetails.items")}):
                        </Typography>
                      </Box>
                      <Box className={classes.priceDetailsContainer}>
                        <Box className="cardCalculationRow">
                          <Typography className={classes.totalDiscriptionText}>
                            {t("orderDetails.total")}
                          </Typography>
                          <Typography
                            className={classes.totalDiscriptionAmountText}
                            dir="ltr"
                          >
                            <div className={classes.CurrencySymbol}>{currency_symbol}</div>
                            <div>{Number(subTotalPrice).toFixed(2)}</div>
                          </Typography>
                        </Box>
                        <Box className="cardCalculationRow">
                          <Typography className={classes.totalDiscriptionText}>
                            {t("orderDetails.discount")}
                          </Typography>
                          <Typography
                            className={classes.totalDiscriptionAmountText}
                            dir="ltr"
                          >
                            -&nbsp;<div className={classes.CurrencySymbol}>{currency_symbol}</div>
                            <div>{Number(discountPrice).toFixed(2)}</div>
                          </Typography>
                        </Box>
                        <Box className="cardCalculationRow">
                          <Typography className={classes.totalDiscriptionText}>
                            {
                              getTaxTitle(selectedCountry.attributes.tax_details.type, selectedCountry.attributes.tax_details.value)
                            }
                          </Typography>
                          <Typography
                            className={classes.totalDiscriptionAmountText}
                            dir="ltr"
                          >
                            <div className={classes.CurrencySymbol}>{currency_symbol}</div>
                            <div>{Number(total_tax).toFixed(2)}</div>
                          </Typography>
                        </Box>
                        <Divider />
                        <Box className="cardCalculationRow">
                          <Typography className={classes.totalAmountText}>
                            {t("orderDetails.totalAmount")}
                          </Typography>
                          <Typography className={classes.totalAmountPriceText} dir="ltr">
                            {" "}
                            <div className={classes.CurrencySymbol}>{currency_symbol}</div>
                            <div>{Number(TotalPrice).toFixed(2)}</div>
                          </Typography>
                        </Box>
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default OrderDetailsView;
export { OrderDetailsView }
// Customizable Area End
