import React from "react";
// Customizable Area Start
import ResetPasswordController, {
    Props
} from "./ResetPasswordController.web";
import Spinner from "../../../components/src/Spinner.web";
import { Box, Grid, TextField, Paper, Typography, Theme, withStyles } from '@material-ui/core'
import { forgotPasswordLeftImage } from "./assets";
import { LeftImageCard, MessageModal, PasswordErrorChecks, PasswordVisibility, SubmitButton, TitleContainer } from "./Reusables.web";
import { t } from "../../../components/src/i18n";
import { InputField, flexEnd, forgotPasswordSubmitGrid } from "../../../components/src/CommonStyles";
// Customizable Area End

export class ResetPassword extends ResetPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const { showLoader, firstPasswordVisible, firstPassword, secondPassword, secondPasswordVisible, apiError } = this.state;
        const { passwordUCTest, passwordLCTest, passwordNumberTest, min8LengthPassword, passwordEqual, allConditionSatified } = this.getTwoPasswordValidations();
        const noPasswordCondition = (firstPassword.length > 0 && secondPassword.length > 0) ? !allConditionSatified : false;
        const { classes } = this.props;
        return (
            <Box >
                <Spinner showLoader={showLoader} />
                <Grid container className={classes.RPMainContainer}>

                    <LeftImageCard image={forgotPasswordLeftImage} />

                    <Grid item xs={12} md={6} className={classes.RPRightGridItem}>
                        <Box className={classes.RPFormBox}>
                            <Paper elevation={2} className="RPFormPaper">
                                <TitleContainer title={t("forgotPassword.resetPasswordTitle")} subTitle={t("forgotPassword.resetPasswordSubTitle")} titleClass="RPTitle" subTitleClass="RPSubTitle" />
                                <Grid container >
                                    <Grid item xs={12}>
                                        <TextField
                                            data-testId="PasswordInputFirst"
                                            placeholder={t("common.newPasswordLabel")!}
                                            label={t("common.newPasswordLabel")}
                                            className="InputField"
                                            type={firstPasswordVisible ? "text" : "password"}
                                            variant="outlined"
                                            value={firstPassword}
                                            error={noPasswordCondition}
                                            onChange={this.handleFirstPasswordChange}
                                            InputProps={{
                                                endAdornment: (<PasswordVisibility toggleVisibility={this.toggleFirstPasswordVisibility} visibility={firstPasswordVisible} />),
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            data-testId="PasswordInputSecond"
                                            placeholder={t("common.repeatPasswordLabel")!}
                                            label={t("common.repeatPasswordLabel")}
                                            className="InputField"
                                            type={secondPasswordVisible ? "text" : "password"}
                                            variant="outlined"
                                            value={secondPassword}
                                            onChange={this.handleSecondPasswordChange}
                                            error={(firstPassword.length > 0 && secondPassword.length > 0) && !(passwordEqual && !noPasswordCondition)}
                                            InputProps={{
                                                endAdornment: (<PasswordVisibility toggleVisibility={this.toggleSecondPasswordVisibility} visibility={secondPasswordVisible} />),
                                            }}
                                        />
                                    </Grid>
                                    {apiError != '' &&
                                        <Grid item xs={12} className={classes.PasswordChecksRow}>
                                            <Box className="ApiErrorBox">
                                                <Typography className="ApiErrorMessage">{apiError}</Typography>
                                            </Box>
                                        </Grid>
                                    }
                                    {(firstPassword.length > 0 && secondPassword.length > 0) && (
                                        <>
                                            <Grid xs={12} sm={6} lg={4} item className={classes.PasswordChecksRow}>
                                                <PasswordErrorChecks condition={min8LengthPassword} message={t("forgotPassword.validations.eightCharMin")} />
                                                <PasswordErrorChecks condition={passwordNumberTest} message={t("forgotPassword.validations.oneNumber")} />
                                            </Grid>
                                            <Grid item xs={12} sm={6} lg={4} className={classes.PasswordChecksRow}>
                                                <PasswordErrorChecks condition={passwordUCTest} message={t("forgotPassword.validations.oneUppercase")} />
                                                <PasswordErrorChecks condition={passwordLCTest} message={t("forgotPassword.validations.oneLowercase")} />
                                            </Grid>
                                            {secondPassword.length > 0 && !passwordEqual &&
                                                <Grid item xs={12} sm={6} lg={4} className={classes.PasswordChecksRow}>
                                                    <Box className="PasswordChecksBox">
                                                        <Typography className="PasswordMessage InvalidPassword">{t("forgotPassword.validations.passwordNotMatched")}</Typography>
                                                    </Box>
                                                </Grid>
                                            }
                                        </>
                                    )}
                                    <Grid item xs={12}>
                                        <SubmitButton submitText={t("common.done")} onSubmit={this.resetPassword} />
                                    </Grid>
                                </Grid>
                            </Paper>

                        </Box>
                    </Grid>
                </Grid>
                <MessageModal
                    isOpen={this.state.openSuccessModal}
                    title={t("forgotPassword.resetPasswordSuccessTitle")}
                    subTitle={t("forgotPassword.resetPasswordSuccessSubTitle")}
                    onClickOk={this.goToHome}
                    okBtnText={t("forgotPassword.goToHome")}
                />
            </Box>
        );
        // Customizable Area End
    }
}


// Customizable Area Start
export const resetPasswordStyles = (theme: Theme) => ({
    RPMainContainer:{
        backgroundColor: '#FAFAFA',
        "& .FPLIGrid":{
            ...flexEnd,
            "& .FPLI": {
                height: "100%",
                width: "80%",
                objectFit: "contain",
                marginBottom: 48,
            }
        }
    },
    RPRightGridItem:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('md')]:{
            justifyContent: 'center'
        }
    },
    RPFormBox:{
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '24px',
        "& .RPFormPaper": {
            width: '100%',
            maxWidth: '698px',
            padding: '48px',
            borderRadius: '20px',
            boxShadow: '0px 1px 10px #EBEBEB',
            marginTop: '48px',
            [theme.breakpoints.down('md')]:{
                padding: 24
            },
            "& .TitleContainer":{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginBottom: '16px',
                "& .RPTitle":{
                    fontFamily: 'Manrope, sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '28px',
                    lineHeight: 1.7,
                    letterSpacing: 2,
                    color: '#515151',
                },
                "& .RPSubTitle":{
                    fontFamily: 'Manrope, sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontSize: '16px',
                    lineHeight: 1.3,
                    color: '#01BEFE',
                    mixBlendMode: 'normal',
                    opacity: 0.5,
                    letterSpacing: 1,
                }
            },
            "& .InputField": InputField,
            "& .MuiTableCell-root":{
                border:'none',
                padding: '0px',
                whiteSpace: 'nowrap',
            },
            "& .ApiErrorBox":{
                display: 'flex',
                alignItems: 'center',
                margin: '4px',
                width: '100%',
                justifyContent: 'flex-end',
                "& .ApiErrorMessage":{
                    fontFamily: 'Manrope,sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: 1.5,
                    color: '#FF2121',
                    textAlign: 'end' as const,
                }
            },
            "& .FPSubmitBtnGrid":forgotPasswordSubmitGrid(theme),
        }
    },
    PasswordChecksRow:{
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'flex-start',
        "& .PasswordChecksBox":{
            display: 'flex',
            alignItems: 'center',
            margin:4,
            "& .PasswordMessage": {
                fontFamily: 'Manrope, sans-serif',
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: 14,
                lineHeight: 1.2,
                color: '#008443',
                marginLeft: 12,
                "&.InvalidPassword":{
                    color:theme.palette.error.main
                },
            },
            "& .MuiAvatar-circular":{
                height: 24,
                width: 24,
            }
        },
    }
});
export default withStyles(resetPasswordStyles)(ResetPassword);
// Customizable Area End