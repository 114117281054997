import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { callApi, getNavigationMessage } from "../../../components/src/Toolkit";
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from 'react-toastify';

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  isOpen?: boolean;
  handleSurveyFormCloseBtn?: any;
  surveyQuestions?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  inputValues: any;
  surveySubmitted: boolean;
  validationError: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SurveysController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  surveyAnswerApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      inputValues: {},
      surveySubmitted: false,
      validationError: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if(!responseJson.errors){
          if(apiRequestCallId === this.surveyAnswerApiCallId) {
            this.setState({surveySubmitted: true})
            toast.success(responseJson?.message)
          }}}
    }
    
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  handleInputChanges = (questionId: any, value: any) => {
    if (value?.rating === null) {
      delete this.state.inputValues[questionId];
     return false;
    }

    this.setState((prevState) => ({
      inputValues: {
        ...prevState.inputValues,
        [questionId]: value, // Store input value with questionId as key
      },
    }), () => this.setState({ validationError: false }));
  };

  getAlphabeticalIndex = (index:number) => String.fromCharCode(65 + index);
  
  validateResponses = () => {
    const { inputValues } = this.state;
    // Check if all questions have been answered
    const unansweredQuestions = this.props.surveyQuestions.attributes?.questions.filter((question: any) => !inputValues[question.id]);
    return unansweredQuestions?.length === 0;
  }

  submitSurvey = async () => {
    // Prepare user responses in the given format
    if (!this.validateResponses()) {
      // Display validation error if not all questions have been answered
      this.setState({ validationError: true });
      return;
    }
    const { inputValues } = this.state;
    const formattedResponses = Object.keys(inputValues).map((questionId) => {
      const response = inputValues[questionId];
      if ('rating' in response) {
        // If the response contains a 'rating' key, it's a rating question
        return {
          question_id: parseInt(questionId),
          star: response.rating,
        };
      } else if ('content' in response) {
        // If the response contains a 'content' key, it's a text-based question
        return {
          question_id: parseInt(questionId),
          content: response.content,
        };
      } else if ('option_id' in response) {
        // If the response contains an 'option_id' key, it's a multiple-choice question
        return {
          question_id: parseInt(questionId),
          option_id: response.option_id,
        };
      }
      // Handle other types of questions if necessary
    });
    const token = await getStorageData('token');
    const account_id = await getStorageData('user_id');
    const order_id = await getStorageData('order_id');

    const httpBody = {
      "account_id": account_id,
      "order_id": order_id,
      "answers": formattedResponses
    }
    this.surveyAnswerApiCallId = callApi({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.surveyAnswerEndPoint,
      headers: {token:token},
      body : JSON.stringify(httpBody)
    },runEngine)
  };
  
  conditionalRendering = (condition:boolean,value1:any, value2: any) => {
    return condition ? value1 : value2
  }
  
  handleBackToHomeBtn = () => {
    this.send(getNavigationMessage("Home", this.props))
  }
  // Customizable Area End
}
